import React, { useRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
import Logo from "../assets/images/2024_TGS_logo_white.png";
import userIcon from "../assets/images/icons/userIcon.svg";
import useOutsideClick from "../components/useOutsideClick";
import config from "../configs/appSettings";
import arrowClose from "../assets/images/icons/arrow-close.svg";
/* eslint-disable */

const AppHeader = (props) => {
  const [loginState, setloginState] = useState(false);
  const logoutRef = useRef();
  const logoutIconRef = useRef();

  const logoutHandle = async () => {
    try {
      await props.auth.logout("/");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      props.history.push("/");
    }
  };

  const homelink = () => {
    window.location.reload();

    props.history.push("/");
  };

  useOutsideClick(logoutIconRef, () => {
    logoutRef.current.style.display = "none";
  });

  const helpGuideGeoThermal = (e) => {
    e.preventDefault();
    const url = `${config.endpoints.HelpGuideurl}`;
    window.open(url, "_blank");
  };

  const loginccsAtlas = (_e) => {
    //window.location.reload();
    //   document.cookie.split(";").forEach(function(c)
    //  { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    props.history.push("/map");
  };
  const logoutMenu = (_e) => {
    if (logoutRef.current.style.display === "none") {
      logoutRef.current.style.display = "block";
    } else {
      logoutRef.current.style.display = "none";
    }
  };

  const userInfo = async (auth) => {
    let authenticated = await props.auth.isAuthenticated();

    if (authenticated) {
      setloginState(authenticated);

      try {
        let userDetails = await props.auth.getUser();
        localStorage.setItem(`${config.users.email}`, userDetails.email);        
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const auth = props.auth;

    if (auth) {
      userInfo(auth);
    }
  }, [props ? props.auth : null]);

  useEffect(() => {
    // setloginState(localStorage.getItem(`${config.users.email}`));
  }, [loginState]);

  let logoPath = window.location.protocol + "//" + window.location.host + Logo;

  return (
    <header className="arlas-cs-header-section">
      <div className="logo-section">
          <a
            className="tgs-logo"
            style={{ cursor: "pointer" }}
            onClick={homelink}
          >
            <img src={logoPath} alt="TGS logo" />
          </a>
        
        <span className="arlas-cs-title">Carbon AXIOM</span>
        <div className="arlas-logout">
          {loginState !== null && loginState !== false ? (
            <div className="btn-group userIconGroup">
              <img
                className="userIcon"
                src={userIcon}
                alt="userIcon"
                onClick={logoutMenu}
                ref={logoutIconRef}
              />

              <div
                style={{ display: "none" }}
                className="dropdown-menu"
                ref={logoutRef}
              >
                <a
                  className="dropdown-item"
                  style={{
                    color: "white",
                  }}
                >
                  {localStorage.getItem(`${config.users.email}`)}
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  style={{
                    color: "white",
                  }}
                  href="mailto:CarbonSupport@tgs.com"
                >
                 CarbonSupport@tgs.com
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={logoutHandle}
                  id="logoutClickHandler"
                >
                  Logout
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default withRouter(withAuth(AppHeader));
