import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import { ccsFilterContext } from "../App";
import DotIcon from "../assets/images/three-dots-vertical.svg";
import config from "../configs/appSettings";
import GulfCoastLayers from "./GulfCoastLayers";
import SouthernLayers from "./SouthernLayers";
import { RadioLike } from './UtilityFunc';
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="threedots" />
    </a>
));

const RootLayers = ({ map, layerCollapsableChange, fnaddccsatlasLayerChange, roleAccess, genaccesToken }) => {
    const FilterDetails = useContext(ccsFilterContext);
    let filtervalues = FilterDetails.ccsFilterStore;
    let logoPath = window.location.protocol + "//" + window.location.host + DotIcon;
    const csvData = [
        ["firstname", "lastname", "email"],
        ["Ahmed", "Tomi", "ah@smthing.co.com"],
        ["Raed", "Labes", "rl@smthing.co.com"],
        ["Yezzi", "Min l3b", "ymin@cocococo.com"]
    ];

    const Offshore_Gulf_Coast_Expands = [filtervalues.expand_upper_miocene || filtervalues.expand_middle_miocene || filtervalues.expand_lower_miocene_shallow || filtervalues.expand_lower_miocene_deep || filtervalues.expand_well_control_points || filtervalues.expand_upper_miocene_fault || filtervalues.expand_middle_miocene_fault || filtervalues.expand_lower_miocene_shallow_fault || filtervalues.expand_upper_miocene_well || filtervalues.expand_middle_miocene_well || filtervalues.expand_lower_miocene_shallow_well || filtervalues.expand_lower_miocene_deep_well];

    const Southern_AR_MS_AL_Expands = [filtervalues.expand_Vicksburg || filtervalues.expand_Claiborne || filtervalues.expand_Up_Wilcox || filtervalues.expand_Lr_Wilcox || filtervalues.expand_taylor || filtervalues.expand_austin || filtervalues.expand_lr_tuscaloosa || filtervalues.expand_cretaceous || filtervalues.expand_paluxy || filtervalues.expand_trinity || filtervalues.expand_rodessa || filtervalues.expand_sligo || filtervalues.expand_Hosston_TravisPeak || filtervalues.expand_Cotton_Valley || filtervalues.expand_Lr_Cotton_Valley || filtervalues.expand_Bossier || filtervalues.expand_Haynesville || filtervalues.expand_Smackover || filtervalues.expand_Lr_Smackover || filtervalues.expand_Norphlet || filtervalues.expand_Eagle_Mills || filtervalues.expand_Midway || filtervalues.expand_Navarro || filtervalues.expand_Mid_Tuscaloosa || filtervalues.expand_Ferry_Lake || filtervalues.expand_Buckner || filtervalues.expand_Louann];

    const Southern_AR_MS_AL_WellControlPoints_Expands = [filtervalues.expand_drax_sa_well_control_points || filtervalues.expand_arkansasmiss_faults || filtervalues.expand_gcdiapirg_tx_southcentral]

    const Saline_Aquifer_Expands = [
        Offshore_Gulf_Coast_Expands[0] || Southern_AR_MS_AL_Expands[0] || Southern_AR_MS_AL_WellControlPoints_Expands[0]
    ];

    return (
        <ul
            className="datalist" id={'radiocb'} onClick={(e) => RadioLike(e, 'radiocb')}
        >
            {config.LayerMetaData.map(lyrObj => (
                <li>
                    <span onClick={(e) => layerCollapsableChange(e)}
                        className={`${Saline_Aquifer_Expands[0] ? 'collapseList layerActive' : 'collapseList'} `}>
                        {lyrObj.label}
                        {/* {(roleAccess.CA_GC_Offshore_SA != true) && <span className="rspan">Not Subscribed</span>} */}
                    </span>
                    <GulfCoastLayers
                        map={map}
                        fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
                        layerCollapsableChange={layerCollapsableChange}
                        roleAccess={roleAccess}
                        genaccesToken={genaccesToken}
                        Saline_Aquifer_Expands={Saline_Aquifer_Expands}
                        Offshore_Gulf_Coast_Expands={Offshore_Gulf_Coast_Expands}

                    ></GulfCoastLayers>
                    <SouthernLayers
                        map={map}
                        fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
                        layerCollapsableChange={layerCollapsableChange}
                        roleAccess={roleAccess}
                        genaccesToken={genaccesToken}
                        Saline_Aquifer_Expands={Saline_Aquifer_Expands}
                        Southern_AR_MS_AL_Expands={Southern_AR_MS_AL_Expands}
                        Southern_AR_MS_AL_WellControlPoints_Expands = {Southern_AR_MS_AL_WellControlPoints_Expands}
                    ></SouthernLayers>
                </li>
            ))}
        </ul>
    );

};

export default RootLayers;