import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core/";
import React from "react";

export default ({ open, handleClose, alertReceived }) => {
  return (
    <Dialog open={open} onClose={handleClose} alertReceived={alertReceived}>
      <DialogContent
        style={{ width: 300 }}
        className="d-flex justify-content-center"
      >
        <p>{alertReceived}</p>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center">
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
