import React from "react";
const Legend = ({legendData}) => {
    return (
        <div>
            <ul class="LegendList">
                {legendData && legendData.map((legend)=>(
                    <li><div style={{"background-color": legend.bgColor && legend.bgColor}}></div><div>{legend.label && legend.label}</div></li>
                ))}     
            </ul>
        </div>
    );
};

export default Legend;