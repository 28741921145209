import React, { useState, useEffect } from "react";
import XYZ from "ol/source/XYZ";
import config from "../configs/appSettings";
import { Tile as TileLayer, Group as LayerGroup } from "ol/layer";

const BaseMap = (props) => {
  const [changeBaseMap, setchangeBaseMap] = useState(false);
  const [selectedBaseMap, setSelectedBaseMap] = useState("null");
  const [showBaseMap, setshowBaseMap] = useState(false);

  const map = props.map;

  const handleSelectChange = (evt) => {
    setSelectedBaseMap(evt.currentTarget.id);
    setchangeBaseMap(!changeBaseMap);
  };

  // ==========changeBaseMapfunction  Start ==================
  const changeBaseMapfunction = (_evt) => {
    setshowBaseMap(!showBaseMap);
  };
  // ==========changeBaseMapfunction  End ==================

  useEffect(() => {
    if (map) {
      localStorage.setItem("InitialLoad", true); // used this key later to check if the application is under initial load or not
      if (selectedBaseMap !== "null") {
        var baseMap;
        if (selectedBaseMap === "OSM") {
          baseMap = new TileLayer({
            source: new XYZ({
              url: config.endpoints.OSMBaseMap,
            }),
            visible: true,
            title: "Streets",
          });
        } else if (selectedBaseMap === "LightGreyCanvas") {
          baseMap = new TileLayer({
            source: new XYZ({
              url: config.endpoints.LightGrayCanvasBaseMap,
            }),
            visible: true,
            title: "LightGreyCanvas",
          });
        } else if (selectedBaseMap === "ImageryLabel") {
          baseMap = new LayerGroup({
            layers: [
              new TileLayer({
                source: new XYZ({
                  url: config.endpoints.ImageryBaseMap,
                }),
                visible: true,
                title: "ImageryLabel",
              }),
              new TileLayer({
                source: new XYZ({
                  url: config.endpoints.LabelBaseMap,
                }),
              }),
            ],
          });
        }
        map.getLayers().setAt(0, baseMap);
      }
    }
  }, [changeBaseMap]);

    return (<ul className="ma-center-action">
    <div
      className={showBaseMap !== false ? "d-block" : "d-none"}
      style={{
        position: "absolute",
        backgroundColor: "white",
        width: "297px",
        height: "253px",
        zIndex: "1000",
        right: "6px",
        bottom: "65px",
      }}
    >
      <div dojoattachpoint="flowContainer">
        <div
          id="OSM"
          className="esriBasemapGalleryNode"
          onClick={handleSelectChange}
        >
          <a href="javascript:void(0);">
            <img
              className="esriBasemapGalleryThumbnail"
              src="https://js.arcgis.com/4.16/esri/images/basemap/topo-vector.jpg"
              title="OSM"
              alt="OSM"
            />
          </a>
          <div className="esriBasemapGalleryLabelContainer">
            <span alt="Light Gray Canvas" title="Light Gray Canvas">
              OSM
            </span>
          </div>
          </div>

          <div
            id="LightGreyCanvas"
            className="esriBasemapGalleryNode"
            onClick={handleSelectChange}
          >
            <a href="javascript:void(0);">
              <img
                className="esriBasemapGalleryThumbnail"
                src="https://js.arcgis.com/4.16/esri/images/basemap/gray-vector.jpg"
                title="LightGreyCanvas"
                alt="LightGreyCanvas"
              />
            </a>
            <div className="esriBasemapGalleryLabelContainer">
              <span alt="Topographic" title="Topographic">
                Light Grey Canvas
              </span>
            </div>
          </div>

          <div
            id="ImageryLabel"
            className="esriBasemapGalleryNode"
            onClick={handleSelectChange}
          >
            <a href="javascript:void(0);">
              <img
                className="esriBasemapGalleryThumbnail"
                src="https://js.arcgis.com/4.16/esri/images/basemap/hybrid.jpg"
                title="ImageryLabel"
                alt="Imagery with Labels"
              />
            </a>
            <div className="esriBasemapGalleryLabelContainer">
              <span alt="Imagery with Labels" title="Imagery with Labels">
                Imagery with Labels
              </span>
            </div>
          </div>
          <br style={{ clear: "both" }} />
        </div>
      </div>

      <li
        className="ma-default"
        onClick={(e) => changeBaseMapfunction(e)}
        style={{ fontFamily: "DINRegular" }}
      >
        <span className="esri-collapse__icon esri-icon-basemap ">
          <div>&nbsp;</div>
          <span className="ma-tooltip" style={{ fontFamily: "DINRegular" }}>
            Change Basemap
          </span>
        </span>
      </li>      
    </ul>
  );
};

export default BaseMap;
