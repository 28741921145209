// const getSiblings = function (elem) {
export function getSiblings(elem) {
	// Setup siblings array and get the first sibling
	var siblings = [];
	var sibling = elem.parentNode.firstChild;

	// Loop through each sibling and push to the array
	while (sibling) {
		if (sibling.nodeType === 1 && sibling !== elem) {
			siblings.push(sibling);
		}
		sibling = sibling.nextSibling
	}

	return siblings;

}


export function RadioLike(e,elem){
	//  e = e;
	var cb = e.srcElement || e.target;
	if (cb.type !== 'checkbox') {return true;}
	var cbxs = document.getElementById(elem)
				.getElementsByTagName('input'), 
		i    = cbxs.length;
	 while(i--) {
		 if (cbxs[i].type 
			  && cbxs[i].type == 'checkbox' 
			  && cbxs[i].id !== cb.id) {
		   cbxs[i].checked = false;
		 }
	 }
 }
 

