import React, { useEffect } from "react";
import "ol/ol.css";
import $ from 'jquery'
import EsriJSON from "ol/format/EsriJSON";
import VectorSource from "ol/source/Vector";
import { Vector as VectorLayer } from "ol/layer";
import { createXYZ } from "ol/tilegrid";
import { tile as tileStrategy } from "ol/loadingstrategy";
import axios from "axios";
import { Circle, Fill, Stroke, Style } from "ol/style";

const EIALayers = ({ map, fnaddccsatlasLayerChange, layer, zoomItemText }) => {
  const customStyles = layer.vectorStyle;
  let styleToSet;
  if (customStyles) {
    if (customStyles.image) {
      styleToSet = new Style({
        image: new Circle({
          radius: customStyles.image.radius,
          fill: new Fill({
            color: customStyles.image.fillColor,
          }),
          stroke: customStyles.image.stroke
            ? new Stroke({
                color: customStyles.image.stroke.color,
                width: customStyles.image.stroke.width,
              })
            : undefined,
        }),
      });
    }
    if (customStyles.stroke) {
      const { width, color, lineDash } = customStyles.stroke;
      styleToSet = new Style({
        stroke: new Stroke({
          color: color,
          width: width,
          lineDash: lineDash,
        }),
      });
    }
  }

  const loadData = () => {
    const esrijsonFormat = new EsriJSON();

    const featuresRetreived = [];
    const vectorSource = new VectorSource({
      loader: function (extent, resolution, projection) {
        let url
        url =  layer.groupName == "BOEM Pipelines" ? layer.serviceUrl:  
          layer.serviceUrl +
          "/query/?f=json&" +
          "returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=" +
          encodeURIComponent(
            '{"xmin":' +
              extent[0] +
              ',"ymin":' +
              extent[1] +
              ',"xmax":' +
              extent[2] +
              ',"ymax":' +
              extent[3] +
              ',"spatialReference":{"wkid":102100}}'
          ) +
          "&geometryType=esriGeometryEnvelope&inSR=102100&outFields=*" +
          "&outSR=102100";
          $.ajax({
            url: url,
            dataType: "jsonp",
            success: function (response) {
              if (response.error) {       
           
              } else { 
                 const features = esrijsonFormat.readFeatures(response, {
                      featureProjection: projection
                    });
                    if (features.length > 0) {
                      vectorSource.addFeatures(features);
                    }
            
              }
            }
            
          });
      },
      strategy: tileStrategy(
        createXYZ({
          tileSize: 512,
        })
      ),
    });

    const vector = new VectorLayer({
      minZoom: layer.zoomLevel-1,
      source: vectorSource,
      title: layer.lyrName,
      visible: JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName] ? JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName] : false,
      style: styleToSet,
    });
    vector.setOpacity(JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName+"_opacity_value"] != undefined ? (100-JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName+"_opacity_value"])/100 : 0.8);
    
    map.addLayer(vector);
  };



  const sendfeaturestoFunction = (featuresRetreived, vectorSource) => {
    var features = featuresRetreived.flat();
    vectorSource.addFeatures(features);
    const vector = new VectorLayer({
      source: vectorSource,
      title: layer.lyrName,
      visible: JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName] ? JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName] : false,
      style: styleToSet,
    });
    vector.setOpacity(JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName+"_opacity_value"] != undefined ? (100-JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName+"_opacity_value"])/100 : 0.8);
  };

  useEffect(() => {
    let layerPresent = false;
    map.getLayers().forEach((l) => {
      if (layer !== undefined) {
        if (l.get("title") === layer.lyrName) {
          layerPresent = true;
        }
      }
    });
    if (!layerPresent) loadData();
    
   
  }, []);

  return (
    <li className="icon-list">
      <label className={`arlas-checkbox icon-text ${zoomItemText < layer.zoomLevel && "disabled pointernone"}`}>
        {layer.displayName}
        <input
          type="checkbox"
          //checked = {JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName]}
          checked = {zoomItemText >= layer.zoomLevel ? JSON.parse(localStorage.getItem('DefaultValues'))[layer.lyrName] : false}
          name={layer.lyrName}
          id={layer.lyrName + "1"}
         onChange={(e) => fnaddccsatlasLayerChange(e, layer.lyrName)}
        />
        <span className="checkmark"></span>
      </label>
      <span className={layer.iconType} style={layer.groupName == "BOEM Pipelines" ? {borderBottomColor: layer.iconColor, borderStyle: "dashed", borderWidth: "0 0 4px"} : {background: layer.iconColor} }></span>
    </li>
  );
};

export default EIALayers;
