export default {
  name: "default",
  oidc: {
    clientId: "0oaak9utnd3Pv2iwH357",
    issuer: "https://login.tgsnopec.com/oauth2/aus2w3la8yUN7Nras357", // verify the issuer is correct
    redirectUri: "http://localhost:8000/implicit/callback",
    scope: [
      "openid",
      "profile",
      "email",
      "address",
      "phone",
      "offline_access",
      "CCS-Atlas-API-Scope",
    ],
    allowedGroups: [],
  },
  users: {
    email: "CCSAtlas-Prod-userEmail",
  },
  welPopupText: {
    title: "Welcome to Carbon AXIOM!",
    description:
      "Here you can rapidly evaluate CCS opportunities from capture to container.",
  },
  endpoints: {
    //TGS layers Url Start
    WellLocationCache:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Standard/WellLocationCacheMultiLayer/MapServer",
    WellLocationCacheProxy:
      "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    basinLayer:
      "https://gisprod01.tgsr360.com/arcgis/rest/services/R360Premium/TGS_Formations/MapServer",
    basinLayerProxy: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    SesimicSurvey:
      "https://map.tgs.com/arcgis/rest/services/web/Map_NoWells_Dynamic/MapServer",
    SesimicSurveyProxy: "https://gisprod01.tgsr360.com/arcgis-proxy/proxy.ashx",
    BTMPermianUrl:
      "https://map.tgs.com/arcgis/rest/services/web/selection/MapServer/5",
    //TGS Layers url End

    HelpGuideurl:
      "https://storymaps.arcgis.com/stories/e9d18fe0cd564fba90fc7a798c5c4746",
    secureLayerUrl:
      "https://mapdev.tgs.com/MapServiceProxy/geoserver/gc-ccs-atlas/wfs?f=json",
    newsecureLayerUrl:
      "https://map-preview.datalake.tgs.com/geoserver/gc-ccs-atlas/wfs?f=json",
    OpenLayerBaseMap:
      "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf",
    OSMBaseMap:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    LightGrayCanvasBaseMap:
      "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
    ImageryBaseMap:
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    LabelBaseMap:
      "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
    wfsLayerUrl:
      "https://map.datalake.tgs.com/MapServiceProxy/geoserver/nes_ccs_gc/wfs",
    wmsLayerUrl:
      "https://map.datalake.tgs.com/MapServiceProxy/geoserver/nes_ccs_gc/wms",
    queriesDbUrl:
      "https://carbon-axiom-service-api-gateway-2ipqpok2.uc.gateway.dev/ccs-axiom",
    timberHarvestUrl:
      "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_TimberHarvest_01/MapServer/",
    forestLayerUrl:
      "https://apps.fs.usda.gov/arcx/rest/services/EDW/EDW_ForestSystemBoundaries_01/MapServer",
    carbonWorkSpaceName: "nes_ccs_gc_prod",
  },

  staticInformation: {
    propertyOwnershipToken:
      "vFyF9d_hu5sDGzDsy39KUd9rdFLdcnex4yV3iRA9njSig-dzcetAetEGucGzxavG",
    propertyOwnershipUsageLimit: 2000,
    propertyOwnershipUsedPercent: 100,
    headers: [
      { label: "Storage Unit Name", key: "strunt_nm" },
      { label: "High Storage (Mt)", key: "hstormt" },
      { label: "Mid Storage (Mt)", key: "mstormt" },
      { label: "Low Storage (Mt)", key: "lstormt" },
      { label: "Confidence", key: "stor_cnfl" },
      { label: "County/Parish", key: "county" },
      { label: "Field", key: "cdl_fldgp" },
      { label: "Major Hydrocarbon Phase", key: "pool_type" },
      { label: "Producing Formation(s)", key: "cdl_prdgp" },
      { label: "Producing Formation Age", key: "cdl_ag_nm" },
      { label: "Reservoir Drive", key: "drive" },
      { label: "Area (acres)", key: "acres" },
      { label: "No. of Well Penetration", key: "well_count" },
      { label: "Average Producer Well Density (no/sq mile)", key: "well_dens" },
      { label: "Cum Oil Production (bbls)", key: "cuoilbbl" },
      { label: "Cum Gas Production (mcf)", key: "cugasmcf" },
      { label: "Cum Water Production (bbls)", key: "cuwatbbl" },
      { label: "Projected Oil Production (bbls)", key: "proilbbl" },
      { label: "Projected Gas Production (mcf)", key: "prgasmcf" },
      { label: "Projected Water Production (bbls)", key: "prwatbbl" },
      { label: "Median TVD Depth (ft)", key: "mmdepf" },
      { label: "Median Net Reservoir Thickness (ft)", key: "mnetresf" },
      { label: "Median Porosity (fraction)", key: "mporfrac" },
      { label: "Median Permeability (mD)", key: "mpermmd" },
      { label: "Median Temperature (°F)", key: "mtempdgf" },
      { label: "Gas/Oil Ratio (scf/bbl)", key: "mgorscfb" },
      { label: "Oil Gravity (°API)", key: "mapigrav" },
      { label: "Formation Volume Factor", key: "m_fvf" },
      { label: "Z Factor", key: "m_z_fac" },
      { label: "Primary Lithology", key: "rock_primy" },
      { label: "Secondary Lithology", key: "rock_secnd" },
      { label: "Other Lithologies", key: "rock_other" },
      { label: "P10 Low TVD Depth (ft)", key: "lmdepf" },
      { label: "P50 Median TVD Depth (ft)", key: "mmdepf" },
      { label: "P90 High TVD Depth (ft)", key: "hmdepf" },
      { label: "TVD Depth Confidence", key: "depth_cnfl" },
      { label: "P10 Low Net Reservoir Thickness (ft)", key: "lnetresf" },
      { label: "P50 Median Net Reservoir Thickness (ft)", key: "mnetresf" },
      { label: "P90 High Net Reservoir Thickness (ft)", key: "hnetresf" },
      { label: "Net Reservoir Data Source", key: "netrs_dtsr" },
      { label: "Net Reservoir Thickness Confidence", key: "netrs_cnfl" },
      { label: "P10 Low Porosity (fraction)", key: "lporfrac" },
      { label: "P50 Median Porosity (fraction)", key: "mporfrac" },
      { label: "P90 High Porosity (fraction)", key: "hporfrac" },
      { label: "Porosity Data Source", key: "por_dtsr" },
      { label: "Porosity Confidence", key: "por_cnfl" },
      { label: "P10 Low Permeability (mD)", key: "lpermmd" },
      { label: "P50 Median Permeability (mD)", key: "mpermmd" },
      { label: "P90 High Permeability (mD)", key: "hpermmd" },
      { label: "Permeability Data Source", key: "perm_dtsr" },
      { label: "Permeability Confidence", key: "perm_cnfl" },
      { label: "Porosity Confidence", key: "por_cnfl" },
      { label: "P10 Low Temperature (°F)", key: "ltempdgf" },
      { label: "P50 Median Temperature (°F)", key: "mtempdgf" },
      { label: "P90 High Temperature (°F)", key: "htempdgf" },
      { label: "Temperature Data Source", key: "temp_dtsr" },
      { label: "Temperature Confidence", key: "temp_cnfl" },
      { label: "P10 Low Pressure (psia)", key: "lprespsi" },
      { label: "P50 Median Pressure (psia)", key: "mprespsi" },
      { label: "P90 High Pressure (psia)", key: "hprespsi" },
      { label: "Pressure Data Source", key: "pres_dtsr" },
      { label: "Pressure Confidence", key: "pres_cnfl" },
      { label: "P10 Low Gas/Oil Ratio (scf/bbl)", key: "lgorscfb" },
      { label: "P50 Median Gas/Oil Ratio (scf/bbl)", key: "mgorscfb" },
      { label: "P90 High Gas/Oil Ratio (scf/bbl)", key: "hgorscfb" },
      { label: "GOR Data Source", key: "gor_dtsr" },
      { label: "GOR Confidence", key: "gor_cnfl" },
      { label: "P10 Low Oil Gravity (°API)", key: "lapigrav" },
      { label: "P50 Median Oil Gravity (°API)", key: "mapigrav" },
      { label: "P90 High Oil Gravity (°API)", key: "hapigrav" },
      { label: "Oil Gravity Data Source", key: "pres_cnfl" },
      { label: "Oil Gravity Confidence", key: "apigv_dtsr" },
      { label: "P10 Low Formation Volume Factor", key: "l_fvf" },
      { label: "P50 Median Formation Volume Factor", key: "m_fvf" },
      { label: "P90 High Formation Volume Factor", key: "h_fvf" },
      { label: "FVF Data Source", key: "fvf_dtsr" },
      { label: "FVF Confidence", key: "apigv_cnfl" },
      { label: "P10 Low Z Factor", key: "l_z_fac" },
      { label: "P50 Median Z Factor", key: "m_z_fac" },
      { label: "P90 High Z Factor", key: "h_z_fac" },
      { label: "Z Factor Data Source", key: "z_fac_dtsr" },
      { label: "Z Factor Confidence", key: "z_fac_cnfl" },
      { label: "P10 Low CO2 Density (kg/m3)", key: "lco2kgm3" },
      { label: "P50 Median CO2 Density (kg/m3)", key: "mco2kgm3" },
      { label: "P90 High CO2 Density (kg/m3)", key: "hco2kgm3" },
      { label: "CO2 Density Data Source", key: "co2dn_dtsr" },
      { label: "CO2 Density Confidence", key: "co2dn_cnfl" },
    ],
    headers_SA: [
      { label: "Storage Unit Name", key: "pool_name" },
      { label: "Mid(P50)Storage(Mt)", key: "mstormt" },
      { label: "High(P90)Storage(Mt)", key: "hstormt" },
      { label: "Low(P10)Storage(Mt)", key: "lstormt" },
      { label: "Field", key: "cdl_fldnm" },
      { label: "Major HC Phase", key: "pool_type" },
      { label: "Producing Formation", key: "cdl_prodfm" },
      { label: "Formation Age", key: "cdl_ag_nm" },
      { label: "Area (acres)", key: "acres" },
      { label: "Well Count", key: "well_count" },
      { label: "Cum Oil Production (bbls)", key: "cuoilbbl" },
      { label: "Cum Gas Production (mcf)", key: "cugasmcf" },
      { label: "Cum Water Production (bbls)", key: "cuwatbbl" },
      { label: "Cum Liquid Injection", key: "cuminjectionliquid_cum" },
      { label: "Cum Gas Injection", key: "cuminjectiongas_cum" },
      { label: "Projected Ultimate Oil Production (bbls)", key: "prjoil_bbl" },
      { label: "Projected Ultimate Gas Production (mcf)", key: "prjgas_mcf" },
      {
        label: "Projected Ultimate Water Production (bbls)",
        key: "prjwat_bbl",
      },
      { label: "Primary Lithology", key: "rock_primy" },
      { label: "Secondary Lithology", key: "rock_secnd" },
      { label: "Low(P10 ) Depth (ft)", key: "lmdepf" },
      { label: "Median(P50 ) Depth (ft)", key: "mmdepf" },
      { label: "High(P90 ) Depth (ft)", key: "hmdepf" },
      { label: "Low(P10 ) NetThickness (ft)", key: "l_netres_f" },
      { label: "Median(P50 ) NetThickness (ft)", key: "m_netres_f" },
      { label: "High(P90 )NetThickness (ft)", key: "h_netres_f" },
      { label: "Low(P10 ) Porosity (fraction)", key: "lporfrac" },
      { label: "Median(P50 ) Porosity (fraction)", key: "mporfrac" },
      { label: "High(P90 ) Porosity (fraction)", key: "hporfrac" },
      { label: "Low(P10 ) Permeablity (mD)", key: "lpermmd" },
      { label: "Median(P50 ) Permeablity (mD)", key: "mpermmd" },
      { label: "High(P90) Permeablity (mD)", key: "hpermmd" },
      { label: "Low(P10) Temperature (°F)", key: "ltempdgf" },
      { label: "Median(P50) Temperature (°F)", key: "mtempdgf" },
      { label: "High(P90) Temperature (°F)", key: "htempdgf" },
      { label: "Low(P10) Pressure (psi)", key: "lprespsi" },
      { label: "Median(P50) Pressure (psi)", key: "mprespsi" },
      { label: "High(P90) Pressure (psi)", key: "hprespsi" },
      { label: "Projected Ultimate GOR (scf/bbl)", key: "foregorultimate" },
      { label: "Low Formation Volume Factor", key: "l_fvf" },
      { label: "Median Formation Volume Factor", key: "m_fvf" },
      { label: "High Formation Volume Factor", key: "h_fvf" },
      { label: "Low(P10) Z Factor", key: "l_z_factor" },
      { label: "Median(P50) Z Factor", key: "m_z_factor" },
      { label: "High(P90) Z Factor", key: "h_z_factor" },
      { label: "Low(P10) CO2 Density (kg/m3)", key: "lco2d_kgm3" },
      { label: "Median(P50) CO2 Density (kg/m3)", key: "mco2d_kgm3" },
      { label: "High(P90) CO2 Density (kg/m3)", key: "hco2d_kgm3" },
    ],

    filterArray: [
      "pooltypeValue",
      "depthGreaterthanValue",
      "depthLessthanValue",
      "welldensityGreaterValue",
      "welldensityLessthanValue",
      "poolareaAcresGreaterValue",
      "poolareaAcresLesserValue",
      "potentialstorageGreaterValue",
      "potentialstorageLesserValue",
      "temperatureGreaterthanValue",
      "temperatureLessthanValue",
      "porosityGreaterthanValue",
      "porosityLessthanValue",
      "permeabilityGreaterthanValue",
      "permeabilityLessthanValue",
      "pressureGreaterthanValue",
      "pressureLessthanValue",
      "oilGravityGreaterthanValue",
      "oilGravityLessthanValue",
      "temperatureGreaterthanValue",
      "temperatureLessthanValue",
      "porosityGreaterthanValue",
      "porosityLessthanValue",
      "permeabilityGreaterthanValue",
      "permeabilityLessthanValue",
      "pressureGreaterthanValue",
      "pressureLessthanValue",
      "oilGravityGreaterthanValue",
      "oilGravityLessthanValue",
      "filterApplied",
    ],

    volumetricLayerTitles: [
      "Volumetrics100MillTons",
      "Volumetrics50to100",
      "Volumetrics15to50",
      "VolumetricsSmall5to15",
      "VolumetricsMarginal5",
      "Unclassified",
      "SAVolumetricsGreater50",
      "SAVolumetrics20to50",
      "SAVolumetrics10to20",
      "SAVolumetrics5to10",
      "SAVolumetrics1to5",
      "SAVolumetrics0_1to1",
    ],

    subscriptionLayers: [
      "TertiaryMiocene",
      "OligoceneFRIO",
      "OligoceneVICKSBURG",
      "OligoceneOther",
      "EoceneCLAIBORNE",
      "EoceneCOCKFIELDYEGUA",
      "EoceneQUEENCITY",
      "EoceneCOOKMOUNTAIN",
      "EoceneJACKSON",
      "EoceneWILCOX",
      "EoceneYEGUA",
      "EoceneOTHER",
      "TertiaryPaleocene",
      "CretaceousUppGulfEAGLEFORD",
      "CretaceousUppGulfAUSTIN",
      "CretaceousUppGulfNAVARRO",
      "CretaceousUppGulfOLMOS",
      "CretaceousUppGulfSANMIGUEL",
      "CretaceousUppGulfTUSCALOOSAWOODBINE",
      "CretaceousUppGulfOTHER",
      "CretaceousLowCoahuilaSLIGO",
      "CretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      "CretaceousLowComancheBUDA",
      "CretaceousLowComancheEDWARDS",
      "CretaceousLowComancheGEORGETOWN",
      "CretaceousLowComancheFREDERICKSBURG",
      "CretaceousLowComancheGLENROSE",
      "CretaceousLowComanchePEARSALL",
      "CretaceousLowComancheOTHER",
      "JurassicUppCottonValley",
      "JurassicUppHaynesville",
      "Volumetrics100MillTons",
      "Volumetrics50to100",
      "Volumetrics15to50",
      "VolumetricsSmall5to15",
      "VolumetricsMarginal5",
      "Unclassified",
      "PoolVolumetrics20",
      "PoolVolumetrics10to20",
      "PoolVolumetrics5to10",
      "PoolVolumetrics2_5to5",
      "PoolVolumetrics1to2_5",
      "PoolVolumetrics0_1to1",
      "PoolVolumetricsLess0_1",
      "PoolVolumetricsNA",
    ],

    SAsubscriptionLayers: [
      "SAEoceneWILCOX",
      "SACretaceousUppGulfNAVARRO",
      "SACretaceousTaylor",
      "SACretaceousAustin",
      "SACretaceousUppGulfTUSCALOOSAWOODBINE",
      "SACretaceousLowComancheGoodland",
      "SACretaceousLowComanchePaluxy",
      "SACretaceousLowComancheGLENROSE",
      "SACretaceousLowComancheJames",
      "SACretaceousLowCoahuilaSLIGO",
      "SACretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      "SAJurassicUppCottonValley",
      "SAJurassicUppHaynesville",
      "SAJurassicUppSmackover",
      "SAVolumetricsGreater50",
      "SAVolumetrics20to50",
      "SAVolumetrics10to20",
      "SAVolumetrics5to10",
      "SAVolumetrics1to5",
      "SAVolumetrics0_1to1",
      "SAPoolVolumetricsGreater50",
      "SAPoolVolumetrics20to50",
      "SAPoolVolumetrics10to20",
      "SAPoolVolumetrics5to10",
      "SAPoolVolumetrics1to5",
      "SAPoolVolumetrics0_1to1",
      "SAPoolVolumetricsLess0_1",
      "SAPoolVolumetricsNA",
    ],

    WMSLayers: [
      "BuildOut",
      "ProtectedLands",
      "StorageInjectorWells",
      "EORInjectorWells",
      "DisposalInjectorWells",
      "ProductionWells",
      "OrphanedWells",
      "PoolVolumetrics20",
      "PoolVolumetrics10to20",
      "PoolVolumetrics5to10",
      "PoolVolumetrics2_5to5",
      "PoolVolumetrics1to2_5",
      "PoolVolumetrics0_1to1",
      "PoolVolumetricsLess0_1",
      "PoolVolumetricsNA",
      "SAPoolVolumetricsGreater50",
      "SAPoolVolumetrics20to50",
      "SAPoolVolumetrics10to20",
      "SAPoolVolumetrics5to10",
      "SAPoolVolumetrics1to5",
      "SAPoolVolumetrics0_1to1",
      "SAPoolVolumetricsLess0_1",
      "SAPoolVolumetricsNA",
      "Deviated",
      "Horizontal",
      "Vertical",
      "Unknown",
      "well_control_points",
      "drax_sa_well_control_points",
      "gcdiapirg_tx_southcentral",
      "arkansasmiss_faults",
      "fed_state_boundary",
      "upmio_faults",
      "lowermio_faults",
      "midmio_faults",
      "upper_miocene_wells",
      "middle_miocene_wells",
      "lower_miocene_shallow_wells",
      "lower_miocene_deep_wells",
    ],

    SouthernArkansasWFSLayers: [
      "SAVolumetrics20to50",
      "SAVolumetrics10to20",
      "SAVolumetrics5to10",
      "SAVolumetrics1to5",
      "SAVolumetrics0_1to1",
      "SAEoceneWILCOX",
      "SACretaceousUppGulfNAVARRO",
      "SACretaceousTaylor",
      "SACretaceousAustin",
      "SACretaceousUppGulfTUSCALOOSAWOODBINE",
      "SACretaceousLowComancheGoodland",
      "SACretaceousLowComanchePaluxy",
      "SACretaceousLowComancheGLENROSE",
      "SACretaceousLowComancheJames",
      "SACretaceousLowCoahuilaSLIGO",
      "SACretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      "SAJurassicUppCottonValley",
      "SAJurassicUppHaynesville",
      "SAJurassicUppSmackover",
    ],

    WFSPolyLayers: [
      "TertiaryMiocene",
      "OligoceneFRIO",
      "OligoceneVICKSBURG",
      "OligoceneOther",
      "EoceneCLAIBORNE",
      "EoceneCOCKFIELDYEGUA",
      "EoceneQUEENCITY",
      "EoceneCOOKMOUNTAIN",
      "EoceneJACKSON",
      "EoceneWILCOX",
      "EoceneYEGUA",
      "EoceneOTHER",
      "TertiaryPaleocene",
      "CretaceousUppGulfEAGLEFORD",
      "CretaceousUppGulfAUSTIN",
      "CretaceousUppGulfNAVARRO",
      "CretaceousUppGulfOLMOS",
      "CretaceousUppGulfSANMIGUEL",
      "CretaceousUppGulfTUSCALOOSAWOODBINE",
      "CretaceousUppGulfOTHER",
      "CretaceousLowCoahuilaSLIGO",
      "CretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      "CretaceousLowComancheBUDA",
      "CretaceousLowComancheEDWARDS",
      "CretaceousLowComancheGEORGETOWN",
      "CretaceousLowComancheFREDERICKSBURG",
      "CretaceousLowComancheGLENROSE",
      "CretaceousLowComanchePEARSALL",
      "CretaceousLowComancheOTHER",
      "JurassicUppCottonValley",
      "JurassicUppHaynesville",
      "Volumetrics50to100",
      "Volumetrics15to50",
      "VolumetricsSmall5to15",
      "VolumetricsMarginal5",
      "Unclassified",
    ],

    EmittersDataKeys: [
      { CDL_STRUNT: "CDL_STRUNT" },
      { L_STOR_MT: "Low (Mt)" },
      { M_STOR_MT: "Mid (Mt)" },
      { H_STOR_MT: "High (Mt)" },
      { DIST_Meters: "DIST_Meters" },
      { NEAR_RANK: "NEAR_RANK" },
      { DIST_Miles: "DIST_Miles" },
      { Facility_ID: "Facility_ID" },
      { Facility_Name: "Facility_Name" },
      { CO2_Emissions_T: "CO2_Emissions_T" },
      { m_stor_Tons: "m_stor_Tons" },
      { Storage_Potential: "Storage_Potential" },
      { Feasibility: "Feasibility" },
      { Feasible_YN: "Feasible_YN" },
    ],

    storageKeySummaryAlias1: [{ strunt_nm: ["Storage Unit Name", 0] }],

    storageKeySummaryAlias2: [
      { mstormt: ["Mid (Mt) ", 2] },
      { hstormt: ["High (Mt) ", 2] },
      { lstormt: ["Low (Mt)", 2] },
      { stor_cnfl: ["Confidence ", 0] },
    ],

    storageKeySummaryAlias3: [
      { county: ["County/Parish", 0] },
      { cdl_fldgp: ["Field", 0] },
      { pool_type: ["Major Hydrocarbon Phase", 0] },
      { cdl_prdgp: ["Producing Formation(s)", 0] },
      { cdl_ag_nm: ["Producing Formation Age", 0] },
      { drive: ["Reservoir Drive", 0] },
      { acres: ["Area (acres)", 0] },
      { well_count: ["No. of Well Penetration", 0] },
      { well_dens: ["Well (Producers) Density (no/sq mile)", 2] },
      { cuoilbbl: ["Cum Oil Production (bbls)", 0] },
      { cugasmcf: ["Cum Gas Production (mcf)", 0] },
      { cumwatbbl: ["Cum Water Production (bbls)", 0] },
      { proilbbl: ["Projected Oil Production (bbls)", 0] },
      { prgasmcf: ["Projected Gas Production (mcf)", 0] },
      { prwatbbl: ["Projected Water Production (bbls)", 0] },
    ],

    storageKeySummaryAlias4: [
      { rock_primy: ["Primary Lithology", 0] },
      { mmdepthf: ["Median TVD Depth (ft)", 0] },
      { mnetresf: ["Median Net Reservoir Thickness (ft)", 0] },
      { mporfrac: ["Median Porosity (fraction)", 2] },
      { mpermmd: ["Median Permeability (mD)", 2] },
      { mtempdgf: ["Median Temperature (°F)", 0] },
      { mprespsi: ["Median Pressure (psia)", 0] },
    ],

    storageKeySummaryAlias5: [
      { mgorscfb: ["Gas/Oil Ratio (scf/bbl)", 2] },
      { mapigrav: ["Oil Gravity (°API)", 1] },
      { m_fvf: ["Formation Volume Factor", 4] },
      { m_z_fac: ["Z Factor", 4] },
      { mco2kgm3: ["Median CO2 Density (kg/m3)", 0] },
    ],

    storageKeyAlias1: [
      { rock_primy: ["Primary Lithology", 0] },
      { rock_secnd: ["Secondary Lithology", 0] },
      { rock_other: ["Other Lithologies", 0] },
      { lmdepf: ["P10 Low TVD Depth (ft)", 0] },
      { mmdepf: ["P50 Median TVD Depth (ft)", 0] },
      { hmdepf: ["P90 High TVD Depth (ft)", 0] },
      { depth_cnfl: ["TVD Depth Confidence", 0] },
      { lnetresf: ["P10 Low Net Reservoir Thickness (ft)", 2] },
      { mnetresf: ["P50 Median Net Reservoir Thickness (ft)", 2] },
      { hnetresf: ["P90 High Net Reservoir Thickness (ft)", 2] },
      { netrs_dtsr: ["Net Reservoir Data Source", 0] },
      { netrs_cnfl: ["Net Reservoir Thickness Confidence", 0] },
      { lporfrac: ["P10 Low Porosity (fraction)", 2] },
      { mporfrac: ["P50 Median Porosity (fraction)", 2] },
      { hporfrac: ["P90 High Porosity (fraction)", 2] },
      { por_dtsr: ["Porosity Data Source", 0] },
      { por_cnfl: ["Porosity Confidence", 0] },
      { lpermmd: ["P10 Low Permeability (mD)", 0] },
      { mpermmd: ["P50 Median Permeability (mD)", 0] },
      { hpermmd: ["P90 High Permeability (mD)", 0] },
      { perm_dtsr: ["Permeability Data Source", 0] },
      { perm_cnfl: ["Permeability Confidence", 0] },
      { ltempdgf: ["P10 Low Temperature (°F)", 0] },
      { mtempdgf: ["P50 Median Temperature (°F)", 0] },
      { htempdgf: ["P90 High Temperature (°F)", 0] },
      { temp_dtsr: ["Temperature Data Source", 0] },
      { temp_cnfl: ["Temperature Confidence ", 0] },
      { lprespsi: ["P10 Low Pressure (psia)", 0] },
      { mprespsi: ["P50 Median Pressure (psia)", 0] },
      { hprespsi: ["P90 High Pressure (psia)", 0] },
      { pres_dtsr: ["Pressure Data Source", 0] },
      { pres_cnfl: ["Pressure Confidence", 0] },
    ],

    storageKeyAlias2: [
      { lgorscfb: ["P10 Low Gas/Oil Ratio (scf/bbl)", 2] },
      { mgorscfb: ["P50 Median Gas/Oil Ratio (scf/bbl)", 2] },
      { hgorscfb: ["P90 High Gas/Oil Ratio (scf/bbl)", 2] },
      { gor_dtsr: ["GOR Data Source", 0] },
      { gor_cnfl: ["GOR Confidence", 0] },
      { lapigrav: ["P10 Low Oil Gravity (°API)", 1] },
      { mapigrav: ["P50 Median Oil Gravity (°API)", 1] },
      { hapigrav: ["P90 High Oil Gravity (°API)", 1] },
      { apigv_dtsr: ["Oil Gravity Data Source", 0] },
      { apigv_cnfl: ["Oil Gravity Confidence", 0] },
      { l_fvf: ["P10 Low Formation Volume Factor", 4] },
      { m_fvf: ["P50 Median Formation Volume Factor", 4] },
      { h_fvf: ["P90 High Formation Volume Factor", 4] },
      { fvf_dtsr: ["FVF Data Source", 0] },
      { fvf_cnfl: ["FVF Confidence", 0] },
      { l_z_fac: ["P10 Low Z Factor", 4] },
      { m_z_fac: ["P50 Median Z Factor", 4] },
      { h_z_fac: ["P90 High Z Factor", 4] },
      { z_fac_dtsr: ["Z Factor Data Source", 0] },
      { z_fac_cnfl: ["Z Factor Confidence", 0] },
      { lco2kgm3: ["P10 Low CO2 Density (kg/m3)", 0] },
      { mco2kgm3: ["P50 Median CO2 Density (kg/m3)", 0] },
      { hco2kgm3: ["P90 High CO2 Density (kg/m3)", 0] },
      { co2dn_dtsr: ["CO2 Density Data Source", 0] },
      { co2dn_cnfl: ["CO2 Density Confidence", 0] },
    ],

    PropertyownershipData: [
      /*  { owner: "Owner Name " }, */
      { address: "Property Address" },
      { careof: "In Care Of " },
      { mailadd: "Mailing Address " },
      { city: "Mailing Address City " },
      { state2: "Mailing Address State " },
    ],

    poolKeySummaryAlias1: [{ pool_name: ["Pool Name", 0] }],

    poolKeySummaryAlias2: [
      { m_stor_mt: ["Mid (Mt) ", 2] },
      { h_stor_mt: ["High (Mt) ", 2] },
      { l_stor_mt: ["Low (Mt)", 2] },
      { stor_cnfl: ["Confidence ", 0] },
    ],

    poolKeySummaryAlias3: [
      { county_par: ["County/Parish", 0] },
      { cdl_fldnm: ["Field", 0] },
      { pool_type: ["Major Hydrocarbon Phase", 0] },
      { cdl_prodfm: ["Producing Formation(s)", 0] },
      { cdl_ag_nm: ["Producing Formation Age", 0] },
      { drive: ["Reservoir Drive", 0] },
      { area_acres: ["Area (acres)", 0] },
      { well_dens: ["Well (Producers) Density (no/sq mile)", 2] },
      { cumoil_bbl: ["Cum Oil Production (bbls)", 0] },
      { cumgas_mcf: ["Cum Gas Production (mcf)", 0] },
      { cumwat_bbl: ["Cum Water Production (bbls)", 0] },
      { prjoil_bbl: ["Projected Oil Production (bbls)", 0] },
      { prjgas_mcf: ["Projected Gas Production (mcf)", 0] },
      { prjwat_bbl: ["Projected Water Production (bbls)", 0] },
      { eor_suit: ["EOR Suitability", 0] },
    ],

    poolKeySummaryAlias4: [
      { rock_primy: ["Primary Lithology", 0] },
      { m_mdepth_f: ["Median TVD Depth (ft)", 0] },
      { m_netres_f: ["Median Net Reservoir Thickness (ft)", 0] },
      { m_por_frac: ["Median Porosity (fraction)", 2] },
      { m_perm_md: ["Median Permeability (mD)", 2] },
      { m_temp_dgf: ["Median Temperature (°F)", 0] },
      { m_pres_psi: ["Median Pressure (psia)", 0] },
      { m_mmp_psi: ["Median Min Miscibility Pressure (psia)", 0] },
    ],

    poolKeySummaryAlias5: [
      { m_gor_scfb: ["Gas/Oil Ratio (scf/bbl)", 2] },
      { m_api_grav: ["Oil Gravity (°API)", 1] },
      { m_fvf: ["Formation Volume Factor", 4] },
      { m_z_factor: ["Z Factor", 4] },
      { mco2d_kgm3: ["Median CO2 Density (kg/m3)", 0] },
    ],

    poolKeyAlias1: [
      { rock_primy: ["Primary Lithology", 0] },
      { rock_secnd: ["Secondary Lithology", 0] },
      { rock_other: ["Other Lithologies", 0] },
      { l_mdepth_f: ["P10 Low TVD Depth (ft)", 0] },
      { m_mdepth_f: ["P50 Median TVD Depth (ft)", 0] },
      { h_mdepth_f: ["P90 High TVD Depth (ft)", 0] },
      { depth_cnfl: ["TVD Depth Confidence", 0] },
      { l_netres_f: ["P10 Low Net Reservoir Thickness (ft)", 2] },
      { m_netres_f: ["P50 Median Net Reservoir Thickness (ft)", 2] },
      { h_netres_f: ["P90 High Net Reservoir Thickness (ft)", 2] },
      { netrs_dtsr: ["Net Reservoir Data Source", 0] },
      { netrs_cnfl: ["Net Reservoir Thickness Confidence", 0] },
      { l_por_frac: ["P10 Low Porosity (fraction)", 2] },
      { m_por_frac: ["P50 Median Porosity (fraction)", 2] },
      { h_por_frac: ["P90 High Porosity (fraction)", 2] },
      { por_dtsr: ["Porosity Data Source", 0] },
      { por_cnfl: ["Porosity Confidence", 0] },
      { l_perm_md: ["P10 Low Permeability (mD)", 0] },
      { m_perm_md: ["P50 Median Permeability (mD)", 0] },
      { h_perm_md: ["P90 High Permeability (mD)", 0] },
      { perm_dtsr: ["Permeability Data Source", 0] },
      { perm_cnfl: ["Permeability Confidence", 0] },
      { l_temp_dgf: ["P10 Low Temperature (°F)", 0] },
      { m_temp_dgf: ["P50 Median Temperature (°F)", 0] },
      { h_temp_dgf: ["P90 High Temperature (°F)", 0] },
      { temp_dtsr: ["Temperature Data Source", 0] },
      { temp_cnfl: ["Temperature Confidence ", 0] },
      { l_pres_psi: ["P10 Low Pressure (psia)", 0] },
      { m_pres_psi: ["P50 Median Pressure (psia)", 0] },
      { h_pres_psi: ["P90 High Pressure (psia)", 0] },
      { pres_dtsr: ["Pressure Data Source", 0] },
      { pres_cnfl: ["Pressure Confidence", 0] },
      { l_mmp_psi: ["P10 Low Min Miscibility Pressure (psia)", 0] },
      { m_mmp_psi: ["P50 Median Min Miscibility Pressure (psia)", 0] },
      { h_mmp_psi: ["P90 High Min Miscibility Pressure (psia)", 0] },
      { eor_note: ["EOR_Notes", 0] },
    ],

    poolKeyAlias2: [
      { l_gor_scfb: ["P10 Low Gas/Oil Ratio (scf/bbl)", 2] },
      { m_gor_scfb: ["P50 Median Gas/Oil Ratio (scf/bbl)", 2] },
      { h_gor_scfb: ["P90 High Gas/Oil Ratio (scf/bbl)", 2] },
      { gor_dtsr: ["GOR Data Source", 0] },
      { gor_cnfl: ["GOR Confidence", 0] },
      { l_api_grav: ["P10 Low Oil Gravity (°API)", 1] },
      { m_api_grav: ["P50 Median Oil Gravity (°API)", 1] },
      { h_api_grav: ["P90 High Oil Gravity (°API)", 1] },
      { apigv_dtsr: ["Oil Gravity Data Source", 0] },
      { apigv_cnfl: ["Oil Gravity Confidence", 0] },
      { l_fvf: ["P10 Low Formation Volume Factor", 4] },
      { m_fvf: ["P50 Median Formation Volume Factor", 4] },
      { h_fvf: ["P90 High Formation Volume Factor", 4] },
      { fvf_dtsr: ["FVF Data Source", 0] },
      { fvf_cnfl: ["FVF Confidence", 0] },
      { l_z_factor: ["P10 Low Z Factor", 4] },
      { m_z_factor: ["P50 Median Z Factor", 4] },
      { h_z_factor: ["P90 High Z Factor", 4] },
      { z_fac_dtsr: ["Z Factor Data Source", 0] },
      { z_fac_cnfl: ["Z Factor Confidence", 0] },
      { lco2d_kgm3: ["P10 Low CO2 Density (kg/m3)", 0] },
      { mco2d_kgm3: ["P50 Median CO2 Density (kg/m3)", 0] },
      { hco2d_kgm3: ["P90 High CO2 Density (kg/m3)", 0] },
      { co2dn_dtsr: ["CO2 Density Data Source", 0] },
      { co2dn_cnfl: ["CO2 Density Confidence", 0] },
    ],
    SAstorageKeySummaryAlias1: [{ pool_name: ["Storage Unit Name", 0] }],
    SAstorageKeySummaryAlias2: [
      { mstormt: ["Mid (Mt) ", 2] },
      { hstormt: ["High (Mt) ", 2] },
      { lstormt: ["Low (Mt)", 2] },
    ],
    SAstorageKeySummaryAlias3: [
      { cdl_fldnm: ["Field", 0] },
      { pool_type: ["Major Hydrocarbon Phase", 0] },
      { cdl_prodfm: ["Producing Formation(s)", 0] },
      { cdl_ag_nm: ["Producing Formation Age", 0] },
      { acres: ["Area (acres)", 0] },
      { well_count: ["No. of Well Penetration", 0] },
      { cuoilbbl: ["Cum Oil Production (bbls)", 0] },
      { cugasmcf: ["Cum Gas Production (mcf)", 0] },
      { cuwatbbl: ["Cum Water Production (bbls)", 0] },
      { cuminjectionliquid_cum: ["Cum Liquid Injection", 0] },
      { cuminjectiongas_cum: ["Cum gas Injection", 0] },
      { prjoil_bbl: ["Projected Ultimate Oil Production (bbls)", 0] },
      { prjgas_mcf: ["Projected Ultimate Gas Production (mcf)", 0] },
      { prjwat_bbl: ["Projected Ultimate Water Production (bbls)", 0] },
      ,
    ],

    SAstorageKeySummaryAlias4: [
      { rock_primy: ["Primary Lithology", 0] },
      { mmdepf: ["Median Depth (ft)", 0] },
      { m_netres_f: ["Median Net Reservoir Thickness (ft)", 0] },
      { mporfrac: ["Median Porosity (fraction)", 2] },
      { mpermmd: ["Median Permeability (mD)", 2] },
      { mtempdgf: ["Median Temperature (°F)", 0] },
      { mprespsi: ["Median Pressure (psia)", 0] },
    ],

    SAstorageKeySummaryAlias5: [
      { foregorultimate: ["Gas/Oil Ratio (scf/bbl)", 2] },
      { m_fvf: ["Median Formation Volume Factor", 4] },
      { m_z_factor: ["Median Z Factor", 4] },
      { mco2d_kgm3: ["Median CO2 Density (kg/m3)", 0] },
    ],
    SAstorageKeyAlias1: [
      { rock_primy: ["Primary Lithology", 0] },
      { rock_secnd: ["Secondary Lithology", 0] },
      { lmdepf: ["Low TVD Depth (ft)", 0] },
      { mmdepf: ["Median TVD Depth (ft)", 0] },
      { hmdepf: ["High TVD Depth (ft)", 0] },
      { l_netres_f: ["Low Net Reservoir Thickness (ft)", 2] },
      { m_netres_f: ["Median Net Reservoir Thickness (ft)", 2] },
      { h_netres_f: ["High Net Reservoir Thickness (ft)", 2] },
      { hporfrac: ["Low Porosity (fraction)", 2] },
      { lporfrac: ["Median Porosity (fraction)", 2] },
      { mporfrac: ["High Porosity (fraction)", 2] },
      { lpermmd: ["Low Permeability (mD)", 0] },
      { mpermmd: ["Median Permeability (mD)", 0] },
      { hpermmd: ["High Permeability (mD)", 0] },
      { ltempdgf: ["P10 Low Temperature (°F)", 0] },
      { mtempdgf: ["P50 Median Temperature (°F)", 0] },
      { htempdgf: ["P90 High Temperature (°F)", 0] },
      { lprespsi: ["Low Pressure (psia)", 0] },
      { mprespsi: ["Median Pressure (psia)", 0] },
      { hprespsi: ["High Pressure (psia)", 0] },
    ],

    SAstorageKeyAlias2: [
      { l_fvf: ["Low Formation Volume Factor", 4] },
      { m_fvf: ["Median Formation Volume Factor", 4] },
      { h_fvf: ["High Formation Volume Factor", 4] },
      { l_z_factor: ["Low Z Factor", 4] },
      { m_z_factor: ["Median Z Factor", 4] },
      { h_z_factor: ["High Z Factor", 4] },
      { lco2d_kgm3: ["P10 Low CO2 Density (kg/m3)", 0] },
      { mco2d_kgm3: ["P50 Median CO2 Density (kg/m3)", 0] },
      { hco2d_kgm3: ["P90 High CO2 Density (kg/m3)", 0] },
    ],
    SApoolKeySummaryAlias2: [
      { m_stor_mt: ["Mid (Mt) ", 2] },
      { h_stor_mt: ["High (Mt) ", 2] },
      { l_stor_mt: ["Low (Mt)", 2] },
    ],

    SApoolKeySummaryAlias3: [
      { county_par: ["County/Parish", 0] },
      { cdl_fldnm: ["Field", 0] },
      { pool_type: ["Major Hydrocarbon Phase", 0] },
      { cdl_prodfm: ["Producing Formation(s)", 0] },
      { cdl_ag_nm: ["Producing Formation Age", 0] },
      { area_acres: ["Area (acres)", 0] },
      { cumoil_bbl: ["Cum Oil Production (bbls)", 0] },
      { cumgas_mcf: ["Cum Gas Production (mcf)", 0] },
      { cumwat_bbl: ["Cum Water Production (bbls)", 0] },
      { prjoil_bbl: ["Projected Oil Production (bbls)", 0] },
      { prjgas_mcf: ["Projected Gas Production (mcf)", 0] },
      { prjwat_bbl: ["Projected Water Production (bbls)", 0] },
      { percent__depl: ["Depletion (%)", 0] },
    ],
    SApoolKeySummaryAlias4: [
      { rock_primy: ["Primary Lithology", 0] },
      { rock_secnd: ["Secondary Lithology", 0] },
      { mmdepf: ["Median Depth (ft)", 0] },
      { m_netres_f: ["Median Net Reservoir Thickness (ft)", 0] },
      { mporfrac: ["Median Porosity (fraction)", 2] },
      { m_perm_md: ["Median Permeability (mD)", 2] },
      { mtempdgf: ["Median Temperature (°F)", 0] },
      { mprespsi: ["Median Pressure (psia)", 0] },
    ],
    SApoolKeySummaryAlias5: [
      { foregorultimate: ["Gas/Oil Ratio (scf/bbl)", 2] },
      { m_fvf: ["Median Formation Volume Factor", 4] },
      { m_z_factor: ["Median Z Factor", 4] },
      { mco2d_kgm3: ["Median CO2 Density (kg/m3)", 0] },
    ],

    SApoolKeyAlias1: [
      { rock_primy: ["Primary Lithology", 0] },
      { rock_secnd: ["Secondary Lithology", 0] },
      { lmdepf: ["Low TVD Depth (ft)", 0] },
      { mmdepf: ["Median TVD Depth (ft)", 0] },
      { hmdepf: ["High TVD Depth (ft)", 0] },
      { l_netres_f: ["Low Net Reservoir Thickness (ft)", 2] },
      { m_netres_f: ["Median Net Reservoir Thickness (ft)", 2] },
      { h_netres_f: ["High Net Reservoir Thickness (ft)", 2] },
      { lporfrac: ["Low Porosity (fraction)", 2] },
      { mporfrac: ["Median Porosity (fraction)", 2] },
      { hporfrac: ["High Porosity (fraction)", 2] },
      { l_perm_md: ["Low Permeability (mD)", 0] },
      { m_perm_md: ["Median Permeability (mD)", 0] },
      { h_perm_md: ["High Permeability (mD)", 0] },
      { ltempdgf: ["P10 Low Temperature (°F)", 0] },
      { mtempdgf: ["P50 Median Temperature (°F)", 0] },
      { htempdgf: ["P90 High Temperature (°F)", 0] },
      { lprespsi: ["Low Pressure (psi)", 0] },
      { mprespsi: ["Median Pressure (psi)", 0] },
      { hprespsi: ["High Pressure (psi)", 0] },
    ],
    SApoolKeyAlias2: [
      { l_fvf: ["Low Formation Volume Factor", 4] },
      { m_fvf: ["Median Formation Volume Factor", 4] },
      { h_fvf: ["High Formation Volume Factor", 4] },
      { l_z_factor: ["Low Z Factor", 4] },
      { m_z_factor: ["Median Z Factor", 4] },
      { h_z_factor: ["High Z Factor", 4] },
      { lco2d_kgm3: ["P10 Low CO2 Density (kg/m3)", 0] },
      { mco2d_kgm3: ["P50 Median CO2 Density (kg/m3)", 0] },
      { hco2d_kgm3: ["P90 High CO2 Density (kg/m3)", 0] },
    ],

    /* PropertyownershipData1: [
      { parcel_lot_id: "Parcel Lot ID " },
      { parcel_id: "Parcel ID " },
      { account: "Account " },
    ],

    PropertyownershipData2: [
      { prop_street_number: "Street Number " },
      { prop_street: "Street: County Road " },
      { prop_city: "Property City " },
      { DBA: "DBA " },
    ], */
  },
 

  EIADatasets: [
    {
      groupName: "Power Plants",
      "Power Plants": {
        source: [
          {
            displayName: "Battery Storage",
            groupName: "Power Plants",
            lyrName: "BatteryStorage",
            iconType: "circle",
            iconColor: "#0000FF",
            zoomLevel: 1,
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#0000FF", // required
              },
            },
            visible: false,
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#0000FF",
              borderRadius: "50%",
              display: "inline-block",
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/0",
          },
          {
            displayName: "Biomass",
            groupName: "Power Plants",
            lyrName: "Biomass",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#808080",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#808080", // required
              },
            },
            visible: false,
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#808080",
              borderRadius: "50%",
              display: "inline-block",
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/1",
          },
          {
            displayName: "Coal",
            groupName: "Power Plants",
            lyrName: "Coal",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#008000",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#008000",
              borderRadius: "50%",
              display: "inline-block",
            },
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#008000", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/2",
            visible: false,
          },
          {
            displayName: "Geothermal",
            groupName: "Power Plants",
            lyrName: "Geothermal",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#800080",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#800080",
              borderRadius: "50%",
              display: "inline-block",
            },
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#800080", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/3",
            visible: false,
          },
          {
            displayName: "Hydroelectric",
            groupName: "Power Plants",
            lyrName: "Hydroelectric",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FF0000",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FF0000",
              borderRadius: "50%",
              display: "inline-block",
            },
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FF0000", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/4",
            visible: false,
          },
          {
            displayName: "Natural Gas",
            groupName: "Power Plants",
            lyrName: "NaturalGas",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#F0F8FF",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#F0F8FF",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#F0F8FF", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/5",
          },
          {
            displayName: "Nuclear",
            groupName: "Power Plants",
            lyrName: "Nuclear",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FF7F50",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FF7F50", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/6",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FF7F50",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Petroleum",
            groupName: "Power Plants",
            lyrName: "Petroleum",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#B22222",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#B22222", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/7",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#B22222",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Pumped Storage",
            groupName: "Power Plants",
            lyrName: "PumpedStorage",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FF69B4",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FF69B4", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/8",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FF69B4",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Solar",
            groupName: "Power Plants",
            lyrName: "Solar",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FFFACD",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FFFACD", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/9",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FFFACD",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Wind",
            groupName: "Power Plants",
            lyrName: "Wind",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FFD700",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FFD700", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/10",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FFD700",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Other",
            groupName: "Power Plants",
            lyrName: "OtherPowerPlants",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#FFDAB9",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#FFDAB9", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/ElectricPowerPlants/FeatureServer/11",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#FFDAB9",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
        ],
      },
    },
    {
      groupName: "Oil And Gas Refining And Processing",
      "Oil And Gas Refining And Processing": {
        source: [
          {
            displayName: "Petroleum Refineries",
            groupName: "Oil And Gas Refining And Processing",
            lyrName: "PetroleumRefineries",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#BDB76B",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#BDB76B", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/Petroleum_Refineries_US_EIA/FeatureServer/22",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#BDB76B",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "BioDiesel Plants",
            groupName: "Oil And Gas Refining And Processing",
            lyrName: "BioDieselPlants",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#ADFF2F",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#ADFF2F", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/Biodiesel_Plants_US_EIA/FeatureServer/113",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#ADFF2F",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Ethanol Plants",
            groupName: "Oil And Gas Refining And Processing",
            lyrName: "EthanolPlants",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#808000",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#808000", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/Ethanol_Plants_US_EIA/FeatureServer/112",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#808000",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Natural Gas Processing Plants",
            groupName: "Oil And Gas Refining And Processing",
            lyrName: "NaturalGasPlants",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#7FFFD4",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#7FFFD4", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/NaturalGas_ProcessingPlants_US_EIA/FeatureServer/0",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#7FFFD4",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
          {
            displayName: "Ethylene Crackers",
            groupName: "Oil And Gas Refining And Processing",
            lyrName: "EthyleneCrackers",
            iconType: "circle",
            zoomLevel: 1,
            iconColor: "#6495ED",
            vectorStyle: {
              image: {
                radius: 7, // required
                fillColor: "#6495ED", // required
              },
            },
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/Ethylene_Crackers_US_EIA/FeatureServer/0",
            stylClass: {
              height: "15px",
              width: "15px",
              backgroundColor: "#6495ED",
              borderRadius: "50%",
              display: "inline-block",
            },
            visible: false,
          },
        ],
      },
    },
  ],

  Pipelines: [
    {
      groupName: "EIA Pipelines",
      "EIA Pipelines": {
        source: [
          {
            displayName: "Natural Gas Interstate and Intrastate Pipelines",
            groupName: "EIA Pipelines",
            lyrName: "NaturalGasPipelines",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgb(255,0,0)",
            zoomLevel: 8,
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/NaturalGas_InterIntrastate_Pipelines_US_EIA/FeatureServer/0",
            vectorStyle: {
              stroke: {
                color: "rgb(255,0,0)",
                width: 2,
              },
            },
            stylClass: {
              borderLeft: "32px solid #ffbee8",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Hydrocarbon Gas Liquids (HGL) Pipelines",
            groupName: "EIA Pipelines",
            lyrName: "HGLPipelines",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgb(16,16,252)",
            zoomLevel: 1,
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/HGL_Pipelines_US_EIA/FeatureServer/0",
            vectorStyle: {
              stroke: {
                color: "rgb(16,16,252)",
                width: 2,
              },
            },
            stylClass: {
              borderLeft: "32px solid #cc5500",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Crude Oil Pipelines",
            groupName: "Pipelines and Transmission",
            lyrName: "CrudeOilPipelines",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgb(0,176,80)",
            zoomLevel: 1,
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/CrudeOil_Pipelines_US_EIA/FeatureServer/0",
            vectorStyle: {
              stroke: {
                color: "rgb(0,176,80)",
                width: 2,
              },
            },
            stylClass: {
              borderLeft: "32px solid blue",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Petroleum Product Pipelines",
            groupName: "Pipelines and Transmission",
            lyrName: "Petroleum_Product_Pipelines",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(179,137,55,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/arcgis/rest/services/PetroleumProduct_Pipelines_US_EIA/FeatureServer/0",
            vectorStyle: {
              stroke: {
                color: "rgba(179,137,55,255)",
                width: 2,
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(179,137,55,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
        ],
      },
    },
    {
      groupName: "BOEM Pipelines",
      "BOEM Pipelines": {
        source: [
          {
            displayName: "Bulk Gas",
            groupName: "BOEM Pipelines",
            lyrName: "Bulk_Gas",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(237,81,81,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='BLKG'",
            vectorStyle: {
              stroke: {
                color: "rgba(237,81,81,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(237,81,81,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Bulk Oil",
            groupName: "BOEM Pipelines",
            lyrName: "Bulk_Oil",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(20,158,206,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='BLKO'",
            vectorStyle: {
              stroke: {
                color: "rgba(20,158,206,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(20,158,206,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Gas Transported After First Processing",
            groupName: "BOEM Pipelines",
            lyrName: "Gas_Transported_After_First_Processing",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(167,198,54,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='GAS'",
            vectorStyle: {
              stroke: {
                color: "rgba(167,198,54,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(167,198,54,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Gas Lift",
            groupName: "BOEM Pipelines",
            lyrName: "Gas_Lift",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(158,85,156,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='LIFT'",
            vectorStyle: {
              stroke: {
                color: "rgba(158,85,156,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(158,85,156,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Gas And Condensate Service After First Processing",
            groupName: "BOEM Pipelines",
            lyrName: "Gas_And_Condensate_Service_After_First_Processing",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(252,146,31,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='G/C'",
            vectorStyle: {
              stroke: {
                color: "rgba(252,146,31,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(252,146,31,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Oil Transported After First Processing",
            groupName: "BOEM Pipelines",
            lyrName: "Oil_Transported_After_First_Processing",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(255,222,62,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='OIL'",
            vectorStyle: {
              stroke: {
                color: "rgba(255,222,62,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(255,222,62,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Supply Gas",
            groupName: "BOEM Pipelines",
            lyrName: "Supply_Gas",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(247,137,216,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='SPLY'",
            vectorStyle: {
              stroke: {
                color: "rgba(247,137,216,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(247,137,216,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Water",
            groupName: "BOEM Pipelines",
            lyrName: "Water",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(183,129,74,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='H2O'",
            vectorStyle: {
              stroke: {
                color: "rgba(183,129,74,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(183,129,74,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Umbilical Line",
            groupName: "BOEM Pipelines",
            lyrName: "Umbilical_Line",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(60,175,153,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='UMB'",
            vectorStyle: {
              stroke: {
                color: "rgba(60,175,153,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(60,175,153,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Flare Gas",
            groupName: "BOEM Pipelines",
            lyrName: "Flare_Gas",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(107,107,214,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='FLG'",
            vectorStyle: {
              stroke: {
                color: "rgba(107,107,214,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(107,107,214,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Gas And Oil Service After First Processing",
            groupName: "BOEM Pipelines",
            lyrName: "Gas_And_Oil_Service_After_First_Processing",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "rgba(181,71,121,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE='G/O'",
            vectorStyle: {
              stroke: {
                color: "rgba(181,71,121,255)",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid rgba(181,71,121,255)",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
          {
            displayName: "Other",
            groupName: "BOEM Pipelines",
            lyrName: "Other",
            lyrType: "arcgis",
            iconType: "lineicon",
            iconColor: "#000",
            zoomLevel: 1,
            serviceUrl:
              "https://services5.arcgis.com/g7OtfotLzNoMMSUp/ArcGIS/rest/services/Pipelines/FeatureServer/0/query?f=json&resultType=standard&maxRecordCountFactor=4&standardMaxRecordCount=8000&outFields=OBJECTID,PROD_CODE&returnGeometry=true&where=PROD_CODE IN ('UBEH', 'SERV','TEST','UMBH','CSNG','METH','AIR','COND','BLOH','BLGH','O/W','UMBE','CHEM','INJ','UMBC','CBLC','CBLP','GASH','SPRE','OILH','ACID','SULF','G/OH')",
            vectorStyle: {
              stroke: {
                color: "#000",
                width: 2,
                lineDash: [4, 4],
              },
            },
            stylClass: {
              borderLeft: "32px solid #000",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
        ],
      },
    },
    {
      groupName: "CO2 Pipelines",
      "CO2 Pipelines": {
        source: [
          {
            displayName: "CO2 Pipelines",
            groupName: "Pipelines and Transmission",
            lyrName: "co2_pipelines",
            lyrType: "geoserver_wms",
            iconType: "lineicon",
            iconColor: "rgb(195,25,255)",
            zoomLevel: 1,
            serviceUrl:
              "https://services7.arcgis.com/FGr1D95XCGALKXqM/ArcGIS/rest/services/NaturalGas_InterIntrastate_Pipelines_US_EIA/FeatureServer/0",
            vectorStyle: {
              stroke: {
                color: "#C319FF",
                width: 2,
              },
            },
            stylClass: {
              borderLeft: "32px solid #C319FF",
              height: "2px",
              left: "5%",
              top: "10",
            },
            visible: false,
          },
        ],
      },
    },
  ],
  LayerMetaData: [
    // { label: "Emission Sources", key: "emission_Sources" },

    {
      label: "Saline Aquifer",
      key: "saline_aquifer",
      subCat: [
        {
          label: "Offshore Gulf Coast",
          key: "offshore_Gulf_Coast",
          subCat: [
            {
              faultPolygons: "upmio_faults",
              faultBG: "rgb(64,65,67)",
              fault_id: "expand_upper_miocene_fault",
              well_penetration: "upper_miocene_wells",
              well_penetration_id: "expand_upper_miocene_well",
              groupName: "Upper Miocene",
              root_id: "offshore_Gulf_Coast",
              id: "expand_upper_miocene",
              gulf_cost: true,
              exportlayerName: "gc_saline_upmiocene",
              "Upper Miocene": {
                source: [
                  {
                    displayName: "Fault Polygons",
                    groupName: "Upper Miocene",
                    eleType: "checkbox",
                    lyrName: "upmio_faults",
                    lyrGroupName: "upmio_faults",
                    faultBG: "rgb(64,65,67)",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Well Penetration",
                    groupName: "Upper Miocene",
                    eleType: "checkbox",
                    lyrName: "upper_miocene_wells",
                    lyrGroupName: "upper_miocene_wells",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Storage (Mt)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_storage",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_storage",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Net Thickness (Ft)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_thickness",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_thickness",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Effective Porosity (Fraction)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_porosity",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_porosity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Permeability (mD)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_permeability",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                   lyrStyle: "gc_perm",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Temperature (Deg F)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_temp",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_temp",
                    visible: false,
                    legendData:null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Pressure (PSI)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_PorePressure",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_porepr",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Salinity (ppm)",
                    groupName: "Upper Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_upmiocene_sa_salinity",
                    lyrGroupName: "gc_saline_upmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_salinity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                ],
              },
            },
            {
              faultPolygons: "midmio_faults",
              faultBG: "rgb(48,164,255)",
              well_penetration: "middle_miocene_wells",
              well_penetration_id: "expand_middle_miocene_well",
              fault_id: "expand_middle_miocene_fault",
              groupName: "Middle Miocene",
              id: "expand_middle_miocene",
              root_id: "offshore_Gulf_Coast",
              gulf_cost: true,
              exportlayerName: "gc_saline_midmiocene",
              "Middle Miocene": {
                source: [
                  {
                    displayName: "Fault Polygons",
                    groupName: "Middle Miocene",
                    eleType: "checkbox",
                    lyrName: "midmio_faults",
                    lyrGroupName: "midmio_faults",
                    faultBG: "rgb(48,164,255)",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Well Penetration",
                    groupName: "Middle Miocene",
                    eleType: "checkbox",
                    lyrName: "middle_miocene_wells",
                    lyrGroupName: "middle_miocene_wells",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Storage (Mt)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_storage",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_storage",
                    visible: false,
                    legendData:null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Net Thickness (Ft)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_thickness",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_thickness",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Effective Porosity (Fraction)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_porosity",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_porosity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Permeability (mD)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_permeability",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                   lyrStyle: "gc_perm",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Temperature (Deg F)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_temp",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_temp",
                    visible: false,
                    legendData:null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Pressure (PSI)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_PorePressure",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_porepr",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Salinity (ppm)",
                    groupName: "Middle Miocene",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_midmiocene_sa_salinity",
                    lyrGroupName: "gc_saline_midmiocene",
                    layerFilter: null,
                    lyrStyle: "gc_salinity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                ],
              },
            },
            {
              faultPolygons: "lowermio_faults",
              faultBG: "rgb(127,0,85)",
              well_penetration: "lower_miocene_shallow_wells",
              well_penetration_id: "expand_lower_miocene_shallow_well",
              fault_id: "expand_lower_miocene_shallow_fault",
              groupName: "Lower Miocene (Shallow)",
              id: "expand_lower_miocene_shallow",
              root_id: "offshore_Gulf_Coast",
              gulf_cost: true,
              exportlayerName: "gc_saline_lrmiocene_shallow",
              "Lower Miocene (Shallow)": {
                source: [
                  {
                    displayName: "Fault Polygons",
                    groupName: "Lower Miocene (Shallow)",
                    eleType: "checkbox",
                    lyrName: "lowermio_faults",
                    lyrGroupName: "lowermio_faults",
                    faultBG: "rgb(127,0,85)",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Well Penetration",
                    groupName: "Lower Miocene (Shallow)",
                    eleType: "checkbox",
                    lyrName: "lower_miocene_shallow_wells",
                    lyrGroupName: "lower_miocene_shallow_wells",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Storage (Mt)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_storage",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_storage",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Net Thickness (Ft)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_thickness",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_thickness",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Effective Porosity (Fraction)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_porosity",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_porosity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Permeability (mD)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_permeability",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                   lyrStyle: "gc_perm",
                    visible: false,
                    legendData:null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Temperature (Deg F)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_temp",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_temp",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Pressure (PSI)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_PorePressure",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_porepr",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Salinity (ppm)",
                    groupName: "Lower Miocene (Shallow)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_shallow_sa_salinity",
                    lyrGroupName: "gc_saline_lrmiocene_shallow",
                    layerFilter: null,
                    lyrStyle: "gc_salinity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                ],
              },
            },
            {
              well_penetration: "lower_miocene_deep_wells",
              well_penetration_id: "expand_lower_miocene_deep_well",
              groupName: "Lower Miocene (Deep)",
              id: "expand_lower_miocene_deep",
              root_id: "offshore_Gulf_Coast",
              gulf_cost: true,
              exportlayerName: "gc_saline_lrmiocene_deep",
              "Lower Miocene (Deep)": {
                source: [
                  {
                    displayName: "Well Penetration",
                    groupName: "Lower Miocene (Deep)",
                    eleType: "checkbox",
                    lyrName: "lower_miocene_deep_wells",
                    lyrGroupName: "lower_miocene_deep_wells",
                    layerFilter: null,
                    lyrStyle: null,
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Storage (Mt)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_storage",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_storage",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Net Thickness (Ft)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_thickness",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_thickness",
                    visible: false,
                    legendData:null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Effective Porosity (Fraction)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_porosity",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_porosity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Permeability (mD)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_permeability",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                   lyrStyle: "gc_perm",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Temperature (Deg F)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_temp",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_temp",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                  {
                    displayName: "Pressure (PSI)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_PorePressure",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_porepr",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
        
                  {
                    displayName: "Salinity (ppm)",
                    groupName: "Lower Miocene (Deep)",
                    radiogroupName: "miocene_storage",
                    lyrName: "gc_saline_lrmiocene_deep_sa_salinity",
                    lyrGroupName: "gc_saline_lrmiocene_deep",
                    layerFilter: null,
                    lyrStyle: "gc_salinity",
                    visible: false,
                    legendData: null,
                    alllowedRole:"CA_GC_Offshore_SA"
                  },
                ],
              },
            },
          ],
        },
        {
          label: "Southern AR-MS-AL",
          key: "southern_ar_ms_al",
          subCat: [
            {
              groupName: "Storage Units",
              id: "expand_Storage_Units",
              southern_ar_ms_al: true,
              lyrGroupName: "storage_units_group",
              exportlayerName: "storage_units_group",
              "Storage Units": {
                source: [
                  {
                    groupName: "Tertiary",
                    categoryName: "southern_ar_ms_al",
                    Tertiary: {
                      source: [
                        {
                          groupName: "Vicksburg",
                          id: "expand_Vicksburg",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Vicksburg,true",
                          Vicksburg: {
                            source: [
                              {
                                displayName: "Structure",
                                groupName: "Vicksburg",
                                eleType: "checkbox",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_depth",
                                lyrGroupName: "Vicksburg_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_vicksburg",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "Vicksburg",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_PorePressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Vicksburg",
                                radiogroupName: "miocene_storage",
                                lyrName: "vicksburg_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Vicksburg'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Claiborne",
                          id: "expand_Claiborne",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Claiborne,true",
                          Claiborne: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_depth",
                                lyrGroupName: "Claiborne_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_claiborne",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "Claiborne",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Claiborne",
                                radiogroupName: "miocene_storage",
                                lyrName: "claiborne_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Claiborne'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Up Wilcox",
                          id: "expand_Up_Wilcox",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Up_Wilcox,true",
                          "Up Wilcox": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "upwilcox_depth",
                                lyrGroupName: "UpWilcox_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "Wellpenetrations_up_wilcox",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "Up Wilcox",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Up Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "up_wilcox_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Up_Wilcox'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Lr Wilcox",
                          id: "expand_Lr_Wilcox",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Lr_Wilcox,true",
                          "Lr Wilcox": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrwilcox_depth",
                                lyrGroupName: "LrWilcox_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "Wellpenetrations_lr_wilcox",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "Lr Wilcox",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Lr Wilcox",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_wilcox_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Wilcox'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    groupName: "Cretaceous",
                    id: "expand_Cretaceous",
                    categoryName: "southern_ar_ms_al",
                    Cretaceous: {
                      source: [
                        {
                          groupName: "Taylor",
                          id: "expand_taylor",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Taylor,true",
                          Taylor: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_depth",
                                lyrGroupName: "Taylor_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_taylor",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "Taylor",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Taylor",
                                radiogroupName: "miocene_storage",
                                lyrName: "taylor_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Taylor'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Austin",
                          id: "expand_austin",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Austin,true",
                          Austin: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_depth",
                                lyrGroupName: "Austin_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_austin",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "Austin",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Austin",
                                radiogroupName: "miocene_storage",
                                lyrName: "austin_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Austin'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Lr Tuscaloosa",
                          id: "expand_lr_tuscaloosa",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Lr_Tusca,true",
                          "Lr Tuscaloosa": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tusca_sa_depth",
                                lyrGroupName: "LrTuscaloosa_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_lr_tusca",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "Lr Tuscaloosa",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tusca_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Lr Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_tuscaloosa_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Tusca'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Lr Cretaceous",
                          id: "expand_cretaceous",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Lr_Creta,true",
                          "Lr Cretaceous": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_depth",
                                lyrGroupName: "LrCret_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_lr_creta",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "Lr Cretaceous",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Lr Cretaceous",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_cretaceous_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Lr_Creta'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Paluxy",
                          id: "expand_paluxy",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Paluxy,true",
                          Paluxy: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_depth",
                                lyrGroupName: "Paluxy_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_paluxy",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "Paluxy",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Paluxy",
                                radiogroupName: "miocene_storage",
                                lyrName: "paluxy_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Paluxy'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Trinity",
                          id: "expand_trinity",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Trinity,true",
                          Trinity: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_depth",
                                lyrGroupName: "Trinity_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_trinity",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "Trinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Trinity",
                                radiogroupName: "miocene_storage",
                                lyrName: "trinity_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Trinity'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Rodessa",
                          id: "expand_rodessa",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Rodessa,true",
                          Rodessa: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_depth",
                                lyrGroupName: "Rodessa_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_rodessa",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "Rodessa",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Rodessa",
                                radiogroupName: "miocene_storage",
                                lyrName: "rodessa_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Rodessa'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Sligo",
                          id: "expand_sligo",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Sligo,true",
                          Sligo: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_depth",
                                lyrGroupName: "Sligo_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_sligo",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "Sligo",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"                                
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Sligo",
                                radiogroupName: "miocene_storage",
                                lyrName: "sligo_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Sligo'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Pettet",
                          id: "expand_Pettet",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Pettet,true",
                          Pettet: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_Depth_Crop_COG",
                                lyrGroupName: "Pettet_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_pettet",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "Pettet",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Pettet",
                                radiogroupName: "miocene_storage",
                                lyrName: "Pettet_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Pettet'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Hosston-TravisPeak",
                          id: "expand_Hosston_TravisPeak",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter:
                            "formation_name,Hosston_Travis_Peak,true",
                          "Hosston-TravisPeak": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_depth",
                                lyrGroupName: "HTP_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_Hosston_Travis_Peak",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "Hosston-TravisPeak",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Hosston-TravisPeak",
                                radiogroupName: "miocene_storage",
                                lyrName: "hosston_travis_peak_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter:
                                  "formation_name='Hosston_Travis_Peak'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    groupName: "Jurassic",
                    id: "expand_Jurassic",
                    categoryName: "southern_ar_ms_al",
                    Jurassic: {
                      source: [
                        {
                          groupName: "Cotton Valley",
                          id: "expand_Cotton_Valley",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,CottonValley,true",
                          "Cotton Valley": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_depth",
                                lyrGroupName: "CV_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_cottonvalley",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "Cotton Valley",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "cotton_valley_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='CottonValley'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Lr Cotton Valley",
                          id: "expand_Lr_Cotton_Valley",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter:
                            "formation_name,LrCottonValley,true",
                          "Lr Cotton Valley": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_depth",
                                lyrGroupName: "LrCV_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_lrcottonvalley",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "Lr Cotton Valley",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Lr Cotton Valley",
                                radiogroupName: "miocene_storage",
                                lyrName: "lrcottonvalley_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrCottonValley'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Bossier",
                          id: "expand_Bossier",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Bossier,true",
                          Bossier: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_depth",
                                lyrGroupName: "Bossier_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_bossier",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "Bossier",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Bossier",
                                radiogroupName: "miocene_storage",
                                lyrName: "bossier_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Bossier'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Haynesville",
                          id: "expand_Haynesville",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Haynesville,true",
                          Haynesville: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_depth",
                                lyrGroupName: "Haynesville_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_haynesville",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "Haynesville",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Haynesville",
                                radiogroupName: "miocene_storage",
                                lyrName: "haynesville_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Haynesville'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Smackover",
                          id: "expand_Smackover",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Smackover,true",
                          Smackover: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_depth",
                                lyrGroupName: "Smackover_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_smackover",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "Smackover",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "smackover_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Smackover'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Lr Smackover",
                          id: "expand_Lr_Smackover",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,LrSmackover,true",
                          "Lr Smackover": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "LrSmackover_Depth_Crop_COG",
                                lyrGroupName: "LrSmackover_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_lrsmackover",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "Lr Smackover",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_storage",
                              },                            
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_thickness",
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Lr Smackover",
                                radiogroupName: "miocene_storage",
                                lyrName: "lr_smackover_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='LrSmackover'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Norphlet",
                          id: "expand_Norphlet",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,Norphlet,true",
                          Norphlet: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_depth",
                                lyrGroupName: "Norphlet_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_norphlet",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "Norphlet",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Norphlet",
                                radiogroupName: "miocene_storage",
                                lyrName: "norphlet_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Norphlet'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    groupName: "Triassic",
                    id: "expand_Triassic",
                    categoryName: "southern_ar_ms_al",
                    Triassic: {
                      source: [
                        {
                          groupName: "Eagle Mills",
                          id: "expand_Eagle_Mills",
                          exportlayerName: "storage_units_group",
                          exportLayerFilter: "formation_name,EagleMills,true",
                          "Eagle Mills": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_depth",
                                lyrGroupName: "EagleMills_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_eagle_mills",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "Eagle Mills",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Storage (Mt)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_storage",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_storage",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Net Thickness (Ft)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_thickness",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Effective Porosity (Fraction)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_porosity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_porosity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_permeability",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Temperature (Deg F)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_temp",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_temp",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Pressure (PSI)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_porepressure",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_PorePressure",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Salinity (ppm)",
                                groupName: "Eagle Mills",
                                radiogroupName: "miocene_storage",
                                lyrName: "eagle_mills_sa_salinity",
                                lyrGroupName: "storage_units_group",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='EagleMills'",
                                lyrStyle: "sa_salinity",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              groupName: "Barrier Units",
              id: "expand_Barrier_Units",
              southern_ar_ms_al: true,
              lyrGroupName: "barrier_units",
              "Barrier Units": {
                source: [
                  {
                    groupName: "Tertiary",
                    categoryName: "southern_ar_ms_al",
                    Tertiary: {
                      source: [
                        {
                          groupName: "Midway",
                          exportlayerName: "barrier_units",
                          exportLayerFilter: "formation_name,Midway,true",
                          id: "expand_Midway",
                          Midway: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Midway",
                                radiogroupName: "miocene_storage",
                                lyrName: "midway_depth_ft",
                                lyrGroupName: "Midway_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Midway",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_midway",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Midway'",
                                lyrStyle: "Midway",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Midway",
                                radiogroupName: "miocene_storage",
                                lyrName: "midway_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Midway'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Midway",
                                radiogroupName: "miocene_storage",
                                lyrName: "midway_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Midway'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    groupName: "Cretaceous",
                    categoryName: "southern_ar_ms_al",
                    Cretaceous: {
                      source: [
                        {
                          groupName: "Navarro",
                          id: "expand_Navarro",
                          exportlayerName: "barrier_units",
                          exportLayerFilter: "formation_name,Navarro,true",
                          Navarro: {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Navarro",
                                radiogroupName: "miocene_storage",
                                lyrName: "navarro_depth_ft",
                                lyrGroupName: "Navarro_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Navarro",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_navarro",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Navarro'",
                                lyrStyle: "Navarro",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Navarro",
                                radiogroupName: "miocene_storage",
                                lyrName: "navarro_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Navarro'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Navarro",
                                radiogroupName: "miocene_storage",
                                lyrName: "navarro_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Navarro'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Mid Tuscaloosa",
                          id: "expand_Mid_Tuscaloosa",
                          exportlayerName: "barrier_units",
                          exportLayerFilter:
                            "formation_name,Mid_Tuscaloosa,true",
                          "Mid Tuscaloosa": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Mid Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "mid_tuscaloosa_depth_ft",
                                lyrGroupName: "MidTuscaloosa_Depth_Crop_COG",
                                eleType: "checkbox",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Mid Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_mid_tuscaloosa",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Mid_Tuscaloosa'",
                                lyrStyle: "Mid Tuscaloosa",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Mid Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "mid_tuscaloosa_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Mid_Tuscaloosa'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Mid Tuscaloosa",
                                radiogroupName: "miocene_storage",
                                lyrName: "mid_tuscaloosa_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Mid_Tuscaloosa'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Ferry Lake",
                          id: "expand_Ferry_Lake",
                          exportlayerName: "barrier_units",
                          exportLayerFilter: "formation_name,Ferrylake,true",
                          "Ferry Lake": {
                            source: [
                              {
                                displayName: "Structure",
                                eleType: "checkbox",
                                groupName: "Ferry Lake",
                                radiogroupName: "miocene_storage",
                                lyrName: "ferry_lake_depth_ft",
                                lyrGroupName: "FerryLake_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Ferry Lake",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_ferrylake",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Ferrylake'",
                                lyrStyle: "Ferry Lake",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Ferry Lake",
                                radiogroupName: "miocene_storage",
                                lyrName: "ferry_lake_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Ferrylake'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Ferry Lake",
                                radiogroupName: "miocene_storage",
                                lyrName: "ferry_lake_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Ferrylake'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    groupName: "Jurassic",
                    categoryName: "southern_ar_ms_al",
                    Jurassic: {
                      source: [
                        {
                          groupName: "Buckner",
                          id: "expand_Buckner",
                          exportlayerName: "barrier_units",
                          exportLayerFilter: "formation_name,Buckner,true",
                          Buckner: {
                            source: [
                              {
                                displayName: "Structure",
                                groupName: "Buckner",
                                eleType: "checkbox",
                                radiogroupName: "miocene_storage",
                                lyrName: "buckner_depth_ft",
                                lyrGroupName: "Buckner_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Buckner",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_buckner",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Buckner'",
                                lyrStyle: "Buckner",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Buckner",
                                radiogroupName: "miocene_storage",
                                lyrName: "buckner_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Buckner'",
                                lyrStyle: "sa_thickness",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Buckner",
                                radiogroupName: "miocene_storage",
                                lyrName: "buckner_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Buckner'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                        {
                          groupName: "Louann",
                          id: "expand_Louann",
                          exportlayerName: "barrier_units",
                          exportLayerFilter: "formation_name,Louanne,true",
                          Louann: {
                            source: [
                              {
                                displayName: "Structure",
                                groupName: "Louann",
                                eleType: "checkbox",
                                radiogroupName: "miocene_storage",
                                lyrName: "louann_depth_ft",
                                lyrGroupName: "Louanne_Depth_Crop_COG",
                                visible: false,
                                legendData: null,
                                layerFilter: null,
                                lyrStyle: null,
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Well Penetration",
                                groupName: "Louann",
                                radiogroupName: "miocene_storage",
                                eleType: "checkbox",
                                lyrName: "wellpenetrations_louann",
                                lyrGroupName: "wellpenetrations",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Louanne'",
                                lyrStyle: "Louann",
                                alllowedRole:"CA_AR_S_SA"
                              },
                              {
                                displayName: "Non-reservoir Thickness (Ft)",
                                groupName: "Louann",
                                radiogroupName: "miocene_storage",
                                lyrName: "louann_thickness_ft",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Louanne'",
                                lyrStyle: "sa_thickness",
                              },
                              {
                                displayName: "Permeability (mD)",
                                groupName: "Louann",
                                radiogroupName: "miocene_storage",
                                lyrName: "louann_permeability",
                                lyrGroupName: "barrier_units",
                                visible: false,
                                legendData: null,
                                layerFilter: "formation_name='Louanne'",
                                lyrStyle: "sa_permeability",
                                alllowedRole:"CA_AR_S_SA"
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              groupName: "Basement",
              id: "expand_basement",
              southern_ar_ms_al: true,
              lyrGroupName: "barrier_units",
              exportlayerName: "midway_depth_ft",
              Basement: {
                source: [
                  {
                    displayName: "Structure",
                    groupName: "Basement",
                    radiogroupName: "miocene_storage",
                    lyrName: "Basement_depth_COG",
                    lyrGroupName: "Basement_depth_COG",
                    visible: false,
                    legendData: null,
                    layerFilter: null,
                    lyrStyle: null,
                    alllowedRole:"CA_AR_S_SA"
                  },
                  {
                    displayName: "Well Penetration",
                    groupName: "Basement",
                    radiogroupName: "miocene_storage",
                    eleType: "checkbox",
                    lyrName: "wellpenetrations_basement",
                    lyrGroupName: "wellpenetrations",
                    visible: false,
                    legendData: null,
                    layerFilter: "formation_name='Basement'",
                    lyrStyle: "Basement",
                    alllowedRole:"CA_AR_S_SA"
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  ExportLayerFieldConfig: [
    {
      lyrName: "gc_storage_resources_pools",
      requiredField: [
        { pool_name: "Pool Name" },
        { m_stor_mt: "Mid(P50)Storage(Mt)" },
        { h_stor_mt: "High(P90)Storage(Mt)" },
        { l_stor_mt: "Low(P10)Storage(Mt)" },
        { stor_cnfl: "Storage Confidence" },
        { county_par: "County/Parish" },
        { cdl_fldnm: "Field" },
        { pool_type: "Major Hydrocarbon Phase" },
        { cdl_prodfm: "Producing Formation(s)" },
        { cdl_ag_nm: "Producing Formation Age" },
        { drive: "Reservoir Drive" },
        { area_acres: "Area (acres)" },
        { well_dens: "Well (Producers) Density (no/sq mile)" },
        { cumoil_bbl: "Cum Oil Production (bbls)" },
        { cumgas_mcf: "Cum Gas Production (mcf)" },
        { cumwat_bbl: "Cum Water Production (bbls)" },
        { prjoil_bbl: "Projected Oil Production (bbls)" },
        { prjgas_mcf: "Projected Gas Production (mcf)" },
        { prjwat_bbl: "Projected Water Production (bbls)" },
        { eor_suit: "EOR Suitability " },
        { rock_primy: "Primary Lithology" },
        { rock_secnd: "Secondary Lithology" },
        { rock_other: "Other Lithologies" },
        { l_mdepth_f: "Low(P10) TVD Depth (ft)" },
        { m_mdepth_f: "Median(P50) TVD Depth (ft)" },
        { h_mdepth_f: "High(P90) TVD Depth (ft)" },
        { depth_cnfl: "TVD Depth Confidence" },
        { l_netres_f: "Low(P10) Net Reservoir Thickness (ft)" },
        { m_netres_f: "Median(P50) Net Reservoir Thickness (ft)" },
        { h_netres_f: "High(P90) Net Reservoir Thickness (ft)" },
        { netrs_dtsr: "Net Reservoir Data Source" },
        { netrs_cnfl: "Net Reservoir Thickness Confidence" },
        { l_por_frac: "Low(P10) Porosity (fraction)" },
        { m_por_frac: "Median(P50) Porosity (fraction)" },
        { h_por_frac: "High(P90) Porosity (fraction)" },
        { por_dtsr: "Porosity Data Source" },
        { por_cnfl: "Porosity Confidence" },
        { l_perm_md: "Low(P10) Permeablity (mD)" },
        { m_perm_md: "Median(P50) Permeablity (mD)" },
        { h_perm_md: "High(P90) Permeablity (mD)" },
        { perm_dtsr: "Permeability Data Source" },
        { perm_cnfl: "Permeablilty Confidence" },
        { l_temp_dgf: "Low(P10) Temperature (°F)" },
        { m_temp_dgf: "Median(P50) Temperature (°F)" },
        { h_temp_dgf: "High(P90) Temperature (°F)" },
        { temp_dtsr: "Temperature Data Source" },
        { temp_cnfl: "Temperature Confidence " },
        { l_pres_psi: "Low(P10) Pressure (psia)" },
        { m_pres_psi: "Median(P50) Pressure (psia)" },
        { h_pres_psi: "High(P90) Pressure (psia)" },
        { pres_dtsr: "Pressure Data Source" },
        { pres_cnfl: "Pressure Confidence" },
        { l_mmp_psi: "Low(P10) Min Miscibility Pressure (psia)" },
        { m_mmp_psi: "Median(P50) Min Miscibility Pressure (psia)" },
        { h_mmp_psi: "High(P90) Min Miscibility Pressure (psia)" },
        { eor_note: "EOR_Notes" },
        { l_gor_scfb: "Low(P10) GOR (scf/bbl)" },
        { m_gor_scfb: "Median(P50) GOR (scf/bbl)" },
        { h_gor_scfb: "High(P90) GOR (scf/bbl)" },
        { gor_dtsr: "GOR Data Source" },
        { gor_cnfl: "GOR Confidence" },
        { l_api_grav: "Low(P10) Oil Gravity (°API)" },
        { m_api_grav: "Median(P50) Oil Gravity (°API)" },
        { h_api_grav: "High(P90) Oil Gravity (°API)" },
        { apigv_dtsr: "Oil Gravity Data Source" },
        { apigv_cnfl: "Oil Gravity Confidence" },
        { l_fvf: "Low(P10) Formation Volume Factor" },
        { m_fvf: "Median(P50) Formation Volume Factor" },
        { h_fvf: "High(P90) Formation Volume Factor" },
        { fvf_dtsr: "FVF Data Source" },
        { fvf_cnfl: "FVF Confidence" },
        { l_z_factor: "Low(P10) Z Factor" },
        { m_z_factor: "Median(P50) Z Factor" },
        { h_z_factor: "High(P90) Z Factor" },
        { z_fac_dtsr: "Z Factor Data Source" },
        { z_fac_cnfl: "Z Factor Confidence" },
        { l_sal_ppm: "Low(P10) Salinity (PPM)" },
        { m_sal_ppm: "Median(P50) Salinity (PPM)" },
        { h_sal_ppm: "High(P90) Salinity (PPM)" },
        { sal_dtsr: "Salinity Data Source" },
        { sal_cnfl: "Salinity Confidence" },
        { lco2d_kgm3: "Low(P10) CO2 Density (kg/m3)" },
        { mco2d_kgm3: "Median(P50) CO2 Density (kg/m3)" },
        { hco2d_kgm3: "High(P90) CO2 Density (kg/m3)" },
        { co2dn_dtsr: "CO2 Density Data Source" },
        { co2dn_cnfl: "CO2 Density Confidence" },
      ],
      eliminateField: [
        "geometry",
        "rec_mode",
        "comments",
        "shape_length",
        "shape_area",
        "cdl_pol_id",
        "cdl_age_cd",
        "state",
        "stor_dtsr",
      ],
    },
    {
      lyrName: "southern_arkansas_pool_polygons",
      requiredField: [
        { pool_name: "Pool Name" },
        { m_stor_mt: "Median(P50)Storage(Mt)" },
        { h_stor_mt: "High(P90)Storage(Mt)" },
        { l_stor_mt: "Low(P10)Storage(Mt)" },
        { cdl_fldnm: "Field" },
        { pool_type: "Major HC Phase" },
        { cdl_prodfm: "Producing Formation" },
        { cdl_ag_nm: "Formation Age" },
        { area_acres: "Area (acres)" },
        { cumoil_bbl: "Cum Oil Production (bbls)" },
        { cumgas_mcf: "Cum Gas Production (mcf)" },
        { cumwat_bbl: "Cum Water Production (bbls)" },
        { prjoil_bbl: "Projected Ultimate Oil Production (bbls)" },
        { prjgas_mcf: "Projected Ultimate Gas Production (mcf)" },
        { prjwat_bbl: "Projected Ultimate Water Production (bbls)" },
        { percent__depl: "Depletion(%)" },
        { rock_primy: "Primary Lithology" },
        { rock_secnd: "Secondary Lithology" },
        { lmdepf: "Low(P10 ) Depth (ft)" },
        { mmdepf: "Median(P50 ) Depth (ft)" },
        { hmdepf: "High(P90 ) Depth (ft)" },
        { l_netres_f: "Low(P10 ) NetThickness (ft)" },
        { m_netres_f: "Median(P50 ) NetThickness (ft)" },
        { h_netres_f: "High(P90 )NetThickness (ft)" },
        { lporfrac: "Low(P10) Porosity (fraction)" },
        { mporfrac: "Median(P50) Porosity (fraction)" },
        { hporfrac: "High(P90) Porosity (fraction)" },
        { l_perm_md: "Low(P10) Permeablity (mD)" },
        { m_perm_md: "Median(P50) Permeablity (mD)" },
        { h_perm_md: "High(P90) Permeablity (mD)" },
        { ltempdgf: "Low(P10) Temperature (°F)" },
        { mtempdgf: "Median(P50) Temperature (°F)" },
        { htempdgf: "High(P90) Temperature (°F)" },
        { lprespsi: "Low(P10) Pressure (psi)" },
        { mprespsi: "Median(P50) Pressure (psi)" },
        { hprespsi: "High(P90) Pressure (psi)" },
        { foregorultimate: "Projected Ultimate GOR (scf/bbl)" },
        { l_fvf: "Low Formation Volume Factor" },
        { m_fvf: "Median Formation Volume Factor" },
        { h_fvf: "High Formation Volume Factor" },
        { l_z_factor: "Low(P10) Z Factor" },
        { m_z_factor: "Median(P50) Z Factor" },
        { h_z_factor: "High(P90) Z Factor" },
        { lco2d_kgm3: "Low(P10) CO2 Density (kg/m3)" },
        { mco2d_kgm3: "Median(P50) CO2 Density (kg/m3)" },
        { hco2d_kgm3: "High(P90) CO2 Density (kg/m3)" },
      ],
      eliminateField: [
        "geometry",
        "rec_mode",
        "comments",
        "shape_length",
        "shape_area",
        "cdl_pol_id",
        "cdl_age_cd",
        "state",
        "stor_dtsr",
        "cumgor_new_cum",
        "county_par",
      ],
    },
    {
      lyrName: "gc_saline_upmiocene",
      requiredField: [
        { target_fid: "GridNumber" },
        { latitude: "Latitude" },
        { longitude: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { stor_std: "StorageStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { thick_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { por_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PorePressure Mean(PSI)" },
        { porepr_std: "PorePressure StdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salin_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "ogc_fid",
        "shape_leng",
        "stor_p10",
        "eff_p10",
        "eff_p50",
        "eff_p90",
        "data_avail",
        "depth_m",
        "depth_std",
        "join_count",
        "join_cou_1",
        "target_f_1"


      ],
    },
    {
      lyrName: "gc_saline_midmiocene",
      requiredField: [
        { target_fid: "GridNumber" },
        { latitude: "Latitude" },
        { longitude: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { stor_std: "StorageStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { thick_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { por_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PorePressure Mean(PSI)" },
        { porepr_std: "PorePressure StdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salin_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "ogc_fid",
        "shape_leng",
        "stor_p10",
        "eff_p10",
        "eff_p50",
        "eff_p90",
        "data_avail",
        "depth_m",
        "depth_std",
        "join_count",
        "join_cou_1",
        "target_f_1"
      ],
    },
    {
      lyrName: "gc_saline_lrmiocene_shallow",
      requiredField: [
        { target_fid: "GridNumber" },
        { latitude: "Latitude" },
        { longitude: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { stor_std: "StorageStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { thick_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { por_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PorePressure Mean(PSI)" },
        { porepr_std: "PorePressure StdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salin_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "ogc_fid",
        "shape_leng",
        "stor_p10",
        "eff_p10",
        "eff_p50",
        "eff_p90",
        "data_avail",
        "depth_m",
        "depth_std",
        "join_count",
        "join_cou_1",
        "target_f_1"


      ],
    },
    {
      lyrName: "gc_saline_lrmiocene_deep",
      requiredField: [
        { target_fid: "GridNumber" },
        { latitude: "Latitude" },
        { longitude: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { stor_std: "StorageStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { thick_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { por_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PorePressure Mean(PSI)" },
        { porepr_std: "PorePressure StdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salin_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "ogc_fid",
        "shape_leng",
        "stor_p10",
        "eff_p10",
        "eff_p50",
        "eff_p90",
        "data_avail",
        "depth_m",
        "depth_std",
        "join_count",
        "join_cou_1",
        "target_f_1"


      ],
    },
    // {
    //   lyrName: "midmiocene_storage_new",
    //   requiredField: [
    //     { grid: "GridNumber" },
    //     { latitude: "Latitude" },
    //     { longitude: "Longitude" },
    //     { stor_p50: "Mid(P50)Storage(Mt)" },
    //     { stor_p90: "High(P90)Storage(Mt)" },
    //     { stor_p10: "Low(P10)Storage(Mt)" },
    //     { eff_p50: "Mid(P50)StorageEfficiency" },
    //     { eff_p90: "High(P90)StorageEfficiency" },
    //     { eff_p10: "Low(P10)StorageEfficiency" },
    //     { data_avail: "Data Availability" },
    //     { nthick_m: "NetThicknessMean(m)" },
    //     { nthickstd: "NetThicknessStdDev" },
    //     { effpor_m: "EffectivePorosityMean(%)" },
    //     { effporstd: "EffectivePorosityStdDev" },
    //     { temp_m: "TemperatureMean(°C)" },
    //     { tstddev: "TemperatureStdDev" },
    //     { pressure_m: "PressureMean(Mpa)" },
    //     { pstddev: "PressureStdDev" },
    //     { perm_m: "PermeabilityMean(Mpa)" },
    //     { perm_std: "PermeabilityStdDev" },
    //     { salinity_m: "SalinityMean(Mpa)" },
    //     { salinity_s: "SalinityStdDev" },
    //   ],
    //   eliminateField: [
    //     "geometry",
    //     "shape_length",
    //     "shape_area",
    //     "ogc_fid",
    //     "shape_leng",
    //   ],
    // },
    // {
    //   lyrName: "lrmiocene_shallow_storage",
    //   requiredField: [
    //     { grid: "GridNumber" },
    //     { latitude: "Latitude" },
    //     { longitude: "Longitude" },
    //     { stor_p50: "Mid(P50)Storage(Mt)" },
    //     { stor_p90: "High(P90)Storage(Mt)" },
    //     { stor_p10: "Low(P10)Storage(Mt)" },
    //     { eff_p50: "Mid(P50)StorageEfficiency" },
    //     { eff_p90: "High(P90)StorageEfficiency" },
    //     { eff_p10: "Low(P10)StorageEfficiency" },
    //     { data_avail: "Data Availability" },
    //     { nthick_m: "NetThicknessMean(m)" },
    //     { nthickstd: "NetThicknessStdDev" },
    //     { effpor_m: "EffectivePorosityMean(%)" },
    //     { effporstd: "EffectivePorosityStdDev" },
    //     { temp_m: "TemperatureMean(°C)" },
    //     { tstddev: "TemperatureStdDev" },
    //     { pressure_m: "PressureMean(Mpa)" },
    //     { pstddev: "PressureStdDev" },
    //     { perm_m: "PermeabilityMean(Mpa)" },
    //     { perm_std: "PermeabilityStdDev" },
    //     { salinity_m: "SalinityMean(Mpa)" },
    //     { salinity_s: "SalinityStdDev" },
    //   ],
    //   eliminateField: [
    //     "geometry",
    //     "shape_length",
    //     "shape_area",
    //     "ogc_fid",
    //     "shape_leng",
    //   ],
    // },
    // {
    //   lyrName: "lrmiocene_deep_storage",
    //   requiredField: [
    //     { grid: "GridNumber" },
    //     { latitude: "Latitude" },
    //     { longitude: "Longitude" },
    //     { stor_p50: "Mid(P50)Storage(Mt)" },
    //     { stor_p90: "High(P90)Storage(Mt)" },
    //     { stor_p10: "Low(P10)Storage(Mt)" },
    //     { eff_p50: "Mid(P50)StorageEfficiency" },
    //     { eff_p90: "High(P90)StorageEfficiency" },
    //     { eff_p10: "Low(P10)StorageEfficiency" },
    //     { data_avail: "Data Availability" },
    //     { nthick_m: "NetThicknessMean(m)" },
    //     { nthickstd: "NetThicknessStdDev" },
    //     { effpor_m: "EffectivePorosityMean(%)" },
    //     { effporstd: "EffectivePorosityStdDev" },
    //     { temp_m: "TemperatureMean(°C)" },
    //     { tstddev: "TemperatureStdDev" },
    //     { pressure_m: "PressureMean(Mpa)" },
    //     { pstddev: "PressureStdDev" },
    //     { perm_m: "PermeabilityMean(Mpa)" },
    //     { perm_std: "PermeabilityStdDev" },
    //     { salinity_m: "SalinityMean(Mpa)" },
    //     { salinity_s: "SalinityStdDev" },
    //   ],
    //   eliminateField: [
    //     "geometry",
    //     "shape_length",
    //     "shape_area",
    //     "ogc_fid",
    //     "shape_leng",
    //   ],
    // },
    {
      lyrName: "gc_storage_resources",
      requiredField: [
        { strunt_nm: "StorageUnit Name" },
        { mstormt: "Mid(P50)Storage(Mt)" },
        { hstormt: "High(P90)Storage(Mt)" },
        { lstormt: "Low(P10)Storage(Mt)" },
        { stor_cnfl: "StorageConfidence" },
        { county: "County/Parish" },
        { cdl_fldgp: "Field" },
        { pool_type: "Major Hydrocarbon Phase" },
        { cdl_prdgp: "Producing Formation(s)" },
        { cdl_ag_nm: "Producing Formation Age" },
        { drive: "Reservoir Drive" },
        { acres: "Area (acres)" },
        { well_count: "Well Count" },
        { well_dens: "Well (Producers) Density (no/sq mile)" },
        { cuoilbbl: "Cum Oil Production (bbls)" },
        { cugasmcf: "Cum Gas Production (mcf)" },
        { cuwatbbl: "Cum Water Production (bbls)" },
        { proilbbl: "Projected Oil Production (bbls)" },
        { prgasmcf: "Projected Gas Production (mcf)" },
        { prwatbbl: "Projected Water Production (bbls)" },
        { rock_primy: "Primary Lithology" },
        { rock_secnd: "Secondary Lithology" },
        { rock_other: "Other Lithologies" },
        { lmdepf: "Low(P10 ) TVD Depth (ft)" },
        { mmdepf: "Median(P50 ) TVD Depth (ft)" },
        { hmdepf: "High(P90 ) TVD Depth (ft)" },
        { depth_cnfl: "TVD Depth Confidence" },
        { lnetresf: "Low(P10 ) Net Reservoir Thickness (ft)" },
        { mnetresf: "Median(P50 ) Net Reservoir Thickness (ft)" },
        { hnetresf: "High(P90 )Net Reservoir Thickness (ft)" },
        { netrs_dtsr: "Net Reservoir Data Source" },
        { netrs_cnfl: "Net Reservoir Thickness Confidence" },
        { lporfrac: "Low(P10 ) Porosity (fraction)" },
        { mporfrac: "Median(P50 ) Porosity (fraction)" },
        { hporfrac: "High(P90 )Porosity (fraction)" },
        { por_dtsr: "Porosity Data Source" },
        { por_cnfl: "Porosity Confidence" },
        { lpermmd: "Low(P10 ) Permeablity (mD)" },
        { mpermmd: "Median(P50 ) Permeablity (mD)" },
        { hpermmd: "High(P90) Permeablity (mD)" },
        { perm_dtsr: "Permeability Data Source" },
        { perm_cnfl: "Permeablilty Confidence" },
        { ltempdgf: "Low(P10) Temperature (°F)" },
        { mtempdgf: "Median(P50) Temperature (°F)" },
        { htempdgf: "High(P90) Temperature (°F)" },
        { temp_dtsr: "Temperature Data Source" },
        { temp_cnfl: "Temperature Confidence " },
        { lprespsi: "Low(P10) Pressure (psia)" },
        { mprespsi: "Median(P50) Pressure (psia)" },
        { hprespsi: "High(P90) Pressure (psia)" },
        { pres_dtsr: "Pressure Data Source" },
        { pres_cnfl: "Pressure Confidence" },
        { lgorscfb: "Low(P10) GOR (scf/bbl)" },
        { mgorscfb: "Median(P50) GOR (scf/bbl)" },
        { hgorscfb: "High(P90) GOR (scf/bbl)" },
        { gor_dtsr: "GOR Data Source" },
        { gor_cnfl: "GOR Confidence" },
        { lapigrav: "Low(P10) Oil Gravity (°API)" },
        { mapigrav: "Median(P50) Oil Gravity (°API)" },
        { hapigrav: "High(P90) Oil Gravity (°API)" },
        { apigv_dtsr: "Oil Gravity Data Source" },
        { apigv_cnfl: "Oil Gravity Confidence" },
        { l_fvf: "Low(P10) Formation Volume Factor" },
        { m_fvf: "Median(P50) Formation Volume Factor" },
        { h_fvf: "High(P90) Formation Volume Factor" },
        { fvf_dtsr: "FVF Data Source" },
        { fvf_cnfl: "FVF Confidence" },
        { l_z_fac: "Low(P10) Z Factor" },
        { m_z_fac: "Median(P50) Z Factor" },
        { h_z_fac: "High(P90) Z Factor" },
        { z_fac_dtsr: "Z Factor Data Source" },
        { z_fac_cnfl: "Z Factor Confidence" },
        { l_sal_ppm: "Low(P10) Salinity (PPM)" },
        { m_sal_ppm: "Median(P50) Salinity (PPM)" },
        { h_sal_ppm: "High(P90) Salinity (PPM)" },
        { sal_dtsr: "Salinity Data Source" },
        { sal_cnfl: "Salinity Confidence" },
        { lco2kgm3: "Low(P10) CO2 Density (kg/m3)" },
        { mco2kgm3: "Median(P50) CO2 Density (kg/m3)" },
        { hco2kgm3: "High(P90) CO2 Density (kg/m3)" },
        { co2dn_dtsr: "CO2 Density Data Source" },
        { co2dn_cnfl: "CO2 Density Confidence" },
      ],
      eliminateField: [
        "geometry",
        "shape_leng",
        "cdl_strunt",
        "cdlagecd",
        "cdl_prodfm",
        "state",
        "stor_dtsr",
      ],
    },
    {
      lyrName: "southern_arkansas_su_pool_polygons",
      requiredField: [
        { pool_name: "Storage Unit Name" },
        { mstormt: "Mid(P50)Storage(Mt)" },
        { hstormt: "High(P90)Storage(Mt)" },
        { lstormt: "Low(P10)Storage(Mt)" },
        { cdl_fldnm: "Field" },
        { pool_type: "Major HC Phase" },
        { cdl_prodfm: "Producing Formation" },
        { cdl_ag_nm: "Formation Age" },
        { acres: "Area (acres)" },
        { well_count: "Well Count" },
        { cuoilbbl: "Cum Oil Production (bbls)" },
        { cugasmcf: "Cum Gas Production (mcf)" },
        { cuwatbbl: "Cum Water Production (bbls)" },
        { cuminjectionliquid_cum: "Cum Liquid Injection" },
        { cuminjectiongas_cum: "Cum Gas Injection" },
        { prjoil_bbl: "Projected Ultimate Oil Production (bbls)" },
        { prjgas_mcf: "Projected Ultimate Gas Production (mcf)" },
        { prjwat_bbl: "Projected Ultimate Water Production (bbls)" },
        { rock_primy: "Primary Lithology" },
        { rock_secnd: "Secondary Lithology" },
        { lmdepf: "Low(P10 ) Depth (ft)" },
        { mmdepf: "Median(P50 ) Depth (ft)" },
        { hmdepf: "High(P90 ) Depth (ft)" },
        { l_netres_f: "Low(P10 ) NetThickness (ft)" },
        { m_netres_f: "Median(P50 ) NetThickness (ft)" },
        { h_netres_f: "High(P90 )NetThickness (ft)" },
        { lporfrac: "Low(P10 ) Porosity (fraction)" },
        { mporfrac: "Median(P50 ) Porosity (fraction)" },
        { hporfrac: "High(P90 ) Porosity (fraction)" },
        { lpermmd: "Low(P10 ) Permeablity (mD)" },
        { mpermmd: "Median(P50 ) Permeablity (mD)" },
        { hpermmd: "High(P90) Permeablity (mD)" },
        { ltempdgf: "Low(P10) Temperature (°F)" },
        { mtempdgf: "Median(P50) Temperature (°F)" },
        { htempdgf: "High(P90) Temperature (°F)" },
        { lprespsi: "Low(P10) Pressure (psi)" },
        { mprespsi: "Median(P50) Pressure (psi)" },
        { hprespsi: "High(P90) Pressure (psi)" },
        { foregorultimate: "Projected Ultimate GOR (scf/bbl)" },
        { l_fvf: "Low Formation Volume Factor" },
        { m_fvf: "Median Formation Volume Factor" },
        { h_fvf: "High Formation Volume Factor" },
        { l_z_factor: "Low(P10) Z Factor" },
        { m_z_factor: "Median(P50) Z Factor" },
        { h_z_factor: "High(P90) Z Factor" },
        { lco2d_kgm3: "Low(P10) CO2 Density (kg/m3)" },
        { mco2d_kgm3: "Median(P50) CO2 Density (kg/m3)" },
        { hco2d_kgm3: "High(P90) CO2 Density (kg/m3)" },
      ],
      eliminateField: [
        "geometry",
        "shape_leng",
        "cdl_strunt",
        "cdlagecd",
        "state",
        "stor_dtsr",
        "cumgor",
        "shape_area",
        "shape_length",
      ],
    },
    {
      lyrName: "storage_units_group",
      requiredField: [
        { target_fid: "GridNumber" },
        { lat: "Latitude" },
        { long: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { storage_std: "StorageStdDev" },
        { depth_mean: "DepthMean (Ft)" },
        { depth_std: "DepthStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { netres_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { porosity_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PressureMean(PSI)" },
        { porepr_std: "PressureStdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salinity_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "shape_leng",
        "ogc_fid",
      ],
    },
    {
      lyrName: "barrier_units",
      requiredField: [
        { target_fid: "GridNumber" },
        { lat: "Latitude" },
        { long: "Longitude" },
        { storage_mean: "StorageMean(Mt)" },
        { storage_std: "StorageStdDev" },
        { depth_mean: "DepthMean (Ft)" },
        { depth_std: "DepthStdDev" },
        { thickness_mean: "NetThicknessMean(Ft)" },
        { netres_std: "NetThicknessStdDev" },
        { porosity_mean: "EffectivePorosityMean(Fraction)" },
        { porosity_std: "EffectivePorosityStdDev" },
        { perm_mean: "PermeabilityMean (mD)" },
        { perm_std: "PermeabilityStdDev" },
        { temp_mean: "TemperatureMean(°F)" },
        { temp_std: "TemperatureStdDev" },
        { porepr_mean: "PressureMean(PSI)" },
        { porepr_std: "PressureStdDev" },
        { salinity_mean: "SalinityMean (ppm)" },
        { salinity_std: "SalinityStdDev" },
      ],
      eliminateField: [
        "geometry",
        "shape_length",
        "shape_area",
        "ogc_fid",
        "shape_leng",
      ],
    },
  ],
};
