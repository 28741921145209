import axios from "axios";
import $ from "jquery";
import { getCenter } from "ol/extent";
import Feature from "ol/Feature";
import { GeoJSON } from "ol/format";
import { MultiPolygon, Polygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";
import VectorSource from "ol/source/Vector";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style
} from "ol/style";
import EmittersData from "../../../src/assets/data/EmittersData.json";
import config from "../../../src/configs/appSettings";
import { createfilterSummaryCard, createSummaryCard } from "./SummaryCardFunc";

const image = new CircleStyle({
  radius: 5,
  fill: new Fill({
    color: "red",
  }),
  stroke: new Stroke({ color: "red", width: 1 }),
});

const highlightStylePoint = new Style({
  image: new RegularShape({
    fill: new Fill({ color: "#666666" }),
    stroke: new Stroke({ color: "#bada55", width: 1 }),
    points: 4,
    radius: 9,
    angle: Math.PI / 4,
  }),
});
const highlightStyleCircle = new Style({
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: "#000080",
    }),
  }),
});

const highlightStyleLine = new Style({
  stroke: new Stroke({
    color: "black",
    width: 2,
  }),
});

const pointFeatures = ["epa_emitters", "wood_pellet_plants_2019"];

var highlightStylePoly = new Style({
  fill: new Fill({
    color: "transparent",
  }),
  stroke: new Stroke({
    color: "#000",
    width: 2,
  }),
});

const arrowClick = (selected) => {
  var highlightStylePolyNew = new Style({
    fill: new Fill({
      color: selected && selected.layer_color ? selected.layer_color : "transparent",
    }),
    stroke: new Stroke({
      color: "#000",
      width: 2,
    }),
  });
  try {
    if (typeof selected.id_ === "number") {
      if (selected.getGeometry().getType() === "LineString")
        selected.setStyle(highlightStyleLine);
      else selected.setStyle(highlightStyleCircle);
    } else {
      if (pointFeatures.includes(selected.id_.split(".")[0]))
        selected.setStyle(highlightStylePoint);
      else {
        selected.setStyle(highlightStylePolyNew);
      }
    }
  } catch (e) {}
};

const mapSelector = (selected, map) => {
  map
    .getLayers()
    .getArray()
    .filter((layer) => layer.get("name") === "pool_highlightfeature")
    .forEach((layer) => map.removeLayer(layer));
  const source = new VectorSource({
    features: new GeoJSON().readFeatures(selected),
  });

  const layerFeatures = new VectorLayer({
    source: source,
    style: new Style({
      stroke: new Stroke({
        color: "#000",
        width: 2,
      }),
      fill: new Fill({
        color: "transparent",
      }),
    }),
  });
  layerFeatures.set("name", "pool_highlightfeature");
  map.addLayer(layerFeatures);
};
export const GetSortOrder = (prop) => {
  return function (a, b) {
    if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
      return 1;
    } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
      return -1;
    }

    return 0;
  };
};

export const sortByProperty = (property, sort_c) => {
  return function (a, b) {
    if (sort_c === "asc") {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;

      return 0;
    } else {
      if (a[property] < b[property]) return 1;
      else if (a[property] > b[property]) return -1;

      return 0;
    }
  };
};

export const summaryCaardPopupTempalte = (
  layerTitleName,
  layerData,
  layerIndex,
  featureSummaryLength,
  selected,
  map,
  southerLayerStyle
) => {
  var popup = layerData;
  let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");
  basemapInfo.style.display = "block";
  if (featureSummaryLength[0].length === 0 && !featureSummaryLength[0][0].id) {
    basemapInfo.innerHTML = "";
    basemapInfo.style.display = "none";
  }

  if (featureSummaryLength[0].length === 0 && !featureSummaryLength[0][0].id) {
    basemapInfo.innerHTML = "";
    basemapInfo.style.display = "none";
  }

  let wmsdata = true;
  let GC_Miocene = [
    "gc_saline_upmiocene",
    "gc_saline_midmiocene",
    "gc_saline_lrmiocene_shallow",
    "gc_saline_lrmiocene_deep"
  ]
  // let storage = [
  //   "uppermiocene_storage",
  //   "midmiocene_storage_new",
  //   "lrmiocene_shallow_storage",
  //   "lrmiocene_deep_storage",
  //   "vicksburg_sa_storage",
  // ];
  let southernStorageLayer = ["sa_storage", "gc_storage"];

  let southernLayerOthers = [
    "sa_thickness",
    "sa_porosity",
    "sa_permeability",
    "sa_temp",
    "sa_PorePressure",
    "sa_salinity",
    "gc_thickness",
    "gc_porosity",
    "gc_perm",
    "gc_temp",
    "gc_porepr",
    "gc_salinity",
  ];

  // let thickness = [
  //   "uppermiocene_thickness",
  //   "midmiocene_thickness_new",
  //   "lrmiocene_shallow_thickness",
  //   "lrmiocene_deep_thickness",
  // ];
  // let porosity = [
  //   "uppermiocene_porosity",
  //   "midmiocene_porosity_new",
  //   "lrmiocene_shallow_porosity",
  //   "lrmiocene_deep_porosity",
  // ];
  // let temprature = [
  //   "uppermiocene_temp",
  //   "midmiocene_temp_new",
  //   "lrmiocene_shallow_temp",
  //   "lrmiocene_deep_temp",
  // ];
  // let pressure = [
  //   "uppermiocene_press",
  //   "midmiocene_press_new",
  //   "lrmiocene_shallow_pressure",
  //   "lrmiocene_deep_press",
  // ];
  // let permeability = [
  //   "uppermiocene_perm",
  //   "midmiocene_perm",
  //   "lrmiocene_shallow_perm",
  //   "lrmiocene_deep_perm",
  // ];
  // let salinity = [
  //   "uppermiocene_salinity",
  //   "midmiocene_salinity",
  //   "lrmiocene_shallow_salinity",
  //   "lrmiocene_deep_salinity",
  // ];
  try {
    if (typeof popup[layerIndex].id_ === "number") wmsdata = false;
    else {
      popup[layerIndex].id_.split(".");
      wmsdata = false;
    }
  } catch (e) {}

  if (!wmsdata) {
    if (typeof popup[layerIndex].id_ !== "number") {
      if (popup[layerIndex].id_.split(".")[0] === "epa_emitters") {
        let contentInfo = popup[layerIndex].values_;
        layerTitleName = "Emitter Summary Card";
        let filterViewData = ["facility_name", "co2_emissions_value"];
        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent">';
        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan3" class="textspan"></span><span id="leftArrowSummaryCard3" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard3" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard3" class="closesummary">X</span></div>';

        for (let i = 0; i < filterViewData.length; i++) {
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview">';
          if (filterViewData[i] === "facility_name") {
            filterSummaryCard +=
              '<div class="summarycardLabel">' + "Emitter Name" + "</div>";
          } else if (filterViewData[i] === "co2_emissions_value") {
            filterSummaryCard +=
              '<div class="summarycardLabel">' +
              "CO2 Emissions(Tons/Yr)" +
              "</div>";
          }
          if (typeof contentInfo[filterViewData[i]] === "number") {
            filterSummaryCard +=
              '<div class="summarycardvalue">' +
              Number(contentInfo[filterViewData[i]].toFixed(1)).toLocaleString(
                "en-AU"
              ) +
              "</div>";
          } else {
            filterSummaryCard +=
              '<div class="summarycardvalue">' +
              contentInfo[filterViewData[i]] +
              "</div>";
          }
          filterSummaryCard += "</div>";
        }

        var temp_dict = [];

        EmittersData.map((values) => {
          if (
            contentInfo["facility_id"] === values.Facility_ID &&
            values.Feasible_YN === "Y"
          ) {
            temp_dict.push({
              CDL_STRUNT: values.CDL_STRUNT,
              DIST_Miles: values.DIST_Miles,
            });
          }
        });

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Nearest Feasible Storage Options" +
          "</div> </div>";

        temp_dict = temp_dict.sort(sortByProperty("DIST_Miles", "asc"));
        if (temp_dict.length > 0) {
          let len_dict = temp_dict.length;
          let temp_var = 3;
          if (len_dict < 3) temp_var = len_dict;
          for (let i = 0; i < temp_var; i++) {
            filterSummaryCard +=
              '<div class="summarycardview moresummview filterview">';
            filterSummaryCard +=
              '<div class="summarycardLabel">' +
              temp_dict[i]["CDL_STRUNT"] +
              '</div><div class="summarycardvalue">' +
              Number(temp_dict[i]["DIST_Miles"].toFixed(1)).toLocaleString(
                "en-AU"
              ) +
              " miles away" +
              "</div>";
            filterSummaryCard += "</div>";
          }
        }

        temp_dict = [];
        EmittersData.map((values) => {
          if (
            contentInfo["facility_id"] === values.Facility_ID &&
            values.Feasible_YN === "Y"
          ) {
            temp_dict.push({
              CDL_STRUNT: values.CDL_STRUNT,
              M_STOR_MT: Number(values.M_STOR_MT.toFixed(3)).toLocaleString(
                "en-AU"
              ),
            });
          }
        });
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Largest Feasible Storage Options" +
          "</div> </div>";

        temp_dict = temp_dict.sort(sortByProperty("M_STOR_MT", "asc"));
        if (temp_dict.length > 0) {
          let len_dict = temp_dict.length;
          let temp_var = 3;
          if (len_dict < 3) temp_var = len_dict;
          for (let i = len_dict - 1; i >= len_dict - temp_var; i--) {
            filterSummaryCard +=
              '<div class="summarycardview moresummview filterview">';
            filterSummaryCard +=
              '<div class="summarycardLabel">' +
              temp_dict[i]["CDL_STRUNT"] +
              '</div> <div class="summarycardvalue">' +
              temp_dict[i]["M_STOR_MT"] +
              " MT Storage Potential" +
              "</div>";
            filterSummaryCard += "</div>";
          }
        }

        filterSummaryCard += '<div class="summarycardview">';
        filterSummaryCard +=
          '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
          "More Details" +
          "</a></div>";

        filterSummaryCard += "</div>";

        filterSummaryCard += "</div>";

        let SummaryCard =
          '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
        SummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan4" class="textspan"></span><span id="leftArrowSummaryCard4" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard4" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard4" class="closesummary">X</span></div>';
        for (var key of Object.keys(contentInfo)) {
          if (key !== "geometry") {
            if (key === "facility_name" || key === "co2_emissions_value") {
              SummaryCard +=
                '<div class="summarycardview moresummview filterview">';
              if (key === "facility_name") {
                SummaryCard +=
                  '<div class="summarycardLabel">' + "Emitter Name" + "</div>";
              } else if (key === "co2_emissions_value") {
                SummaryCard +=
                  '<div class="summarycardLabel">' +
                  "CO2 Emissions(Tons/Yr)" +
                  "</div>";
              }
              SummaryCard +=
                '<div class="summarycardvalue">' + contentInfo[key] + "</div>";
              SummaryCard += "</div>";
            }
          }
        }

        EmittersData.map((values) => {
          if (
            contentInfo["facility_id"] === values.Facility_ID &&
            values.Feasible_YN === "Y"
          ) {
            config.staticInformation.EmittersDataKeys.forEach((item) => {
              let val = item;
              SummaryCard +=
                '<div class="summarycardview moresummview filterview">';
              SummaryCard +=
                '<div class="summarycardLabel">' +
                val[Object.keys(val)[0]] +
                '</div> <div class="summarycardvalue">' +
                values[Object.keys(val)[0]] +
                "</div>";
              //  }

              SummaryCard += "</div>";
            });
          }
        });

        SummaryCard += '<div class="summarycardview">';
        SummaryCard +=
          '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
          "Less Details" +
          "</a></div>";

        SummaryCard += "</div>";

        SummaryCard += "</div>";

        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = SummaryCard + filterSummaryCard;

        //Overlapping Summarycard Data Start for right Arrow
        let textcardDataSpan = document.querySelector("#textcardDataSpan3");
        let textSummincrem = Number(layerIndex) + 1;
        textcardDataSpan.innerText =
          "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary = document.querySelector(
              "#rightArrowSummaryCard3"
            );
            rightArrowSummary.classList.remove("arrowdisabled");
            rightArrowSummary.addEventListener("click", function (_event) {
              layerIndex = Number(layerIndex) + 1;
              if (selected !== null) {
                if (selected !== undefined) {
                  selected.setStyle(undefined);
                  selected = null;
                }
              }
              selected = layerData[layerIndex];
              arrowClick(selected);

              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
            });
          }
        }
        //Overlapping Summarycard Data Start for left Arrow
        if (layerIndex > 0) {
          let leftArrowSummary = document.querySelector(
            "#leftArrowSummaryCard3"
          );
          leftArrowSummary.classList.remove("arrowdisabled");

          leftArrowSummary.addEventListener("click", function (_event) {
            layerIndex = Number(layerIndex) - 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
        //Overlapping End

        //Overlapping Summarycard Data Start moreitems right arrow
        let textcardDataSpan1 = document.querySelector("#textcardDataSpan4");
        let textSummincrem1 = Number(layerIndex) + 1;
        textcardDataSpan1.innerText =
          "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary1 = document.querySelector(
              "#rightArrowSummaryCard4"
            );
            rightArrowSummary1.classList.remove("arrowdisabled");
            rightArrowSummary1.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;

              if (selected !== null) {
                selected.setStyle(undefined);
                selected = null;
              }
              selected = layerData[layerIndex];
              selected.setStyle(highlightStylePoint);

              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
              let moreDet = document.querySelector("#detailsSummaryCard");
              let lessDet = document.querySelector("#filterSummaryCard");
              if (moreDet) {
                moreDet.style.display = "block";
                lessDet.style.display = "none";
              }
            });
          }
        }

        //More Items Left arrow
        if (layerIndex > 0) {
          let leftArrowSummary1 = document.querySelector(
            "#leftArrowSummaryCard4"
          );
          leftArrowSummary1.classList.remove("arrowdisabled");

          leftArrowSummary1.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              selected.setStyle(undefined);
              selected = null;
            }
            selected = layerData[layerIndex];

            selected.setStyle(highlightStylePoly);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");

            if (moreDet) {
              moreDet.style.display = "block";
              lessDet.style.display = "none";
            }
          });
        }
        //Overlapping End Moreitems
        let closecardContainer = document.querySelector("#closeSummaryCard3");

        closecardContainer.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        let closecardContainer1 = document.querySelector("#closeSummaryCard4");

        closecardContainer1.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        let moresummcardContainer = document.querySelector("#summaryMore");
        moresummcardContainer.addEventListener("click", function (event) {
          event.preventDefault();
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");
          moreDet.style.display = "block";
          lessDet.style.display = "none";
        });

        let moresummcardContainer1 = document.querySelector("#summaryMore1");
        moresummcardContainer1.addEventListener("click", function (_event) {
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");

          moreDet.style.display = "none";
          lessDet.style.display = "block";
        });
      } else if (
        popup[layerIndex].id_.split(".")[0] === "gc_storage_resources"
      ) {
        let contentInfo = popup[layerIndex].values_;
        layerTitleName = "Storage Unit Summary";

        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent">';
        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan" class="textspan"></span><span id="leftArrowSummaryCard" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard" class="closesummary">X</span></div>';
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Storage Unit Summary" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeySummaryAlias1,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Effective CO2 Storage Potential" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeySummaryAlias2,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Storage Unit Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeySummaryAlias3,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Reservoir Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div class="subitems" style="display:none">';
        filterSummaryCard += '<div class="lessdetails">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeySummaryAlias4,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard += '<div class="moredetails" style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeyAlias1,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

        filterSummaryCard +=
          '<a href="#" class="viewmore" style="color:#00aaab";>' +
          "More Details" +
          "</a>";

        filterSummaryCard +=
          '<a href="#" class="lessmore" style="display:none">' +
          "Less Details" +
          "</a>" +
          "</div>" +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Fluid Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div class="subitems" style="display:none">';
        filterSummaryCard += '<div class="lessdetails">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeySummaryAlias5,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard += '<div class="moredetails" style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.storageKeyAlias2,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

        filterSummaryCard +=
          '<a href="#" class="viewmore" style="color:#00aaab";>' +
          "More Details" +
          "</a>";

        filterSummaryCard +=
          '<a href="#" class="lessmore" style="display:none">' +
          "Less Details" +
          "</a>" +
          "</div>" +
          "</div>" +
          "</div>";

        filterSummaryCard += '<div class="summarycardview">';
        filterSummaryCard +=
          '<div class="summarycardLabel" style="display:none"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
          "More Details" +
          "</a></div>";

        filterSummaryCard += "</div>";

        filterSummaryCard += "</div>";

        let SummaryCard =
          '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
        SummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan1" class="textspan"></span><span id="leftArrowSummaryCard1" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard1" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard1" class="closesummary">X</span></div>';

        SummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Detailed Reservoir Properties" +
          "</div> </div>";

        SummaryCard = createSummaryCard(
          config.staticInformation.storageKeyAlias1,
          SummaryCard,
          contentInfo
        );

        SummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Fluid Properties" +
          "</div> </div>";

        SummaryCard = createSummaryCard(
          config.staticInformation.storageKeyAlias2,
          SummaryCard,
          contentInfo
        );

        SummaryCard += '<div class="summarycardview">';
        SummaryCard +=
          '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
          "Less Details" +
          "</a></div>";

        SummaryCard += "</div>";

        SummaryCard += "</div>";

        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = SummaryCard + filterSummaryCard;
        //Overlapping Summarycard Data Start for right Arrow
        let textcardDataSpan = document.querySelector("#textcardDataSpan");
        let textSummincrem = Number(layerIndex) + 1;
        textcardDataSpan.innerText =
          "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary = document.querySelector(
              "#rightArrowSummaryCard"
            );
            rightArrowSummary.classList.remove("arrowdisabled");
            rightArrowSummary.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;
              if (selected !== null) {
                if (selected !== undefined) {
                  selected.setStyle(undefined);
                  selected = null;
                }
              }
              selected = layerData[layerIndex];
              arrowClick(selected);
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
            });
          }
        }
        //Overlapping Summarycard Data Start for left Arrow
        if (layerIndex > 0) {
          let leftArrowSummary = document.querySelector(
            "#leftArrowSummaryCard"
          );
          leftArrowSummary.classList.remove("arrowdisabled");

          leftArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
        //Overlapping End

        //Overlapping Summarycard Data Start moreitems right arrow
        let textcardDataSpan1 = document.querySelector("#textcardDataSpan1");
        let textSummincrem1 = layerIndex + 1;
        textcardDataSpan1.innerText =
          "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary1 = document.querySelector(
              "#rightArrowSummaryCard1"
            );
            rightArrowSummary1.classList.remove("arrowdisabled");
            rightArrowSummary1.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;

              if (selected !== null) {
                selected.setStyle(undefined);
                selected = null;
              }
              selected = layerData[layerIndex];
              try {
                if (pointFeatures.includes(selected.id_.split(".")[0]))
                  selected.setStyle(highlightStylePoint);
                else selected.setStyle(highlightStylePoly);
              } catch (e) {}

              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
              let moreDet = document.querySelector("#detailsSummaryCard");
              let lessDet = document.querySelector("#filterSummaryCard");
              if (moreDet) {
                moreDet.style.display = "block";
                lessDet.style.display = "none";
              }
            });
          }
        }

        //More Items Left arrow
        if (layerIndex > 0) {
          let leftArrowSummary1 = document.querySelector(
            "#leftArrowSummaryCard1"
          );
          leftArrowSummary1.classList.remove("arrowdisabled");

          leftArrowSummary1.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              selected.setStyle(undefined);
              selected = null;
            }
            selected = layerData[layerIndex];
            try {
              if (pointFeatures.includes(selected.id_.split(".")[0]))
                selected.setStyle(highlightStylePoint);
              else selected.setStyle(highlightStylePoly);
            } catch (e) {}
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");

            moreDet.style.display = "block";
            lessDet.style.display = "none";
          });
        }
        //Overlapping End Moreitems
        let closecardContainer = document.querySelector("#closeSummaryCard");

        closecardContainer.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        let closecardContainer1 = document.querySelector("#closeSummaryCard1");

        closecardContainer1.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        $(document).ready(function () {
          $(".subheading").click(function () {
            $(this).next().toggle();
            $(this).toggleClass("esri-icon-down");
          });
          $(".btnmoredetails").click(function () {
            $(this).children(".lessmore").toggle();
            $(this).children(".viewmore").toggle();
            $(this).siblings(".moredetails").toggle();
            $(this).siblings(".lessdetails").toggle();
          });
        });

        let moresummcardContainer = document.querySelector("#summaryMore");
        moresummcardContainer.addEventListener("click", function (event) {
          event.preventDefault();
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");
          moreDet.style.display = "block";
          lessDet.style.display = "none";
        });

        let moresummcardContainer1 = document.querySelector("#summaryMore1");
        moresummcardContainer1.addEventListener("click", function (_event) {
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");
          moreDet.style.display = "none";
          lessDet.style.display = "block";
        });
      } else if (
        popup[layerIndex].id_.split(".")[0] ===
        "southern_arkansas_su_pool_polygons"
      ) {
        let contentInfo = popup[layerIndex].values_;
        layerTitleName = "Storage Unit Summary";

        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent">';
        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan" class="textspan"></span><span id="leftArrowSummaryCard" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard" class="closesummary">X</span></div>';
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Storage Unit Summary" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeySummaryAlias1,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Effective CO2 Storage Potential" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeySummaryAlias2,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Storage Unit Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeySummaryAlias3,
            filterSummaryCard,
            contentInfo
          ) +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Reservoir Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div class="subitems" style="display:none">';
        filterSummaryCard += '<div class="lessdetails">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeySummaryAlias4,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard += '<div class="moredetails" style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeyAlias1,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

        filterSummaryCard +=
          '<a href="#" class="viewmore" style="color:#00aaab";>' +
          "More Details" +
          "</a>";

        filterSummaryCard +=
          '<a href="#" class="lessmore" style="display:none">' +
          "Less Details" +
          "</a>" +
          "</div>" +
          "</div>" +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
        filterSummaryCard +=
          '<a href="#" style="color:#00aaab";>' +
          "Fluid Properties" +
          "</a>" +
          "</div>";
        filterSummaryCard += '<div class="subitems" style="display:none">';
        filterSummaryCard += '<div class="lessdetails">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeySummaryAlias5,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard += '<div class="moredetails" style="display:none">';
        filterSummaryCard =
          createfilterSummaryCard(
            config.staticInformation.SAstorageKeyAlias2,
            filterSummaryCard,
            contentInfo
          ) + "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

        filterSummaryCard +=
          '<a href="#" class="viewmore" style="color:#00aaab";>' +
          "More Details" +
          "</a>";

        filterSummaryCard +=
          '<a href="#" class="lessmore" style="display:none">' +
          "Less Details" +
          "</a>" +
          "</div>" +
          "</div>" +
          "</div>";

        filterSummaryCard += '<div class="summarycardview">';
        filterSummaryCard +=
          '<div class="summarycardLabel" style="display:none"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
          "More Details" +
          "</a></div>";

        filterSummaryCard += "</div>";

        filterSummaryCard += "</div>";

        let SummaryCard =
          '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
        SummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan1" class="textspan"></span><span id="leftArrowSummaryCard1" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard1" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard1" class="closesummary">X</span></div>';

        SummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Detailed Reservoir Properties" +
          "</div> </div>";

        SummaryCard = createSummaryCard(
          config.staticInformation.SAstorageKeyAlias1,
          SummaryCard,
          contentInfo
        );

        SummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Fluid Properties" +
          "</div> </div>";

        SummaryCard = createSummaryCard(
          config.staticInformation.SAstorageKeyAlias2,
          SummaryCard,
          contentInfo
        );

        SummaryCard += '<div class="summarycardview">';
        SummaryCard +=
          '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
          "Less Details" +
          "</a></div>";

        SummaryCard += "</div>";

        SummaryCard += "</div>";

        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = SummaryCard + filterSummaryCard;
        //Overlapping Summarycard Data Start for right Arrow
        let textcardDataSpan = document.querySelector("#textcardDataSpan");
        let textSummincrem = Number(layerIndex) + 1;
        textcardDataSpan.innerText =
          "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary = document.querySelector(
              "#rightArrowSummaryCard"
            );
            rightArrowSummary.classList.remove("arrowdisabled");
            rightArrowSummary.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;
              if (selected !== null) {
                if (selected !== undefined) {
                  selected.setStyle(undefined);
                  selected = null;
                }
              }
              selected = layerData[layerIndex];
              arrowClick(selected);
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
            });
          }
        }
        //Overlapping Summarycard Data Start for left Arrow
        if (layerIndex > 0) {
          let leftArrowSummary = document.querySelector(
            "#leftArrowSummaryCard"
          );
          leftArrowSummary.classList.remove("arrowdisabled");

          leftArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
        //Overlapping End

        //Overlapping Summarycard Data Start moreitems right arrow
        let textcardDataSpan1 = document.querySelector("#textcardDataSpan1");
        let textSummincrem1 = layerIndex + 1;
        textcardDataSpan1.innerText =
          "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary1 = document.querySelector(
              "#rightArrowSummaryCard1"
            );
            rightArrowSummary1.classList.remove("arrowdisabled");
            rightArrowSummary1.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;

              if (selected !== null) {
                selected.setStyle(undefined);
                selected = null;
              }
              selected = layerData[layerIndex];
              try {
                if (pointFeatures.includes(selected.id_.split(".")[0]))
                  selected.setStyle(highlightStylePoint);
                else selected.setStyle(highlightStylePoly);
              } catch (e) {}

              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
              let moreDet = document.querySelector("#detailsSummaryCard");
              let lessDet = document.querySelector("#filterSummaryCard");
              if (moreDet) {
                moreDet.style.display = "block";
                lessDet.style.display = "none";
              }
            });
          }
        }

        //More Items Left arrow
        if (layerIndex > 0) {
          let leftArrowSummary1 = document.querySelector(
            "#leftArrowSummaryCard1"
          );
          leftArrowSummary1.classList.remove("arrowdisabled");

          leftArrowSummary1.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              selected.setStyle(undefined);
              selected = null;
            }
            selected = layerData[layerIndex];
            try {
              if (pointFeatures.includes(selected.id_.split(".")[0]))
                selected.setStyle(highlightStylePoint);
              else selected.setStyle(highlightStylePoly);
            } catch (e) {}
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");

            moreDet.style.display = "block";
            lessDet.style.display = "none";
          });
        }
        //Overlapping End Moreitems
        let closecardContainer = document.querySelector("#closeSummaryCard");

        closecardContainer.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        let closecardContainer1 = document.querySelector("#closeSummaryCard1");

        closecardContainer1.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        $(document).ready(function () {
          $(".subheading").click(function () {
            $(this).next().toggle();
            $(this).toggleClass("esri-icon-down");
          });
          $(".btnmoredetails").click(function () {
            $(this).children(".lessmore").toggle();
            $(this).children(".viewmore").toggle();
            $(this).siblings(".moredetails").toggle();
            $(this).siblings(".lessdetails").toggle();
          });
        });

        let moresummcardContainer = document.querySelector("#summaryMore");
        moresummcardContainer.addEventListener("click", function (event) {
          event.preventDefault();
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");
          moreDet.style.display = "block";
          lessDet.style.display = "none";
        });

        let moresummcardContainer1 = document.querySelector("#summaryMore1");
        moresummcardContainer1.addEventListener("click", function (_event) {
          //let showlessTextContainer = event.currentTarget;
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");
          moreDet.style.display = "none";
          lessDet.style.display = "block";
        });
      } else if (
        popup[layerIndex].id_.split(".")[0] === "wood_pellet_plants_2019"
      ) {
        let contentInfo = featureSummaryLength;
        layerTitleName = "Pellet Plants Summary Card";
        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent">';
        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="textcardDataSpan5" class="textspan"></span><span id="leftArrowSummaryCard5" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard5" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard5" class="closesummary">X</span></div>';
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Plant Name" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          contentInfo[layerIndex].values_.company +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Plant Location" + "</div>";

        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          contentInfo[layerIndex].values_.address +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Capacity" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          contentInfo[layerIndex].values_.annual_capacity +
          "</div>";
        filterSummaryCard += "</div>";
        filterSummaryCard += "</div>";

        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = filterSummaryCard;

        let closecardContainer1 = document.querySelector("#closeSummaryCard5");

        closecardContainer1.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });

        let textcardDataSpan = document.querySelector("#textcardDataSpan5");
        let textSummincrem = Number(layerIndex) + 1;
        textcardDataSpan.innerText =
          "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
        if (featureSummaryLength.length > 1) {
          if (featureSummaryLength.length - 1 !== layerIndex) {
            let rightArrowSummary = document.querySelector(
              "#rightArrowSummaryCard5"
            );
            rightArrowSummary.classList.remove("arrowdisabled");
            rightArrowSummary.addEventListener("click", function (_event) {
              layerIndex = layerIndex + 1;
              if (selected !== null) {
                if (selected !== undefined) {
                  selected.setStyle(undefined);
                  selected = null;
                }
              }
              selected = layerData[layerIndex];
              arrowClick(selected);
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected
              );
            });
          }
        }

        //Overlapping Summarycard Data Start for left Arrow
        if (layerIndex > 0) {
          let leftArrowSummary = document.querySelector(
            "#leftArrowSummaryCard5"
          );
          leftArrowSummary.classList.remove("arrowdisabled");

          leftArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex - 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);

            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
        //Overlapping End
      }
    } else {
      let contentInfo = popup[layerIndex].values_;
      layerTitleName = "Summary Card";
      let filterSummaryCard =
        '<div id="filterSummaryCard" class="summarycardcontent">';
      filterSummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan7" class="textspan"></span><span id="leftArrowSummaryCard7" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard7" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard7" class="closesummary">X</span></div>';
      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview">';
      for (var k of Object.keys(contentInfo)) {
        if (!["geometry", "OBJECTID", "FID"].includes(k)) {
          if (contentInfo[k] !== null) {
            filterSummaryCard +=
              '<div class="summarycardLabel">' + k + "</div>";
            filterSummaryCard +=
              '<div class="summarycardvalue">' + contentInfo[k] + "</div>";
          }
        }
      }

      filterSummaryCard += "</div>";
      filterSummaryCard += "</div>";

      basemapInfo.innerHTML = "";
      basemapInfo.innerHTML = filterSummaryCard;

      let closecardContainer1 = document.querySelector("#closeSummaryCard7");

      closecardContainer1.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let textcardDataSpan = document.querySelector("#textcardDataSpan7");
      let textSummincrem = Number(layerIndex) + 1;
      textcardDataSpan.innerText =
        "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary = document.querySelector(
            "#rightArrowSummaryCard7"
          );
          rightArrowSummary.classList.remove("arrowdisabled");
          rightArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex + 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);

            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
      }

      //Overlapping Summarycard Data Start for left Arrow
      if (layerIndex > 0) {
        let leftArrowSummary = document.querySelector("#leftArrowSummaryCard7");
        leftArrowSummary.classList.remove("arrowdisabled");

        leftArrowSummary.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);

          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
      }
      //Overlapping End
    }
  } else if (layerTitleName === "Emitter Summary Card") {
    if (featureSummaryLength.length > 0) {
      let contentInfo = featureSummaryLength[0][0].properties;
      layerTitleName = "Emitter Summary Card";
      let filterViewData = ["facility_name", "co2_emissions_value"];
      let filterSummaryCard =
        '<div id="filterSummaryCard" class="summarycardcontent">';
      filterSummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan3" class="textspan"></span><span id="leftArrowSummaryCard3" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard3" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard3" class="closesummary">X</span></div>';

      for (let i = 0; i < filterViewData.length; i++) {
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        if (filterViewData[i] === "facility_name") {
          filterSummaryCard +=
            '<div class="summarycardLabel">' + "Emitter Name" + "</div>";
        } else if (filterViewData[i] === "co2_emissions_value") {
          filterSummaryCard +=
            '<div class="summarycardLabel">' +
            "CO2 Emissions(Tons/Yr)" +
            "</div>";
        }
        if (typeof contentInfo[filterViewData[i]] === "number") {
          filterSummaryCard +=
            '<div class="summarycardvalue">' +
            Number(contentInfo[filterViewData[i]].toFixed(1)).toLocaleString(
              "en-AU"
            ) +
            "</div>";
        } else {
          filterSummaryCard +=
            '<div class="summarycardvalue">' +
            contentInfo[filterViewData[i]] +
            "</div>";
        }
        filterSummaryCard += "</div>";
      }

      var temp_dict = [];

      EmittersData.map((values) => {
        if (
          contentInfo["facility_id"] === values.Facility_ID &&
          values.Feasible_YN === "Y"
        ) {
          temp_dict.push({
            CDL_STRUNT: values.CDL_STRUNT,
            DIST_Miles: values.DIST_Miles,
          });
        }
      });

      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Nearest Feasible Storage Options" +
        "</div> </div>";

      temp_dict = temp_dict.sort(sortByProperty("DIST_Miles", "asc"));
      if (temp_dict.length > 0) {
        let len_dict = temp_dict.length;
        let temp_var = 3;
        if (len_dict < 3) temp_var = len_dict;
        for (let i = 0; i < temp_var; i++) {
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview">';
          filterSummaryCard +=
            '<div class="summarycardLabel">' +
            temp_dict[i]["CDL_STRUNT"] +
            '</div><div class="summarycardvalue">' +
            Number(temp_dict[i]["DIST_Miles"].toFixed(1)).toLocaleString(
              "en-AU"
            ) +
            " miles away" +
            "</div>";
          filterSummaryCard += "</div>";
        }
      }

      temp_dict = [];
      EmittersData.map((values) => {
        if (
          contentInfo["facility_id"] === values.Facility_ID &&
          values.Feasible_YN === "Y"
        ) {
          temp_dict.push({
            CDL_STRUNT: values.CDL_STRUNT,
            M_STOR_MT: Number(values.M_STOR_MT.toFixed(3)).toLocaleString(
              "en-AU"
            ),
          });
        }
      });
      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Largest Feasible Storage Options" +
        "</div> </div>";

      temp_dict = temp_dict.sort(sortByProperty("M_STOR_MT", "asc"));
      if (temp_dict.length > 0) {
        let len_dict = temp_dict.length;
        let temp_var = 3;
        if (len_dict < 3) temp_var = len_dict;
        for (let i = len_dict - 1; i >= len_dict - temp_var; i--) {
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview">';
          filterSummaryCard +=
            '<div class="summarycardLabel">' +
            temp_dict[i]["CDL_STRUNT"] +
            '</div> <div class="summarycardvalue">' +
            temp_dict[i]["M_STOR_MT"] +
            " MT Storage Potential" +
            "</div>";
          filterSummaryCard += "</div>";
        }
      }

      filterSummaryCard += '<div class="summarycardview">';
      filterSummaryCard +=
        '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
        "More Details" +
        "</a></div>";

      filterSummaryCard += "</div>";

      filterSummaryCard += "</div>";

      let SummaryCard =
        '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
      SummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan4" class="textspan"></span><span id="leftArrowSummaryCard4" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard4" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard4" class="closesummary">X</span></div>';
      for (var key of Object.keys(contentInfo)) {
        if (key !== "geometry") {
          if (key === "facility_name" || key === "co2_emissions_value") {
            SummaryCard +=
              '<div class="summarycardview moresummview filterview">';
            if (key === "facility_name") {
              SummaryCard +=
                '<div class="summarycardLabel">' + "Emitter Name" + "</div>";
            } else if (key === "co2_emissions_value") {
              SummaryCard +=
                '<div class="summarycardLabel">' +
                "CO2 Emissions(Tons/Yr)" +
                "</div>";
            }
            SummaryCard +=
              '<div class="summarycardvalue">' + contentInfo[key] + "</div>";
            SummaryCard += "</div>";
          }
        }
      }

      EmittersData.map((values) => {
        if (
          contentInfo["facility_id"] === values.Facility_ID &&
          values.Feasible_YN === "Y"
        ) {
          config.staticInformation.EmittersDataKeys.forEach((item) => {
            let val = item;
            SummaryCard +=
              '<div class="summarycardview moresummview filterview">';
            SummaryCard +=
              '<div class="summarycardLabel">' +
              val[Object.keys(val)[0]] +
              '</div> <div class="summarycardvalue">' +
              values[Object.keys(val)[0]] +
              "</div>";
            //  }

            SummaryCard += "</div>";
          });
        }
      });

      SummaryCard += '<div class="summarycardview">';
      SummaryCard +=
        '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
        "Less Details" +
        "</a></div>";

      SummaryCard += "</div>";

      SummaryCard += "</div>";

      basemapInfo.innerHTML = "";
      basemapInfo.innerHTML = SummaryCard + filterSummaryCard;

      //Overlapping Summarycard Data Start for right Arrow
      let textcardDataSpan = document.querySelector("#textcardDataSpan3");
      let textSummincrem = Number(layerIndex) + 1;
      textcardDataSpan.innerText =
        "(" + featureSummaryLength.length + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary = document.querySelector(
            "#rightArrowSummaryCard3"
          );
          rightArrowSummary.classList.remove("arrowdisabled");
          rightArrowSummary.addEventListener("click", function (_event) {
            layerIndex = Number(layerIndex) + 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);

            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
      }
      //Overlapping Summarycard Data Start for left Arrow
      if (layerIndex > 0) {
        let leftArrowSummary = document.querySelector("#leftArrowSummaryCard3");
        leftArrowSummary.classList.remove("arrowdisabled");

        leftArrowSummary.addEventListener("click", function (_event) {
          layerIndex = Number(layerIndex) - 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
      }
      //Overlapping End

      //Overlapping Summarycard Data Start moreitems right arrow
      let textcardDataSpan1 = document.querySelector("#textcardDataSpan4");
      let textSummincrem1 = Number(layerIndex) + 1;
      textcardDataSpan1.innerText =
        "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary1 = document.querySelector(
            "#rightArrowSummaryCard4"
          );
          rightArrowSummary1.classList.remove("arrowdisabled");
          rightArrowSummary1.addEventListener("click", function (_event) {
            layerIndex = layerIndex + 1;

            if (selected !== null) {
              selected.setStyle(undefined);
              selected = null;
            }
            selected = layerData[layerIndex];
            selected.setStyle(highlightStylePoint);

            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            if (moreDet) {
              moreDet.style.display = "block";
              lessDet.style.display = "none";
            }
          });
        }
      }

      //More Items Left arrow
      if (layerIndex > 0) {
        let leftArrowSummary1 = document.querySelector(
          "#leftArrowSummaryCard4"
        );
        leftArrowSummary1.classList.remove("arrowdisabled");

        leftArrowSummary1.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            selected.setStyle(undefined);
            selected = null;
          }
          selected = layerData[layerIndex];

          selected.setStyle(highlightStylePoly);
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");

          if (moreDet) {
            moreDet.style.display = "block";
            lessDet.style.display = "none";
          }
        });
      }
      //Overlapping End Moreitems
      let closecardContainer = document.querySelector("#closeSummaryCard3");

      closecardContainer.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let closecardContainer1 = document.querySelector("#closeSummaryCard4");

      closecardContainer1.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let moresummcardContainer = document.querySelector("#summaryMore");
      moresummcardContainer.addEventListener("click", function (event) {
        event.preventDefault();
        //let showlessTextContainer = event.currentTarget;
        let moreDet = document.querySelector("#detailsSummaryCard");
        let lessDet = document.querySelector("#filterSummaryCard");
        moreDet.style.display = "block";
        lessDet.style.display = "none";
      });

      let moresummcardContainer1 = document.querySelector("#summaryMore1");
      moresummcardContainer1.addEventListener("click", function (_event) {
        //let showlessTextContainer = event.currentTarget;
        let moreDet = document.querySelector("#detailsSummaryCard");
        let lessDet = document.querySelector("#filterSummaryCard");

        moreDet.style.display = "none";
        lessDet.style.display = "block";
      });
    } else if (popup[layerIndex].id_.split(".")[0] === "gc_storage_resources") {
      let contentInfo = popup[layerIndex].values_;
      layerTitleName = "Storage Unit Summary";

      let filterSummaryCard =
        '<div id="filterSummaryCard" class="summarycardcontent">';
      filterSummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan" class="textspan"></span><span id="leftArrowSummaryCard" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard" class="closesummary">X</span></div>';
      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Storage Unit Summary" +
        "</div> </div>";

      filterSummaryCard = createfilterSummaryCard(
        config.staticInformation.storageKeySummaryAlias1,
        filterSummaryCard,
        contentInfo
      );
      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Effective CO2 Storage Potential" +
        "</div> </div>";

      filterSummaryCard = createfilterSummaryCard(
        config.staticInformation.storageKeySummaryAlias2,
        filterSummaryCard,
        contentInfo
      );

      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Storage Unit Properties" +
        "</div> </div>";

      filterSummaryCard = createfilterSummaryCard(
        config.staticInformation.storageKeySummaryAlias3,
        filterSummaryCard,
        contentInfo
      );

      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Reservoir Properties" +
        "</div> </div>";

      filterSummaryCard = createfilterSummaryCard(
        config.staticInformation.storageKeySummaryAlias4,
        filterSummaryCard,
        contentInfo
      );

      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Fluid Properties" +
        "</div> </div>";

      filterSummaryCard = createfilterSummaryCard(
        config.staticInformation.storageKeySummaryAlias5,
        filterSummaryCard,
        contentInfo
      );

      filterSummaryCard += '<div class="summarycardview">';
      filterSummaryCard +=
        '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
        "More Details" +
        "</a></div>";

      filterSummaryCard += "</div>";

      filterSummaryCard += "</div>";

      let SummaryCard =
        '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
      SummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan1" class="textspan"></span><span id="leftArrowSummaryCard1" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard1" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard1" class="closesummary">X</span></div>';

      SummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Detailed Reservoir Properties" +
        "</div> </div>";

      SummaryCard = createSummaryCard(
        config.staticInformation.storageKeyAlias1,
        SummaryCard,
        contentInfo
      );

      SummaryCard +=
        '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
        "Fluid Properties" +
        "</div> </div>";

      SummaryCard = createSummaryCard(
        config.staticInformation.storageKeyAlias2,
        SummaryCard,
        contentInfo
      );

      SummaryCard += '<div class="summarycardview">';
      SummaryCard +=
        '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
        "Less Details" +
        "</a></div>";

      SummaryCard += "</div>";

      SummaryCard += "</div>";

      basemapInfo.innerHTML = "";
      basemapInfo.innerHTML = SummaryCard + filterSummaryCard;
      //Overlapping Summarycard Data Start for right Arrow
      let textcardDataSpan = document.querySelector("#textcardDataSpan");
      let textSummincrem = Number(layerIndex) + 1;
      textcardDataSpan.innerText =
        "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary = document.querySelector(
            "#rightArrowSummaryCard"
          );
          rightArrowSummary.classList.remove("arrowdisabled");
          rightArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex + 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
      }
      //Overlapping Summarycard Data Start for left Arrow
      if (layerIndex > 0) {
        let leftArrowSummary = document.querySelector("#leftArrowSummaryCard");
        leftArrowSummary.classList.remove("arrowdisabled");

        leftArrowSummary.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
      }
      //Overlapping End

      //Overlapping Summarycard Data Start moreitems right arrow
      let textcardDataSpan1 = document.querySelector("#textcardDataSpan1");
      let textSummincrem1 = layerIndex + 1;
      textcardDataSpan1.innerText =
        "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary1 = document.querySelector(
            "#rightArrowSummaryCard1"
          );
          rightArrowSummary1.classList.remove("arrowdisabled");
          rightArrowSummary1.addEventListener("click", function (_event) {
            layerIndex = layerIndex + 1;

            if (selected !== null) {
              selected.setStyle(undefined);
              selected = null;
            }
            selected = layerData[layerIndex];
            try {
              if (pointFeatures.includes(selected.id_.split(".")[0]))
                selected.setStyle(highlightStylePoint);
              else selected.setStyle(highlightStylePoly);
            } catch (e) {}

            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            if (moreDet) {
              moreDet.style.display = "block";
              lessDet.style.display = "none";
            }
          });
        }
      }

      //More Items Left arrow
      if (layerIndex > 0) {
        let leftArrowSummary1 = document.querySelector(
          "#leftArrowSummaryCard1"
        );
        leftArrowSummary1.classList.remove("arrowdisabled");

        leftArrowSummary1.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            selected.setStyle(undefined);
            selected = null;
          }
          selected = layerData[layerIndex];
          try {
            if (pointFeatures.includes(selected.id_.split(".")[0]))
              selected.setStyle(highlightStylePoint);
            else selected.setStyle(highlightStylePoly);
          } catch (e) {}
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
          let moreDet = document.querySelector("#detailsSummaryCard");
          let lessDet = document.querySelector("#filterSummaryCard");

          moreDet.style.display = "block";
          lessDet.style.display = "none";
        });
      }
      //Overlapping End Moreitems
      let closecardContainer = document.querySelector("#closeSummaryCard");

      closecardContainer.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let closecardContainer1 = document.querySelector("#closeSummaryCard1");

      closecardContainer1.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let moresummcardContainer = document.querySelector("#summaryMore");
      moresummcardContainer.addEventListener("click", function (event) {
        event.preventDefault();
        //let showlessTextContainer = event.currentTarget;
        let moreDet = document.querySelector("#detailsSummaryCard");
        let lessDet = document.querySelector("#filterSummaryCard");
        moreDet.style.display = "block";
        lessDet.style.display = "none";
      });

      let moresummcardContainer1 = document.querySelector("#summaryMore1");
      moresummcardContainer1.addEventListener("click", function (_event) {
        //let showlessTextContainer = event.currentTarget;
        let moreDet = document.querySelector("#detailsSummaryCard");
        let lessDet = document.querySelector("#filterSummaryCard");
        moreDet.style.display = "none";
        lessDet.style.display = "block";
      });
    } else if (
      popup[layerIndex].id_.split(".")[0] === "wood_pellet_plants_2019"
    ) {
      let contentInfo = featureSummaryLength;
      layerTitleName = "Pellet Plants Summary Card";
      let filterSummaryCard =
        '<div id="filterSummaryCard" class="summarycardcontent">';
      filterSummaryCard +=
        '<div class="summarycardtitle"> ' +
        layerTitleName +
        '<span id="textcardDataSpan5" class="textspan"></span><span id="leftArrowSummaryCard5" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard5" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard5" class="closesummary">X</span></div>';
      filterSummaryCard +=
        '<div class="summarycardview moresummview filterview">';
      filterSummaryCard +=
        '<div class="summarycardLabel">' + "Plant Name" + "</div>";
      filterSummaryCard +=
        '<div class="summarycardvalue">' +
        contentInfo[layerIndex].values_.company +
        "</div>";

      filterSummaryCard +=
        '<div class="summarycardLabel">' + "Plant Location" + "</div>";

      filterSummaryCard +=
        '<div class="summarycardvalue">' +
        contentInfo[layerIndex].values_.address +
        "</div>";

      filterSummaryCard +=
        '<div class="summarycardLabel">' + "Capacity" + "</div>";
      filterSummaryCard +=
        '<div class="summarycardvalue">' +
        contentInfo[layerIndex].values_.annual_capacity +
        "</div>";
      filterSummaryCard += "</div>";
      filterSummaryCard += "</div>";

      basemapInfo.innerHTML = "";
      basemapInfo.innerHTML = filterSummaryCard;

      let closecardContainer1 = document.querySelector("#closeSummaryCard5");

      closecardContainer1.addEventListener("click", function (_event) {
        basemapInfo.innerHTML = "";
        basemapInfo.style.display = "none";
      });

      let textcardDataSpan = document.querySelector("#textcardDataSpan5");
      let textSummincrem = Number(layerIndex) + 1;
      textcardDataSpan.innerText =
        "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
      if (featureSummaryLength.length > 1) {
        if (featureSummaryLength.length - 1 !== layerIndex) {
          let rightArrowSummary = document.querySelector(
            "#rightArrowSummaryCard5"
          );
          rightArrowSummary.classList.remove("arrowdisabled");
          rightArrowSummary.addEventListener("click", function (_event) {
            layerIndex = layerIndex + 1;
            if (selected !== null) {
              if (selected !== undefined) {
                selected.setStyle(undefined);
                selected = null;
              }
            }
            selected = layerData[layerIndex];
            arrowClick(selected);
            summaryCaardPopupTempalte(
              layerTitleName,
              layerData,
              layerIndex,
              featureSummaryLength,
              selected
            );
          });
        }
      }

      //Overlapping Summarycard Data Start for left Arrow
      if (layerIndex > 0) {
        let leftArrowSummary = document.querySelector("#leftArrowSummaryCard5");
        leftArrowSummary.classList.remove("arrowdisabled");

        leftArrowSummary.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);

          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
      }
      //Overlapping End
    }
  } else if (featureSummaryLength[0].length > 0) {
    if (featureSummaryLength[0][0]) {
      // if (storage.includes(featureSummaryLength[0][0].id.split(".")[0])) {
      //   layerTitleName = "Storage Summary Card";
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';

      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
      //     "CO<sub>2</sub> Storage Parameters" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Storage Potential (Mt)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mid" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.stor_p50).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "High" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.stor_p90).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Low" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.stor_p10).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Storage Efficiency" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mid" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.eff_p50).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "High" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.eff_p90).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Low" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.eff_p10).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel subtitlelabel">' +
      //     "Data Availability" +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue subtitlevalue">' +
      //     contentInfo.data_avail.toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
      //     "Reservoir Parameters" +
      //     "</div> </div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Net Thickness (m)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.nthick_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.nthickstd).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Effective Porosity (%)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.effpor_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.effporstd).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Temperature (°C)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.temp_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.tstddev).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Pressure (MPa)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.pressure_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.pstddev).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Permeability (mD)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.perm_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.perm_std).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Salinity (ppm)" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Mean" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.salinity_m).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.salinity_s).toLocaleString("en-AU") +
      //     "</div>";

      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } 
       if (southernStorageLayer.includes(southerLayerStyle)) {
        layerTitleName = "Storage Summary Card";
        let contentInfo = featureSummaryLength[0][0].properties;
        let isGCMiocene = GC_Miocene.includes(featureSummaryLength[0][0].id.split(".")[0])
        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';

        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="closeSummaryCard5" class="closesummary">X</span></div>';

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Unit ID" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Grid Number" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.target_fid).toLocaleString("en-AU") +
          "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Latitude" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
          "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Longitude" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Storage Potential (Mt)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.storage_mean).toLocaleString("en-AU") +
          "</div>";
         if (isGCMiocene) {
            filterSummaryCard +=
            '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
          filterSummaryCard +=
            '<div class="summarycardvalue">' +
            parseFloat(contentInfo.stor_std).toLocaleString("en-AU") +
            "</div>";
         } 
        

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
          "Reservoir Parameters" +
          "</div> </div>";
         if(!isGCMiocene) {
          filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Depth (Ft)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.depth_mean).toLocaleString("en-AU") +
          "</div>";
         }
        

        // filterSummaryCard +=
        //   '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        // filterSummaryCard +=
        //   '<div class="summarycardvalue">' +
        //   parseFloat(contentInfo.depth_std).toLocaleString("en-AU") +
        //   "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Net Thickness (FT)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(
            contentInfo.netres_mean != undefined
              ? contentInfo.netres_mean
              : contentInfo.thickness_mean
          ).toLocaleString("en-AU") +
          "</div>";

          if (isGCMiocene) {
            filterSummaryCard +=
            '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
          filterSummaryCard +=
            '<div class="summarycardvalue">' +
            parseFloat(
              contentInfo.netres_std != undefined
                ? contentInfo.netres_std
                : contentInfo.thickness_std ? contentInfo.thickness_std : contentInfo.thick_std
            ).toLocaleString("en-AU") +
            "</div>";
          }

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Effective Porosity (Fraction)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.porosity_mean).toLocaleString("en-AU") +
          "</div>";
          if (isGCMiocene) {
            filterSummaryCard +=
          '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.porosity_std ? contentInfo.porosity_std : contentInfo.por_std).toLocaleString("en-AU") +
          "</div>";
          }
        

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Permeability (mD)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.perm_mean).toLocaleString("en-AU") +
          "</div>";
          if(isGCMiocene) {
             filterSummaryCard +=
          '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.perm_std).toLocaleString("en-AU") +
          "</div>";
          }
       

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Temperature (°F)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.temp_mean).toLocaleString("en-AU") +
          "</div>";
          if (isGCMiocene) {
            filterSummaryCard +=
          '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.temp_std).toLocaleString("en-AU") +
          "</div>";
          }
        

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Pore Pressure (PSI)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.porepr_mean).toLocaleString("en-AU") +
          "</div>";
          if (isGCMiocene) { 
            filterSummaryCard +=
          '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.porepr_std).toLocaleString("en-AU") +
          "</div>";
          }
        

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Salinity (ppm)" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Mean" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.salinity_mean).toLocaleString("en-AU") +
          "</div>";
          if(isGCMiocene) {
            filterSummaryCard +=
          '<div class="summarycardLabel">' + "Standard Deviation" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.salin_std).toLocaleString("en-AU") +
          "</div>";
          }
        

        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = filterSummaryCard;
      } 
      else if (southernLayerOthers.includes(southerLayerStyle)) {
        let contentInfo = featureSummaryLength[0][0].properties;
        let isGCMiocene = GC_Miocene.includes(featureSummaryLength[0][0].id.split(".")[0])
        let layerTitleName;
        let meanVal;
        let stdVal;
        let reservoirTitle;
        let ThicknessData = ["NetThickMe", "NetThickST"];

        if (southerLayerStyle === "sa_thickness" || southerLayerStyle === "gc_thickness") {
          layerTitleName =
            featureSummaryLength[0][0].id.split(".")[0] === "barrier_units"
              ? "Non-Reservoir Thickness Summary"
              : "Net Thickness Summary";
          meanVal =
            contentInfo.thickness_mean != undefined
              ? contentInfo.thickness_mean
              : contentInfo.netres_mean;
            

            if (contentInfo.netres_std) {
              stdVal =  contentInfo.netres_std
            } else if (contentInfo.thickness_std) {
              stdVal =  contentInfo.thickness_std
            } else if (contentInfo.thick_std) {
              stdVal =  contentInfo.thick_std
            }

          reservoirTitle =
            featureSummaryLength[0][0].id.split(".")[0] === "barrier_units"
              ? "Non-Reservoir Parameter: Non-Reservoir Thickness (FT)"
              : "Reservoir Parameter: Net Thickness (FT)";
        } else if (southerLayerStyle === "sa_porosity" || southerLayerStyle === "gc_porosity") {
          layerTitleName = "Effective Porosity Summary";
          meanVal = contentInfo.porosity_mean;
          stdVal = contentInfo.por_std;
          reservoirTitle = "Reservoir Parameter: Effective Porosity (Fraction)";
        } else if (southerLayerStyle === "sa_permeability" || southerLayerStyle === "gc_perm") {
          layerTitleName = "Permeability Summary";
          meanVal = contentInfo.perm_mean;
          stdVal = contentInfo.perm_std;
          reservoirTitle = "Reservoir Parameter: Permeability (mD)";
        } else if (southerLayerStyle === "sa_temp" || southerLayerStyle === "gc_temp") {
          layerTitleName = "Temperature Summary";
          meanVal = contentInfo.temp_mean;
          stdVal = contentInfo.temp_std;
          reservoirTitle = "Reservoir Parameter: Temperature (°F)";
        } else if (southerLayerStyle === "sa_PorePressure" || southerLayerStyle === "gc_porepr") {
          layerTitleName = "Pore Pressure summary";
          meanVal = contentInfo.porepr_mean;
          stdVal = contentInfo.porepr_std;
          reservoirTitle = "Reservoir Parameter: Pressure(PSI)";
        } else if (southerLayerStyle === "sa_salinity" || southerLayerStyle === "gc_salinity") {
          layerTitleName = "Salinity summary";
          meanVal = contentInfo.salinity_mean;
          stdVal = contentInfo.salin_std;
          reservoirTitle = "Reservoir Parameter: Salinity (ppm)";
        }

        let filterSummaryCard =
          '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
        filterSummaryCard +=
          '<div class="summarycardtitle"> ' +
          layerTitleName +
          '<span id="closeSummaryCard5" class="closesummary">X</span></div>';

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          "Unit ID" +
          "</div> </div>";
        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview">';
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Grid Number" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.target_fid).toLocaleString("en-AU") +
          "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Latitude" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
          "</div>";
        filterSummaryCard +=
          '<div class="summarycardLabel">' + "Longitude" + "</div>";
        filterSummaryCard +=
          '<div class="summarycardvalue">' +
          parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
          "</div>";

        filterSummaryCard +=
          '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
          reservoirTitle +
          "</div> </div>";
        for (let i = 0; i < ThicknessData.length; i++) {
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview">';
          if (ThicknessData[i] === "NetThickMe") {
            filterSummaryCard +=
              '<div class="summarycardLabel">' + "Mean" + "</div>";
            filterSummaryCard +=
              '<div class="summarycardvalue">' +
              parseFloat(meanVal).toLocaleString("en-AU") +
              "</div>";
          } else if (ThicknessData[i] === "NetThickST") {
              if (isGCMiocene) {
                filterSummaryCard +=
              '<div class="summarycardLabel">' +
              "Standard Deviation" +
              "</div>";
            filterSummaryCard +=
              '<div class="summarycardvalue">' +
              parseFloat(stdVal).toLocaleString("en-AU") +
              "</div>";
              }
            
          }

          // filterSummaryCard +=
          //   '<div class="summarycardvalue">' +
          //   Number(contentInfo[0][0].properties[StoragePotentialData[i]].toFixed(1)).toLocaleString(
          //     "en-AU"
          //   ) +
          //   'val'+
          //   "</div>";
        }
        basemapInfo.innerHTML = "";
        basemapInfo.innerHTML = filterSummaryCard;
      }
      // else if (
      //   thickness.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Net Thickness Summary";
      //   let ThicknessData = ["NetThickMe", "NetThickST"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Net Thickness (m)" +
      //     "</div> </div>";
      //   for (let i = 0; i < ThicknessData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (ThicknessData[i] === "NetThickMe") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.nthick_m).toLocaleString("en-AU") +
      //         "</div>";
      //     } else if (ThicknessData[i] === "NetThickST") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.nthickstd).toLocaleString("en-AU") +
      //         "</div>";
      //     }

      //     // filterSummaryCard +=
      //     //   '<div class="summarycardvalue">' +
      //     //   Number(contentInfo[0][0].properties[StoragePotentialData[i]].toFixed(1)).toLocaleString(
      //     //     "en-AU"
      //     //   ) +
      //     //   'val'+
      //     //   "</div>";
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // }  else if (
      //   porosity.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Effective Porosity Summary";
      //   let PorosityData = ["EffPorMean", "EffPorStd"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Effective Porosity (%)" +
      //     "</div> </div>";
      //   for (let i = 0; i < PorosityData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (PorosityData[i] === "EffPorMean") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.effpor_m) +
      //         // .toLocaleString(
      //         //   "en-AU"
      //         // )
      //         "</div>";
      //     } else if (PorosityData[i] === "EffPorStd") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.effporstd) +
      //         // .toLocaleString(
      //         //   "en-AU"
      //         // )
      //         "</div>";
      //     }
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } else if (
      //   temprature.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Temperature Summary";
      //   let TempData = ["TMean", "TSTD"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Temperature (°C)" +
      //     "</div> </div>";
      //   for (let i = 0; i < TempData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (TempData[i] === "TMean") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.temp_m).toLocaleString("en-AU") +
      //         "</div>";
      //     } else if (TempData[i] === "TSTD") {
      //       // let val;
      //       // if (featureSummaryLength[0][0].id.includes("midmiocene")) {
      //       //   val = contentInfo.tstddev
      //       // } else if (featureSummaryLength[0][0].id.includes("lrmiocene")) {
      //       //   val = contentInfo.tstd
      //       // }
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.tstddev).toLocaleString("en-AU") +
      //         "</div>";
      //     }
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } else if (
      //   pressure.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Pressure Summary";
      //   let PressureData = ["PMean", "PSTD"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Pressure (MPa)" +
      //     "</div> </div>";
      //   for (let i = 0; i < PressureData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (PressureData[i] === "PMean") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.pressure_m).toLocaleString("en-AU") +
      //         "</div>";
      //     } else if (PressureData[i] === "PSTD") {
      //       // let val;
      //       // if (featureSummaryLength[0][0].id.includes("midmiocene")) {
      //       //   val = contentInfo.pstddev
      //       // } else if (featureSummaryLength[0][0].id.includes("lrmiocene")) {
      //       //   val = contentInfo.pstd
      //       // }
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.pstddev).toLocaleString("en-AU") +
      //         "</div>";
      //     }
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } else if (
      //   permeability.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Permeability Summary";
      //   let PermeabilityData = ["PerMean", "PerSTD"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Permeability (mD)" +
      //     "</div> </div>";
      //   for (let i = 0; i < PermeabilityData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (PermeabilityData[i] === "PerMean") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.perm_m).toLocaleString("en-AU") +
      //         "</div>";
      //     } else if (PermeabilityData[i] === "PerSTD") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.perm_std).toLocaleString("en-AU") +
      //         "</div>";
      //     }
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } else if (
      //   salinity.includes(featureSummaryLength[0][0].id.split(".")[0])
      // ) {
      //   let contentInfo = featureSummaryLength[0][0].properties;
      //   let layerTitleName = "Salinity Summary";
      //   let SalinityData = ["SMean", "SSTD"];
      //   let filterSummaryCard =
      //     '<div id="filterSummaryCard" class="summarycardcontent sa_summary_card">';
      //   filterSummaryCard +=
      //     '<div class="summarycardtitle"> ' +
      //     layerTitleName +
      //     '<span id="closeSummaryCard5" class="closesummary">X</span></div>';
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Unit ID" +
      //     "</div> </div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview">';
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Grid Number" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.grid).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Latitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.latitude).toLocaleString("en-AU") +
      //     "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardLabel">' + "Longitude" + "</div>";
      //   filterSummaryCard +=
      //     '<div class="summarycardvalue">' +
      //     parseFloat(contentInfo.longitude).toLocaleString("en-AU") +
      //     "</div>";

      //   filterSummaryCard +=
      //     '<div class="summarycardview moresummview filterview"> <div class="summarycardsubtitle">' +
      //     "Reservoir Parameter: Salinity (ppm)" +
      //     "</div> </div>";
      //   for (let i = 0; i < SalinityData.length; i++) {
      //     filterSummaryCard +=
      //       '<div class="summarycardview moresummview filterview">';
      //     if (SalinityData[i] === "SMean") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' + "Mean" + "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.salinity_m).toLocaleString("en-AU") +
      //         "</div>";
      //     } else if (SalinityData[i] === "SSTD") {
      //       filterSummaryCard +=
      //         '<div class="summarycardLabel">' +
      //         "Standard Deviation" +
      //         "</div>";
      //       filterSummaryCard +=
      //         '<div class="summarycardvalue">' +
      //         parseFloat(contentInfo.salinity_s).toLocaleString("en-AU") +
      //         "</div>";
      //     }
      //   }
      //   basemapInfo.innerHTML = "";
      //   basemapInfo.innerHTML = filterSummaryCard;
      // } 
      else if (
        popup &&
        popup[layerIndex].id.split(".")[0] === "gc_storage_resources_pools"
      ) {
        {
          let contentInfo = popup[layerIndex].properties;

          layerTitleName = "Pool Summary";

          let filterSummaryCard =
            '<div id="filterSummaryCard" class="summarycardcontent">';
          filterSummaryCard +=
            '<div class="summarycardtitle"> ' +
            layerTitleName +
            '<span id="textcardDataSpan" class="textspan"></span><span id="leftArrowSummaryCard" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard" class="closesummary">X</span></div>';
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Pool Summary" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias1,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Effective CO2 Storage Potential" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias2,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Pool Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias3,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Reservoir Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div class="subitems" style="display:none">';
          filterSummaryCard += '<div class="lessdetails">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias4,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard += '<div class="moredetails" style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeyAlias1,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard +=
            '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

          filterSummaryCard +=
            '<a href="#" class="viewmore" style="color:#00aaab";>' +
            "More Details" +
            "</a>";

          filterSummaryCard +=
            '<a href="#" class="lessmore" style="display:none">' +
            "Less Details" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Fluid Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div class="subitems" style="display:none">';
          filterSummaryCard += '<div class="lessdetails">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias5,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard += '<div class="moredetails" style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeyAlias2,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard +=
            '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

          filterSummaryCard +=
            '<a href="#" class="viewmore" style="color:#00aaab";>' +
            "More Details" +
            "</a>";

          filterSummaryCard +=
            '<a href="#" class="lessmore" style="display:none">' +
            "Less Details" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>";

          filterSummaryCard += '<div class="summarycardview">';
          filterSummaryCard +=
            '<div class="summarycardLabel" style="display:none"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
            "More Details" +
            "</a></div>";

          filterSummaryCard += "</div>";

          filterSummaryCard += "</div>";

          let SummaryCard =
            '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
          SummaryCard +=
            '<div class="summarycardtitle"> ' +
            layerTitleName +
            '<span id="textcardDataSpan1" class="textspan"></span><span id="leftArrowSummaryCard1" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard1" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard1" class="closesummary">X</span></div>';

          SummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
            "Detailed Reservoir Properties" +
            "</div> </div>";

          SummaryCard = createSummaryCard(
            config.staticInformation.poolKeyAlias1,
            SummaryCard,
            contentInfo
          );

          SummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
            "Fluid Properties" +
            "</div> </div>";

          SummaryCard = createSummaryCard(
            config.staticInformation.poolKeyAlias2,
            SummaryCard,
            contentInfo
          );

          SummaryCard += '<div class="summarycardview">';
          SummaryCard +=
            '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
            "Less Details" +
            "</a></div>";

          SummaryCard += "</div>";

          SummaryCard += "</div>";

          basemapInfo.innerHTML = "";
          basemapInfo.innerHTML = SummaryCard + filterSummaryCard;
          //Overlapping Summarycard Data Start for right Arrow
          let textcardDataSpan = document.querySelector("#textcardDataSpan");
          let textSummincrem = Number(layerIndex) + 1;
          textcardDataSpan.innerText =
            "(" +
            textSummincrem +
            " of " +
            featureSummaryLength[0].length +
            ")";

          selected = layerData[layerIndex];
          map && mapSelector(selected, map);
          if (featureSummaryLength[0].length > 1) {
            if (featureSummaryLength[0].length - 1 !== layerIndex) {
              let rightArrowSummary = document.querySelector(
                "#rightArrowSummaryCard"
              );
              rightArrowSummary.classList.remove("arrowdisabled");
              rightArrowSummary.addEventListener("click", function (_event) {
                layerIndex = layerIndex + 1;

                selected = layerData[layerIndex];
                map && mapSelector(selected, map);
                summaryCaardPopupTempalte(
                  layerTitleName,
                  layerData,
                  layerIndex,
                  featureSummaryLength,
                  selected,
                  map
                );
              });
            }
          }
          // Overlapping Summarycard Data Start for left Arrow
          if (layerIndex > 0) {
            let leftArrowSummary = document.querySelector(
              "#leftArrowSummaryCard"
            );
            leftArrowSummary.classList.remove("arrowdisabled");

            leftArrowSummary.addEventListener("click", function (_event) {
              layerIndex = layerIndex - 1;

              selected = layerData[layerIndex];
              map && mapSelector(selected, map);
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected,
                map
              );
            });
          }
          //Overlapping End

          //Overlapping Summarycard Data Start moreitems right arrow
          let textcardDataSpan1 = document.querySelector("#textcardDataSpan1");
          let textSummincrem1 = layerIndex + 1;
          textcardDataSpan1.innerText =
            "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
          if (featureSummaryLength.length > 1) {
            if (featureSummaryLength.length - 1 !== layerIndex) {
              let rightArrowSummary1 = document.querySelector(
                "#rightArrowSummaryCard1"
              );
              rightArrowSummary1.classList.remove("arrowdisabled");
              rightArrowSummary1.addEventListener("click", function (_event) {
                layerIndex = layerIndex + 1;

                selected = layerData[layerIndex];
                try {
                  if (pointFeatures.includes(selected.id_.split(".")[0]))
                    selected.setStyle(highlightStylePoint);
                  else {
                    map && mapSelector(selected, map);
                  }
                } catch (e) {}

                summaryCaardPopupTempalte(
                  layerTitleName,
                  layerData,
                  layerIndex,
                  featureSummaryLength,
                  selected,
                  map
                );
                let moreDet = document.querySelector("#detailsSummaryCard");
                let lessDet = document.querySelector("#filterSummaryCard");
                if (moreDet) {
                  moreDet.style.display = "block";
                  lessDet.style.display = "none";
                }
              });
            }
          }

          //More Items Left arrow
          if (layerIndex > 0) {
            let leftArrowSummary1 = document.querySelector(
              "#leftArrowSummaryCard1"
            );
            leftArrowSummary1.classList.remove("arrowdisabled");

            leftArrowSummary1.addEventListener("click", function (_event) {
              layerIndex = layerIndex - 1;

              selected = layerData[layerIndex];
              try {
                if (pointFeatures.includes(selected.id_.split(".")[0]))
                  selected.setStyle(highlightStylePoint);
                else {
                  map && mapSelector(selected, map);
                }
              } catch (e) {}
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected,
                map
              );
              let moreDet = document.querySelector("#detailsSummaryCard");
              let lessDet = document.querySelector("#filterSummaryCard");

              moreDet.style.display = "block";
              lessDet.style.display = "none";
            });
          }
          //Overlapping End Moreitems
          let closecardContainer = document.querySelector("#closeSummaryCard");

          closecardContainer.addEventListener("click", function (_event) {
            basemapInfo.innerHTML = "";
            basemapInfo.style.display = "none";
          });

          let closecardContainer1 =
            document.querySelector("#closeSummaryCard1");

          closecardContainer1.addEventListener("click", function (_event) {
            basemapInfo.innerHTML = "";
            basemapInfo.style.display = "none";
          });

          $(document).ready(function () {
            $(".subheading").click(function () {
              $(this).next().toggle();
              $(this).toggleClass("esri-icon-down");
            });
            $(".btnmoredetails").click(function () {
              $(this).children(".lessmore").toggle();
              $(this).children(".viewmore").toggle();
              $(this).siblings(".moredetails").toggle();
              $(this).siblings(".lessdetails").toggle();
            });
          });

          let moresummcardContainer = document.querySelector("#summaryMore");
          moresummcardContainer.addEventListener("click", function (event) {
            event.preventDefault();
            //let showlessTextContainer = event.currentTarget;
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            moreDet.style.display = "block";
            lessDet.style.display = "none";
          });

          let moresummcardContainer1 = document.querySelector("#summaryMore1");
          moresummcardContainer1.addEventListener("click", function (_event) {
            //let showlessTextContainer = event.currentTarget;
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            moreDet.style.display = "none";
            lessDet.style.display = "block";
          });
        }
      } else if (
        popup &&
        popup[layerIndex].id.split(".")[0] === "southern_arkansas_pool_polygons"
      ) {
        {
          let contentInfo = popup[layerIndex].properties;

          layerTitleName = "Pool Summary";

          let filterSummaryCard =
            '<div id="filterSummaryCard" class="summarycardcontent">';
          filterSummaryCard +=
            '<div class="summarycardtitle"> ' +
            layerTitleName +
            '<span id="textcardDataSpan" class="textspan"></span><span id="leftArrowSummaryCard" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard" class="closesummary">X</span></div>';
          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Pool Summary" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.poolKeySummaryAlias1,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Effective CO2 Storage Potential" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeySummaryAlias2,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Pool Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeySummaryAlias3,
              filterSummaryCard,
              contentInfo
            ) +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Reservoir Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div class="subitems" style="display:none">';
          filterSummaryCard += '<div class="lessdetails">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeySummaryAlias4,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard += '<div class="moredetails" style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeyAlias1,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard +=
            '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

          filterSummaryCard +=
            '<a href="#" class="viewmore" style="color:#00aaab";>' +
            "More Details" +
            "</a>";

          filterSummaryCard +=
            '<a href="#" class="lessmore" style="display:none">' +
            "Less Details" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>";

          filterSummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle subheading esri-icon-right">';
          filterSummaryCard +=
            '<a href="#" style="color:#00aaab";>' +
            "Fluid Properties" +
            "</a>" +
            "</div>";
          filterSummaryCard += '<div class="subitems" style="display:none">';
          filterSummaryCard += '<div class="lessdetails">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeySummaryAlias5,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard += '<div class="moredetails" style="display:none">';
          filterSummaryCard =
            createfilterSummaryCard(
              config.staticInformation.SApoolKeyAlias2,
              filterSummaryCard,
              contentInfo
            ) + "</div>";
          filterSummaryCard +=
            '<div class="summarycardLabel btnmoredetails" 1style="display:none">';

          filterSummaryCard +=
            '<a href="#" class="viewmore" style="color:#00aaab";>' +
            "More Details" +
            "</a>";

          filterSummaryCard +=
            '<a href="#" class="lessmore" style="display:none">' +
            "Less Details" +
            "</a>" +
            "</div>" +
            "</div>" +
            "</div>";

          filterSummaryCard += '<div class="summarycardview">';
          filterSummaryCard +=
            '<div class="summarycardLabel" style="display:none"> <a href="#" id="summaryMore" style="color:#00aaab";>' +
            "More Details" +
            "</a></div>";

          filterSummaryCard += "</div>";

          filterSummaryCard += "</div>";

          let SummaryCard =
            '<div id="detailsSummaryCard" class="summarycardcontent" style="display:none">';
          SummaryCard +=
            '<div class="summarycardtitle"> ' +
            layerTitleName +
            '<span id="textcardDataSpan1" class="textspan"></span><span id="leftArrowSummaryCard1" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard1" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard1" class="closesummary">X</span></div>';

          SummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
            "Detailed Reservoir Properties" +
            "</div> </div>";

          SummaryCard = createSummaryCard(
            config.staticInformation.SApoolKeyAlias1,
            SummaryCard,
            contentInfo
          );

          SummaryCard +=
            '<div class="summarycardview moresummview filterview"> <div class="summarycardtitle">' +
            "Fluid Properties" +
            "</div> </div>";

          SummaryCard = createSummaryCard(
            config.staticInformation.SApoolKeyAlias2,
            SummaryCard,
            contentInfo
          );

          SummaryCard += '<div class="summarycardview">';
          SummaryCard +=
            '<div class="summarycardLabel" style ="width:100%"; margin-top:15px"> <a href="#" id="summaryMore1" style="color:#00aaab";>' +
            "Less Details" +
            "</a></div>";

          SummaryCard += "</div>";

          SummaryCard += "</div>";

          basemapInfo.innerHTML = "";
          basemapInfo.innerHTML = SummaryCard + filterSummaryCard;
          //Overlapping Summarycard Data Start for right Arrow
          let textcardDataSpan = document.querySelector("#textcardDataSpan");
          let textSummincrem = Number(layerIndex) + 1;
          textcardDataSpan.innerText =
            "(" +
            textSummincrem +
            " of " +
            featureSummaryLength[0].length +
            ")";

          selected = layerData[layerIndex];
          map && mapSelector(selected, map);
          if (featureSummaryLength[0].length > 1) {
            if (featureSummaryLength[0].length - 1 !== layerIndex) {
              let rightArrowSummary = document.querySelector(
                "#rightArrowSummaryCard"
              );
              rightArrowSummary.classList.remove("arrowdisabled");
              rightArrowSummary.addEventListener("click", function (_event) {
                layerIndex = layerIndex + 1;

                selected = layerData[layerIndex];
                map && mapSelector(selected, map);
                summaryCaardPopupTempalte(
                  layerTitleName,
                  layerData,
                  layerIndex,
                  featureSummaryLength,
                  selected,
                  map
                );
              });
            }
          }
          // Overlapping Summarycard Data Start for left Arrow
          if (layerIndex > 0) {
            let leftArrowSummary = document.querySelector(
              "#leftArrowSummaryCard"
            );
            leftArrowSummary.classList.remove("arrowdisabled");

            leftArrowSummary.addEventListener("click", function (_event) {
              layerIndex = layerIndex - 1;

              selected = layerData[layerIndex];
              map && mapSelector(selected, map);
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected,
                map
              );
            });
          }
          //Overlapping End

          //Overlapping Summarycard Data Start moreitems right arrow
          let textcardDataSpan1 = document.querySelector("#textcardDataSpan1");
          let textSummincrem1 = layerIndex + 1;
          textcardDataSpan1.innerText =
            "(" + textSummincrem1 + " of " + featureSummaryLength.length + ")";
          if (featureSummaryLength.length > 1) {
            if (featureSummaryLength.length - 1 !== layerIndex) {
              let rightArrowSummary1 = document.querySelector(
                "#rightArrowSummaryCard1"
              );
              rightArrowSummary1.classList.remove("arrowdisabled");
              rightArrowSummary1.addEventListener("click", function (_event) {
                layerIndex = layerIndex + 1;

                selected = layerData[layerIndex];
                try {
                  if (pointFeatures.includes(selected.id_.split(".")[0]))
                    selected.setStyle(highlightStylePoint);
                  else {
                    map && mapSelector(selected, map);
                  }
                } catch (e) {}

                summaryCaardPopupTempalte(
                  layerTitleName,
                  layerData,
                  layerIndex,
                  featureSummaryLength,
                  selected,
                  map
                );
                let moreDet = document.querySelector("#detailsSummaryCard");
                let lessDet = document.querySelector("#filterSummaryCard");
                if (moreDet) {
                  moreDet.style.display = "block";
                  lessDet.style.display = "none";
                }
              });
            }
          }

          //More Items Left arrow
          if (layerIndex > 0) {
            let leftArrowSummary1 = document.querySelector(
              "#leftArrowSummaryCard1"
            );
            leftArrowSummary1.classList.remove("arrowdisabled");

            leftArrowSummary1.addEventListener("click", function (_event) {
              layerIndex = layerIndex - 1;

              selected = layerData[layerIndex];
              try {
                if (pointFeatures.includes(selected.id_.split(".")[0]))
                  selected.setStyle(highlightStylePoint);
                else {
                  map && mapSelector(selected, map);
                }
              } catch (e) {}
              summaryCaardPopupTempalte(
                layerTitleName,
                layerData,
                layerIndex,
                featureSummaryLength,
                selected,
                map
              );
              let moreDet = document.querySelector("#detailsSummaryCard");
              let lessDet = document.querySelector("#filterSummaryCard");

              moreDet.style.display = "block";
              lessDet.style.display = "none";
            });
          }
          //Overlapping End Moreitems
          let closecardContainer = document.querySelector("#closeSummaryCard");

          closecardContainer.addEventListener("click", function (_event) {
            basemapInfo.innerHTML = "";
            basemapInfo.style.display = "none";
          });

          let closecardContainer1 =
            document.querySelector("#closeSummaryCard1");

          closecardContainer1.addEventListener("click", function (_event) {
            basemapInfo.innerHTML = "";
            basemapInfo.style.display = "none";
          });

          $(document).ready(function () {
            $(".subheading").click(function () {
              $(this).next().toggle();
              $(this).toggleClass("esri-icon-down");
            });
            $(".btnmoredetails").click(function () {
              $(this).children(".lessmore").toggle();
              $(this).children(".viewmore").toggle();
              $(this).siblings(".moredetails").toggle();
              $(this).siblings(".lessdetails").toggle();
            });
          });

          let moresummcardContainer = document.querySelector("#summaryMore");
          moresummcardContainer.addEventListener("click", function (event) {
            event.preventDefault();
            //let showlessTextContainer = event.currentTarget;
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            moreDet.style.display = "block";
            lessDet.style.display = "none";
          });

          let moresummcardContainer1 = document.querySelector("#summaryMore1");
          moresummcardContainer1.addEventListener("click", function (_event) {
            //let showlessTextContainer = event.currentTarget;
            let moreDet = document.querySelector("#detailsSummaryCard");
            let lessDet = document.querySelector("#filterSummaryCard");
            moreDet.style.display = "none";
            lessDet.style.display = "block";
          });
        }
      }

      let closecardContainer1 = document.querySelector("#closeSummaryCard5");
      closecardContainer1 &&
        closecardContainer1.addEventListener("click", function (_event) {
          basemapInfo.innerHTML = "";
          basemapInfo.style.display = "none";
        });
    }

    //Overlapping Summarycard Data Start for left Arrow
    if (layerIndex > 0) {
      let leftArrowSummary = document.querySelector("#leftArrowSummaryCard6");
      leftArrowSummary && leftArrowSummary.classList.remove("arrowdisabled");

      leftArrowSummary &&
        leftArrowSummary.addEventListener("click", function (_event) {
          layerIndex = layerIndex - 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
    }
    //Overlapping End
  } else if (
    featureSummaryLength.length > 0 &&
    Object.keys(featureSummaryLength[0]).length > 0
  ) {
    let contentInfo = featureSummaryLength;
    layerTitleName = "Property Ownership Summary";
    let filterSummaryCard =
      '<div id="filterSummaryCard" class="summarycardcontent">';
    filterSummaryCard +=
      '<div class="summarycardtitle"> ' +
      layerTitleName +
      '<span id="textcardDataSpan6" class="textspan"></span><span id="leftArrowSummaryCard6" class="leftsummaryArrow esri-icon-left arrowdisabled"></span><span id="rightArrowSummaryCard6" class="rightsummaryArrow esri-icon-right arrowdisabled"></span><span id="closeSummaryCard6" class="closesummary">X</span></div>';
    filterSummaryCard +=
      '<div class="summarycardview moresummview filterview">';

    let ownerVal = contentInfo[layerIndex].owner.toLocaleString();

    var x = document.createElement("a");
    var linkText = document.createTextNode(ownerVal);
    x.appendChild(linkText);
    x.title = "my title text";
    x.href = "#";
    x.addEventListener("click", function () {
      getDataOwner(ownerVal, map);
    });

    filterSummaryCard +=
      '<div class="summarycardLabel">' +
      "Owner Name " +
      '</div> <div class="summarycardvalue" id="ownerProperty"></div>';

    const propertyOwnershipCard = (dataReceived) => {
      dataReceived.forEach((item) => {
        let val = item;
        filterSummaryCard +=
          '<div class="summarycardLabel">' +
          val[Object.keys(val)[0]] +
          '</div> <div class="summarycardvalue">' +
          contentInfo[layerIndex][Object.keys(val)[0]] +
          "</div>";
      });
    };

    propertyOwnershipCard(config.staticInformation.PropertyownershipData);

    filterSummaryCard += "</div>";
    filterSummaryCard += "</div>";

    basemapInfo.innerHTML = "";
    basemapInfo.innerHTML = filterSummaryCard;

    document.getElementById("ownerProperty").appendChild(x);
    let closecardContainer1 = document.querySelector("#closeSummaryCard6");

    closecardContainer1.addEventListener("click", function (_event) {
      basemapInfo.innerHTML = "";
      basemapInfo.style.display = "none";
    });

    let textcardDataSpan = document.querySelector("#textcardDataSpan6");
    let textSummincrem = Number(layerIndex) + 1;
    textcardDataSpan.innerText =
      "(" + textSummincrem + " of " + featureSummaryLength.length + ")";
    if (featureSummaryLength.length > 1) {
      if (featureSummaryLength.length - 1 !== layerIndex) {
        let rightArrowSummary = document.querySelector(
          "#rightArrowSummaryCard6"
        );
        rightArrowSummary.classList.remove("arrowdisabled");
        rightArrowSummary.addEventListener("click", function (_event) {
          layerIndex = layerIndex + 1;
          if (selected !== null) {
            if (selected !== undefined) {
              selected.setStyle(undefined);
              selected = null;
            }
          }
          selected = layerData[layerIndex];
          arrowClick(selected);
          summaryCaardPopupTempalte(
            layerTitleName,
            layerData,
            layerIndex,
            featureSummaryLength,
            selected
          );
        });
      }
    }
  }
};

export const getDataOwner = async (a, map) => {
  let closecardContainer = document.querySelector("#closeCard");

  closecardContainer.addEventListener("click", function (_event) {
    document.getElementById("relatedProperties").style.display = "none";
  });

  if (document.getElementById("relatedProperties").style.display === "block") {
    var resultsEle = document.getElementById("results");
    var div = document.createElement("div");
    div.classList.add("loader");
    resultsEle.appendChild(div);
  }

  await axios
    .get("https://app.regrid.com/api/v1/search.json", {
      params: {
        owner: a,
        limit: 5,
        token: config.staticInformation.propertyOwnershipToken,
      },
    })
    .then(function (response) {
      createRelatedPropertiesCard(response, map);
    });
};
var vectorSource = new Vector();
const vectorLayer = new VectorLayer({
  source: vectorSource,
});

export const getDataArea = async (val1, val2, map) => {
  let closecardContainer = document.querySelector("#closeCard");

  closecardContainer.addEventListener("click", function (_event) {
    document.getElementById("relatedProperties").style.display = "none";
  });
  if (document.getElementById("relatedProperties").style.display === "block") {
    var resultsEle = document.getElementById("results");
    var div = document.createElement("div");
    div.classList.add("loader");
    resultsEle.appendChild(div);
  } else {
    document.getElementById("relatedProperties").style.display = "none";
  }

  await axios
    .get("https://app.regrid.com/api/v1/query?", {
      params: {
        "fields[ll_gisacre][between]": [val1, val2],
        limit: 5,
        token: config.staticInformation.propertyOwnershipToken,
      },
    })
    .then(function (response) {
      createRelatedPropertiesCard(response, map);
    });
};

export const checkUsage = async () => {
  await axios
    .get("https://app.regrid.com/api/v1/usage.json?", {
      params: {
        full_history: true,
        token: config.staticInformation.propertyOwnershipToken,
      },
    })
    .then(function (response) {
      console.log(response.data.results.full_history[0].total.results);
      const stat = response.data.results.full_history[0].total.results;
      localStorage.setItem(
        "stat",
        (stat / config.staticInformation.propertyOwnershipUsageLimit) * 100
      );
    });
};

export const createRelatedPropertiesCard = (response, map) => {
  var resultsEle = document.getElementById("results");
  resultsEle.innerHTML = "";
  response.data.results.forEach(function (arrayItem) {
    var dt = arrayItem.properties.fields.parcelnumb;
    var li = document.createElement("li");
    var x = document.createElement("a");
    var linkText = document.createTextNode(dt);
    x.appendChild(linkText);
    x.href = "#";
    x.addEventListener("click", function () {
      if (vectorLayer && vectorSource) {
        vectorSource.clear();
        map.removeLayer(vectorLayer);
      }
      highlightProperty(arrayItem.geometry, map);
    });
    li.appendChild(x);
    document.getElementById("relatedProperties").style.display = "block";
    resultsEle.appendChild(li);
  });
};
export const highlightProperty = (val, map) => {
  var feat;
  if (val.type === "Polygon") {
    feat = new Feature({
      geometry: new Polygon(val.coordinates),
    });
  } else {
    feat = new Feature({
      geometry: new MultiPolygon(val.coordinates),
    });
  }
  feat.getGeometry().transform("EPSG:4326", "EPSG:3857");

  const extent = feat.getGeometry().getExtent();
  const center = getCenter(extent);
  vectorSource.addFeature(feat);
  map.addLayer(vectorLayer);
  map.getView().setCenter(center);
};
