import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import { ccsFilterContext } from "../App";
import DotIcon from "../assets/images/three-dots-vertical.svg";
import config from "../configs/appSettings";
import Legend from "./Legend";
import ExportLayer from "./ExportLayer";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const GulfCoastLayers = ({ map, layerCollapsableChange, fnaddccsatlasLayerChange, roleAccess, genaccesToken, Offshore_Gulf_Coast_Expands, Saline_Aquifer_Expands }) => {
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;
  let logoPath = window.location.protocol + "//" + window.location.host + DotIcon;
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];
 
  return (
    <ul style={
      Saline_Aquifer_Expands[0] ? { display: 'block' } : { display: 'none' }}>
      {
        config.LayerMetaData.map(lyrObj => (
          lyrObj.subCat?.map(subLyr => (
            subLyr.key === "offshore_Gulf_Coast" && <li id={subLyr.key}>
              <span onClick={(e) => layerCollapsableChange(e)}
                className={`${Offshore_Gulf_Coast_Expands[0] ? 'collapseList layerActive' : 'collapseList'}  ${roleAccess.CA_GC_Offshore_SA ? "enableList" : "disablelist"}`}>
                {subLyr.label}
                {(roleAccess.CA_GC_Offshore_SA != true) && <span className="rspan">Not Subscribed</span>}
              </span>
              <div className="collapseGroup" id="well_control_points" style={
Offshore_Gulf_Coast_Expands[0] && roleAccess.CA_GC_Offshore_SA  ? { display: 'block' } : { display: 'none' }}>
                <div className="icon-list tgscollapse-panel" style={{ marginTop: "10px", marginLeft: "10px" }} >
                  <label className="arlas-checkbox icon-text">
                    Well Control Points
                    <input
                      type="checkbox"
                      checked={JSON.parse(localStorage.getItem('DefaultValues'))['well_control_points']}
                      name={"well_control_points"}
                      id={"well_control_points"}
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(
                          e,
                          "well_control_points"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="circle" style={{ backgroundColor: '#fff', border: "1px solid #000" }}></span>
                </div>
              </div>
              {subLyr.subCat?.map((dataset, index) => (
                <div style={
                  Offshore_Gulf_Coast_Expands[0] ? { display: 'block' } : { display: 'none' }}>
                  <div className="collapseGroup" style={{ marginTop: "10px", marginLeft: "10px" }}>
                    <span onClick={(e) => layerCollapsableChange(e)}
                       className={ Offshore_Gulf_Coast_Expands[0] ? 'collapseList layerActive' : 'collapseList'}
                    >
                      {dataset.groupName}
                    </span>

                    {roleAccess.CA_EXPORT && <ExportLayer customClassname="exportcustdiv GC_layer" genaccesToken={genaccesToken} filename={dataset.groupName} layerData={dataset?.exportlayerName} ></ExportLayer>}

                    <ul className="tgscollapse-panel" id={dataset.id} style={
Offshore_Gulf_Coast_Expands[0] ? { display: 'block' } : { display: 'none' }}>
                      {
                        dataset[dataset.groupName].source.map((lyr, idx) => (
                          lyr.eleType == "checkbox" ? 

                          <li>
                              <label className="arlas-checkbox icon-text">
                                {lyr.displayName}
                                <input
                                  type="checkbox"
                                  checked={JSON.parse(localStorage.getItem('DefaultValues'))[lyr.lyrName]}
                                  name={lyr.lyrName}
                                  id={lyr.lyrName}
                                  onChange={(e) => fnaddccsatlasLayerChange(e, lyr.lyrName)}
                                />
                                <span className="checkmark"></span>
                              </label>
                              {lyr.displayName == "Well Penetration" ? <span className={`SA-GC four-pointed-star ${lyr.lyrName}`}></span>
                                : <span className="box" style={{ backgroundColor: '#fff', border: `2px solid ${lyr.faultBG}` }}></span>}
                            </li>
                            : <li>
                            <label className="custom-radio">
                              {lyr.displayName}
                              <input
                                type="checkbox"
                                checked={JSON.parse(localStorage.getItem('DefaultValues'))[lyr.lyrName]}
                                name={lyr.lyrName}
                                id={lyr.lyrName + "radio"}
                                groupName="salineGroupName"
                                visibleLegend={index + "radio" + idx}
                                visibleLegendImg={lyr.lyrName + "_img"}
                                onChange={(e) => fnaddccsatlasLayerChange(e, lyr.lyrName)}
                              />
                              <span className="checkmarkradio"></span>
                            </label>
                            <div style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} id={index + "radio" + idx} class="radiocheck">
                              <Legend legendData={lyr.legendData} />
                            </div>
                            <div id={lyr.lyrName + "_img"} style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} class="legendimage">
                                            <img src="" />
                            </div>
                          </li>

                        ))
                      }
                    </ul>
                  </div>
                </div>
              ))}
            </li>
          ))
        ))
      }
    </ul>
  );
};
export default GulfCoastLayers;