import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from '@material-ui/icons/Delete';

const ImportedShapefileTemplate = ({ fnaddccsatlasLayerChange, filename, removeImportedLayer }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDelete = (file) => {
    const element = document.getElementById(file + "Div");
    element.remove();
    removeImportedLayer(file);
  }

  return (
    <li id={filename + "Div"} style={{ display: "block" }}>
      <label className="arlas-checkbox" style={{ width: "90%" }}>
        {filename}
        <input
        checked = {JSON.parse(localStorage.getItem('DefaultValues'))[filename]}
          type="checkbox"
          name={filename + "1"}
          id={filename + "2"}
          onChange={(e) => fnaddccsatlasLayerChange(e, filename)}
          defaultChecked
        />
        <span className="checkmark"></span>
      </label>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          color: "rgba(0,0,0,.87)",
          height: 35,
          padding: 5,
          marginTop: -10,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onDelete(filename);
            handleClose();
          }}
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <DeleteIcon />
          <span style={{ marginLeft: 4, fontSize: 12, marginRight: 4 }}>
            Delete
          </span>
        </MenuItem>
      </Menu>
    </li>
  );
};

export default ImportedShapefileTemplate;
