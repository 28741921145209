export const createfilterSummaryCard = (dataReceived, filterSummaryCard, contentInfo) => {
  let currUnit = document.getElementById("units").value
  dataReceived.forEach((item) => {
    let val = item;
    filterSummaryCard +=
      '<div class="summarycardview moresummview filterview">';
    if (typeof contentInfo[Object.keys(val)[0]] === "number") {
      if (val[Object.keys(val)[0]][0].includes("Area")) {
        filterSummaryCard +=
          '<div id="arealabel" class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div id="areavalue"  class="summarycardvalue">' +
          Number(
            contentInfo[Object.keys(val)[0]].toFixed(
              val[Object.keys(val)[0]][1]
            )
          ).toLocaleString("en-AU") +
          "</div>";
      } else if (val[Object.keys(val)[0]][0].includes("Median Net Reservoir Thickness")) {
        filterSummaryCard +=
          '<div id="reservoirlabel" class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div id="reservoirvalue" class="summarycardvalue">' +
          Number(
            contentInfo[Object.keys(val)[0]].toFixed(
              val[Object.keys(val)[0]][1]
            )
          ).toLocaleString("en-AU") +
          "</div>";
      } 
      else if ( contentInfo[Object.keys(val)[0]] === 0) {
        filterSummaryCard +=
        '<div class="summarycardLabel">' +
        val[Object.keys(val)[0]][0] +
        '</div> <div class="summarycardvalue">' +
       "NA" +
        "</div>";
      }
      
      else {
        filterSummaryCard +=
          '<div class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div class="summarycardvalue">' +
          Number(
            contentInfo[Object.keys(val)[0]].toFixed(
              val[Object.keys(val)[0]][1]
            )
          ).toLocaleString("en-AU") +
          "</div>";
      }
    } else {
      if (val[Object.keys(val)[0]][0].includes("Median TVD Depth")) {
        filterSummaryCard +=
          '<div id ="depthlabel" class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div id ="depthvalue" class="summarycardvalue">' +
          contentInfo[Object.keys(val)[0]] +
          "</div>";
      } else {
        filterSummaryCard +=
          '<div class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div class="summarycardvalue">' +
          contentInfo[Object.keys(val)[0]] +
          "</div>";
      }
    }
    filterSummaryCard += "</div>";
  });
  return filterSummaryCard;
};

export const createSummaryCard = (dataReceived, SummaryCard, contentInfo) => {
  dataReceived.forEach((item) => {
    let currUnit = document.getElementById("units").value
    let val = item;
    if (contentInfo[Object.keys(val)[0]] !== " ") {
      SummaryCard +=
        '<div class="summarycardview moresummview filterview">';
      if (typeof contentInfo[Object.keys(val)[0]] === "number") {
        if (val[Object.keys(val)[0]][0].includes("P10 Low TVD Depth")) {
          SummaryCard +=
            '<div id="P10depthlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P10depthvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else if (val[Object.keys(val)[0]][0].includes("P50 Median TVD Depth")) {
          SummaryCard +=
            '<div id="P50depthlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P50depthvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else if (val[Object.keys(val)[0]][0].includes("P90 High TVD Depth")) {
          SummaryCard +=
            '<div id="P90depthlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P90depthvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else if (val[Object.keys(val)[0]][0].includes("P10 Low Net Reservoir Thickness")) {
          SummaryCard +=
            '<div id="P10reservoirlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P10reservoirvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else if (val[Object.keys(val)[0]][0].includes("P50 Median Net Reservoir Thickness")) {
          SummaryCard +=
            '<div id="P50reservoirlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P50reservoirvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else if (val[Object.keys(val)[0]][0].includes("P90 High Net Reservoir Thickness")) {
          SummaryCard +=
            '<div id="P90reservoirlabel" class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div id="P90reservoirvalue" class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        } else {
          SummaryCard +=
            '<div class="summarycardLabel">' +
            val[Object.keys(val)[0]][0] +
            '</div> <div class="summarycardvalue">' +
            Number(
              contentInfo[Object.keys(val)[0]].toFixed(
                val[Object.keys(val)[0]][1]
              )
            ).toLocaleString("en-AU") +
            "</div>";
        }
      } else {
        SummaryCard +=
          '<div class="summarycardLabel">' +
          val[Object.keys(val)[0]][0] +
          '</div> <div class="summarycardvalue">' +
          contentInfo[Object.keys(val)[0]] +
          "</div>";
      }
      SummaryCard += "</div>";
    }
  });

  return SummaryCard;
};