import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import { ccsFilterContext } from "../App";
import DotIcon from "../assets/images/three-dots-vertical.svg";
import config from "../configs/appSettings";
import ExportLayer from "./ExportLayer";
import Legend from "./Legend";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
const SouthernLayers = ({ map, layerCollapsableChange, fnaddccsatlasLayerChange, roleAccess, genaccesToken, Southern_AR_MS_AL_Expands,Saline_Aquifer_Expands, Southern_AR_MS_AL_WellControlPoints_Expands }) => {
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;
  let logoPath = window.location.protocol + "//" + window.location.host + DotIcon;
  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];
  return (
    <ul style={
      (Saline_Aquifer_Expands[0] || Southern_AR_MS_AL_WellControlPoints_Expands[0]) ? { display: 'block' } : { display: 'none' }}>
      {
        config.LayerMetaData.map(lyrObj => (
          lyrObj.subCat?.map(subLyr => (
            subLyr.key === "southern_ar_ms_al" && <li id={subLyr.key}>
              <span onClick={(e) => layerCollapsableChange(e)}
                className={`${Southern_AR_MS_AL_Expands[0] || Southern_AR_MS_AL_WellControlPoints_Expands[0]? 'collapseList layerActive' : 'collapseList'}  ${roleAccess.CA_AR_S_SA ? "enableList" : "disablelist"}`}>
                {subLyr.label}
                {(roleAccess.CA_AR_S_SA != true && subLyr.key === "southern_ar_ms_al") && <span className="rspan">Not Subscribed</span>}
              </span>
              <div className="collapseGroup" id={"expand_drax_sa_well_control_points"} style={
(Southern_AR_MS_AL_WellControlPoints_Expands[0] || Southern_AR_MS_AL_Expands[0]) && roleAccess.CA_AR_S_SA ? { display: 'block' } : { display: 'none' }}>
                <div className="icon-list tgscollapse-panel" style={{ marginTop: "10px", marginLeft: "10px" }} >
                  <label className="arlas-checkbox icon-text">
                    Well Control Points
                    <input
                      type="checkbox"
                      checked={JSON.parse(localStorage.getItem('DefaultValues'))['drax_sa_well_control_points']}
                      name={"drax_sa_well_control_points"}
                      id={"drax_sa_well_control_points"}
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(
                          e,
                          "drax_sa_well_control_points"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="circle" style={{ backgroundColor: '#fff', border: "1px solid #000" }}></span>
                </div>
              </div>
              <div className="collapseGroup" id={"expand_arkansasmiss_faults"} style={
(Southern_AR_MS_AL_WellControlPoints_Expands[0] || Southern_AR_MS_AL_Expands[0]) && roleAccess.CA_AR_S_SA ? { display: 'block' } : { display: 'none' }}>
                <div className="icon-list tgscollapse-panel" style={{ marginTop: "10px", marginLeft: "10px" }} >
                  <label className="arlas-checkbox icon-text sa-ar-text">
                    Fault Outlines
                    <input
                      type="checkbox"
                      checked={JSON.parse(localStorage.getItem('DefaultValues'))['arkansasmiss_faults']}
                      name={"arkansasmiss_faults"}
                      id={"arkansasmiss_faults"}
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(
                          e,
                          "arkansasmiss_faults"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="box sa-ar-box-1" style={{ backgroundColor: '#fff', border: "2px solid #000" }}></span>
                </div>
              </div>
              <div className="collapseGroup" id={"expand_gcdiapirg_tx_southcentral"} style={
(Southern_AR_MS_AL_WellControlPoints_Expands[0] || Southern_AR_MS_AL_Expands[0])&& roleAccess.CA_AR_S_SA ? { display: 'block' } : { display: 'none' }}>
                <div className="icon-list tgscollapse-panel" style={{ marginTop: "10px", marginLeft: "10px" }} >
                  <label className="arlas-checkbox icon-text sa-ar-text">
                    Salt Diapirs
                    <input
                      type="checkbox"
                      checked={JSON.parse(localStorage.getItem('DefaultValues'))['gcdiapirg_tx_southcentral']}
                      name={"gcdiapirg_tx_southcentral"}
                      id={"gcdiapirg_tx_southcentral"}
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(
                          e,
                          "gcdiapirg_tx_southcentral"
                        )
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="box sa-ar-box-2" style={{ backgroundColor: 'rgb(250 51 255)'}}></span>
                </div>
              </div>
              {subLyr.subCat?.map((dataset, index) => (
                <div style={
                  (Southern_AR_MS_AL_Expands[0] || Southern_AR_MS_AL_WellControlPoints_Expands[0]) && roleAccess.CA_AR_S_SA ? { display: 'block' } : { display: 'none' }}>
                  <div className="collapseGroup" style={{ marginTop: "10px", marginLeft: "10px" }}>
                    <span onClick={(e) => layerCollapsableChange(e)}
                      className={
                        Southern_AR_MS_AL_Expands[0] ? 'collapseList layerActive' : 'collapseList'
                      }
                    >
                      {dataset.groupName}
                    </span>
                    <ul className="tgscollapse-panel" id={dataset.id} style={
      Southern_AR_MS_AL_Expands[0] ? { display: 'block' } : { display: 'none' }}>
                      {
                        dataset.southern_ar_ms_al && dataset[dataset.groupName].source.map((subset, i) => {
                          return <div >
                            {subset.groupName != "Basement" && <span className="label-list">{subset.groupName}</span>}
                            {
                              subset.groupName != "Basement" && subset[subset.groupName].source.map((superSub, idx) => {
                                return <ul className="southern_ar_ms_al_list">
                                  <span id={superSub.id} style={{ paddingLeft: "30px" }} onClick={(e) => layerCollapsableChange(e)}
                                    className={filtervalues[superSub.id] ? 'collapseList layerActive' : 'collapseList'}>{superSub.groupName}
                                  </span>
                                  {roleAccess.CA_EXPORT && <ExportLayer customClassname="exportcustdiv" genaccesToken={genaccesToken} filename={superSub.groupName} layerData={superSub?.exportlayerName} exportFilter={superSub?.exportLayerFilter} ></ExportLayer>}
                                  {
                                    superSub[superSub.groupName].source.map((lyr, idx) => (
                                      lyr?.eleType == "checkbox" ?
                                        <li style={filtervalues[superSub.id] ? { display: "block", paddingLeft: "35px" } : { paddingLeft: "35px", display: "none" }}>
                                        <label className={`arlas-checkbox ${lyr.displayName == "Well Penetration" && 'icon-text'}`}>
                                        {lyr.displayName == "Well Penetration" && <span className={`four-pointed-star ${lyr.lyrName}`}></span>}{lyr.displayName}
                                          <input
                                            type="checkbox"
                                            checked={JSON.parse(localStorage.getItem('DefaultValues'))[lyr.lyrName]}
                                            name={lyr.lyrName}
                                            id={lyr.lyrName}
                                            visibleLegend={index + "radio" + idx}
                                            visibleLegendImg={lyr.lyrName + "_img"}
                                            onChange={(e) => fnaddccsatlasLayerChange(e, lyr.lyrName)}
                                          />
                                          <span className="checkmark"></span>
                                        </label>
                                        <div style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} id={index + "radio" + idx} class="radiocheck">
                                          <Legend legendData={lyr.legendData} />
                                        </div>
                                        {lyr.displayName != "Well Penetration" && <div id={lyr.lyrName + "_img"} style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} class="legendimage">
                                          <img src="" />
                                        </div>}
                                      </li>
                                        : <li style={filtervalues[superSub.id] ? { display: "block", paddingLeft: "35px" } : { paddingLeft: "35px", display: "none" }}>
                                          <label className="custom-radio">
                                            {lyr.displayName}
                                            <input
                                              type="checkbox"
                                              checked={JSON.parse(localStorage.getItem('DefaultValues'))[lyr.lyrName]}
                                              name={lyr.lyrName}
                                              id={lyr.lyrName + "radio"}
                                              groupName="salineGroupName"
                                              visibleLegend={index + "radio" + idx}
                                              visibleLegendImg={lyr.lyrName + "_img"}
                                              onChange={(e) => fnaddccsatlasLayerChange(e, lyr.lyrName)}
                                            />
                                            <span className="checkmarkradio"></span>
                                          </label>
                                          <div style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} id={index + "radio" + idx} class="radiocheck">
                                            <Legend legendData={lyr.legendData} />
                                          </div>
                                          <div id={lyr.lyrName + "_img"} style={filtervalues[lyr.lyrName] ? { display: "block" } : { display: "none" }} class="legendimage">
                                            <img src="" />
                                          </div>
                                        </li>
                                    ))
                                  }
                                </ul>
                              })
                            }
                            {
                              subset.groupName == "Basement" &&
                              <li style={filtervalues[subset.id] ? { display: "block", paddingLeft: "35px" } : { paddingLeft: "35px" }}>
                                <label className={`arlas-checkbox ${subset.displayName == "Well Penetration" && 'icon-text'}`}>
                                {subset.displayName == "Well Penetration" && <span className={`four-pointed-star ${subset.lyrName}`}></span>}{subset.displayName}
                                  <input
                                    type="checkbox"
                                    checked={JSON.parse(localStorage.getItem('DefaultValues'))[subset.lyrName]}
                                    name={subset.lyrName}
                                    id={subset.lyrName}
                                    visibleLegend={subset.lyrName + "_legend"}
                                    visibleLegendImg={subset.lyrName + "_img"}
                                    onChange={(e) => fnaddccsatlasLayerChange(e, subset.lyrName)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <div style={filtervalues[subset.lyrName] ? { display: "block" } : { display: "none" }} id={subset.lyrName + "_legend"} class="radiocheck">
                                  <Legend legendData={subset.legendData} />
                                </div>
                                {subset.displayName != "Well Penetration" && <div id={subset.lyrName + "_img"} style={filtervalues[subset.lyrName] ? { display: "block" } : { display: "none" }} class="legendimage">
                                  <img src="" />
                                </div>}
                              </li>
                            }
                          </div>
                        })
                      }
                    </ul>
                  </div>
                </div>
              ))}
            </li>
          ))
        ))
      }
    </ul>
  );
};
export default SouthernLayers;