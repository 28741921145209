import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
} from "@material-ui/core/";
import { ccsFilterContext } from "../App";
import { GetSortOrder } from "./utils/calFunc";
import config from "../configs/appSettings";
import { saveQuery,getQueries,updateQuery,getQueryById } from "./utils/queryServicesLocal";
import Loading from "./Loading";
import 'ol/ol.css';

const SelectQuery = (props) => {
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;
  const map= props.map;
  const userEmail = localStorage.getItem(`${config.users.email}`);
  const accessToken = props.authorization;
  const [selectedQuery, setselectedQuery] = useState(-1);
  const [saveMenuOpen, setSaveMenuOpen] = useState(false);
  const [isDisabledSave, setisDisabledSave] = useState(false);
  const [isdisableMainSave, setisdisableMainSave] = useState(false);
  const [QueryNameModal, setQueryNameModal] = useState(false);
  const [queryName, setqueryName] = useState("");
  const [savedQueryNames,setsavedQueryNames]= useState([]);
  const [isValidQueryName, setIsValidQueryName] = useState(true);
  const [isLoading, setIsLoading]= useState(false);

  const handleQueryChange = (evt) => {
    setIsLoading(true);
    setselectedQuery(evt.target.value);
    updateMapOnQueryChange(evt.target.value);
  };
 
   useEffect(() => {
    if (accessToken)
    {
    const getQueryData = async () => {
      const queries = await getQueries({ token:accessToken, userId: userEmail });
      queries.sort(GetSortOrder("queryName"));
      queries.splice(0, 0, {
        'queryName':'<Select Query>',
        'queryId':-1
      });      
      setsavedQueryNames (queries);
      setselectedQuery(-1);
      return queries;  
    }
    getQueryData();    
  }
  else
  {
    setsavedQueryNames ([{
      'queryName':'<Select Query>',
      'queryId':-1
    }]); 
    setselectedQuery(-1);
  }    
  }, [accessToken,userEmail]);  

  useEffect(() => {
    if (userEmail === null) setisdisableMainSave(true);
    else setisdisableMainSave(false);
  }, [userEmail]);

  const filterArray = config.staticInformation.filterArray;

  const updateMapOnQueryChange = async(query_id) => {
    let queryData;
    if (query_id !== -1)
    {
     queryData = await getQueryById({
      token: accessToken, queryId:query_id
    });
  }
  else 
  {
     const defaultData = JSON.parse(localStorage.getItem('DefaultValues'));
     queryData = { 'querydata': defaultData};
  }

    for (let x in queryData.querydata) {
      if (x[0] === x[0].toUpperCase()) {
        try {
          document.getElementsByName(x)[0].checked = queryData.querydata[x];
          let currElement = document.getElementsByName(x)[0];
          let parntElem =
            currElement.parentElement.parentElement.parentElement;
          let previousElement = parntElem.previousSibling;
          let childrenList = previousElement.childNodes[1];

          let chkdArray = [];
          for (let j = 0; j < parntElem.children.length; j++) {
            let chekedElem =
              parntElem.children[j].childNodes[0].childNodes[1];
            if (chekedElem.checked === true) {
              chkdArray.push(chekedElem.checked);
            }
          }
          if (chkdArray.length === parntElem.children.length) {
            childrenList.checked = true;
          } else {
            if (childrenList !== undefined) {
              childrenList.checked = false;
            }
          }

          checkUncheckLayers(queryData,x);
          
        } catch (e) {}
      } else if (filterArray.includes(x)) {
        FilterDetails.updatelayerVisibleStore(x, queryData.querydata[x]);
      }

      
    }

    FilterDetails.updatelayerVisibleStore("applyEnable", !(filtervalues.applyEnable));
        FilterDetails.updatelayerVisibleStore("applyResetEnable", true);
        FilterDetails.updateccsFilterStore(
          queryData.querydata.pooltypeValue,
          queryData.querydata.depthGreaterthanValue,
          queryData.querydata.depthLessthanValue,
          queryData.querydata.welldensityGreaterValue,
          queryData.querydata.welldensityLessthanValue,
          queryData.querydata.poolareaAcresGreaterValue,
          queryData.querydata.poolareaAcresLesserValue,
          queryData.querydata.potentialstorageGreaterValue,
          queryData.querydata.potentialstorageLesserValue,
          queryData.querydata.temperatureGreaterthanValue,
          queryData.querydata.temperatureLessthanValue,
          queryData.querydata.porosityGreaterthanValue,
          queryData.querydata.porosityLessthanValue,
          queryData.querydata.permeabilityGreaterthanValue,
          queryData.querydata.permeabilityLessthanValue,
          queryData.querydata.pressureGreaterthanValue,
          queryData.querydata.pressureLessthanValue,
          queryData.querydata.oilGravityGreaterthanValue,
          queryData.querydata.oilGravityLessthanValue,
          true
        );    
        setIsLoading(false);
  };

  const checkUncheckLayers = (queryData,x) => {
    map.getLayers().forEach((layer) => {
      if (layer.get("title") === x) {
        let getLegend = document.querySelector("#" + x);
        getLegend.style.display = queryData.querydata[x] ?  "block" : "none";
        FilterDetails.updatelayerVisibleStore(x, queryData.querydata[x]);
        if (FilterDetails.ccsFilterStore[x] === !queryData.querydata[x]) {
          layer.setVisible(queryData.querydata[x]);
        }
      }
    });
  }

  const handleSaveQuery = async () => {
    setSaveMenuOpen(false);
    updateQuery({
      token: accessToken,
      payload: {
      userId: userEmail,
      queryName: selectedQuery,
      querydata: filtervalues,
      },
      queryId: selectedQuery
    });     
  };

  useEffect(() => {
    if (selectedQuery === -1) setisDisabledSave(true);
    else setisDisabledSave(false);
  }, [selectedQuery]);

  const handleSaveAsQuery = async () => {
    setSaveMenuOpen(false);
    setQueryNameModal(true);
  };

  const handleSaveQueryWithName = async () => {

    saveQuery({
      token: accessToken,
      payload: {
      userId: userEmail,
      queryName: queryName,
      querydata: filtervalues,
      },
    }).then(async (response) => {  
      if (response)
      {   
      const queries = await getQueries({ token:accessToken, userId: userEmail });
      queries.sort(GetSortOrder("queryName"));
      queries.splice(0, 0, {
        'queryName':'<Select Query>',
        'queryId':-1
      });        
      setsavedQueryNames (queries);  
      setselectedQuery(response.queryId);   
    } 
    });  
    setQueryNameModal(false);
    setqueryName("");
  
  };

  const setDefaultFilter = () => {
    FilterDetails.returnToDefault();
  }

  return (
    <div>
        <Button
            className="rounded"
            style={{
              height: "40px",
              backgroundColor: "#00aaab",
              border: "none",
              fontSize: "inherit",
              margin: "10px auto",
              color: "white",
              fontFamily: "DINRegular",
              float: "none",
              display: "block"
            }}
            disabled={isdisableMainSave}
            onClick={() => setDefaultFilter()}
          >
            Return to Default
          </Button>
      {/* <div
        className="d-flex flex-row justify-content-between"
        style={{ padding: 18, fontFamily: "DINRegular", width: "100%" }}
      >
        <Select
          fullWidth
          variant="outlined"
          value={selectedQuery}
          onChange={handleQueryChange}
          style={{
            width: "212px",
            fontSize: 15,
            marginTop: "10px",
            height: "39px",
            fontFamily: "DINRegular",
          }}
        >
         {savedQueryNames.map((query) => (
            <MenuItem
              key={query.queryId}
              value={query.queryId}
              style={{ fontSize: 15, fontFamily: "DINRegular" }}
            >
             <p> {query.queryName} {isLoading && <Loading/>}  </p>
            </MenuItem>
          ))}  
        </Select>
        <div
          className="save-wrapper"
          style={{
            position: "relative",
          }}
        >
          <Button
            className="rounded"
            style={{
              height: "40px",
              backgroundColor: "#00aaab",
              border: "none",
              fontSize: "inherit",
              marginTop: "10px",
              marginRight: "23px",
              color: "white",
              fontFamily: "DINRegular",
            }}
            disabled={isdisableMainSave}
            onClick={() => setSaveMenuOpen(true)}
          >
            Save
          </Button>
          <Popper
            open={saveMenuOpen}
            transition
            disablePortal
            className="save-dropdown"
            style={{
              position: "absolute",
              top: 37,
              zIndex: 2,
            }}
          >
            {({ TransitionProps, _placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setSaveMenuOpen(false)}>
                    <MenuList autoFocusItem={saveMenuOpen} id="menu-list-grow">
                      <MenuItem
                        style={{
                          fontSize: 16,
                          fontFamily: "DINRegular",
                        }}
                        disabled={isDisabledSave}
                        onClick={handleSaveQuery}
                      >
                        Save
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontSize: 16,
                          fontFamily: "DINRegular",
                        }}
                        onClick={handleSaveAsQuery}
                      >
                        Save as
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div> */}

      {/* <Dialog
        open={QueryNameModal}
        onClose={() => setQueryNameModal(false)}
        className="Query-drawer"
      >
        <DialogContent>
          <div className="form-container">
            <p className="title">Save Query</p>
            <p className="description">Give your query a name.</p>

            <TextField
              id="outlined-basic"
              label="Query Name"
              variant="outlined"
              value={queryName}
              onChange={(e) => {
                setIsValidQueryName(true);
                setqueryName(e.target.value);
              }}
              onBlur={() => {
                if (queryName.length > 0) setIsValidQueryName(true);
                else setIsValidQueryName(false);
              }}
              error={!isValidQueryName}
              inputProps={{ maxLength: 60 }}
              helperText={
                isValidQueryName
                  ? `${queryName.length}/60 Characters`
                  : "this is a required field"
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setQueryNameModal(false);
              setqueryName("");
            }}
            className="save-btn"
          >
            Cancel
          </Button>
          <Button
            disabled={!(queryName.length > 0 && isValidQueryName)}
            onClick={() =>
              queryName.length > 0 &&
              isValidQueryName &&
              handleSaveQueryWithName()
            }
            className="save-btn"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
       */}
    </div>
  );
};

export default SelectQuery;
