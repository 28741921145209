import React from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "@okta/okta-react";
import config from "../configs/appSettings";

import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

const AppLayout = props => {
  const ensureInGroup = token => {
    const { allowedGroups } = config.oidc;
    let inGroup = false;
    for (let allowedGroup of allowedGroups) {
      inGroup = token.payload.groups.includes(allowedGroup);
      if (inGroup) {
        break;
      }
    }
    return inGroup;
  };

  // eslint-disable-next-line no-unused-vars
  const ensureLoggedIn = async () => {
    const authenticated = props.auth.isAuthenticated();
    if (authenticated) {
      const accessToken = await props.auth.getAccessToken();
      const accessTokenDecoded = props.auth._oktaAuth.token.decode(accessToken);

      const inGroup = ensureInGroup(accessTokenDecoded);
      if (!inGroup) {
        props.history.push("/access-denied");
      }
    }
  };

  // ensureLoggedIn();

  return (
    <>
      <AppHeader />
      <main role="main">{props.children}</main>
      <AppFooter />
    </>
  );
};

export default withRouter(withAuth(AppLayout));
