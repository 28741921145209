import React, { useEffect, useState,useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../configs/appSettings";
import { withAuth } from "@okta/okta-react";
import OpenLayerFuncComp from "../components/OpenLayerFuncComp"
import MapInputPanel from "../components/MapInputPanel";
import { ccsFilterContext } from "../App";
import arrowClose from "../assets/images/icons/arrow-close.svg";
import arrowOpen from "../assets/images/icons/arrow-open.svg";


// eslint-disable-next-line

function MapArea(props) {
  const [csvData, setcsvData] = useState([]);
  const [genaccesToken, setgenaccesToken] = useState(null);
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;
  const [infoData, setInfoData] = useState({
    layerObject: {
      layerWidth: "23%",
      mapBoxWidth: "100%",
      layerFloat: "left",
      layerHeight: "94.5vh",
      layerArrowLeft: "23%",
      layerArrow: arrowOpen,
      layerList: "block",
      drawerEnable: true,
      layerTop: "60px",
    },
  });
  const [isMeter, setIsMeter] = useState(false)
  const [isSummaryOpen, setIsSummaryOpen] = useState(false)


  const getcrosstoken = async () => {
    const oktaaccessToken = await props.auth.getAccessToken();
    setgenaccesToken(oktaaccessToken);
    return oktaaccessToken;
  };
  getcrosstoken();
  const CustomToastIntroMsg = () => (
    <div className="introMsg-container">
      <div className="introMsg-title">
      {config.welPopupText.title}
      </div>    
      <div className="introMsg-text">
      {config.welPopupText.description}
      </div>
      {/* <div className="introMsg-text">
        -View geologic surfaces relevant for carbon storage in the North Sea
      </div> */}
    </div>
  );
  //==================sorting query dropdown==================

 useEffect(() => {
    toast.success(CustomToastIntroMsg, { containerId: "introMsg" });
  }, []);

  useEffect(() => {
    localStorage.setItem('DefaultValues', JSON.stringify(filtervalues));
  }, [])

  return (
    <section className="arlas-cs-map-section">
      {/* Base map with  layer */}
      {/* <Mapgenerator
        userEmail={localStorage.getItem(`${config.users.email}`)}
        AccessTokens={AccessTokens}
      /> */}
      {genaccesToken && <OpenLayerFuncComp userEmail={localStorage.getItem(`${config.users.email}`)}
        setcsvData={setcsvData} oktaaccessToken={genaccesToken} infoData={infoData} setInfoData={setInfoData} setIsSummaryOpen={setIsSummaryOpen} isMeter={isMeter} setIsMeter={setIsMeter}
      />}
      <MapInputPanel csvData={csvData} infoData={infoData} isSummaryOpen={isSummaryOpen} setIsSummaryOpen={setIsSummaryOpen} isMeter={isMeter} oktaaccessToken={genaccesToken}/>
      <ToastContainer
        enableMultiContainer
        containerId={"introMsg"}
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default withAuth(MapArea);
