import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import shp from "shpjs";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from "ol/format";
import { Vector as VectorLayer } from "ol/layer";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import FileImportedDialog from "./FileImportedDialog";
import { getCenter } from 'ol/extent';

const ImportShapefile = ({ map, setImportedFiles }) => {
  const [importModal, setImportModal] = useState(false);
  const [alertText, setalertText] = useState("File can't be imported");

  let mapCenter = [];
  const handleCloseImport = () => {
    setImportModal(false);
  };

  const image = new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: "red",
    }),
    stroke: new Stroke({ color: "red", width: 1 }),
  });

  const styles = {
    Point: new Style({
      image: image,
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: "green",
        width: 1,
      }),
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: "green",
        width: 1,
      }),
    }),
    MultiPoint: new Style({
      image: image,
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: "yellow",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 0)",
      }),
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(0, 0, 255)",
      }),
    }),
    GeometryCollection: new Style({
      stroke: new Stroke({
        color: "magenta",
        width: 2,
      }),
      fill: new Fill({
        color: "magenta",
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: "magenta",
        }),
      }),
    }),
    Circle: new Style({
      stroke: new Stroke({
        color: "red",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(255,0,0,0.2)",
      }),
    }),
  };

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  useEffect(() => {
    let importedShapefileInLocalStorage = JSON.parse(
      localStorage.getItem("ImportedShapefile")
    );
    if (importedShapefileInLocalStorage) {
      const addedLayers = [];
      importedShapefileInLocalStorage.map((file) => {
        const vectorLyr = createVectorLayer(file.geojson, file.fileName);
        map.addLayer(vectorLyr);
        addedLayers.push(vectorLyr);
      });
      setImportedFiles(addedLayers);
    }
  }, []);

  const createVectorLayer = (geojson, fileName) => {
    const features = new GeoJSON().readFeatures(geojson, {
      featureProjection: map.getView().getProjection(),
    });
    const vectorSource = new VectorSource({
      features: features,
    });
    if (!features[0]) return;
    const aa = features[0].getGeometry().getExtent();
    mapCenter = getCenter(aa);
    const featureType = features[0].getGeometry().getType();
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction,
      title: fileName,
      featureType: featureType,
      visible: false
    });
    vectorLayer.setOpacity(0.8);
    return vectorLayer;
  };

  const onFileChange = (evt) => {
    const files = evt.target.files;
    let fileExtension = files[0].name.split(".")[1];
    let fileName = files[0].name.split(".")[0];
    let importedShapefileInLocalStorage = JSON.parse(
      localStorage.getItem("ImportedShapefile")
    );
    let shapefilePresent = false;
    if (importedShapefileInLocalStorage) {
      importedShapefileInLocalStorage.map((shapefile) => {
        if (shapefile.fileName === fileName)
          shapefilePresent = true;
      })
    }

    if (fileExtension === "zip" && shapefilePresent === false) {
      (async () => {
        const buffer = await files[0].arrayBuffer();
        const geojson = await shp(buffer);
        try {
          setalertText("File imported successfully");
          setImportModal(true);
          if (importedShapefileInLocalStorage)
            importedShapefileInLocalStorage.push({
              geojson: geojson,
              fileName: fileName,
            });
          else
            importedShapefileInLocalStorage = [
              { geojson: geojson, fileName: fileName },
            ];
          localStorage.setItem(
            "ImportedShapefile",
            JSON.stringify(importedShapefileInLocalStorage)
          );
          const vectorLyr = createVectorLayer(geojson, fileName);
          vectorLyr.setVisible(true)
          map.addLayer(vectorLyr);
          map.getView().setCenter(mapCenter);
          const addedLayers = [];
          addedLayers.push(vectorLyr);
          setImportedFiles(addedLayers);
          document.getElementById("importedshapefile").style.display = "block"
          setTimeout(() => {
            let reqID = document.getElementById("importedshapefile").lastChild.lastChild.firstChild.innerText + 2
            console.log(document.getElementById(reqID).checked)
            document.getElementById(reqID).checked = true

          }, 0);
        } catch (err) {
          console.log(err);
          if (err == "TypeError: Cannot read properties of null (reading 'style')") {
            setTimeout(() => {
              document.getElementById("importedshapefile").style.display = "block"
              let reqID = document.getElementById("importedshapefile").lastChild.lastChild.firstChild.innerText + 2
              document.getElementById(reqID).checked = true

            }, 0);
            setalertText("File imported successfully");
          }
          else {
            setalertText("File can't be imported");
            setImportModal(true);
          }
        }
      })();
    } else {
      setalertText("File can't be imported");
      setImportModal(true);
    }
  };
  return (
    <div style={{margin: "0 auto", width: "175px"}}>

      <Button
        className='arlas-cs-btn primary'
        variant="contained"
        component="label"
        style={{
          fontFamily: "DINRegular",
          width: "100%",
        }}
      >
        <p style={{ fontSize: 15,}}>
          Import Shapefile
        </p>
        <input
          type="file"
          hidden
          onInput={onFileChange}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </Button>

      <FileImportedDialog
        open={importModal}
        handleClose={handleCloseImport}
        alertReceived={alertText}
      />
    </div>
  );
};

export default ImportShapefile;
