import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Slider from "@material-ui/core/Slider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { withAuth } from "@okta/okta-react";
import axios from "axios";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import Feature from "ol/Feature";
import { GeoJSON, WFS } from "ol/format";
import {
  and as andFilter,
  between,
  equalTo as equalToFilter,
  greaterThan,
  greaterThanOrEqualTo,
  lessThanOrEqualTo,
  like,
  notEqualTo,
  or as orFilter
} from "ol/format/filter";
import { LineString, MultiPolygon, Point, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Map from "ol/Map";
import { unByKey } from "ol/Observable";
import "ol/ol.css";
import Overlay from "ol/Overlay";
import { transform } from "ol/proj";
import TileWMS from "ol/source/TileWMS";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { getArea, getLength } from "ol/sphere";
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  RegularShape,
  Stroke,
  Style
} from "ol/style";
import View from "ol/View";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ccsFilterContext } from "../App";
import marker from "../assets/data/marker.png";
import arrowClose from "../assets/images/icons/arrow-close.svg";
import arrowOpen from "../assets/images/icons/arrow-open.svg";
import config from "../configs/appSettings";
import BaseMap from "./BaseMap";
import EIALayers from "./EIALayers";
import ExportLayer from "./ExportLayer";
import ForestLayers from "./ForestLayers";
import ImportedShapefileTemplate from "./ImportedShapefileTemplate";
import ImportShapefile from "./ImportShapefile";
import PropertyOwnership from "./PropertyOwnership";
import RootLayers from "./RootLayers";
import SelectQuery from "./SelectQuery";
import SurfaceAcreage from "./SurfaceAcreage";
import UsageDialog from "./UsageDialog";
import { getSiblings } from "./UtilityFunc";
import { checkUsage, summaryCaardPopupTempalte } from "./utils/calFunc";

function OpenLayerFuncComp(props) {
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;
  let defaultStore = FilterDetails.defaultStore;
  const [drawerEnable, setdrawerEnable] = useState(true);
  const [tabValue, settabValue] = useState(0);
  const [layerTabData, setlayerTabData] = useState(true);
  const [layerBlock, setlayerBlock] = useState("block");
  const [legendTabData, setlegendTabData] = useState(false);
  const [legendBlock, setlegendBlock] = useState("none");
  const [zoomState, setzoomState] = useState(false);
  const [zoomItemText, setzoomItemText] = useState(6);
  const [usageModal, setUsageModal] = useState(false);
  const [alertText, setalertText] = useState("Usage Stat");
  const [roleAccess, setRoleAccess] = useState({});
  const [genaccesToken, setgenaccesToken] = useState(null);
  const [map, setMap] = useState(null);
  const [legendSelectedColor, setlegendSelectedColor] = useState(null);
  const [newcolorlayerTitle, setnewcolorlayerTitle] = useState(null);
  const [dataFiltered, setdataFiltered] = useState([]);
  const [exportData, setexportData] = useState(false);
  const [importedFiles, setImportedFiles] = useState([]);
  const [importedShapeFiles, setimportedShapeFiles] = useState([]);
  const [fileImported, setfileImported] = useState(false);
  const [datasetNames, setDatasetNames] = useState([]);
  const defaultZoomref = useRef();
  const customZoomOut = useRef();
  const customZoomIn = useRef();
  const mapElement = useRef();
  let summaryIndexLayer = "";

  const mapRef = useRef();
  mapRef.current = map;

  var csvObj = [];
  var count = 0;
  const WFSPointLayers = ["PelletPlants"];

  useEffect(() => {
    let datasetnm = [];
    config.Pipelines.map((dataset) => {
      dataset[dataset.groupName].source.map((file) => {
        if (file.lyrType !== "geoserver_wms") {
          datasetnm.push({
            layerName: file.lyrName,
            displayName: file.displayName,
            stylClass: file.stylClass,
          });
        }
      });
    });
    setDatasetNames(datasetnm.flat());
  }, []);

  useEffect(() => {
    let queryData;
    const defaultData = JSON.parse(localStorage.getItem("DefaultValues"));
    queryData = { querydata: defaultData };
    for (let x in queryData.querydata) {
      if (x === x) {
        try {
          document.getElementsByName(x)[0].checked = queryData.querydata[x];
          let currElement = document.getElementsByName(x)[0];
          let parntElem = currElement.parentElement.parentElement.parentElement;
          let previousElement = parntElem.previousSibling;
          let childrenList = previousElement.childNodes[1];
          let chkdArray = [];
          for (let j = 0; j < parntElem.children.length; j++) {
            let chekedElem = parntElem.children[j].childNodes[0].childNodes[1];
            if (chekedElem.checked === true) {
              chkdArray.push(chekedElem.checked);
            }
          }
          if (chkdArray.length === parntElem.children.length) {
            childrenList.checked = true;
          } else {
            if (childrenList !== undefined) {
              childrenList.checked = false;
            }
          }
          checkUncheckLayers(queryData, x);
        } catch (e) {}
      } else {
        FilterDetails.updatelayerVisibleStore(x, queryData.querydata[x]);
      }
      map &&
        map
          .getLayers()
          .getArray()
          .filter(
            (layer) =>
              layer.get("name") === "saline_highlightfeature" ||
              layer.get("name") === "pool_highlightfeature"
          )
          .forEach((layer) => map.removeLayer(layer));
    }
  }, [defaultStore]);

  const checkUncheckLayers = (queryData, x) => {
    map.getLayers() &&
      map.getLayers().forEach((layer) => {
        if (layer.get("title") === x) {
          let getLegend = document.querySelector("#" + x);
          getLegend.style.display = queryData.querydata[x] ? "block" : "none";
          FilterDetails.updatelayerVisibleStore(x, queryData.querydata[x]);
          if (FilterDetails.ccsFilterStore[x] === queryData.querydata[x]) {
            layer.setVisible(queryData.querydata[x]);
            console.log(FilterDetails.ccsFilterStore[x], "ccsFilterStore[x");
          }
          // legend
          legendSliderChange("key", 20, x);
        } else if (layer.get("title") === "PropertyOwnership") {
          let getLegend = document.querySelector("#" + x);
          getLegend.style.display = queryData.querydata[x] ? "block" : "none";
          FilterDetails.updatelayerVisibleStore(x, queryData.querydata[x]);
          if (FilterDetails.ccsFilterStore[x] === queryData.querydata[x]) {
            layer.setVisible(queryData.querydata[x]);
            console.log(FilterDetails.ccsFilterStore[x], "ccsFilterStore[x");
          }
        } else if (layer.get("title") === "epa_emitters") {
          legendSliderChange("key", 20, "epa_emitters");
        }
      });
  };
  const handleCloseUsage = () => {
    setUsageModal(false);
  };

  useEffect(() => {
    if (importedFiles.length !== 0) {
      let filesImportedList = importedShapeFiles;
      importedFiles.map((file) => {
        const layerName = file.values_.title;
        const legendType = file.values_.featureType;

        filesImportedList.push({ filename: layerName, legendType: legendType });
      });
      const updatedFilesArray = [filesImportedList];
      setimportedShapeFiles(updatedFilesArray.flat());
      setfileImported(true);

      filesImportedList &&
        filesImportedList.map((importFile) => {
          const name = importFile.filename;
          map.getLayers().forEach((layer) => {
            if (layer.get("title") === name) {
              if (
                JSON.parse(localStorage.getItem("DefaultValues"))[name] ===
                undefined
              ) {
                let existing = localStorage.getItem("DefaultValues");
                existing = existing ? JSON.parse(existing) : {};
                existing[name] = true;
                localStorage.setItem("DefaultValues", JSON.stringify(existing));
              }
              FilterDetails.updatelayerVisibleStore(
                name,
                JSON.parse(localStorage.getItem("DefaultValues"))[name]
              );
              layer.setVisible(
                JSON.parse(localStorage.getItem("DefaultValues"))[name]
              );
            }
          });
        });
    }
  }, [importedFiles]);

  useEffect(() => {
    if (importedFiles.length !== 0) {
      let filesImportedList = importedShapeFiles;
      importedFiles.map((file) => {
        const layerName = file.values_.title;
        const legendType = file.values_.featureType;

        filesImportedList.push({ filename: layerName, legendType: legendType });
      });

      filesImportedList &&
        filesImportedList.map((importFile) => {
          const name = importFile.filename;
          map.getLayers().forEach((layer) => {
            if (layer && layer.get("title") === name) {
              FilterDetails.updatelayerVisibleStore(name, false);
              layer.setVisible(false);
              removeImportedLayer(name);
            }
          });
        });
    }
  }, [defaultStore]);

  const removeImportedLayer = (file) => {
    map.getLayers().forEach((layer) => {
      if (layer !== undefined) {
        if (layer.get("title") === file) {
          map.removeLayer(layer);
          let getLegend = document.querySelector("#" + file);
          getLegend.remove();
          let importedShapefileInLocalStorage = JSON.parse(
            localStorage.getItem("ImportedShapefile")
          );
          if (importedShapefileInLocalStorage) {
            importedShapefileInLocalStorage.splice(
              importedShapefileInLocalStorage.findIndex(
                (a) => a.filename !== file
              ),
              1
            );
          }
          localStorage.setItem(
            "ImportedShapefile",
            JSON.stringify(importedShapefileInLocalStorage)
          );
          if (importedShapefileInLocalStorage.length === 0)
            setfileImported(false);

          let existing = localStorage.getItem("DefaultValues");
          existing = existing ? JSON.parse(existing) : {};
          existing[file] = true;
          localStorage.setItem("DefaultValues", JSON.stringify(existing));
        }
      }
    });
  };
  useEffect(() => {
    if (map !== null) {
      map.getView().on("change:resolution", function (_evt) {
        var mapZoom = parseInt(map.getView().getZoom());
        setzoomItemText(mapZoom);
        setzoomState(false);
      });
    }
  }, [map]);

  // ==========Legend Color Chnage Function Start ==================
  let legendColorValue;
  const legendColorChange = (e) => {
    legendColorValue = e.currentTarget;
    let colorvalparent = legendColorValue.parentElement.parentElement;
    setlegendSelectedColor(legendColorValue.value);
    setnewcolorlayerTitle(colorvalparent.id);
    // legend
    let existing = localStorage.getItem("DefaultValues");
    existing = existing ? JSON.parse(existing) : {};
    existing[colorvalparent.id + "_BG"] = legendColorValue.value;
    localStorage.setItem("DefaultValues", JSON.stringify(existing));
  };
  // ==========Legend Color Chnage Function End ==================

  useEffect(() => {
    //setuserEmail(props.userEmail);
    setgenaccesToken(props.oktaaccessToken);
  }, []);
  // ==========getcrosstoken Function End ==================

  //initail base map
  useEffect(() => {
    let unitsSelect = document.getElementById("units");
    let typeSelect = document.getElementById("type");
    let scaleType = "";
    let scaleBarSteps = 2;
    let scaleBarText = true;
    let control;

    function scaleControl() {
      if (scaleType === "scaleline") {
        control = new ScaleLine({
          units: "imperial",
        });
        return control;
      }
      control = new ScaleLine({
        units: "imperial",
        bar: true,
        steps: scaleBarSteps,
        text: scaleBarText,
        minWidth: 140,
      });
      return control;
    }
    function onChange(_e) {
      control.setUnits(unitsSelect.value);
      let reqObjDist = document.getElementsByClassName(
        "ol-tooltip ol-tooltip-static"
      );
      let MeasurementArray = [];
      let allFeatures = [];

      for (let feature in source.getFeatures()) {
        allFeatures.push(
          source.getFeatures()[feature].values_.geometry.getType()
        );
      }

      if (unitsSelect.value == "imperial") {
        props.setIsMeter(false);
        for (let i = 0; i < allFeatures.length; i++) {
          if (allFeatures[i] == "LineString") {
            MeasurementArray.push(
              parseFloat([reqObjDist[i].innerHTML.slice(0, -3)])
            );
            MeasurementArray[i] = MeasurementArray[i] / 1.609344;
            reqObjDist[i].innerHTML = MeasurementArray[i].toFixed(2) + " mi";
          } else if (allFeatures[i] == "Polygon") {
            MeasurementArray.push(
              parseFloat([reqObjDist[i].innerHTML.slice(0, -15)])
            );
            MeasurementArray[i] = MeasurementArray[i] / 2.58998811;
            reqObjDist[i].innerHTML =
              MeasurementArray[i].toFixed(2) + " mi<sup>2</sup>";
          }
        }
      } else if (unitsSelect.value == "metric") {
        props.setIsMeter(true);
        for (let i = 0; i < allFeatures.length; i++) {
          if (allFeatures[i] == "LineString") {
            MeasurementArray.push(
              parseFloat([reqObjDist[i].innerHTML.slice(0, -3)])
            );
            MeasurementArray[i] = MeasurementArray[i] * 1.609344;
            reqObjDist[i].innerHTML = MeasurementArray[i].toFixed(2) + " km";
          } else if (allFeatures[i] == "Polygon") {
            MeasurementArray.push(
              parseFloat([reqObjDist[i].innerHTML.slice(0, -15)])
            );
            MeasurementArray[i] = MeasurementArray[i] * 2.59;
            reqObjDist[i].innerHTML =
              MeasurementArray[i].toFixed(2) + " km<sup>2</sup>";
          }
        }
      }
    }
    unitsSelect.addEventListener("change", onChange);
    const baseMap = new TileLayer({
      source: new XYZ({
        url: config.endpoints.OSMBaseMap,
      }),
      visible: true,
      title: "Streets",
    });
    //======================Draw Line============================

    const source = new VectorSource();

    const vector = new VectorLayer({
      source: source,
      title: "vector",
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#000000",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({
            color: "#000000",
          }),
        }),
      }),
    });
    let sketch;
    let measureTooltipElement;
    let measureTooltip;

    const pointerMoveHandler = function (evt) {
      if (evt.dragging) {
        return;
      }
    };
    const initialMap = new Map({
      target: mapElement.current,
      controls: defaultControls().extend([scaleControl()]),
      layers: [baseMap],
      view: new View({
        projection: "EPSG:3857",
        center: [-10396155.0388, 3469234.1119],
        zoom: 6,
        constrainResolution: true,
      }),
    });
    initialMap.on("pointermove", pointerMoveHandler);

    // initialMap.getViewport().addEventListener("mouseout", function () { });

    let draw;
    const formatLength = function (line) {
      const length = getLength(line);
      let output, outputVal;
      if (unitsSelect.value == "metric") {
        output = Math.round((length / 1000) * 100) / 100;
        outputVal = output.toString().concat(" km");
      } else {
        output = Math.round((length / 1609.344) * 100) / 100;
        outputVal = output.toString().concat(" mi");
      }
      return outputVal;
    };
    const formatArea = function (polygon) {
      const area = getArea(polygon);
      let output, outputVal;
      if (unitsSelect.value == "metric") {
        output = Math.round((area / 1000000) * 100) / 100;
        outputVal = output.toString().concat(` km<sup>2</sup>`);
      } else {
        output = Math.round((area / 2.59e6) * 100) / 100;
        outputVal = output.toString().concat(" mi<sup>2</sup>");
      }
      return outputVal;
    };
    function addInteraction() {
      initialMap.removeInteraction(draw);
      let type;
      if (typeSelect.value !== "None") {
        type = typeSelect.value == "area" ? "Polygon" : "LineString";
        draw = new Draw({
          source: source,
          type: type,
        });
        initialMap.addInteraction(draw);
        initialMap.addLayer(vector);
        createMeasureTooltip();

        let listener;
        draw.on("drawstart", function (evt) {
          // set sketch
          sketch = evt.feature;
          let tooltipCoord = evt.coordinate;

          listener = sketch.getGeometry().on("change", function (e) {
            const geom = e.target;
            let output;
            if (geom instanceof Polygon) {
              output = formatArea(geom);
              tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
              output = formatLength(geom);
              tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
          });
        });

        draw.on("drawend", function () {
          measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
          measureTooltip.setOffset([0, -7]);
          // unset sketch
          sketch = null;
          measureTooltipElement = null;
          createMeasureTooltip();
          unByKey(listener);
        });
      } else {
        initialMap.removeInteraction(draw);
        source.clear();
        initialMap.removeLayer(vector);
        initialMap.getOverlays().clear();
      }
    }
    function createMeasureTooltip() {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.setAttribute(
        "className",
        `ol-tooltip ol-tooltip-measure-${Math.floor(
          Math.random() * 1000
        )}-${Math.floor(Math.random() * 1000)}`
      );
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });
      initialMap.addOverlay(measureTooltip);
    }
    typeSelect.onchange = function () {
      initialMap.removeInteraction(draw);
      initialMap.removeLayer(vector);
      addInteraction();
    };

    addInteraction();
    FilterDetails.setMapObject(initialMap);
    setMap(initialMap);
  }, [props.isSummaryOpen]);

  // ======================= filter Details Object start ==================
  let filterDetails = {
    PropertyOwnership: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PropertyOwnership",
      layerVisible: filtervalues.PropertyOwnership,
      // layerOpacity: 0.8,
      // layerColor: "rgba(255, 140, 0, 0.75)",
      layerColor: `${filtervalues.PropertyOwnership_BG}50`,
      layerOpacity:
        (100 -
          JSON.parse(localStorage.getItem("DefaultValues"))[
            "PropertyOwnership_opacity_value"
          ]) /
        100,
    },

    PelletPlants: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PelletPlants",
      layerVisible: filtervalues.PelletPlants,
      // layerColor: "#00aaab",
      layerColor: filtervalues.PelletPlants_BG
        ? filtervalues.PelletPlants_BG
        : "#00aaab",
      layerOpacity:
        filtervalues.PelletPlants_opacity_value != undefined
          ? (100 - filtervalues.PelletPlants_opacity_value) / 100
          : 0.8,
      layerName: "",
      // layerOpacity: 0.8,
      layerZindex: 0,
    },
    TertiaryMiocene: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "TertiaryMiocene",
      layerVisible: filtervalues.TertiaryMiocene,
      // // layerColor: "rgba(0, 0, 255)",
      // layerOpacity: 0.8,
      layerColor: filtervalues.TertiaryMiocene_BG
        ? filtervalues.TertiaryMiocene_BG
        : "rgba(0, 0, 255)",
      layerOpacity:
        filtervalues.TertiaryMiocene_opacity_value != undefined
          ? (100 - filtervalues.TertiaryMiocene_opacity_value) / 100
          : 0.8,
      layerZindex: 35,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Miocene"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        )
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Miocene"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    OligoceneFRIO: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "OligoceneFRIO",
      layerVisible: filtervalues.OligoceneFRIO,
      // layerColor: "rgba(255, 0, 255)",
      layerColor: filtervalues.OligoceneFRIO_BG
        ? filtervalues.OligoceneFRIO_BG
        : "rgba(255, 0, 255)",
      layerOpacity:
        filtervalues.OligoceneFRIO_opacity_value != undefined
          ? (100 - filtervalues.OligoceneFRIO_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 34,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        equalToFilter(" cdl_prodfm", "FRIO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        equalToFilter(" cdl_prodfm", "FRIO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    OligoceneVICKSBURG: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "OligoceneVICKSBURG",
      layerVisible: filtervalues.OligoceneVICKSBURG,
      // layerColor: "rgba(255, 85, 0)",
      layerColor: filtervalues.OligoceneVICKSBURG_BG
        ? filtervalues.OligoceneVICKSBURG_BG
        : "rgba(255, 85, 0)",
      layerOpacity:
        filtervalues.OligoceneVICKSBURG_opacity_value != undefined
          ? (100 - filtervalues.OligoceneVICKSBURG_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 33,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        equalToFilter(" cdl_prodfm", "VICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        equalToFilter(" cdl_prodfm", "VICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    OligoceneOther: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "OligoceneOther",
      layerVisible: filtervalues.OligoceneOther,
      // layerColor: "rgba(230, 76, 0)",
      layerColor: filtervalues.OligoceneOther_BG
        ? filtervalues.OligoceneOther_BG
        : "rgba(230, 76, 0)",
      layerOpacity:
        filtervalues.OligoceneOther_opacity_value != undefined
          ? (100 - filtervalues.OligoceneOther_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 32,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        notEqualTo("cdl_prodfm", "VICKSBURG"),
        notEqualTo("cdl_prodfm", "FRIO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Oligocene"),
        notEqualTo("cdl_prodfm", "VICKSBURG"),
        notEqualTo("cdl_prodfm", "FRIO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneCLAIBORNE: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneCLAIBORNE",
      layerVisible: filtervalues.EoceneCLAIBORNE,
      // layerColor: "rgba(230, 152, 0)",
      layerColor: filtervalues.EoceneCLAIBORNE_BG
        ? filtervalues.EoceneCLAIBORNE_BG
        : "rgba(230, 152, 0)",
      layerOpacity:
        filtervalues.EoceneCLAIBORNE_opacity_value != undefined
          ? (100 - filtervalues.EoceneCLAIBORNE_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 30,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "CLAIBORNE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "CLAIBORNE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneCOCKFIELDYEGUA: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneCOCKFIELDYEGUA",
      layerVisible: filtervalues.EoceneCOCKFIELDYEGUA,
      // layerColor: "rgba(137, 68, 101)",
      layerColor: filtervalues.EoceneCOCKFIELDYEGUA_BG
        ? filtervalues.EoceneCOCKFIELDYEGUA_BG
        : "rgba(137, 68, 101)",
      layerOpacity:
        filtervalues.EoceneCOCKFIELDYEGUA_opacity_value != undefined
          ? (100 - filtervalues.EoceneCOCKFIELDYEGUA_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 29,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        orFilter(
          equalToFilter(" cdl_prodfm", "COCKFIELD"),
          equalToFilter(" cdl_prodfm", "YEGUA")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        orFilter(
          equalToFilter(" cdl_prodfm", "COCKFIELD"),
          equalToFilter(" cdl_prodfm", "YEGUA")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneQUEENCITY: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneQUEENCITY",
      layerVisible: filtervalues.EoceneQUEENCITY,
      // layerColor: "rgba(137, 68, 101)",
      layerColor: filtervalues.EoceneQUEENCITY_BG
        ? filtervalues.EoceneQUEENCITY_BG
        : "rgba(137, 68, 101)",
      layerOpacity:
        filtervalues.EoceneQUEENCITY_opacity_value != undefined
          ? (100 - filtervalues.EoceneQUEENCITY_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 27,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "QUEEN CITY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "QUEEN CITY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneCOOKMOUNTAIN: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneCOOKMOUNTAIN",
      layerVisible: filtervalues.EoceneCOOKMOUNTAIN,
      // layerColor: "rgba(112, 68, 137)",
      layerColor: filtervalues.EoceneCOOKMOUNTAIN_BG
        ? filtervalues.EoceneCOOKMOUNTAIN_BG
        : "rgba(112, 68, 137)",
      layerOpacity:
        filtervalues.EoceneCOOKMOUNTAIN_opacity_value != undefined
          ? (100 - filtervalues.EoceneCOOKMOUNTAIN_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 28,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "COOK MOUNTAIN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "COOK MOUNTAIN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneJACKSON: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneJACKSON",
      layerVisible: filtervalues.EoceneJACKSON,
      // layerColor: "rgba(39, 79, 137)",
      layerColor: filtervalues.EoceneJACKSON_BG
        ? filtervalues.EoceneJACKSON_BG
        : "rgba(39, 79, 137)",
      layerOpacity:
        filtervalues.EoceneJACKSON_opacity_value != undefined
          ? (100 - filtervalues.EoceneJACKSON_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 31,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "JACKSON"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "JACKSON"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneWILCOX: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneWILCOX",
      layerVisible: filtervalues.EoceneWILCOX,
      // layerColor: "rgba(68, 101, 137)",
      layerColor: filtervalues.EoceneWILCOX_BG
        ? filtervalues.EoceneWILCOX_BG
        : "rgba(68, 101, 137)",
      layerOpacity:
        filtervalues.EoceneWILCOX_opacity_value != undefined
          ? (100 - filtervalues.EoceneWILCOX_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 26,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "WILCOX"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "WILCOX"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneYEGUA: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneYEGUA",
      layerVisible: filtervalues.EoceneYEGUA,
      // layerColor: "rgba(68, 137, 112)",
      layerColor: filtervalues.EoceneYEGUA_BG
        ? filtervalues.EoceneYEGUA_BG
        : "rgba(68, 137, 112)",
      layerOpacity:
        filtervalues.EoceneYEGUA_opacity_value != undefined
          ? (100 - filtervalues.EoceneYEGUA_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 26,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "YEGUA"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        equalToFilter(" cdl_prodfm", "YEGUA"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    EoceneOTHER: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EoceneOTHER",
      layerVisible: filtervalues.EoceneOTHER,
      // layerColor: "rgba(137, 112, 68)",
      layerColor: filtervalues.EoceneOTHER_BG
        ? filtervalues.EoceneOTHER_BG
        : "rgba(137, 112, 68)",
      layerOpacity:
        filtervalues.EoceneOTHER_opacity_value != undefined
          ? (100 - filtervalues.EoceneOTHER_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 25,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        notEqualTo("cdl_prodfm", "CLAIBORNE"),
        notEqualTo("cdl_prodfm", "COCKFIELD"),
        notEqualTo("cdl_prodfm", "COOK MOUNTAIN"),
        notEqualTo("cdl_prodfm", "JACKSON"),
        notEqualTo("cdl_prodfm", "WILCOX"),
        notEqualTo("cdl_prodfm", "YEGUA"),
        notEqualTo("cdl_prodfm", "QUEEN CITY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Eocene"),
        notEqualTo("cdl_prodfm", "CLAIBORNE"),
        notEqualTo("cdl_prodfm", "COCKFIELD"),
        notEqualTo("cdl_prodfm", "COOK MOUNTAIN"),
        notEqualTo("cdl_prodfm", "JACKSON"),
        notEqualTo("cdl_prodfm", "WILCOX"),
        notEqualTo("cdl_prodfm", "YEGUA"),
        notEqualTo("cdl_prodfm", "QUEEN CITY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    TertiaryPaleocene: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "TertiaryPaleocene",
      layerVisible: filtervalues.TertiaryPaleocene,
      // layerColor: "rgba(0, 0, 0)",
      layerColor: filtervalues.TertiaryPaleocene_BG
        ? filtervalues.TertiaryPaleocene_BG
        : "rgba(0, 0, 0)",
      layerOpacity:
        filtervalues.TertiaryPaleocene_opacity_value != undefined
          ? (100 - filtervalues.TertiaryPaleocene_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 24,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Paleocene"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Tertiary Paleocene"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousUppGulfEAGLEFORD: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfEAGLEFORD",
      layerVisible: filtervalues.CretaceousUppGulfEAGLEFORD,
      // layerColor: "rgba(205, 102, 153)",
      layerColor: filtervalues.CretaceousUppGulfEAGLEFORD_BG
        ? filtervalues.CretaceousUppGulfEAGLEFORD_BG
        : "rgba(205, 102, 153)",
      layerOpacity:
        filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 19,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "EAGLE FORD"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "EAGLE FORD"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousUppGulfAUSTIN: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfAUSTIN",
      layerVisible: filtervalues.CretaceousUppGulfAUSTIN,
      // layerColor: "rgba(205, 102, 153)",
      layerColor: filtervalues.CretaceousUppGulfAUSTIN_BG
        ? filtervalues.CretaceousUppGulfAUSTIN_BG
        : "rgba(205, 102, 153)",
      layerOpacity:
        filtervalues.CretaceousUppGulfAUSTIN_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfAUSTIN_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 20,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "AUSTIN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "AUSTIN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousUppGulfNAVARRO: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfNAVARRO",
      layerVisible: filtervalues.CretaceousUppGulfNAVARRO,
      // layerColor: "rgba(170, 102, 205)",
      layerColor: filtervalues.CretaceousUppGulfNAVARRO_BG
        ? filtervalues.CretaceousUppGulfNAVARRO_BG
        : "rgba(170, 102, 205)",
      layerOpacity:
        filtervalues.CretaceousUppGulfNAVARRO_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfNAVARRO_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 23,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "NAVARRO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "NAVARRO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    CretaceousUppGulfOLMOS: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfOLMOS",
      layerVisible: filtervalues.CretaceousUppGulfOLMOS,
      // layerColor: "rgba(102, 119, 205)",
      layerColor: filtervalues.CretaceousUppGulfOLMOS_BG
        ? filtervalues.CretaceousUppGulfOLMOS_BG
        : "rgba(102, 119, 205)",
      layerOpacity:
        filtervalues.CretaceousUppGulfOLMOS_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfOLMOS_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 22,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "OLMOS"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "OLMOS"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousUppGulfSANMIGUEL: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfSANMIGUEL",
      layerVisible: filtervalues.CretaceousUppGulfSANMIGUEL,
      // layerColor: "rgba(0, 77, 168)",
      layerColor: filtervalues.CretaceousUppGulfSANMIGUEL_BG
        ? filtervalues.CretaceousUppGulfSANMIGUEL_BG
        : "rgba(0, 77, 168)",
      layerOpacity:
        filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 21,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "SAN MIGUEL"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        equalToFilter(" cdl_prodfm", "SAN MIGUEL"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    CretaceousUppGulfTUSCALOOSAWOODBINE: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfTUSCALOOSAWOODBINE",
      layerVisible: filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE,
      // layerColor: "rgba(0, 197, 255)",
      layerColor: filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_BG
        ? filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_BG
        : "rgba(0, 197, 255)",
      layerOpacity:
        filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value !=
        undefined
          ? (100 -
              filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 18,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        orFilter(
          equalToFilter(" cdl_prodfm", "TUSCALOOSA"),
          equalToFilter(" cdl_prodfm", "WOODBINE")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        orFilter(
          equalToFilter(" cdl_prodfm", "TUSCALOOSA"),
          equalToFilter(" cdl_prodfm", "WOODBINE")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    CretaceousUppGulfOTHER: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousUppGulfOTHER",
      layerVisible: filtervalues.CretaceousUppGulfOTHER,
      // layerColor: "rgba(52, 52, 52)",
      layerColor: filtervalues.CretaceousUppGulfOTHER_BG
        ? filtervalues.CretaceousUppGulfOTHER_BG
        : "rgba(52, 52, 52)",
      layerOpacity:
        filtervalues.CretaceousUppGulfOTHER_opacity_value != undefined
          ? (100 - filtervalues.CretaceousUppGulfOTHER_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 17,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        notEqualTo("cdl_prodfm", "AUSTIN"),
        notEqualTo("cdl_prodfm", "NAVARRO"),
        notEqualTo("cdl_prodfm", "OLMOS"),
        notEqualTo("cdl_prodfm", "SAN MIGUEL"),
        notEqualTo("cdl_prodfm", "TUSCALOOSA"),
        notEqualTo("cdl_prodfm", "WOODBINE"),
        notEqualTo("cdl_prodfm", "EAGLE FORD"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Upp Gulf"),
        notEqualTo("cdl_prodfm", "AUSTIN"),
        notEqualTo("cdl_prodfm", "NAVARRO"),
        notEqualTo("cdl_prodfm", "OLMOS"),
        notEqualTo("cdl_prodfm", "SAN MIGUEL"),
        notEqualTo("cdl_prodfm", "TUSCALOOSA"),
        notEqualTo("cdl_prodfm", "WOODBINE"),
        notEqualTo("cdl_prodfm", "EAGLE FORD"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    CretaceousLowCoahuilaSLIGO: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowCoahuilaSLIGO",
      layerVisible: filtervalues.CretaceousLowCoahuilaSLIGO,
      // layerColor: "rgba(255, 0, 0)",
      layerColor: filtervalues.CretaceousLowCoahuilaSLIGO_BG
        ? filtervalues.CretaceousLowCoahuilaSLIGO_BG
        : "rgba(255, 0, 0)",
      layerOpacity:
        filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 9,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Coahuila"),
        equalToFilter(" cdl_prodfm", "SLIGO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Coahuila"),
        equalToFilter(" cdl_prodfm", "SLIGO"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowCoahuilaHOSSTONTRAVISPEAK: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      layerVisible: filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK,
      // layerColor: "rgba(255, 115, 223)",
      layerColor: filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG
        ? filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG
        : "rgba(255, 115, 223)",
      layerOpacity:
        filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value !=
        undefined
          ? (100 -
              filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 8,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Coahuila"),
        orFilter(
          equalToFilter(" cdl_prodfm", "TRAVIS PEAK"),
          equalToFilter(" cdl_prodfm", "HOSSTON")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Coahuila"),
        orFilter(
          equalToFilter(" cdl_prodfm", "TRAVIS PEAK"),
          equalToFilter(" cdl_prodfm", "HOSSTON")
        ),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheBUDA: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheBUDA",
      layerVisible: filtervalues.CretaceousLowComancheBUDA,
      // layerColor: "rgba(115,255,223)",
      layerColor: filtervalues.CretaceousLowComancheBUDA_BG
        ? filtervalues.CretaceousLowComancheBUDA_BG
        : "rgba(115,255,223)",
      layerOpacity:
        filtervalues.CretaceousLowComancheBUDA_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComancheBUDA_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 16,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "BUDA"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "BUDA"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheEDWARDS: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheEDWARDS",
      layerVisible: filtervalues.CretaceousLowComancheEDWARDS,
      // layerColor: "rgba(115,178,255)",
      layerColor: filtervalues.CretaceousLowComancheEDWARDS_BG
        ? filtervalues.CretaceousLowComancheEDWARDS_BG
        : "rgba(115,178,255)",
      layerOpacity:
        filtervalues.CretaceousLowComancheEDWARDS_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComancheEDWARDS_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 13,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "EDWARDS"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "EDWARDS"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheGEORGETOWN: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheGEORGETOWN",
      layerVisible: filtervalues.CretaceousLowComancheGEORGETOWN,
      // layerColor: "rgba(255,115,223)",
      layerColor: filtervalues.CretaceousLowComancheGEORGETOWN_BG
        ? filtervalues.CretaceousLowComancheGEORGETOWN_BG
        : "rgba(255,115,223)",
      layerOpacity:
        filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 15,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "GEORGETOWN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "GEORGETOWN"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheFREDERICKSBURG: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheFREDERICKSBURG",
      layerVisible: filtervalues.CretaceousLowComancheFREDERICKSBURG,
      // layerColor: "rgba(255,115,223)",
      layerColor: filtervalues.CretaceousLowComancheFREDERICKSBURG_BG
        ? filtervalues.CretaceousLowComancheFREDERICKSBURG_BG
        : "rgba(255,115,223)",
      layerOpacity:
        filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value !=
        undefined
          ? (100 -
              filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 14,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "FREDERICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "FREDERICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheGLENROSE: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheGLENROSE",
      layerVisible: filtervalues.CretaceousLowComancheGLENROSE,
      // layerColor: "rgba(255,115,223)",
      layerColor: filtervalues.CretaceousLowComancheGLENROSE_BG
        ? filtervalues.CretaceousLowComancheGLENROSE_BG
        : "rgba(255,115,223)",
      layerOpacity:
        filtervalues.CretaceousLowComancheGLENROSE_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComancheGLENROSE_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 12,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "GLEN ROSE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "GLEN ROSE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComanchePEARSALL: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComanchePEARSALL",
      layerVisible: filtervalues.CretaceousLowComanchePEARSALL,
      // layerColor: "rgba(255,115,223)",
      layerColor: filtervalues.CretaceousLowComanchePEARSALL_BG
        ? filtervalues.CretaceousLowComanchePEARSALL_BG
        : "rgba(255,115,223)",
      layerOpacity:
        filtervalues.CretaceousLowComanchePEARSALL_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComanchePEARSALL_opacity_value) /
            100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 11,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "PEARSALL"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        equalToFilter(" cdl_prodfm", "PEARSALL"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    CretaceousLowComancheOTHER: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "CretaceousLowComancheOTHER",
      layerVisible: filtervalues.CretaceousLowComancheOTHER,
      // layerColor: "rgba(255,255,0)",
      layerColor: filtervalues.CretaceousLowComancheOTHER_BG
        ? filtervalues.CretaceousLowComancheOTHER_BG
        : "rgba(255,255,0)",
      layerOpacity:
        filtervalues.CretaceousLowComancheOTHER_opacity_value != undefined
          ? (100 - filtervalues.CretaceousLowComancheOTHER_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 10,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        notEqualTo("cdl_prodfm", "BUDA"),
        notEqualTo("cdl_prodfm", "EDWARDS"),
        notEqualTo("cdl_prodfm", "GEORGETOWN"),
        notEqualTo("cdl_prodfm", "PEARSALL"),
        notEqualTo("cdl_prodfm", "GLEN ROSE"),
        notEqualTo("cdl_prodfm", "FREDERICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Cretaceous Low Comanche"),
        notEqualTo("cdl_prodfm", "BUDA"),
        notEqualTo("cdl_prodfm", "EDWARDS"),
        notEqualTo("cdl_prodfm", "GEORGETOWN"),
        notEqualTo("cdl_prodfm", "PEARSALL"),
        notEqualTo("cdl_prodfm", "GLEN ROSE"),
        notEqualTo("cdl_prodfm", "FREDERICKSBURG"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    JurassicUppCottonValley: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "JurassicUppCottonValley",
      layerVisible: filtervalues.JurassicUppCottonValley,
      // layerColor: "rgba(255,127,127)",
      layerColor: filtervalues.JurassicUppCottonValley_BG
        ? filtervalues.JurassicUppCottonValley_BG
        : "rgba(255,127,127)",
      layerOpacity:
        filtervalues.JurassicUppCottonValley_opacity_value != undefined
          ? (100 - filtervalues.JurassicUppCottonValley_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Jurassic Upp"),
        equalToFilter("cdl_prodfm", "COTTON VALLEY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Jurassic Upp"),
        equalToFilter("cdl_prodfm", "COTTON VALLEY"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    JurassicUppHaynesville: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "JurassicUppHaynesville",
      layerVisible: filtervalues.JurassicUppHaynesville,
      // layerColor: "rgba(255,127,127)",
      layerColor: filtervalues.JurassicUppHaynesville_BG
        ? filtervalues.JurassicUppHaynesville_BG
        : "rgba(255,127,127)",
      layerOpacity:
        filtervalues.JurassicUppHaynesville_opacity_value != undefined
          ? (100 - filtervalues.JurassicUppHaynesville_opacity_value) / 100
          : 0.8,
      // layerOpacity: 0.8,
      layerZindex: 6,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "Jurassic Upp"),
        equalToFilter("cdl_prodfm", "HAYNESVILLE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "Jurassic Upp"),
        equalToFilter("cdl_prodfm", "HAYNESVILLE"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    SAEoceneWILCOX: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAEoceneWILCOX",
      layerVisible: filtervalues.SAEoceneWILCOX,
      layerColor: filtervalues.SAEoceneWILCOX_BG
        ? filtervalues.SAEoceneWILCOX_BG
        : "rgb(68, 101, 137)",
      layerOpacity:
        filtervalues.SAEoceneWILCOX_opacity_value != undefined
          ? (100 - filtervalues.SAEoceneWILCOX_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "TERTIARY EOCENE"),
        equalToFilter("cdl_prodfm", "Wilcox"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "TERTIARY EOCENE"),
        equalToFilter("cdl_prodfm", "Wilcox"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousUppGulfNAVARRO: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousUppGulfNAVARRO",
      layerVisible: filtervalues.SACretaceousUppGulfNAVARRO,
      layerColor: filtervalues.SACretaceousUppGulfNAVARRO_BG
        ? filtervalues.SACretaceousUppGulfNAVARRO_BG
        : "rgb(170, 102, 205)",
      layerOpacity:
        filtervalues.SACretaceousUppGulfNAVARRO_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousUppGulfNAVARRO_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Navarro"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Navarro"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousTaylor: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousTaylor",
      layerVisible: filtervalues.SACretaceousTaylor,
      layerColor: filtervalues.SACretaceousTaylor_BG
        ? filtervalues.SACretaceousTaylor_BG
        : "rgb(102, 119, 205)",
      layerOpacity:
        filtervalues.SACretaceousTaylor_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousTaylor_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Taylor"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Taylor"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousAustin: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousAustin",
      layerVisible: filtervalues.SACretaceousAustin,
      layerColor: filtervalues.SACretaceousAustin_BG
        ? filtervalues.SACretaceousAustin_BG
        : "rgb(205, 102, 153)",
      layerOpacity:
        filtervalues.SACretaceousAustin_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousAustin_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Austin"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Austin"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousUppGulfTUSCALOOSAWOODBINE: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousUppGulfTUSCALOOSAWOODBINE",
      layerVisible: filtervalues.SACretaceousUppGulfTUSCALOOSAWOODBINE,
      layerColor: filtervalues.SACretaceousUppGulfTUSCALOOSAWOODBINE_BG
        ? filtervalues.SACretaceousUppGulfTUSCALOOSAWOODBINE_BG
        : "rgb(0, 197, 255)",
      layerOpacity:
        filtervalues.SACretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value !=
        undefined
          ? (100 -
              filtervalues.SACretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Tuscaloosa"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS UPP GULF"),
        equalToFilter("cdl_prodfm", "Tuscaloosa"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowComancheGoodland: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowComancheGoodland",
      layerVisible: filtervalues.SACretaceousLowComancheGoodland,
      layerColor: filtervalues.SACretaceousLowComancheGoodland_BG
        ? filtervalues.SACretaceousLowComancheGoodland_BG
        : "rgb(255, 255, 0)",
      layerOpacity:
        filtervalues.SACretaceousLowComancheGoodland_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousLowComancheGoodland_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Goodland"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Goodland"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowComanchePaluxy: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowComanchePaluxy",
      layerVisible: filtervalues.SACretaceousLowComanchePaluxy,
      layerColor: filtervalues.SACretaceousLowComanchePaluxy_BG
        ? filtervalues.SACretaceousLowComanchePaluxy_BG
        : "rgb(115, 255, 223)",
      layerOpacity:
        filtervalues.SACretaceousLowComanchePaluxy_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousLowComanchePaluxy_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Paluxy"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Paluxy"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowComancheGLENROSE: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowComancheGLENROSE",
      layerVisible: filtervalues.SACretaceousLowComancheGLENROSE,
      layerColor: filtervalues.SACretaceousLowComancheGLENROSE_BG
        ? filtervalues.SACretaceousLowComancheGLENROSE_BG
        : "rgb(115, 178, 255)",
      layerOpacity:
        filtervalues.SACretaceousLowComancheGLENROSE_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousLowComancheGLENROSE_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Rodessa-GlenRose"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "Rodessa-GlenRose"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowComancheJames: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowComancheJames",
      layerVisible: filtervalues.SACretaceousLowComancheJames,
      layerColor: filtervalues.SACretaceousLowComancheJames_BG
        ? filtervalues.SACretaceousLowComancheJames_BG
        : "rgb(0, 0, 0)",
      layerOpacity:
        filtervalues.SACretaceousLowComancheJames_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousLowComancheJames_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "James"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COMANCHE"),
        equalToFilter("cdl_prodfm", "James"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowCoahuilaSLIGO: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowCoahuilaSLIGO",
      layerVisible: filtervalues.SACretaceousLowCoahuilaSLIGO,
      layerColor: filtervalues.SACretaceousLowCoahuilaSLIGO_BG
        ? filtervalues.SACretaceousLowCoahuilaSLIGO_BG
        : "rgb(255, 0, 0)",
      layerOpacity:
        filtervalues.SACretaceousLowCoahuilaSLIGO_opacity_value != undefined
          ? (100 - filtervalues.SACretaceousLowCoahuilaSLIGO_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COAHUILA"),
        equalToFilter("cdl_prodfm", "Sligo-Pettet"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COAHUILA"),
        equalToFilter("cdl_prodfm", "Sligo-Pettet"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SACretaceousLowCoahuilaHOSSTONTRAVISPEAK: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SACretaceousLowCoahuilaHOSSTONTRAVISPEAK",
      layerVisible: filtervalues.SACretaceousLowCoahuilaHOSSTONTRAVISPEAK,
      layerColor: filtervalues.SACretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG
        ? filtervalues.SACretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG
        : "rgb(255, 115, 223)",
      layerOpacity:
        filtervalues.SACretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value !=
        undefined
          ? (100 -
              filtervalues.SACretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value) /
            100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COAHUILA"),
        equalToFilter("cdl_prodfm", "Hosston-TravisPeak"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "CRETACEOUS LOW COAHUILA"),
        equalToFilter("cdl_prodfm", "Hosston-TravisPeak"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAJurassicUppCottonValley: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAJurassicUppCottonValley",
      layerVisible: filtervalues.SAJurassicUppCottonValley,
      layerColor: filtervalues.SAJurassicUppCottonValley_BG
        ? filtervalues.SAJurassicUppCottonValley_BG
        : "rgb(39, 79, 137)",
      layerOpacity:
        filtervalues.SAJurassicUppCottonValley_opacity_value != undefined
          ? (100 - filtervalues.SAJurassicUppCottonValley_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Cotton Valley"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Cotton Valley"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAJurassicUppHaynesville: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAJurassicUppHaynesville",
      layerVisible: filtervalues.SAJurassicUppHaynesville,
      layerColor: filtervalues.SAJurassicUppHaynesville_BG
        ? filtervalues.SAJurassicUppHaynesville_BG
        : "rgb(230, 152, 0)",
      layerOpacity:
        filtervalues.SAJurassicUppHaynesville_opacity_value != undefined
          ? (100 - filtervalues.SAJurassicUppHaynesville_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Haynesville"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Haynesville"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAJurassicUppSmackover: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAJurassicUppSmackover",
      layerVisible: filtervalues.SAJurassicUppSmackover,
      layerColor: filtervalues.SAJurassicUppSmackover_BG
        ? filtervalues.SAJurassicUppSmackover_BG
        : "rgb(137, 68, 101)",
      layerOpacity:
        filtervalues.SAJurassicUppSmackover_opacity_value != undefined
          ? (100 - filtervalues.SAJurassicUppSmackover_opacity_value) / 100
          : 0.8,
      layerZindex: 7,
      layerFilter: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Smackover"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
      ),
      layerFilternew: andFilter(
        equalToFilter("cdl_ag_nm", "JURASSIC UPP"),
        equalToFilter("cdl_prodfm", "Smackover"),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
       lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    Volumetrics100MillTons: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Volumetrics100MillTons",
      layerVisible:
        filtervalues.Volumetrics100MillTons != undefined
          ? filtervalues.Volumetrics100MillTons
          : true,
      layerColor: filtervalues.Volumetrics100MillTons_BG
        ? `${filtervalues.Volumetrics100MillTons_BG}70`
        : "#ff000070",
      layerOpacity:
        filtervalues.Volumetrics100MillTons_opacity_value != undefined
          ? (100 - filtervalues.Volumetrics100MillTons_opacity_value) / 100
          : 0.7,
      layerZindex: 5,
      layerFilter: andFilter(
        greaterThan("mstormt", 20),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          "mstormt",
          filtervalues.potentialstorageGreaterValue
        )
        //lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue),
      ),
      layerFilternew: andFilter(
        greaterThan("mstormt", 20),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    Volumetrics50to100: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Volumetrics50to100",
      layerVisible:
        filtervalues.Volumetrics50to100 != undefined
          ? filtervalues.Volumetrics50to100
          : true,
      layerColor: filtervalues.Volumetrics50to100_BG
        ? `${filtervalues.Volumetrics50to100_BG}70`
        : "#ffaa0070",
      layerOpacity:
        filtervalues.Volumetrics50to100_opacity_value != undefined
          ? (100 - filtervalues.Volumetrics50to100_opacity_value) / 100
          : 0.7,
      layerZindex: 4,
      layerFilter: andFilter(
        between("mstormt", 10, 20),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    Volumetrics15to50: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Volumetrics15to50",
      layerVisible:
        filtervalues.Volumetrics15to50 != undefined
          ? filtervalues.Volumetrics15to50
          : true,
      layerColor: filtervalues.Volumetrics15to50_BG
        ? `${filtervalues.Volumetrics15to50_BG}70`
        : "#ffff0070",
      layerOpacity:
        filtervalues.Volumetrics15to50_opacity_value != undefined
          ? (100 - filtervalues.Volumetrics15to50_opacity_value) / 100
          : 0.7,
      layerZindex: 3,
      layerFilter: andFilter(
        between("mstormt", 5, 10),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    VolumetricsSmall5to15: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "VolumetricsSmall5to15",
      layerVisible:
        filtervalues.VolumetricsSmall5to15 != undefined
          ? filtervalues.VolumetricsSmall5to15
          : true,
      layerColor: filtervalues.VolumetricsSmall5to15_BG
        ? `${filtervalues.VolumetricsSmall5to15_BG}70`
        : "#aaff0070",
      layerOpacity:
        filtervalues.VolumetricsSmall5to15_opacity_value != undefined
          ? (100 - filtervalues.VolumetricsSmall5to15_opacity_value) / 100
          : 0.7,
      layerZindex: 2,
      layerFilter: andFilter(
        between("mstormt", 2.5, 5),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    VolumetricsMarginal5: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "VolumetricsMarginal5",
      layerVisible:
        filtervalues.VolumetricsMarginal5 != undefined
          ? filtervalues.VolumetricsMarginal5
          : true,
      layerColor: filtervalues.VolumetricsMarginal5_BG
        ? `${filtervalues.VolumetricsMarginal5_BG}70`
        : "#73deff70",
      layerOpacity:
        filtervalues.VolumetricsMarginal5_opacity_value != undefined
          ? (100 - filtervalues.VolumetricsMarginal5_opacity_value) / 100
          : 0.7,
      layerZindex: 1,
      layerFilter: andFilter(
        between("mstormt", 1, 2.5),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    Unclassified: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Unclassified",
      layerVisible:
        filtervalues.Unclassified != undefined
          ? filtervalues.Unclassified
          : true,
      layerColor: filtervalues.Unclassified_BG
        ? `${filtervalues.Unclassified_BG}50`
        : "#0000ff50",
      layerOpacity:
        filtervalues.Unclassified_opacity_value != undefined
          ? (100 - filtervalues.Unclassified_opacity_value) / 100
          : 0.7,
      layerZindex: 0,
      layerFilter: andFilter(
        between("mstormt", 0.1, 1),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    SAVolumetricsGreater50: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetricsGreater50",
      layerVisible:
        filtervalues.SAVolumetricsGreater50 != undefined
          ? filtervalues.SAVolumetricsGreater50
          : false,
      layerColor: filtervalues.SAVolumetricsGreater50_BG
        ? `${filtervalues.SAVolumetricsGreater50_BG}70`
        : "#ff000070",
      layerOpacity:
        filtervalues.SAVolumetricsGreater50_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetricsGreater50_opacity_value) / 100
          : 0.7,
      layerZindex: 5,
      layerFilter: andFilter(
        greaterThan("mstormt", 50),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        // greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        // lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          "mstormt",
          filtervalues.potentialstorageGreaterValue
        )
        //lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue),
      ),
      layerFilternew: andFilter(
        greaterThan("mstormt", 50),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        // greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        // lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAVolumetrics20to50: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetrics20to50",
      layerVisible:
        filtervalues.SAVolumetrics20to50 != undefined
          ? filtervalues.SAVolumetrics20to50
          : false,
      layerColor: filtervalues.SAVolumetrics20to50_BG
        ? `${filtervalues.SAVolumetrics20to50_BG}70`
        : "#ffaa0070",
      layerOpacity:
        filtervalues.SAVolumetrics20to50_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetrics20to50_opacity_value) / 100
          : 0.7,
      layerZindex: 4,
      layerFilter: andFilter(
        between("mstormt", 20, 50),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        // greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        // lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAVolumetrics10to20: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetrics10to20",
      layerVisible:
        filtervalues.SAVolumetrics10to20 != undefined
          ? filtervalues.SAVolumetrics10to20
          : false,
      layerColor: filtervalues.SAVolumetrics10to20_BG
        ? `${filtervalues.SAVolumetrics10to20_BG}70`
        : "#ffff0070",
      layerOpacity:
        filtervalues.SAVolumetrics10to20_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetrics10to20_opacity_value) / 100
          : 0.7,
      layerZindex: 3,
      layerFilter: andFilter(
        between("mstormt", 10, 20),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        //greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        // lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAVolumetrics5to10: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetrics5to10",
      layerVisible:
        filtervalues.SAVolumetrics5to10 != undefined
          ? filtervalues.SAVolumetrics5to10
          : false,
      layerColor: filtervalues.SAVolumetrics5to10_BG
        ? `${filtervalues.SAVolumetrics5to10_BG}70`
        : "#aaff0070",
      layerOpacity:
        filtervalues.SAVolumetrics5to10_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetrics5to10_opacity_value) / 100
          : 0.7,
      layerZindex: 2,
      layerFilter: andFilter(
        between("mstormt", 5, 10),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        //greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        //lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAVolumetrics1to5: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetrics1to5",
      layerVisible:
        filtervalues.SAVolumetrics1to5 != undefined
          ? filtervalues.SAVolumetrics1to5
          : false,
      layerColor: filtervalues.SAVolumetrics1to5_BG
        ? `${filtervalues.SAVolumetrics1to5_BG}70`
        : "#73deff70",
      layerOpacity:
        filtervalues.SAVolumetrics1to5_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetrics1to5_opacity_value) / 100
          : 0.7,
      layerZindex: 1,
      layerFilter: andFilter(
        between("mstormt", 1, 5),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        //greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        //lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },
    SAVolumetrics0_1to1: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAVolumetrics0_1to1",
      layerVisible:
        filtervalues.SAVolumetrics0_1to1 != undefined
          ? filtervalues.SAVolumetrics0_1to1
          : false,
      layerColor: filtervalues.SAVolumetrics0_1to1_BG
        ? `${filtervalues.SAVolumetrics0_1to1_BG}50`
        : "#0000ff50",
      layerOpacity:
        filtervalues.SAVolumetrics0_1to1_opacity_value != undefined
          ? (100 - filtervalues.SAVolumetrics0_1to1_opacity_value) / 100
          : 0.7,
      layerZindex: 0,
      layerFilter: andFilter(
        between("mstormt", 0.1, 1),
        like("pool_type", filtervalues.pooltypeValue),
        greaterThanOrEqualTo("lmdepf", filtervalues.depthGreaterthanValue),
        lessThanOrEqualTo("lmdepf", filtervalues.depthLessthanValue),
        // greaterThanOrEqualTo("well_dens", filtervalues.welldensityGreaterValue),
        // lessThanOrEqualTo("well_dens", filtervalues.welldensityLessthanValue),
        greaterThanOrEqualTo("acres", filtervalues.poolareaAcresGreaterValue),
        lessThanOrEqualTo("acres", filtervalues.poolareaAcresLesserValue),
        greaterThanOrEqualTo(
          " mstormt",
          filtervalues.potentialstorageGreaterValue
        ),
        lessThanOrEqualTo("mstormt", filtervalues.potentialstorageLesserValue)
      ),
    },

    ProtectedLands: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "ProtectedLands",
      layerVisible: filtervalues.ProtectedLands,
      layerName: "protected_lands_southern_us_region",
      layerOpacity: 0.8,
    },

    BuildOut: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "BuildOut",
      layerVisible: filtervalues.BuildOut,
      layerName: "ca_future_buildouts",
      layerOpacity: 100,
    },

    epa_emitters: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "epa_emitters",
      layerVisible:
        filtervalues.epa_emitters != undefined
          ? filtervalues.epa_emitters
          : true,
      layerColor: filtervalues.epa_emitters_BG
        ? filtervalues.epa_emitters_BG
        : "#000000",
      layerOpacity:
        filtervalues.epa_emitters_opacity_value != undefined
          ? (100 - filtervalues.epa_emitters_opacity_value) / 100
          : 0.8,
      layerZindex: 100,
    },

    // StorageResourcesPools: {
    //   layerSource: "",
    //   layerFeature: "",
    //   layerTitle: "StorageResourcesPools",
    //   layerVisible: filtervalues.StorageResourcesPools,
    //   layerName: "gc_storage_resources_pools",
    //   // layerOpacity: 0.8,
    //   layerOpacity: filtervalues.StorageResourcesPools_opacity_value != undefined ? (100-filtervalues.StorageResourcesPools_opacity_value)/100 : 0.8,
    // },
    PoolVolumetrics20: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics20",
      layerVisible:
        filtervalues.PoolVolumetrics20 != undefined
          ? filtervalues.PoolVolumetrics20
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics20_opacity_value
        ? (100 - filtervalues.PoolVolumetrics20_opacity_value) / 100
        : 0.7,
      layerFilter: "m_stor_mt>20",
    },
    PoolVolumetrics10to20: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics10to20",
      layerVisible:
        filtervalues.PoolVolumetrics10to20 != undefined
          ? filtervalues.PoolVolumetrics10to20
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics10to20_opacity_value
        ? (100 - filtervalues.PoolVolumetrics10to20_opacity_value) / 100
        : 0.7,
      layerZindex: 4,
      layerFilter: "m_stor_mt>10 and m_stor_mt<=20",
    },
    PoolVolumetrics5to10: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics5to10",
      layerVisible:
        filtervalues.PoolVolumetrics5to10 != undefined
          ? filtervalues.PoolVolumetrics5to10
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics5to10_opacity_value
        ? (100 - filtervalues.PoolVolumetrics5to10_opacity_value) / 100
        : 0.7,
      layerZindex: 3,
      layerFilter: "m_stor_mt>5 and m_stor_mt<=10",
    },
    PoolVolumetrics2_5to5: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics2_5to5",
      layerVisible:
        filtervalues.PoolVolumetrics2_5to5 != undefined
          ? filtervalues.PoolVolumetrics2_5to5
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics2_5to5_opacity_value
        ? (100 - filtervalues.PoolVolumetrics2_5to5_opacity_value) / 100
        : 0.7,
      layerZindex: 2,
      layerFilter: "m_stor_mt>2.5 and m_stor_mt<=5",
    },
    PoolVolumetrics1to2_5: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics1to2_5",
      layerVisible:
        filtervalues.PoolVolumetrics1to2_5 != undefined
          ? filtervalues.PoolVolumetrics1to2_5
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics1to2_5_opacity_value
        ? (100 - filtervalues.PoolVolumetrics1to2_5_opacity_value) / 100
        : 0.7,
      layerZindex: 1,
      layerFilter: "m_stor_mt>1 and m_stor_mt<=2.5",
    },
    PoolVolumetrics0_1to1: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetrics0_1to1",
      layerVisible:
        filtervalues.PoolVolumetrics0_1to1 != undefined
          ? filtervalues.PoolVolumetrics0_1to1
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetrics0_1to1_opacity_value
        ? (100 - filtervalues.PoolVolumetrics0_1to1_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt>0.1 and m_stor_mt<=1",
    },
    PoolVolumetricsLess0_1: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetricsLess0_1",
      layerVisible:
        filtervalues.PoolVolumetricsLess0_1 != undefined
          ? filtervalues.PoolVolumetricsLess0_1
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetricsLess0_1_opacity_value
        ? (100 - filtervalues.PoolVolumetricsLess0_1_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt>0 and m_stor_mt<=0.1",
    },
    PoolVolumetricsNA: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "PoolVolumetricsNA",
      layerVisible:
        filtervalues.PoolVolumetricsNA != undefined
          ? filtervalues.PoolVolumetricsNA
          : false,
      layerName: "gc_storage_resources_pools",
      layerOpacity: filtervalues.PoolVolumetricsNA_opacity_value
        ? (100 - filtervalues.PoolVolumetricsNA_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt is NULL or m_stor_mt = 0",
    },

    SAPoolVolumetricsGreater50: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetricsGreater50",
      layerVisible:
        filtervalues.SAPoolVolumetricsGreater50 != undefined
          ? filtervalues.SAPoolVolumetricsGreater50
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetricsGreater50_opacity_value
        ? (100 - filtervalues.SAPoolVolumetricsGreater50_opacity_value) / 100
        : 0.7,
      layerFilter: "m_stor_mt>50",
    },
    SAPoolVolumetrics20to50: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetrics20to50",
      layerVisible:
        filtervalues.SAPoolVolumetrics20to50 != undefined
          ? filtervalues.SAPoolVolumetrics20to50
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetrics20to50_opacity_value
        ? (100 - filtervalues.SAPoolVolumetrics20to50_opacity_value) / 100
        : 0.7,
      layerZindex: 4,
      layerFilter: "m_stor_mt>20 and m_stor_mt<=50",
    },
    SAPoolVolumetrics10to20: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetrics10to20",
      layerVisible:
        filtervalues.SAPoolVolumetrics10to20 != undefined
          ? filtervalues.SAPoolVolumetrics10to20
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetrics10to20_opacity_value
        ? (100 - filtervalues.SAPoolVolumetrics10to20_opacity_value) / 100
        : 0.7,
      layerZindex: 3,
      layerFilter: "m_stor_mt>10 and m_stor_mt<=20",
    },
    SAPoolVolumetrics5to10: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetrics5to10",
      layerVisible:
        filtervalues.SAPoolVolumetrics5to10 != undefined
          ? filtervalues.SAPoolVolumetrics5to10
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetrics5to10_opacity_value
        ? (100 - filtervalues.SAPoolVolumetrics5to10_opacity_value) / 100
        : 0.7,
      layerZindex: 2,
      layerFilter: "m_stor_mt>5 and m_stor_mt<=10",
    },
    SAPoolVolumetrics1to5: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetrics1to5",
      layerVisible:
        filtervalues.SAPoolVolumetrics1to5 != undefined
          ? filtervalues.SAPoolVolumetrics1to5
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetrics1to5_opacity_value
        ? (100 - filtervalues.SAPoolVolumetrics1to5_opacity_value) / 100
        : 0.7,
      layerZindex: 1,
      layerFilter: "m_stor_mt>1 and m_stor_mt<=5",
    },
    SAPoolVolumetrics0_1to1: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetrics0_1to1",
      layerVisible:
        filtervalues.SAPoolVolumetrics0_1to1 != undefined
          ? filtervalues.SAPoolVolumetrics0_1to1
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetrics0_1to1_opacity_value
        ? (100 - filtervalues.SAPoolVolumetrics0_1to1_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt>0.1 and m_stor_mt<=1",
    },
    SAPoolVolumetricsLess0_1: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetricsLess0_1",
      layerVisible:
        filtervalues.SAPoolVolumetricsLess0_1 != undefined
          ? filtervalues.SAPoolVolumetricsLess0_1
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetricsLess0_1_opacity_value
        ? (100 - filtervalues.SAPoolVolumetricsLess0_1_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt>0 and m_stor_mt<=0.1",
    },
    SAPoolVolumetricsNA: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "SAPoolVolumetricsNA",
      layerVisible:
        filtervalues.SAPoolVolumetricsNA != undefined
          ? filtervalues.SAPoolVolumetricsNA
          : false,
      layerName: "southern_arkansas_pool_polygons",
      layerOpacity: filtervalues.SAPoolVolumetricsNA_opacity_value
        ? (100 - filtervalues.SAPoolVolumetricsNA_opacity_value) / 100
        : 0.7,
      layerZindex: 0,
      layerFilter: "m_stor_mt is NULL or m_stor_mt = 0",
    },

    well_control_points: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "well_control_points",
      layerVisible:
        filtervalues.well_control_points != undefined
          ? filtervalues.well_control_points
          : false,
      layerName: "well_control_points",
      layerOpacity: 100,
      layerZindex: 1,
    },
    drax_sa_well_control_points: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "drax_sa_well_control_points",
      layerVisible:
        filtervalues.drax_sa_well_control_points != undefined
          ? filtervalues.drax_sa_well_control_points
          : false,
      layerName: "drax_sa_well_control_points",
      layerOpacity: 100,
      layerZindex: 100,
    },
    gcdiapirg_tx_southcentral: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "gcdiapirg_tx_southcentral",
      layerVisible:
        filtervalues.gcdiapirg_tx_southcentral != undefined
          ? filtervalues.gcdiapirg_tx_southcentral
          : false,
      layerName: "gcdiapirg_tx_southcentral",
      layerOpacity: 100,
      layerZindex: 100,
    },
    arkansasmiss_faults: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "arkansasmiss_faults",
      layerVisible:
        filtervalues.arkansasmiss_faults != undefined
          ? filtervalues.arkansasmiss_faults
          : false,
      layerName: "arkansasmiss_faults",
      layerOpacity: 100,
      layerZindex: 100,
    },
    upmio_faults: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "upmio_faults",
      layerVisible:
        filtervalues.upmio_faults != undefined
          ? filtervalues.upmio_faults
          : false,
      layerName: "upmio_faults",
      layerOpacity: 100,
      layerZindex: 1,
    },
    midmio_faults: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "midmio_faults",
      layerVisible:
        filtervalues.midmio_faults != undefined
          ? filtervalues.midmio_faults
          : false,
      layerName: "midmio_faults",
      layerOpacity: 100,
      layerZindex: 1,
    },
    lowermio_faults: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "lowermio_faults",
      layerVisible:
        filtervalues.lowermio_faults != undefined
          ? filtervalues.lowermio_faults
          : false,
      layerName: "lowermio_faults",
      layerOpacity: 100,
      layerZindex: 1,
    },
    upper_miocene_wells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "upper_miocene_wells",
      layerVisible:
        filtervalues.upper_miocene_wells != undefined
          ? filtervalues.upper_miocene_wells
          : false,
      layerName: "upper_miocene_wells",
      layerOpacity: 100,
      layerZindex: 1,
    },
    middle_miocene_wells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "middle_miocene_wells",
      layerVisible:
        filtervalues.middle_miocene_wells != undefined
          ? filtervalues.middle_miocene_wells
          : false,
      layerName: "middle_miocene_wells",
      layerOpacity: 100,
      layerZindex: 1,
    },
    lower_miocene_shallow_wells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "lower_miocene_shallow_wells",
      layerVisible:
        filtervalues.lower_miocene_shallow_wells != undefined
          ? filtervalues.lower_miocene_shallow_wells
          : false,
      layerName: "lower_miocene_shallow_wells",
      layerOpacity: 100,
      layerZindex: 1,
    },
    lower_miocene_deep_wells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "lower_miocene_deep_wells",
      layerVisible:
        filtervalues.lower_miocene_deep_wells != undefined
          ? filtervalues.lower_miocene_deep_wells
          : false,
      layerName: "lower_miocene_deep_wells",
      layerOpacity: 100,
      layerZindex: 1,
    },

    StorageInjectorWells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "StorageInjectorWells",
      layerVisible: filtervalues.StorageInjectorWells,
      layerName: "injector_wells_storage",
      // layerOpacity: 0.8,
      layerOpacity:
        filtervalues.StorageInjectorWells_opacity_value != undefined
          ? (100 - filtervalues.StorageInjectorWells_opacity_value) / 100
          : 0.8,
    },

    EORInjectorWells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "EORInjectorWells",
      layerVisible: filtervalues.EORInjectorWells,
      layerName: "injector_wells_eor",
      // layerOpacity: 0.8,
      layerOpacity:
        filtervalues.EORInjectorWells_opacity_value != undefined
          ? (100 - filtervalues.EORInjectorWells_opacity_value) / 100
          : 0.8,
    },

    DisposalInjectorWells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "DisposalInjectorWells",
      layerVisible: filtervalues.DisposalInjectorWells,
      layerName: "injector_wells_disposal",
      // layerOpacity: 0.8,
      layerOpacity:
        filtervalues.DisposalInjectorWells_opacity_value != undefined
          ? (100 - filtervalues.DisposalInjectorWells_opacity_value) / 100
          : 0.8,
    },

    ProductionWells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "ProductionWells",
      layerVisible: filtervalues.ProductionWells,
      layerName: "production_wells_ccs_atlas_phase1",
      // layerOpacity: 0.8,
      layerOpacity:
        filtervalues.ProductionWells_opacity_value != undefined
          ? (100 - filtervalues.ProductionWells_opacity_value) / 100
          : 0.8,
    },
    OrphanedWells: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "OrphanedWells",
      layerVisible: filtervalues.OrphanedWells,
      layerName: "us_orphaned_wells",
      // layerOpacity: 0.8,
      layerOpacity:
        filtervalues.OrphanedWells_opacity_value != undefined
          ? (100 - filtervalues.OrphanedWells_opacity_value) / 100
          : 0.8,
    },
    Deviated: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Deviated",
      layerVisible: filtervalues.Deviated,
      layerName: "well_deviated",
      layerOpacity: 0.9,
      layerZindex: 3,
    },
    Horizontal: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Horizontal",
      layerVisible: filtervalues.Horizontal,
      layerName: "well_horizontal",
      layerOpacity: 0.9,
      layerZindex: 4,
    },
    Vertical: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Vertical",
      layerVisible: filtervalues.Vertical,
      layerName: "well_vertical",
      layerOpacity: 0.9,
      layerZindex: 2,
    },
    Unknown: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "Unknown",
      layerVisible: filtervalues.Unknown,
      layerName: "well_unknown",
      layerOpacity: 0.9,
      layerZindex: 1,
    },
    fed_state_boundary: {
      layerSource: "",
      layerFeature: "",
      layerTitle: "fed_state_boundary",
      layerVisible: filtervalues.fed_state_boundary,
      layerName: "fed_state_boundary",
      layerOpacity: 0.9,
      layerZindex: 1,
    },
  };
  // ======================= filter details object End ==================

  useEffect(() => {
    if (genaccesToken !== null) {
      csvObj = [];
      const accessTokenDecoded =
        props.auth._oktaAuth.token.decode(genaccesToken);
      let roleaccessObj = {
        CA_GC_Offshore_SA: accessTokenDecoded.payload.CA_GC_Offshore_SA
          ? accessTokenDecoded.payload.CA_GC_Offshore_SA
          : "",
        CA_GC_Onshore_Depleted: accessTokenDecoded.payload
          .CA_GC_Onshore_Depleted
          ? accessTokenDecoded.payload.CA_GC_Onshore_Depleted
          : "",
        //"CA_GC_Onshore_Depleted": false,
        CA_GC_Onshore_SA: accessTokenDecoded.payload.CA_GC_Onshore_SA
          ? accessTokenDecoded.payload.CA_GC_Onshore_SA
          : "",
        CA_EXPORT: accessTokenDecoded.payload.CA_EXPORT
          ? accessTokenDecoded.payload.CA_EXPORT
          : "",
        CA_AR_S_Depleted: accessTokenDecoded.payload.CA_AR_S_Depleted
          ? accessTokenDecoded.payload.CA_AR_S_Depleted
          : "",
          CA_AR_S_SA: accessTokenDecoded.payload.CA_AR_S_SA
          ? accessTokenDecoded.payload.CA_AR_S_SA
          : "",
        // "CA_AR_S_Depleted": false
      };
      let roleAccessarr = [];
      roleAccessarr.push(roleaccessObj);
      if (checkIfJSONisEmpty(roleAccess)) {
        setRoleAccess(roleaccessObj);
      }
      const tileLoader = (tile, src) => {
        const client = new XMLHttpRequest();
        client.open("GET", src);
        client.responseType = "arraybuffer";
        client.setRequestHeader("Authorization", "Bearer " + genaccesToken);
        client.onload = function () {
          const arrayBufferView = new Uint8Array(this.response);
          const blob = new Blob([arrayBufferView], { type: "image/png" });
          const urlCreator = window.URL;
          const imageUrl = urlCreator.createObjectURL(blob);
          tile.getImage().src = imageUrl;
        };
        client.send();
      };

      const legendLoader = (element, src) => {
        const client = new XMLHttpRequest();
        client.open("GET", src);
        client.responseType = "arraybuffer";
        client.setRequestHeader("Authorization", "Bearer " + genaccesToken);
        client.onload = function () {
          const arrayBufferView = new Uint8Array(this.response);
          const blob = new Blob([arrayBufferView], { type: "image/png" });
          const urlCreator = window.URL;
          const imageUrl = urlCreator.createObjectURL(blob);
          element.src = imageUrl;
        };
        client.send();
      };

      const fetchWfsLayerReq = (
        layerSource,
        layerFeature,
        layerFilter,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity,
        indexNo
      ) => {
        if (filtervalues.filterApplied === true) {
          localStorage.setItem("InitialLoad", false);
          FilterDetails.updatelayerVisibleStore("filterApplied", false);
          FilterDetails.updatelayerVisibleStore("applyEnable", false);
        }

        // Create a pattern
        const patternCanvas = document.createElement("canvas");
        const patternContext = patternCanvas.getContext("2d");

        patternCanvas.width = 7;
        patternCanvas.height = 10;

        patternContext.beginPath();
        patternContext.fillStyle = layerColor;
        patternContext.fillRect(
          0,
          0,
          patternCanvas.width,
          patternCanvas.height
        );
        patternContext.moveTo(7, 0);
        patternContext.lineTo(0, 10);
        patternContext.lineCap = "square";
        patternContext.strokeStyle = "#666";
        patternContext.lineWidth = 1;
        patternContext.stroke();

        // Create our primary canvas and fill it with the pattern
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const pattern = ctx.createPattern(patternCanvas, "repeat");

        layerSource = new VectorSource();

        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: pattern,
            }),
          }),
        });

        if (localStorage.getItem("InitialLoad") !== "true") {
          var opctyVal =
            document.getElementById(layerTitle + "Slider") &&
            document.getElementById(layerTitle + "Slider").childNodes[2].value;
          layerFeature.setOpacity(1 - opctyVal / 100);
        } else {
          layerFeature.setOpacity(layerOpacity);
        }

        if (
          !config.staticInformation.volumetricLayerTitles.includes(layerTitle)
        ) {
          let lyrStyle = new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: "transparent",
            }),
          });
          layerFeature.setStyle(lyrStyle);
          if (localStorage.getItem("InitialLoad") !== "true") {
            opctyVal = document.getElementById(layerTitle + "Slider")
              .childNodes[2].value;
            layerFeature.setOpacity(1 - opctyVal / 100);
          } else {
            layerFeature.setOpacity(layerOpacity);
          }
        }

        const maxValues = {
          mtempdgf: 500,
          mporfrac: 0.3,
          mpermmd: 500,
          mprespsi: 12000,
          mapigrav: 75,
        };

        if (filtervalues.temperatureLessthanValue < maxValues.mtempdgf)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mtempdgf", filtervalues.temperatureLessthanValue)
          );
        if (filtervalues.porosityLessthanValue < maxValues.mporfrac)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mporfrac", filtervalues.porosityLessthanValue)
          );
        if (filtervalues.permeabilityLessthanValue < maxValues.mpermmd)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mpermmd", filtervalues.permeabilityLessthanValue)
          );
        if (filtervalues.pressureLessthanValue < maxValues.mprespsi)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mprespsi", filtervalues.pressureLessthanValue)
          );
        if (filtervalues.oilGravityLessthanValue < maxValues.mapigrav)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mapigrav", filtervalues.oilGravityLessthanValue)
          );

        layerFilter.conditions.push(
          greaterThanOrEqualTo(
            "mtempdgf",
            filtervalues.temperatureGreaterthanValue
          )
        );
        layerFilter.conditions.push(
          greaterThanOrEqualTo(
            "mporfrac",
            filtervalues.porosityGreaterthanValue
          )
        );
        layerFilter.conditions.push(
          greaterThanOrEqualTo(
            "mpermmd",
            filtervalues.permeabilityGreaterthanValue
          )
        );
        layerFilter.conditions.push(
          greaterThanOrEqualTo(
            "mprespsi",
            filtervalues.pressureGreaterthanValue
          )
        );
        layerFilter.conditions.push(
          greaterThanOrEqualTo(
            "mapigrav",
            filtervalues.oilGravityGreaterthanValue
          )
        );

        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: ["gc_storage_resources"],
          outputFormat: "application/json",
          filter: layerFilter,
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            const features = new GeoJSON().readFeatures(json);
            layerSource.addFeatures(features);

            map
              .getLayers()
              .getArray()
              .filter((layer) => layer.get("title") === layerTitle)
              .forEach((layer) => map.removeLayer(layer));

            map.addLayer(layerFeature);

            layerFeature.setZIndex(indexNo);
            if (
              config.staticInformation.subscriptionLayers.includes(
                layerTitle
              ) &&
              roleAccess.CA_GC_Onshore_Depleted != undefined
            ) {
              //  console.log("TEST 2 "+layerTitle+"=="+"AccessRole  == "+ roleAccess.CA_GC_Onshore_Depleted + " LAYER: " +  layerVisible);
              if (roleAccess.CA_GC_Onshore_Depleted ? layerVisible : false) {
                layerFeature.setVisible(true);
                csvObj.push(features);
              } else {
                layerFeature.setVisible(false);
              }
            } else {
              if (layerVisible) {
                layerFeature.setVisible(true);
                csvObj.push(features);
              } else {
                layerFeature.setVisible(false);
              }
            }

            count++;
            if (count === 37) {
              setdataFiltered(csvObj);
            }
          });
      };

      // Southern layer start

      const SAfetchWfsLayerReq = (
        layerSource,
        layerFeature,
        layerFilter,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity,
        indexNo
      ) => {
        if (filtervalues.filterApplied === true) {
          localStorage.setItem("InitialLoad", false);
          FilterDetails.updatelayerVisibleStore("filterApplied", false);
          FilterDetails.updatelayerVisibleStore("applyEnable", false);
        }

        // Create a pattern
        const patternCanvas = document.createElement("canvas");
        const patternContext = patternCanvas.getContext("2d");

        patternCanvas.width = 7;
        patternCanvas.height = 10;

        patternContext.beginPath();
        patternContext.fillStyle = layerColor;
        patternContext.fillRect(
          0,
          0,
          patternCanvas.width,
          patternCanvas.height
        );
        patternContext.moveTo(7, 0);
        patternContext.lineTo(0, 10);
        patternContext.lineCap = "square";
        patternContext.strokeStyle = "#666";
        patternContext.lineWidth = 1;
        patternContext.stroke();

        // Create our primary canvas and fill it with the pattern
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const pattern = ctx.createPattern(patternCanvas, "repeat");

        layerSource = new VectorSource();

        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: pattern,
            }),
          }),
        });

        if (localStorage.getItem("InitialLoad") !== "true") {
          var opctyVal =
            document.getElementById(layerTitle + "Slider") &&
            document.getElementById(layerTitle + "Slider").childNodes[2].value;
          layerFeature.setOpacity(1 - opctyVal / 100);
        } else {
          layerFeature.setOpacity(layerOpacity);
        }

        if (
          !config.staticInformation.volumetricLayerTitles.includes(layerTitle)
        ) {
          let lyrStyle = new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: "transparent",
            }),
          });
          layerFeature.setStyle(lyrStyle);
          if (localStorage.getItem("InitialLoad") !== "true") {
            // opctyVal = document.getElementById(layerTitle + "Slider")
            //   .childNodes[2].value;
            layerFeature.setOpacity(layerOpacity);
          } else {
            layerFeature.setOpacity(layerOpacity);
          }
        }

        const maxValues = {
          mtempdgf: 500,
          mporfrac: 0.3,
          mpermmd: 500,
          mprespsi: 12000,
          // mapigrav: 75,
        };

        if (filtervalues.temperatureLessthanValue < maxValues.mtempdgf)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mtempdgf", filtervalues.temperatureLessthanValue)
          );
        if (filtervalues.porosityLessthanValue < maxValues.mporfrac)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mporfrac", filtervalues.porosityLessthanValue)
          );
        if (filtervalues.permeabilityLessthanValue < maxValues.mpermmd)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mpermmd", filtervalues.permeabilityLessthanValue)
          );
        if (filtervalues.pressureLessthanValue < maxValues.mprespsi)
          layerFilter.conditions.push(
            lessThanOrEqualTo("mprespsi", filtervalues.pressureLessthanValue)
          );
        // if (filtervalues.oilGravityLessthanValue < maxValues.mapigrav)
        //   layerFilter.conditions.push(
        //     lessThanOrEqualTo("mapigrav", filtervalues.oilGravityLessthanValue)
        //   );

        // layerFilter.conditions.push(
        //   greaterThanOrEqualTo(
        //     "mtempdgf",
        //     filtervalues.temperatureGreaterthanValue
        //   )
        // );
        // layerFilter.conditions.push(
        //   greaterThanOrEqualTo(
        //     "mporfrac",
        //     filtervalues.porosityGreaterthanValue
        //   )
        // );
        // layerFilter.conditions.push(
        //   greaterThanOrEqualTo(
        //     "mpermmd",
        //     filtervalues.permeabilityGreaterthanValue
        //   )
        // );
        // layerFilter.conditions.push(
        //   greaterThanOrEqualTo(
        //     "mprespsi",
        //     filtervalues.pressureGreaterthanValue
        //   )
        // );
        // layerFilter.conditions.push(
        //   greaterThanOrEqualTo(
        //     "mapigrav",
        //     filtervalues.oilGravityGreaterthanValue
        //   )
        // );

        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: ["southern_arkansas_su_pool_polygons"],
          outputFormat: "application/json",
          filter: layerFilter,
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            const features = new GeoJSON().readFeatures(json);
            layerSource.addFeatures(features);

            map
              .getLayers()
              .getArray()
              .filter((layer) => layer.get("title") === layerTitle)
              .forEach((layer) => map.removeLayer(layer));

            map.addLayer(layerFeature);

            layerFeature.setZIndex(indexNo);
            if (
              config.staticInformation.SAsubscriptionLayers.includes(
                layerTitle
              ) &&
              roleAccess.CA_AR_S_Depleted != undefined
            ) {
              //  console.log("TEST 2 "+layerTitle+"=="+"AccessRole  == "+ roleAccess.CA_AR_S_Depleted + " LAYER: " +  layerVisible);
              if (roleAccess.CA_AR_S_Depleted ? layerVisible : false) {
                layerFeature.setVisible(true);
                csvObj.push(features);
              } else {
                layerFeature.setVisible(false);
              }
            } else {
              if (layerVisible) {
                layerFeature.setVisible(true);
                csvObj.push(features);
              } else {
                layerFeature.setVisible(false);
              }
            }

            count++;
            if (count === 37) {
              setdataFiltered(csvObj);
            }
          });
      };

      //Protected Lands layer Start

      const fetchWMSLayers = (
        layerSource,
        layerFeature,
        layerVisible,
        layerTitle,
        layerOpacity,
        layerName,
        layerZindex,
        layerFilter,
        layerStyle
      ) => {
        map.getLayers().forEach((layer) => {
          if (layer !== undefined) {
            if (layer.get("title") === layerTitle) {
              map.removeLayer(layer);
            }
          }
        });

        layerSource = new TileWMS({
          url: `${config.endpoints.wmsLayerUrl}`,
          params: {
            LAYERS: `${config.endpoints.carbonWorkSpaceName + ":" + layerName}`,
            // LAYERS: "protected_lands_southern_us_region",
            STYLES: layerStyle != null ? layerStyle : "",
            TILED: true,
            ...(layerFilter && { CQL_FILTER: layerFilter }),
          },
          tileLoadFunction: tileLoader,
          crossOrigin: "anonymous",
          serverType: "geoserver",
        });
        layerFeature = new TileLayer({
          title: layerTitle,
          source: layerSource,
        });

        if (layerSource != null) {
          let graphicUrl = layerSource.getLegendUrl();
          if (layerSource.getParams().STYLES) {
            graphicUrl =
              graphicUrl + "&STYLE=" + layerSource.getParams().STYLES;
          }
          if (layerTitle) {
            var element = document.querySelector("#" + layerTitle + "_img");
            console.log(element);
            if (element != null) {
              element = element.firstChild;
              legendLoader(element, graphicUrl);
            }
          }
        }

        map.addLayer(layerFeature);
        layerFeature.setOpacity(layerOpacity);

        if (config.staticInformation.subscriptionLayers.includes(layerTitle)) {
          roleAccess.CA_GC_Onshore_Depleted
            ? layerFeature.setVisible(layerVisible)
            : layerFeature.setVisible(false);
        } else {
          layerFeature.setVisible(layerVisible);
        }
        if (
          config.staticInformation.SAsubscriptionLayers.includes(layerTitle)
        ) {
          roleAccess.CA_AR_S_Depleted
            ? layerFeature.setVisible(layerVisible)
            : layerFeature.setVisible(false);
        } else {
          layerFeature.setVisible(layerVisible);
        }

        if (layerZindex) {
          layerFeature.setZIndex(layerZindex);
        }
      };

      const fetchImageWMSLayers = (
        layerSource,
        layerFeature,
        layerVisible,
        layerTitle,
        layerOpacity,
        layerName,
        layerZindex,
        layerFilter
      ) => {
        map.getLayers().forEach((layer) => {
          if (layer !== undefined) {
            if (layer.get("title") === layerTitle) {
              map.removeLayer(layer);
            }
          }
        });

        layerSource = new TileWMS({
          url: `${config.endpoints.wmsLayerUrl}`,
          params: {
            LAYERS: layerName,
            // LAYERS: "protected_lands_southern_us_region",
            TILED: true,
            ...(layerFilter && { CQL_FILTER: layerFilter }),
          },

          crossOrigin: "anonymous",
          serverType: "geoserver",
        });
        layerFeature = new TileLayer({
          title: layerTitle,
          source: layerSource,
        });

        if (layerSource == null) {
          const graphicUrl = layerSource.getLegendUrl();
          var element = document.querySelector("#" + layerTitle).childNodes[1]
            .firstChild;
          legendLoader(element, graphicUrl);
        }

        map.addLayer(layerFeature);
        layerFeature.setOpacity(layerOpacity);

        if (config.staticInformation.subscriptionLayers.includes(layerTitle)) {
          roleAccess.CA_GC_Onshore_Depleted
            ? layerFeature.setVisible(layerVisible)
            : layerFeature.setVisible(false);
        } else {
          layerFeature.setVisible(layerVisible);
        }
        if (
          config.staticInformation.SAsubscriptionLayers.includes(layerTitle)
        ) {
          roleAccess.CA_AR_S_Depleted
            ? layerFeature.setVisible(layerVisible)
            : layerFeature.setVisible(false);
        } else {
          layerFeature.setVisible(layerVisible);
        }

        if (layerZindex) {
          layerFeature.setZIndex(layerZindex);
        }
      };

      //Epa Emitters Layer Start

      const fetchWfsPointLayerReq = (
        layerSource,
        layerFeature,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity
      ) => {
        layerSource = new VectorSource();
        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            image: new RegularShape({
              fill: new Fill({ color: layerColor }),
              stroke: new Stroke({ color: layerColor, width: 1 }),
              points: 4,
              radius: 9,
              angle: Math.PI / 4,
            }),
          }),
        });

        if (localStorage.getItem("InitialLoad") !== "true") {
          var opctyVal = document.getElementById(layerTitle + "Slider")
            .childNodes[2].value;
          layerFeature.setOpacity(1 - opctyVal / 100);
        }
        let featureType = "epa_emitters";
        if (layerTitle === "PelletPlants")
          featureType = "wood_pellet_plants_2019";
        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: [featureType],
          outputFormat: "application/json",
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            var filteredjson = json;
            filteredjson.features = json.features.filter((item) => {
              if (item.properties.co2_emissions_value !== null) {
                return item;
              }
            });

            const features = new GeoJSON().readFeatures(filteredjson);
            layerSource.addFeatures(features);
            map
              .getLayers()
              .getArray()
              .filter((layer) => layer.get("title") === layerTitle)
              .forEach((layer) => map.removeLayer(layer));
            map.addLayer(layerFeature);
            if (layerVisible) {
              layerFeature.setVisible(true);
            } else {
              layerFeature.setVisible(false);
            }
          });
        layerFeature.setOpacity(layerOpacity);
      };

      //protected Lands

      config.staticInformation.WMSLayers.map((val) => {
        fetchWMSLayers(
          filterDetails[val].layerSource ? filterDetails[val].layerSource : "",
          filterDetails[val].layerFeature
            ? filterDetails[val].layerFeature
            : "",
          filterDetails[val].layerVisible
            ? filterDetails[val].layerVisible
            : "",
          filterDetails[val].layerTitle ? filterDetails[val].layerTitle : "",
          filterDetails[val].layerOpacity
            ? filterDetails[val].layerOpacity
            : "",
          filterDetails[val].layerName ? filterDetails[val].layerName : "",
          filterDetails[val].layerZindex ? filterDetails[val].layerZindex : "",
          filterDetails[val].layerFilter ? filterDetails[val].layerFilter : ""
        );
      });

      config.LayerMetaData.map((obj) => {
        return obj.subCat?.map((subSet) =>
          subSet.subCat.map((dataset) => {
            dataset[dataset.groupName].source.map((lyrobj) => {
              if (Object.keys(lyrobj).includes("categoryName")) {
                lyrobj[lyrobj.groupName].source.map((subobj) => {
                  if (Object.keys(subobj).includes("groupName")) {
                    subobj[subobj.groupName].source.map((subobj1) => {
                      return fetchWMSLayers(
                        "",
                        "",
                        roleAccess[subobj1?.alllowedRole]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              subobj1.lyrName
                            ]
                          : false,
                        subobj1.lyrName,
                        0.75,
                        subobj1.lyrGroupName,
                        50,
                        subobj1.layerFilter,
                        subobj1.lyrStyle
                      );
                    });
                  }
                });
              } else {
                return fetchWMSLayers(
                  "",
                  "",
                  roleAccess[lyrobj?.alllowedRole]
                    ? JSON.parse(localStorage.getItem("DefaultValues"))[
                        lyrobj.lyrName
                      ]
                    : false,
                  lyrobj.lyrName,
                  0.75,
                  lyrobj.lyrGroupName,
                  50,
                  lyrobj.layerFilter,
                  lyrobj.lyrStyle
                );
              }
            });
          })
        );
      });

      config.Pipelines.map((dataset) => {
        dataset[dataset.groupName].source.map((file) => {
          if (file.lyrType === "geoserver_wms") {
            fetchWMSLayers(
              "",
              "",
              JSON.parse(localStorage.getItem("DefaultValues"))[file.lyrName],
              file.lyrName,
              0.75,
              file.lyrName
            );
          }
        });
      });

      fetchWMSLayers(
        "",
        "",
        filtervalues.epa_emitters != undefined
          ? filtervalues.epa_emitters
          : true,
        "epa_emitters",
        1,
        "epa_emitters",
        100
      );

      WFSPointLayers.map((val) => {
        fetchWfsPointLayerReq(
          filterDetails[val].layerSource,
          filterDetails[val].layerFeature,
          filterDetails[val].layerVisible,
          filterDetails[val].layerTitle,
          filterDetails[val].layerColor,
          filterDetails[val].layerOpacity,
          filterDetails[val].layerZindex
        );
      });

      //GC Storage Layers fetchWfsLayerReq functions Start
      config.staticInformation.WFSPolyLayers.map((val) => {
        fetchWfsLayerReq(
          filterDetails[val].layerSource,
          filterDetails[val].layerFeature,
          filtervalues.potentialstorageLesserValue === 100 ? filterDetails[val].layerFilter : filterDetails[val].layerFilternew ? filterDetails[val].layerFilternew : filterDetails[val].layerFilter,
          filterDetails[val].layerVisible,
          filterDetails[val].layerTitle,
          filterDetails[val].layerColor,
          filterDetails[val].layerOpacity,
          filterDetails[val].layerZindex
        );
      });

      config.staticInformation.SouthernArkansasWFSLayers.map((val) => {
        SAfetchWfsLayerReq(
          filterDetails[val].layerSource,
          filterDetails[val].layerFeature,
          filtervalues.potentialstorageLesserValue === 100 ? filterDetails[val].layerFilter : filterDetails[val].layerFilternew ? filterDetails[val].layerFilternew : filterDetails[val].layerFilter,
          filterDetails[val].layerVisible,
          filterDetails[val].layerTitle,
          filterDetails[val].layerColor,
          filterDetails[val].layerOpacity,
          filterDetails[val].layerZindex
        );
      });

      //20 mt
      let filter;
      if (filtervalues.potentialstorageLesserValue === 100) {
        filter = filterDetails.Volumetrics100MillTons.layerFilter;
      } else {
        filter = filterDetails.Volumetrics100MillTons.layerFilternew;
      }
      fetchWfsLayerReq(
        filterDetails.Volumetrics100MillTons.layerSource,
        filterDetails.Volumetrics100MillTons.layerFeature,
        filter,
        filterDetails.Volumetrics100MillTons.layerVisible,
        filterDetails.Volumetrics100MillTons.layerTitle,
        filterDetails.Volumetrics100MillTons.layerColor,
        filterDetails.Volumetrics100MillTons.layerOpacity,
        5
      );

      let SAfilter;
      if (filtervalues.potentialstorageLesserValue === 100) {
        SAfilter = filterDetails.SAVolumetricsGreater50.layerFilter;
      } else {
        SAfilter = filterDetails.SAVolumetricsGreater50.layerFilternew;
      }
      SAfetchWfsLayerReq(
        filterDetails.SAVolumetricsGreater50.layerSource,
        filterDetails.SAVolumetricsGreater50.layerFeature,
        SAfilter,
        filterDetails.SAVolumetricsGreater50.layerVisible,
        filterDetails.SAVolumetricsGreater50.layerTitle,
        filterDetails.SAVolumetricsGreater50.layerColor,
        filterDetails.SAVolumetricsGreater50.layerOpacity,
        5
      );
    }
  }, [genaccesToken, filtervalues.applyEnable, defaultStore, roleAccess]);
  //GC Storage Layers fetchWfsLayerReq functions End

  //======================Legend Color Pallete Change functions Start============================

  //LegendColorChange

  useEffect(() => {
    var colorUpdate;

    const pointLayers = ["PelletPlants"];
    if (pointLayers.includes(newcolorlayerTitle)) {
      colorUpdate = filterDetails[newcolorlayerTitle];
    } else if (newcolorlayerTitle !== undefined) {
      colorUpdate = filterDetails[newcolorlayerTitle];
    }
    if (colorUpdate !== undefined) {
      let legendColorPara = document.querySelector("#" + newcolorlayerTitle);
      let legendParagraph = legendColorPara.childNodes[1];

      if (pointLayers.includes(newcolorlayerTitle)) {
        legendParagraph.style.backgroundColor = legendSelectedColor;
      } else if (
        config.staticInformation.volumetricLayerTitles.includes(
          newcolorlayerTitle
        )
      ) {
        legendParagraph.style.backgroundColor = legendSelectedColor;
      } else {
        legendParagraph.style.borderColor = legendSelectedColor;
      }

      //convert hex to rgb to gett boundaries in polygons
      var resultRGB = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        legendSelectedColor
      );
      var res = resultRGB
        ? {
            r: parseInt(resultRGB[1], 16),
            g: parseInt(resultRGB[2], 16),
            b: parseInt(resultRGB[3], 16),
          }
        : null;
      var legendSelectedColorRGB =
        "rgb(" +
        res["r"] +
        "," +
        res["g"] +
        "," +
        res["b"] +
        "," +
        colorUpdate.layerOpacity +
        ")";
      const fetchWfsLayerReq = (
        layerSource,
        layerFeature,
        layerFilter,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity
      ) => {
        map.getLayers().forEach((layer) => {
          if (layer !== undefined) {
            let lyrTitle = layer.get("title");
            if (lyrTitle === layerTitle) {
              layerOpacity = layer.getOpacity();
              map.removeLayer(layer);
            }
          }
        });
        if (filtervalues.filterApplied === true) {
          const baseMapTitles = ["Streets", "LightGreyCanvas", "ImageryLabel"];
          map.getLayers().forEach((layer) => {
            if (layer !== undefined) {
              let lyrTitle = layer.get("title");
              if (!baseMapTitles.includes(lyrTitle)) {
                map.removeLayer(layer);
                FilterDetails.updatelayerVisibleStore("filterApplied", false);
                FilterDetails.updatelayerVisibleStore("applyEnable", false);
              }
            }
          });
        }

        // Create a pattern
        const patternCanvas = document.createElement("canvas");
        const patternContext = patternCanvas.getContext("2d");

        patternCanvas.width = 7;
        patternCanvas.height = 10;

        patternContext.beginPath();
        patternContext.fillStyle = layerColor;
        patternContext.fillRect(
          0,
          0,
          patternCanvas.width,
          patternCanvas.height
        );
        patternContext.moveTo(7, 0);
        patternContext.lineTo(0, 10);
        patternContext.lineCap = "square";
        patternContext.strokeStyle = "#666";
        patternContext.lineWidth = 1;
        patternContext.stroke();

        // Create our primary canvas and fill it with the pattern
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const pattern = ctx.createPattern(patternCanvas, "repeat");

        layerSource = new VectorSource();
        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: pattern,
            }),
          }),
        });

        layerFeature.setOpacity(layerOpacity);

        if (
          !config.staticInformation.volumetricLayerTitles.includes(layerTitle)
        ) {
          let lyrStyle = new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: "transparent",
            }),
          });
          layerFeature.setStyle(lyrStyle);
          layerFeature.setOpacity(layerOpacity);
        }

        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: ["gc_storage_resources"],
          outputFormat: "application/json",
          filter: layerFilter,
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            const features = new GeoJSON().readFeatures(json);
            layerSource.addFeatures(features);
            map.addLayer(layerFeature);
            if (layerVisible) {
              layerFeature.setVisible(true);
            } else {
              layerFeature.setVisible(false);
            }
          });
      };

      const SAfetchWfsLayerReq = (
        layerSource,
        layerFeature,
        layerFilter,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity
      ) => {
        map.getLayers().forEach((layer) => {
          if (layer !== undefined) {
            let lyrTitle = layer.get("title");
            if (lyrTitle === layerTitle) {
              layerOpacity = layer.getOpacity();
              map.removeLayer(layer);
            }
          }
        });
        if (filtervalues.filterApplied === true) {
          const baseMapTitles = ["Streets", "LightGreyCanvas", "ImageryLabel"];
          map.getLayers().forEach((layer) => {
            if (layer !== undefined) {
              let lyrTitle = layer.get("title");
              if (!baseMapTitles.includes(lyrTitle)) {
                map.removeLayer(layer);
                FilterDetails.updatelayerVisibleStore("filterApplied", false);
                FilterDetails.updatelayerVisibleStore("applyEnable", false);
              }
            }
          });
        }

        // Create a pattern
        const patternCanvas = document.createElement("canvas");
        const patternContext = patternCanvas.getContext("2d");

        patternCanvas.width = 7;
        patternCanvas.height = 10;

        patternContext.beginPath();
        patternContext.fillStyle = layerColor;
        patternContext.fillRect(
          0,
          0,
          patternCanvas.width,
          patternCanvas.height
        );
        patternContext.moveTo(7, 0);
        patternContext.lineTo(0, 10);
        patternContext.lineCap = "square";
        patternContext.strokeStyle = "#666";
        patternContext.lineWidth = 1;
        patternContext.stroke();

        // Create our primary canvas and fill it with the pattern
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const pattern = ctx.createPattern(patternCanvas, "repeat");

        layerSource = new VectorSource();
        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: pattern,
            }),
          }),
        });

        layerFeature.setOpacity(layerOpacity);

        if (
          !config.staticInformation.volumetricLayerTitles.includes(layerTitle)
        ) {
          let lyrStyle = new Style({
            stroke: new Stroke({
              color: layerColor,
              width: 2,
            }),
            fill: new Fill({
              color: "transparent",
            }),
          });
          layerFeature.setStyle(lyrStyle);
          layerFeature.setOpacity(layerOpacity);
        }

        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: ["southern_arkansas_su_pool_polygons"],
          outputFormat: "application/json",
          filter: layerFilter,
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            const features = new GeoJSON().readFeatures(json);
            layerSource.addFeatures(features);
            map.addLayer(layerFeature);
            if (layerVisible) {
              layerFeature.setVisible(true);
            } else {
              layerFeature.setVisible(false);
            }
          });
      };

      const fetchWfsPointLayerReq = (
        layerSource,
        layerFeature,
        layerVisible,
        layerTitle,
        layerColor,
        layerOpacity
      ) => {
        layerSource = new VectorSource();
        layerFeature = new VectorLayer({
          source: layerSource,
          title: layerTitle,
          style: new Style({
            image: new RegularShape({
              fill: new Fill({ color: layerColor }),
              stroke: new Stroke({ color: layerColor, width: 1 }),
              points: 4,
              radius: 9,
              angle: Math.PI / 4,
            }),
          }),
        });
        layerFeature.setOpacity(layerOpacity);

        let featureType = "epa_emitters";
        if (layerTitle === "PelletPlants")
          featureType = "wood_pellet_plants_2019";
        const featureRequest = new WFS().writeGetFeature({
          srsName: "EPSG:3857",
          featureNS: "http://openstreemap.org",
          featurePrefix: "osm",
          featureTypes: [featureType],
          outputFormat: "application/json",
        });

        fetch(`${config.endpoints.wfsLayerUrl}`, {
          method: "POST",
          body: new XMLSerializer().serializeToString(featureRequest),
          headers: {
            Authorization: "Bearer " + genaccesToken,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            var filteredjson = json;
            filteredjson.features = json.features.filter((item) => {
              if (item.properties.co2_emissions_value !== null) {
                return item;
              }
            });
            const features = new GeoJSON().readFeatures(filteredjson);
            layerSource.addFeatures(features);
            map
              .getLayers()
              .getArray()
              .filter((layer) => layer.get("title") === layerTitle)
              .forEach((layer) => map.removeLayer(layer));
            map.addLayer(layerFeature);
            layerFeature.setVisible(true);
          });
      };

      //emitter function

      if (pointLayers.includes(newcolorlayerTitle)) {
        fetchWfsPointLayerReq(
          colorUpdate.layerSource,
          colorUpdate.layerFeature,
          colorUpdate.layerVisible,
          colorUpdate.layerTitle,
          legendSelectedColor,
          colorUpdate.layerOpacity
        );
      } else {
        fetchWfsLayerReq(
          colorUpdate.layerSource,
          colorUpdate.layerFeature,
          colorUpdate.layerFilter,
          colorUpdate.layerVisible,
          colorUpdate.layerTitle,
          legendSelectedColorRGB,
          colorUpdate.layerOpacity
        );
        SAfetchWfsLayerReq(
          colorUpdate.layerSource,
          colorUpdate.layerFeature,
          colorUpdate.layerFilter,
          colorUpdate.layerVisible,
          colorUpdate.layerTitle,
          legendSelectedColorRGB,
          colorUpdate.layerOpacity
        );
      }
      setlegendSelectedColor(null);
      setnewcolorlayerTitle(null);
    }
  }, [legendSelectedColor, newcolorlayerTitle]);
  //======================Legend Color Pallete Change functions End============================

  //======================SummaryCard Popup Template functions Start============================
  useEffect(() => {
    document.getElementById("ccsAtlasbaseMap1").style.display = "none";
    if (map !== null && genaccesToken) {
      const pointFeatures = ["wood_pellet_plants_2019"];
      var highlightStylePoly = new Style({
        fill: new Fill({
          color: "transparent",
        }),
        stroke: new Stroke({
          color: "#3399CC",
          width: 5,
        }),
      });

      const image = new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: "red",
        }),
        stroke: new Stroke({ color: "red", width: 1 }),
      });

      const highlightStylePoint = new Style({
        image: new RegularShape({
          fill: new Fill({ color: "#666666" }),
          stroke: new Stroke({ color: "#bada55", width: 1 }),
          points: 4,
          radius: 9,
          angle: Math.PI / 4,
        }),
      });

      const highlightStyleCircle = new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#000080",
          }),
        }),
      });

      const highlightStyleLine = new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
      });
      let selected = null;

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: marker,
        }),
      });

      const vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      map.on("click", function (event) {
        const iconFeature = new Feature({
          geometry: new Point(event.coordinate),
          name: "Null Island",
        });
        iconFeature.setStyle(iconStyle);
        vectorSource.clear();
        map.removeLayer(vectorLayer);
        vectorSource.addFeature(iconFeature);
        vectorLayer.setZIndex(100);
        map.addLayer(vectorLayer);
        let wmsLayers = ["PropertyOwnership"];
        // let SalineMiocene = [
        //   "uppermiocene_storage",
        //   "uppermiocene_thickness",
        //   "uppermiocene_porosity",
        //   "uppermiocene_temp",
        //   "uppermiocene_press",
        //   "midmiocene_storage_new",
        //   "midmiocene_thickness_new",
        //   "midmiocene_porosity_new",
        //   "midmiocene_temp_new",
        //   "midmiocene_press_new",
        //   "lrmiocene_shallow_storage",
        //   "lrmiocene_shallow_thickness",
        //   "lrmiocene_shallow_porosity",
        //   "lrmiocene_shallow_temp",
        //   "lrmiocene_shallow_pressure",
        //   "lrmiocene_deep_storage",
        //   "lrmiocene_deep_thickness",
        //   "lrmiocene_deep_porosity",
        //   "lrmiocene_deep_temp",
        //   "lrmiocene_deep_press",
        //   "uppermiocene_perm",
        //   "midmiocene_perm",
        //   "lrmiocene_shallow_perm",
        //   "lrmiocene_deep_perm",
        //   "uppermiocene_salinity",
        //   "midmiocene_salinity",
        //   "lrmiocene_shallow_salinity",
        //   "lrmiocene_deep_salinity",

        //   // "gc_saline_upmiocene_sa_storage",
        //   // "gc_saline_upmiocene_sa_thickness"
        // ];
        let southernLayers = [
          "vicksburg_sa_storage",
          "vicksburg_sa_thickness",
          "vicksburg_porosity",
          "vicksburg_sa_permeability",
          "vicksburg_sa_temp",
          "vicksburg_sa_PorePressure",
          "vicksburg_sa_salinity",

          "claiborne_sa_storage",
          "claiborne_sa_thickness",
          "claiborne_porosity",
          "claiborne_sa_permeability",
          "claiborne_sa_temp",
          "claiborne_sa_porepressure",
          "claiborne_sa_salinity",

          "up_wilcox_sa_storage",
          "up_wilcox_sa_thickness",
          "up_wilcox_porosity",
          "up_wilcox_sa_permeability",
          "up_wilcox_sa_temp",
          "up_wilcox_sa_porepressure",
          "up_wilcox_sa_salinity",

          "lr_wilcox_sa_storage",
          "lr_wilcox_sa_thickness",
          "lr_wilcox_porosity",
          "lr_wilcox_sa_permeability",
          "lr_wilcox_sa_temp",
          "lr_wilcox_sa_porepressure",
          "lr_wilcox_sa_salinity",

          "Pettet_sa_storage",
          "Pettet_sa_thickness",
          "Pettet_porosity",
          "Pettet_sa_permeability",
          "Pettet_sa_temp",
          "Pettet_sa_porepressure",
          "Pettet_sa_salinity",

          "taylor_sa_storage",
          "taylor_sa_thickness",
          "taylor_porosity",
          "taylor_sa_permeability",
          "taylor_sa_temp",
          "taylor_sa_porepressure",
          "taylor_sa_salinity",

          "austin_sa_storage",
          "austin_sa_thickness",
          "austin_porosity",
          "austin_sa_permeability",
          "austin_sa_temp",
          "austin_sa_porepressure",
          "austin_sa_salinity",

          "lr_tusca_sa_storage",
          "lr_tuscaloosa_sa_thickness",
          "lr_tuscaloosa_porosity",
          "lr_tuscaloosa_sa_permeability",
          "lr_tuscaloosa_sa_temp",
          "lr_tuscaloosa_sa_porepressure",
          "lr_tuscaloosa_sa_salinity",

          "lr_cretaceous_sa_storage",
          "lr_cretaceous_sa_thickness",
          "lr_cretaceous_porosity",
          "lr_cretaceous_sa_permeability",
          "lr_cretaceous_sa_temp",
          "lr_cretaceous_sa_porepressure",
          "lr_cretaceous_sa_salinity",

          "paluxy_sa_storage",
          "paluxy_sa_thickness",
          "paluxy_porosity",
          "paluxy_sa_permeability",
          "paluxy_sa_temp",
          "paluxy_sa_porepressure",
          "paluxy_sa_salinity",

          "trinity_sa_storage",
          "trinity_sa_thickness",
          "trinity_porosity",
          "trinity_sa_permeability",
          "trinity_sa_temp",
          "trinity_sa_porepressure",
          "trinity_sa_salinity",

          "rodessa_sa_storage",
          "rodessa_sa_thickness",
          "rodessa_porosity",
          "rodessa_sa_permeability",
          "rodessa_sa_temp",
          "rodessa_sa_porepressure",
          "rodessa_sa_salinity",

          "sligo_sa_storage",
          "sligo_sa_thickness",
          "sligo_porosity",
          "sligo_sa_permeability",
          "sligo_sa_temp",
          "sligo_sa_porepressure",
          "sligo_sa_salinity",

          "hosston_travis_peak_sa_storage",
          "hosston_travis_peak_sa_thickness",
          "hosston_travis_peak_porosity",
          "hosston_travis_peak_sa_permeability",
          "hosston_travis_peak_sa_temp",
          "hosston_travis_peak_sa_porepressure",
          "hosston_travis_peak_sa_salinity",

          "cotton_valley_sa_storage",
          "cotton_valley_sa_thickness",
          "cotton_valley_porosity",
          "cotton_valley_sa_permeability",
          "cotton_valley_sa_temp",
          "cotton_valley_sa_porepressure",
          "cotton_valley_sa_salinity",

          "lrcottonvalley_sa_storage",
          "lrcottonvalley_sa_thickness",
          "lrcottonvalley_porosity",
          "lrcottonvalley_sa_permeability",
          "lrcottonvalley_sa_temp",
          "lrcottonvalley_sa_porepressure",
          "lrcottonvalley_sa_salinity",

          "bossier_sa_storage",
          "bossier_sa_thickness",
          "bossier_porosity",
          "bossier_sa_permeability",
          "bossier_sa_temp",
          "bossier_sa_porepressure",
          "bossier_sa_salinity",

          "haynesville_sa_storage",
          "haynesville_sa_thickness",
          "haynesville_porosity",
          "haynesville_sa_permeability",
          "haynesville_sa_temp",
          "haynesville_sa_porepressure",
          "haynesville_sa_salinity",

          "smackover_sa_storage",
          "smackover_sa_thickness",
          "smackover_porosity",
          "smackover_sa_permeability",
          "smackover_sa_temp",
          "smackover_sa_porepressure",
          "smackover_sa_salinity",

          "lr_smackover_sa_storage",
          "lr_smackover_sa_thickness",
          "lr_smackover_porosity",
          "lr_smackover_sa_permeability",
          "lr_smackover_sa_temp",
          "lr_smackover_sa_porepressure",
          "lr_smackover_sa_salinity",

          "norphlet_sa_storage",
          "norphlet_sa_thickness",
          "norphlet_porosity",
          "norphlet_sa_permeability",
          "norphlet_sa_temp",
          "norphlet_sa_porepressure",
          "norphlet_sa_salinity",

          "eagle_mills_sa_storage",
          "eagle_mills_sa_thickness",
          "eagle_mills_porosity",
          "eagle_mills_sa_permeability",
          "eagle_mills_sa_temp",
          "eagle_mills_sa_porepressure",
          "eagle_mills_sa_salinity",

          "midway_thickness_ft",
          "midway_permeability",

          "navarro_thickness_ft",
          "navarro_permeability",

          "mid_tuscaloosa_thickness_ft",
          "mid_tuscaloosa_permeability",

          "ferry_lake_thickness_ft",
          "ferry_lake_permeability",

          "buckner_thickness_ft",
          "buckner_permeability",

          "louann_thickness_ft",
          "louann_permeability",

          "gc_saline_upmiocene_sa_storage",
          "gc_saline_upmiocene_sa_thickness",
          "gc_saline_upmiocene_sa_porosity",
          "gc_saline_upmiocene_sa_permeability",
          "gc_saline_upmiocene_sa_temp",
          "gc_saline_upmiocene_sa_PorePressure",
          "gc_saline_upmiocene_sa_salinity",

          "gc_saline_midmiocene_sa_storage",
          "gc_saline_midmiocene_sa_thickness",
          "gc_saline_midmiocene_sa_porosity",
          "gc_saline_midmiocene_sa_permeability",
          "gc_saline_midmiocene_sa_temp",
          "gc_saline_midmiocene_sa_PorePressure",
          "gc_saline_midmiocene_sa_salinity",

          "gc_saline_lrmiocene_shallow_sa_storage",
          "gc_saline_lrmiocene_shallow_sa_thickness",
          "gc_saline_lrmiocene_shallow_sa_porosity",
          "gc_saline_lrmiocene_shallow_sa_permeability",
          "gc_saline_lrmiocene_shallow_sa_temp",
          "gc_saline_lrmiocene_shallow_sa_PorePressure",
          "gc_saline_lrmiocene_shallow_sa_salinity",

          "gc_saline_lrmiocene_deep_sa_storage",
          "gc_saline_lrmiocene_deep_sa_thickness",
          "gc_saline_lrmiocene_deep_sa_porosity",
          "gc_saline_lrmiocene_deep_sa_permeability",
          "gc_saline_lrmiocene_deep_sa_temp",
          "gc_saline_lrmiocene_deep_sa_PorePressure",
          "gc_saline_lrmiocene_deep_sa_salinity",



        ];
        let poolVolumetrics = [
          "PoolVolumetrics20",
          "PoolVolumetrics10to20",
          "PoolVolumetrics5to10",
          "PoolVolumetrics2_5to5",
          "PoolVolumetrics1to2_5",
          "PoolVolumetrics0_1to1",
          "PoolVolumetricsLess0_1",
          "PoolVolumetricsNA",
          "SAPoolVolumetricsGreater50",
          "SAPoolVolumetrics20to50",
          "SAPoolVolumetrics10to20",
          "SAPoolVolumetrics5to10",
          "SAPoolVolumetrics1to5",
          "SAPoolVolumetrics0_1to1",
          "SAPoolVolumetricsLess0_1",
          "SAPoolVolumetricsNA",
        ];

        let moreSummaryFeature = [];
        let selectedSummary = [];
        const feature = map.getFeaturesAtPixel(event.pixel);
        map.getLayers().forEach((lyr) => {
          let moreSummaryFeature = [];
          const feature = map.getFeaturesAtPixel(event.pixel);
          if (wmsLayers.includes(lyr.get("title"))) {
            if (lyr.getVisible() === true && map.getView().getZoom() >= 11) {
              let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");
              // basemapInfo.style.display = "block";
              // basemapInfo.innerHTML = '<div class="loader"></div>';
              const coordinate = event.coordinate;
              const proj4326 = transform(coordinate, "EPSG:3857", "EPSG:4326");
              const token = config.staticInformation.propertyOwnershipToken;
              const urlPopup =
                "https://app.regrid.com/api/v1/search.json?lat=" +
                proj4326[1] +
                "&lon=" +
                proj4326[0] +
                `&nearest=1&radius=2&token=${token}`;
              const getData = async () => {
                await axios
                  .get(urlPopup)
                  .then((res) => {
                    if (res.data.results.length > 0) {
                      basemapInfo.style.display = "none";
                      var feat;
                      res.data.results.forEach(function (arrayItem) {
                        if (arrayItem.geometry.type === "Polygon") {
                          basemapInfo.style.display = "block";
                          // basemapInfo.innerHTML = '<div class="loader"></div>';
                          feat = new Feature({
                            geometry: new Polygon(
                              arrayItem.geometry.coordinates
                            ),
                          });
                        } else if (arrayItem.geometry.type === "MultiPolygon") {
                          basemapInfo.style.display = "block";
                          // basemapInfo.innerHTML = '<div class="loader"></div>';
                          feat = new Feature({
                            geometry: new MultiPolygon(
                              arrayItem.geometry.coordinates
                            ),
                          });
                        } else {
                          basemapInfo.style.display = "none";
                          basemapInfo.innerHTML = "";
                        }
                        feat.getGeometry().transform("EPSG:4326", "EPSG:3857");
                        var polygonGeometry = feat.getGeometry();
                        if (
                          polygonGeometry.intersectsCoordinate(coordinate) ===
                          true
                        ) {
                          let resJSONData = arrayItem.properties.fields;
                          moreSummaryFeature.push(resJSONData);
                          summaryIndexLayer = 0;
                          summaryCaardPopupTempalte(
                            "Property Ownership Summary",
                            feature,
                            0,
                            moreSummaryFeature,
                            selected,
                            map
                          );
                          props.setIsSummaryOpen(true);
                        }
                      });
                    } else {
                      // if (basemapInfo.innerText.includes("Property Ownership Summary" || "")) {
                      //   basemapInfo.style.display = "none";
                      //   basemapInfo.innerHTML = '';
                      // }
                      // else {
                      //   // basemapInfo.innerHTML = '<div class="loader"></div>';
                      //   basemapInfo.style.display = "block";
                      // }
                    }
                  })

                  .catch((e) => {
                    // console.log(e)
                    basemapInfo.style.display = "none";
                    basemapInfo.innerHTML = "";
                  });
              };

              checkUsage();
              const stat = localStorage.getItem("stat");
              if (
                Number(stat) <
                config.staticInformation.propertyOwnershipUsedPercent
              ) {
                getData();
              } else {
                setalertText(
                  "You have gone over your allotted Property Ownership queries. Please contact the Carbon AXIOM team if you need additional queries."
                );
                setUsageModal(true);
              }
            }
          } else if (lyr.get("title") === "epa_emitters") {
            if (lyr.getVisible() === true) {
              const coordinate = event.coordinate;
              let wmsSource = lyr.getSource();
              const viewResolution = map.getView().getResolution();
              // const extent = boundingExtent([coordinate]);
              // buffer(extent, 2 / viewResolution);
              // const matches = wmsSource.getFeaturesInExtent(extent);
              // var srs = map.getProjection().code;
              const getFeatureInfoParams = {
                INFO_FORMAT: "application/json",
                // 'FEATURE_COUNT': 1,
                // 'SRS': srs,
                radius: 10,
              };
              let urlPopup = wmsSource.getFeatureInfoUrl(
                coordinate,
                viewResolution,
                "EPSG:3857",
                getFeatureInfoParams
              );
              const getEPAEmitterData = async () => {
                let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");

                await axios
                  .get(urlPopup, {
                    headers: { Authorization: `Bearer ${genaccesToken}` },
                  })
                  // .then((response) => response.text())
                  .then((res) => {
                    // map.getLayers().getArray()
                    // .filter(layer => layer.get('name') === 'saline_highlightfeature')
                    // .forEach(layer => map.removeLayer(layer));
                    let resJSONData = res.data.features;
                    if (resJSONData.length > 0) {
                      basemapInfo.style.display = "none";
                      moreSummaryFeature.push(resJSONData);
                      summaryIndexLayer = 999;
                      // summaryCaardPopupTempalte(
                      //   "EPA Emitter Summary",
                      //   feature,
                      //   0,
                      //   moreSummaryFeature
                      // );

                      summaryCaardPopupTempalte(
                        "Emitter Summary Card",
                        feature,
                        summaryIndexLayer,
                        moreSummaryFeature,
                        selected,
                        map
                      );

                      // const hightfeature=resJSONData[0];
                      // const source = new VectorSource({
                      //   features: new GeoJSON().readFeatures(hightfeature),
                      // });
                      // const layerFeatures = new VectorLayer({
                      //   source: source,
                      //   style: new Style({
                      //     stroke: new Stroke({
                      //       color: '#000',
                      //       width: 2,
                      //     }),
                      //     fill: new Fill({
                      //       color: "transparent",
                      //     }),
                      //   }),
                      // });
                      // layerFeatures.set('name', 'saline_highlightfeature');
                      // map.addLayer(layerFeatures);
                    } else {
                      // basemapInfo.style.display = "none";
                      // basemapInfo.innerHTML = '';
                    }
                  })
                  .catch((error) => {
                    // console.log(error)
                    basemapInfo.style.display = "none";
                    basemapInfo.innerHTML = "";
                  });
              };
              getEPAEmitterData();
            }
          } 
          // else if (SalineMiocene.includes(lyr.get("title"))) {
          //   if (lyr.getVisible() === true) {
          //     const coordinate = event.coordinate;
          //     let wmsSource = lyr.getSource();
          //     const viewResolution = map.getView().getResolution();
          //     let urlPopup = wmsSource.getFeatureInfoUrl(
          //       coordinate,
          //       viewResolution,
          //       "EPSG:3857",
          //       {
          //         INFO_FORMAT: "application/json",
          //       }
          //     );
          //     const getSalineData = async () => {
          //       let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");

          //       await axios
          //         .get(urlPopup, {
          //           headers: { Authorization: `Bearer ${genaccesToken}` },
          //         })
          //         .then((res) => {
          //           map
          //             .getLayers()
          //             .getArray()
          //             .filter(
          //               (layer) =>
          //                 layer.get("name") === "saline_highlightfeature"
          //             )
          //             .forEach((layer) => map.removeLayer(layer));
          //           let resJSONData = res.data.features;
          //           if (resJSONData.length > 0) {
          //             basemapInfo.style.display = "none";
          //             moreSummaryFeature.push(resJSONData);
          //             summaryIndexLayer = 0;
          //             summaryCaardPopupTempalte(
          //               "Saline Aquafier Summary",
          //               feature,
          //               0,
          //               moreSummaryFeature,
          //               null,
          //               null,
          //               wmsSource.params_.STYLES
          //             );

          //             const hightfeature = resJSONData[0];
          //             const source = new VectorSource({
          //               features: new GeoJSON().readFeatures(hightfeature),
          //             });
          //             const layerFeatures = new VectorLayer({
          //               source: source,
          //               style: new Style({
          //                 stroke: new Stroke({
          //                   color: "#000",
          //                   width: 2,
          //                 }),
          //                 fill: new Fill({
          //                   color: "transparent",
          //                 }),
          //               }),
          //             });
          //             layerFeatures.set("name", "saline_highlightfeature");
          //             map.addLayer(layerFeatures);
          //           } else {
          //             // basemapInfo.style.display = "none";
          //             // basemapInfo.innerHTML = '';
          //           }
          //         })
          //         .catch((error) => {
          //           // console.log(error)
          //           basemapInfo.style.display = "none";
          //           basemapInfo.innerHTML = "";
          //         });
          //     };
          //     getSalineData();
          //   }
          // } 
          else if (southernLayers.includes(lyr.get("title"))) {
            console.log(lyr.get("title"), "TITLE");
            if (lyr.getVisible() === true) {
              const coordinate = event.coordinate;
              let wmsSource = lyr.getSource();
              const viewResolution = map.getView().getResolution();
              let urlPopup = wmsSource.getFeatureInfoUrl(
                coordinate,
                viewResolution,
                "EPSG:3857",
                {
                  INFO_FORMAT: "application/json",
                }
              );
              const getSalineData = async () => {
                let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");

                await axios
                  .get(urlPopup, {
                    headers: { Authorization: `Bearer ${genaccesToken}` },
                  })
                  .then((res) => {
                    map
                      .getLayers()
                      .getArray()
                      .filter(
                        (layer) =>
                          layer.get("name") === "saline_highlightfeature"
                      )
                      .forEach((layer) => map.removeLayer(layer));
                    let resJSONData = res.data.features;
                    if (resJSONData.length > 0) {
                      basemapInfo.style.display = "none";
                      moreSummaryFeature.push(resJSONData);
                      summaryIndexLayer = 0;
                      summaryCaardPopupTempalte(
                        "Saline Aquafier Summary",
                        feature,
                        0,
                        moreSummaryFeature,
                        null,
                        null,
                        wmsSource.params_.STYLES
                      );

                      const hightfeature = resJSONData[0];
                      const source = new VectorSource({
                        features: new GeoJSON().readFeatures(hightfeature),
                      });
                      const layerFeatures = new VectorLayer({
                        source: source,
                        style: new Style({
                          stroke: new Stroke({
                            color: "#000",
                            width: 2,
                          }),
                          fill: new Fill({
                            color: "transparent",
                          }),
                        }),
                      });
                      layerFeatures.set("name", "saline_highlightfeature");
                      map.addLayer(layerFeatures);
                    } else {
                      // basemapInfo.style.display = "none";
                      // basemapInfo.innerHTML = '';
                    }
                  })
                  .catch((error) => {
                    // console.log(error)
                    basemapInfo.style.display = "none";
                    basemapInfo.innerHTML = "";
                  });
              };
              getSalineData();
            }
          } else if (poolVolumetrics.includes(lyr.get("title"))) {
            if (lyr.getVisible() === true) {
              const coordinate = event.coordinate;
              let wmsSource = lyr.getSource();
              const viewResolution = map.getView().getResolution();
              let urlPopup = wmsSource.getFeatureInfoUrl(
                coordinate,
                viewResolution,
                "EPSG:3857",
                {
                  INFO_FORMAT: "application/json",
                  FEATURE_COUNT: "1000",
                }
              );
              const getPoolData = async () => {
                let basemapInfo = document.querySelector("#ccsAtlasbaseMap1");
                let selectedSummaryFeature = [];
                await axios
                  .get(urlPopup, {
                    headers: { Authorization: `Bearer ${genaccesToken}` },
                  })
                  .then((res) => {
                    map.getLayers().getArray();
                    let resJSONData = res.data.features;
                    if (resJSONData.length > 0) {
                      basemapInfo.style.display = "none";
                      summaryIndexLayer = 0;
                      resJSONData.forEach((item) => {
                        selectedSummary.push(item);
                      });
                      selectedSummaryFeature.push(selectedSummary);
                      summaryCaardPopupTempalte(
                        "Storage resource pool summary card",
                        selectedSummary,
                        0,
                        selectedSummaryFeature,
                        "",
                        map
                      );
                    }
                  })
                  .catch((error) => {
                    basemapInfo.style.display = "none";
                    basemapInfo.innerHTML = "";
                  });
              };
              getPoolData();
            }
          }
        });
        let temp_ids = [];
        for (let x in feature) {
          if (feature[x].id_ !== undefined) {
            if (typeof feature[x].id_ === "number") {
              moreSummaryFeature.push(feature[x]);
            } else {
              if (feature[x].id_.split(".")[0] === "propertyOwnership") {
                if (!temp_ids.includes(feature[x].id_.split(".")[1])) {
                  moreSummaryFeature.push(feature[x]);
                  temp_ids.push(feature[x].id_.split(".")[1]);
                }
              } else moreSummaryFeature.push(feature[x]);
            }
          }
        }
        if (selected !== null) {
          if (selected !== undefined) {
            selected.setStyle(undefined);
            selected = null;
          }
        }
        let featureId = "";
        try {
          map.forEachFeatureAtPixel(event.pixel, function (f, layer) {
            selected = f;

            if (typeof selected.id_ === "number" && selected.getGeometry().getType() !== "LineString") {
              if (selected.getGeometry().getType() === "LineString")
                selected.setStyle(highlightStyleLine);
              else selected.setStyle(highlightStyleCircle);
              summaryIndexLayer = 0;
              summaryCaardPopupTempalte(
                "Summary Card",
                feature,
                summaryIndexLayer,
                moreSummaryFeature,
                selected,
                map
              );
              props.setIsSummaryOpen(true);
            } else {
              featureId = f.id_.split(".")[0];
              if (featureId === "wood_pellet_plants_2019") {
                f.setStyle(highlightStylePoint);
                summaryIndexLayer = 0;
                summaryCaardPopupTempalte(
                  "Pellet Plants Summary Card",
                  feature,
                  summaryIndexLayer,
                  moreSummaryFeature,
                  selected,
                  map
                );
                props.setIsSummaryOpen(true);
              } else if (featureId === "gc_storage_resources") {
                const lyr_color = layer.style_.fill_.color_;
                highlightStylePoly = new Style({
                  fill: new Fill({
                    color: lyr_color,
                  }),
                  stroke: new Stroke({
                    color: "#000",
                    width: 2,
                  }),
                });
                f.setStyle(highlightStylePoly);

                let list = [];
                map.forEachFeatureAtPixel(event.pixel, function (f1, layer1) {
                  f1.layer_color = layer1.style_.fill_.color_;
                  list.push(f1);
                });
                summaryCaardPopupTempalte(
                  "Storage Prospect Card Summary",
                  list,
                  0,
                  list,
                  list[0],
                  map
                );
                props.setIsSummaryOpen(true);
              } else if (featureId === "southern_arkansas_su_pool_polygons") {
                const lyr_color = layer.style_.fill_.color_;
                highlightStylePoly = new Style({
                  fill: new Fill({
                    color: lyr_color,
                  }),
                  stroke: new Stroke({
                    color: "#000",
                    width: 2,
                  }),
                });
                f.setStyle(highlightStylePoly);

                let list = [];
                map.forEachFeatureAtPixel(event.pixel, function (f1, layer1) {
                  f1.layer_color = layer1.style_.fill_.color_;
                  list.push(f1);
                });
                summaryCaardPopupTempalte(
                  "Storage Prospect Card Summary",
                  list,
                  0,
                  list,
                  list[0],
                  map
                );
                props.setIsSummaryOpen(true);
              }
            }
            return true;
          });
        } catch (e) {}

        if (feature.length >= 1) {
          if (
            featureId === "epa_emitters" ||
            featureId !== "protected_lands_southern_us_region"
          ) {
          } else {
            document.getElementById("ccsAtlasbaseMap1").style.display = "none";
          }
        }

        let summaryLabel = document.getElementById("arealabel");
        let summaryValue = document.getElementById("areavalue");
        let reservoirLabel = document.getElementById("reservoirlabel");
        let reservoirValue = document.getElementById("reservoirvalue");
        let depthLabel = document.getElementById("depthlabel");
        let depthValue = document.getElementById("depthvalue");
        let P10DepthLabel = document.getElementById("P10depthlabel");
        let P10DepthValue = document.getElementById("P10depthvalue");
        let P50DepthLabel = document.getElementById("P50depthlabel");
        let P50DepthValue = document.getElementById("P50depthvalue");
        let P90DepthLabel = document.getElementById("P90depthlabel");
        let P90DepthValue = document.getElementById("P90depthvalue");
        let P10ReservoirLabel = document.getElementById("P10reservoirlabel");
        let P10ReservoirValue = document.getElementById("P10reservoirvalue");
        let P50ReservoirLabel = document.getElementById("P50reservoirlabel");
        let P50ReservoirValue = document.getElementById("P50reservoirvalue");
        let P90ReservoirLabel = document.getElementById("P90reservoirlabel");
        let P90ReservoirValue = document.getElementById("P90reservoirvalue");

        if (summaryLabel && summaryValue) {
          if (document.getElementById("units").value === "metric") {
            if (summaryLabel && summaryLabel.innerHTML === "Area (acres)") {
              summaryLabel.innerHTML = "Area (sqkm)";
              summaryValue.innerHTML = summaryValue.innerHTML.replace(/,/g, "");
              summaryValue.innerHTML = Number(
                parseFloat(summaryValue.innerHTML / 247.1).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              reservoirLabel &&
              reservoirLabel.innerHTML === "Median Net Reservoir Thickness (ft)"
            ) {
              reservoirLabel.innerHTML = "Median Net Reservoir Thickness (m)";
              reservoirValue.innerHTML = reservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              reservoirValue.innerHTML = Number(
                parseFloat(reservoirValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              depthValue.innerHTML !== null &&
              depthValue.innerHTML !== undefined &&
              depthValue.innerHTML !== ""
            ) {
              if (depthLabel.innerHTML === "Median TVD Depth (ft)") {
                depthLabel.innerHTML = "Median TVD Depth (m)";
                // depthValue.innerHTML = (depthValue.innerHTML.replace(/,/g, ''))
                // depthValue.innerHTML = Number(parseFloat(depthValue.innerHTML / 3.28).toFixed(2)).toLocaleString("en-AU")
              }
            }
            if (
              P10DepthLabel &&
              P10DepthLabel.innerHTML === "P10 Low TVD Depth (ft)"
            ) {
              P10DepthLabel.innerHTML = "P10 Low TVD Depth (m)";
              P10DepthValue.innerHTML = P10DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P10DepthValue.innerHTML = Number(
                parseFloat(P10DepthValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P50DepthLabel &&
              P50DepthLabel.innerHTML === "P50 Median TVD Depth (ft)"
            ) {
              P50DepthLabel.innerHTML = "P50 Median TVD Depth (m)";
              P50DepthValue.innerHTML = P50DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P50DepthValue.innerHTML = Number(
                parseFloat(P50DepthValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P90DepthLabel &&
              P90DepthLabel.innerHTML === "P90 High TVD Depth (ft)"
            ) {
              P90DepthLabel.innerHTML = "P50 High TVD Depth (m)";
              P90DepthValue.innerHTML = P90DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P90DepthValue.innerHTML = Number(
                parseFloat(P90DepthValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P10ReservoirLabel &&
              P10ReservoirLabel.innerHTML ===
                "P10 Low Net Reservoir Thickness (ft)"
            ) {
              P10ReservoirLabel.innerHTML =
                "P10 Low Net Reservoir Thickness (m)";
              P10ReservoirValue.innerHTML = P10ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P10ReservoirValue.innerHTML = Number(
                parseFloat(P10ReservoirValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P50ReservoirLabel &&
              P50ReservoirLabel.innerHTML ===
                "P50 Median Net Reservoir Thickness (ft)"
            ) {
              P50ReservoirLabel.innerHTML =
                "P50 Median Net Reservoir Thickness (m)";
              P50ReservoirValue.innerHTML = P50ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P50ReservoirValue.innerHTML = Number(
                parseFloat(P50ReservoirValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P90ReservoirLabel &&
              P90ReservoirLabel.innerHTML ===
                "P90 High Net Reservoir Thickness (ft)"
            ) {
              P90ReservoirLabel.innerHTML =
                "P90 High Net Reservoir Thickness (m)";
              P90ReservoirValue.innerHTML = P90ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P90ReservoirValue.innerHTML = Number(
                parseFloat(P90ReservoirValue.innerHTML / 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
          }
          if (document.getElementById("units").value === "imperial") {
            if (summaryLabel.innerHTML === "Area (sqkm)") {
              summaryLabel.innerHTML = "Area (acres)";
              summaryValue.innerHTML = summaryValue.innerHTML.replace(/,/g, "");
              summaryValue.innerHTML = Number(
                parseFloat(summaryValue.innerHTML * 247.1).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              reservoirLabel &&
              reservoirLabel.innerHTML === "Median Net Reservoir Thickness (m)"
            ) {
              reservoirLabel.innerHTML = "Median Net Reservoir Thickness (ft)";
              reservoirValue.innerHTML = reservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              reservoirValue.innerHTML = Number(
                parseFloat(reservoirValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              depthValue &&
              depthValue.innerHTML !== null &&
              depthValue.innerHTML !== undefined &&
              depthValue.innerHTML !== ""
            ) {
              if (depthLabel.innerHTML === "Median TVD Depth (m)") {
                depthLabel.innerHTML = "Median TVD Depth (ft)";
                // depthValue.innerHTML = (depthValue.innerHTML.replace(/,/g, ''))
                // depthValue.innerHTML = Number(parseFloat(depthValue.innerHTML * 3.28).toFixed(2)).toLocaleString("en-AU")
              }
            }
            if (
              P10DepthLabel &&
              P10DepthLabel.innerHTML === "P10 Low TVD Depth (m)"
            ) {
              P10DepthLabel.innerHTML = "P10 Low TVD Depth (ft)";
              P10DepthValue.innerHTML = P10DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P10DepthValue.innerHTML = Number(
                parseFloat(P10DepthValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P50DepthLabel &&
              P50DepthLabel.innerHTML === "P50 Median TVD Depth (m)"
            ) {
              P50DepthLabel.innerHTML = "P50 Median TVD Depth (ft)";
              P50DepthValue.innerHTML = P50DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P50DepthValue.innerHTML = Number(
                parseFloat(P50DepthValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P90DepthLabel &&
              P90DepthLabel.innerHTML === "P90 High TVD Depth (m)"
            ) {
              P90DepthLabel.innerHTML = "P50 High TVD Depth (ft)";
              P90DepthValue.innerHTML = P90DepthValue.innerHTML.replace(
                /,/g,
                ""
              );
              P90DepthValue.innerHTML = Number(
                parseFloat(P90DepthValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P10ReservoirLabel &&
              P10ReservoirLabel.innerHTML ===
                "P10 Low Net Reservoir Thickness (m)"
            ) {
              P10ReservoirLabel.innerHTML =
                "P10 Low Net Reservoir Thickness (ft)";
              P10ReservoirValue.innerHTML = P10ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P10ReservoirValue.innerHTML = Number(
                parseFloat(P10ReservoirValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P50ReservoirLabel &&
              P50ReservoirLabel.innerHTML ===
                "P50 Median Net Reservoir Thickness (m)"
            ) {
              P50ReservoirLabel.innerHTML =
                "P50 Median Net Reservoir Thickness (ft)";
              P50ReservoirValue.innerHTML = P50ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P50ReservoirValue.innerHTML = Number(
                parseFloat(P50ReservoirValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
            if (
              P90ReservoirLabel &&
              P90ReservoirLabel.innerHTML ===
                "P90 High Net Reservoir Thickness (m)"
            ) {
              P90ReservoirLabel.innerHTML =
                "P90 High Net Reservoir Thickness (ft)";
              P90ReservoirValue.innerHTML = P90ReservoirValue.innerHTML.replace(
                /,/g,
                ""
              );
              P90ReservoirValue.innerHTML = Number(
                parseFloat(P90ReservoirValue.innerHTML * 3.28).toFixed(2)
              ).toLocaleString("en-AU");
            }
          }
        }
      });
    }
  }, [map, genaccesToken, props.isMeter, defaultStore]);
  //======================SummaryCard Popup Template functions Start============================

  useEffect(() => {
    props.setcsvData(dataFiltered);
  }, [dataFiltered, defaultStore]);
  //======================fnaddccsatlasLayerChange layer checkbox change functions Start============================
  const fnaddccsatlasLayerChange = (e, layerTitle) => {
    //Saline Aqufier Specific Code
    map
      .getLayers()
      .getArray()
      .filter(
        (layer) =>
          layer.get("name") === "saline_highlightfeature" ||
          layer.get("name") === "pool_highlightfeature"
      )
      .forEach((layer) => map.removeLayer(layer));
    let getallOptionValue = document.querySelectorAll(
      'input[groupName="salineGroupName"]'
    );
    if (e.currentTarget.id.includes("radio")) {
      getallOptionValue.forEach((val, i) => {
        if (getallOptionValue[i].checked === true) {
          let collection = document.querySelectorAll(".radiocheck");
          collection.forEach((ele) => (ele.style.display = "none"));    
          document.getElementById(
            getallOptionValue[i].getAttribute("visibleLegend")
          ).style.display = "block";
          if(  document.getElementById(
            getallOptionValue[i].getAttribute("visibleLegendImg")
          )){
            document.getElementById(
              getallOptionValue[i].getAttribute("visibleLegendImg")
            ).style.display = "block";
          }
          let existing = localStorage.getItem("DefaultValues");
          existing = existing ? JSON.parse(existing) : {};
          existing[layerTitle.replaceAll(" ", "_")] = true;
          localStorage.setItem("DefaultValues", JSON.stringify(existing));
        }
        if (getallOptionValue[i].checked === false) {
          if(  document.getElementById(
            getallOptionValue[i].getAttribute("visibleLegend")
          )){
            document.getElementById(
              getallOptionValue[i].getAttribute("visibleLegend")
            ).style.display = "none";
          }
          if(  document.getElementById(
            getallOptionValue[i].getAttribute("visibleLegendImg")
          )){
            document.getElementById(
              getallOptionValue[i].getAttribute("visibleLegendImg")
            ).style.display = "none";
          }
          let radioTitle = getallOptionValue[i].getAttribute("name");
          map.getLayers().forEach((layer) => {
            if (layer.get("title") === radioTitle) {
              layer.setVisible(false);
              document.getElementById(
                getallOptionValue[i].getAttribute("visibleLegend")
              ).style.display = "none";
              if(  document.getElementById(
                getallOptionValue[i].getAttribute("visibleLegendImg")
              )){
                document.getElementById(
                  getallOptionValue[i].getAttribute("visibleLegendImg")
                ).style.display = "none";
              }
              let existing = localStorage.getItem("DefaultValues");
              existing = existing ? JSON.parse(existing) : {};
              existing[radioTitle] = false;
              if (e.currentTarget.id.includes("radio") && e.currentTarget.parentElement.parentElement.parentElement.id === "southern_ar_ms_al_list") {
                existing[
                  getallOptionValue[
                    i
                  ].parentElement.parentElement.parentElement.children[0].id.replaceAll(
                    " ",
                    "_"
                  )
                ] = false;
              } else if (e.currentTarget.id.includes("radio")) {
                existing[
                  getallOptionValue[
                    i
                  ].parentElement.parentElement.parentElement.id.replaceAll(
                    " ",
                    "_"
                  )
                ] = false;
              }
              localStorage.setItem("DefaultValues", JSON.stringify(existing));
            }
          });
        }
      });
    }

    //Saline Aqufier Specific Code
    let currElement = e.currentTarget;
    let parntElem;
    let previousElement;
    let childrenList;
    if( currElement.parentElement.parentElement.parentElement.id === "southern_ar_ms_al_list") {
      parntElem = currElement.parentElement.parentElement.parentElement.childNodes[0];
    } else {
       parntElem = currElement.parentElement.parentElement.parentElement;
       previousElement = parntElem.previousSibling;
       
       if (previousElement !== null) childrenList = previousElement.childNodes[1];
    }
   

    let chkdArray = [];
    if( currElement.parentElement.parentElement.parentElement.id === "southern_ar_ms_al_list") {
      for (let j = 1; j < parntElem.parentElement.children.length; j++) {
        let chekedElem = parntElem.parentElement.children[j].childNodes[0].childNodes[1];
        if (chekedElem != undefined && chekedElem.checked === true) {
          chkdArray.push(chekedElem.checked);
        }
      }
    } else {
      for (let j = 0; j < parntElem.children.length; j++) {
        let chekedElem = parntElem.children[j].childNodes[0].childNodes[1];
        if (chekedElem != undefined && chekedElem.checked === true) {
          chkdArray.push(chekedElem.checked);
        }
      }
    }
    if (chkdArray.length > 0) {
      let existing = localStorage.getItem("DefaultValues");
      existing = existing ? JSON.parse(existing) : {};
      existing[parntElem.id.replaceAll(" ", "_")] = true;
      localStorage.setItem("DefaultValues", JSON.stringify(existing));
    } else {
      let existing = localStorage.getItem("DefaultValues");
      existing = existing ? JSON.parse(existing) : {};
      existing[parntElem.id.replaceAll(" ", "_")] = false;
      localStorage.setItem("DefaultValues", JSON.stringify(existing));
    }

    if (previousElement !== null && childrenList) {
      if (chkdArray.length === parntElem.children.length) {
        childrenList.checked = true;
        let parentNode = localStorage.getItem("DefaultValues");
        parentNode = parentNode ? JSON.parse(parentNode) : {};
        parentNode[childrenList.name] = true;
        parentNode[
          childrenList.id && childrenList.id.replaceAll(" ", "_")
        ] = true;
        localStorage.setItem("DefaultValues", JSON.stringify(parentNode));
      } else {
        if (childrenList !== undefined) {
          childrenList.checked = false;
          let parentNode = localStorage.getItem("DefaultValues");
          parentNode = parentNode ? JSON.parse(parentNode) : {};
          parentNode[childrenList.name] = false;
          parentNode[
            childrenList.id && childrenList.id.replaceAll(" ", "_")
          ] = false;
          localStorage.setItem("DefaultValues", JSON.stringify(parentNode));
        }
      }
    }
    if (e.currentTarget.checked) {
      map.getLayers().forEach((layer) => {
        if (layer.get("title") === layerTitle) {
          let getLegend = document.querySelector("#" + layerTitle);
          if (getLegend != null) {
            getLegend.style.display = "block";
          }
          layer.setVisible(true);
          FilterDetails.updatelayerVisibleStore(layerTitle, true);
          if (
            FilterDetails.ccsFilterStore[layerTitle] === false ||
            FilterDetails.ccsFilterStore[layerTitle] === undefined
          ) {
            layer.setVisible(true);
            let existing = localStorage.getItem("DefaultValues");
            existing = existing ? JSON.parse(existing) : {};
            existing[layerTitle.replaceAll(" ", "_")] = true;
            localStorage.setItem("DefaultValues", JSON.stringify(existing));
          }
        }
      });

      // if(e.currentTarget.parentNode.parentElement.parentElement.firstChild.id) {
      //   let existing = localStorage.getItem("DefaultValues");
      //       existing = existing ? JSON.parse(existing) : {};
      //       existing[e.currentTarget.parentNode.parentElement.parentElement.firstChild.id.replaceAll(" ", "_")] = true;
      //       localStorage.setItem("DefaultValues", JSON.stringify(existing));
      // }

    } else {
      map.getLayers().forEach((layer) => {
        if (layer.get("title") === layerTitle) {
          let getLegend = document.querySelector("#" + layerTitle);
          if (getLegend != null) {
            getLegend.style.display = "none";
          }
          layer.setVisible(false);
          FilterDetails.updatelayerVisibleStore(layerTitle, false);
          if (
            FilterDetails.ccsFilterStore[layerTitle] === true ||
            FilterDetails.ccsFilterStore[layerTitle] === undefined
          ) {
            layer.setVisible(false);
            let existing = localStorage.getItem("DefaultValues");
            existing = existing ? JSON.parse(existing) : {};
            existing[layerTitle.replaceAll(" ", "_")] = false;
            localStorage.setItem("DefaultValues", JSON.stringify(existing));
          }
        }
      });
    }
    setexportData(!exportData);
  };

  useEffect(() => {
    if (map) {
      csvObj = [];
      map.getLayers().forEach((layer) => {
        if (layer.getVisible()) {
          if (!WFSPointLayers.includes(layer.get("title"))) {
            try {
              csvObj.push(layer.getSource().getFeatures());
            } catch (e) {}
          }
        }
      });
      setdataFiltered(csvObj);
    }
  }, [exportData]);
  //======================fnaddccsatlasLayerChange layer checkbox change functions End============================

  //======================legendSliderChange change functions Start============================
  const legendSliderChange = (_e, newValue, layerTitle) => {
    map.getLayers().forEach((layer) => {
      if (layer.get("title") === layerTitle) {
        let sliderValue = newValue;

        let actualSliderValue = 100;
        let totalValue = Math.round(actualSliderValue - sliderValue);
        /*  if (layerTitle === 'PropertyOwnership')
         {
           const layerStyle = new Style({
             stroke: new Stroke({
               color: '#FF8C00',
               width: 2,
             }),
              fill: new Fill({
               color: [
                 255,
                 140,
                 0,
                 totalValue / 100,
               ],
             })
           });
           layer.setStyle(layerStyle);
         } */

        layer.setOpacity(totalValue / 100);

        let sliderSpan = document.querySelector("." + layerTitle);
        sliderSpan.innerText = sliderValue + "%";
        // legend
        let existing = localStorage.getItem("DefaultValues");
        existing = existing ? JSON.parse(existing) : {};
        existing[layerTitle + "_opacity_value"] = sliderValue;
        localStorage.setItem("DefaultValues", JSON.stringify(existing));
      }
    });
  };
  //======================legendSliderChange change functions End============================

  //======================toggleLayer change functions Start============================
  const toggleLayer = (_e) => {
    let drawerSideContent = document.querySelector("#drawercontent");
    if (props.infoData.layerObject.drawerEnable === false) {
      drawerSideContent.style.display = "block";

      props.setInfoData((prevState) => ({
        ...prevState,
        layerObject: {
          ...prevState.layerObject,
          layerWidth: "23%",
          mapBoxWidth: "100%",
          layerFloat: "left",
          layerHeight: "94.5vh",
          layerArrowLeft: "22%",
          layerArrow: arrowOpen,
          layerList: "block",
          drawerEnable: true,
          layerTop: "60px",
        },
      }));
    } else {
      drawerSideContent.style.display = "none";

      props.setInfoData((prevState) => ({
        ...prevState,
        layerObject: {
          ...prevState.layerObject,
          layerWidth: null,
          mapBoxWidth: null,
          layerFloat: null,
          layerHeight: null,
          layerArrowLeft: null,
          layerArrow: arrowClose,
          layerList: "none",
          drawerEnable: false,
          layerTop: null,
        },
      }));
    }
  };
  //======================toggleLayer change functions End============================

  //layerCollapsableChange  func start
  const layerCollapsableChange = (e) => {
    let tgslayersActive = e.currentTarget;
    const sibLen = getSiblings(e.currentTarget);
    let activelayers = tgslayersActive.classList.contains("layerActive");
    if (activelayers === true) {
      let currlayers = e.currentTarget;
      currlayers.classList.remove("layerActive");
      // currlayers.nextSibling.style.display = "none";
      // if (sibLen.length === 2) {
      //   currlayers.nextSibling.nextSibling.style.display = "none";
      // }
      sibLen.map((subList) => {
        console.log(subList.classList.contains("exportdiv"));
        if (subList.classList.contains("exportdiv") === false && subList.classList.contains("exportcustdiv") === false) {
          subList.style.display = "none";
        }
      });
    } else {
      let currlayers = e.currentTarget;
      currlayers.classList.add("layerActive");
      // currlayers.nextSibling.style.display = "block";
      // if (sibLen.length === 2) {
      //   currlayers.nextSibling.nextSibling.style.display = "block";
      // }
      sibLen.map((subList) => {
        subList.style.display = "block";
      });
    }
  };

  //layerCollapsableChange fun end

  const groupLayerToggle = (e) => {
    let currentElement = e.currentTarget;
    let parentChecked = currentElement.checked;
    let parentLayer = currentElement.parentElement;
    let childElement = parentLayer.nextSibling;
    if (childElement !== null) {
      let childList = childElement.children;

      for (let i = 0; i < childList.length; i++) {
        let firstchildClick = childList[i].childNodes[0];
        let firstchildSelected =
          childList[i].childNodes[0].childNodes[1].checked;

        if (parentChecked === false) {
          if (firstchildSelected === true) {
            firstchildClick.click();
          }
        }
        if (parentChecked === true) {
          if (firstchildSelected === false) {
            firstchildClick.click();
          }
        }
      }
    }
  };

  const rootGroupLayerToggle = (e) => {
    let currentElement = e.currentTarget;
    let parentChecked = currentElement.checked;
    let parentLayer = currentElement.parentElement;
    let childElement = parentLayer.nextSibling;
    if (childElement !== null) {
      let childList = childElement.children;

      for (let i = 0; i < childList.length; i++) {
        let firstchildClick = childList[i].childNodes[0].childNodes[0];
        let firstchildSelected =
          childList[i].childNodes[0].childNodes[0].childNodes[1].checked;

        if (parentChecked === false) {
          if (firstchildSelected === true) {
            firstchildClick.click();
          }
        }
        if (parentChecked === true) {
          if (firstchildSelected === false) {
            firstchildClick.click();
          }
        }
      }
    }
  };

  //TabChange function start
  const handleTabChange = (_event, newValue) => {
    settabValue(newValue);
    if (newValue === 0) {
      setlayerTabData(true);
      setlegendTabData(false);
      setlayerBlock("block");
      setlegendBlock("none");
    } else {
      setlayerTabData(false);
      setlegendTabData(true);
      setlayerBlock("none");
      setlegendBlock("block");
    }
  };
  //TabChange function End
  const layerBoxStyle = {
    width: props.infoData.layerObject.layerWidth,
    float: props.infoData.layerObject.layerFloat,
    height: props.infoData.layerObject.layerHeight,
    top: props.infoData.layerObject.layerTop,
    backgroundColor: "white",
    overflowY: "auto",
    overflowX: "hidden",
  };
  //Map Zoom Functionality Start
  const zoomPanelToggle = () => {
    setzoomState(!zoomState);
  };

  const defaultZoomView = (_e) => {
    setzoomItemText(6);
    setzoomState(false);
  };

  const zoomSingleItem = (e) => {
    let selectedZoom = e.currentTarget;
    setzoomItemText(selectedZoom.innerText);
    setzoomState(!zoomState);
    map.getView().setZoom(selectedZoom.innerText);
  };

  setTimeout(() => {
    customZoomIn.current.onclick = () => {
      let zoomlevel = document.querySelector("#mascvalue");
      let zoomtextlevel = zoomlevel.innerText;
      if (parseInt(zoomtextlevel) < 15) {
        map.getView().setZoom(map.getView().getZoom() + 1);
        setzoomItemText(parseInt(zoomtextlevel) + 1);
      }
    };

    customZoomOut.current.onclick = () => {
      let zoomoutlevel = document.querySelector("#mascvalue");
      let zoomouttextlevel = zoomoutlevel.innerText;
      if (parseInt(zoomouttextlevel) > 2) {
        map.getView().setZoom(map.getView().getZoom() - 1);
        setzoomItemText(parseInt(zoomouttextlevel) - 1);
      }
    };
    defaultZoomref.current.onclick = () => {
      map.getView().setZoom(6);
    };
  }, 1000);

  // For legend user saved settings
  // const handleColorChange = (e, name) => {
  //   console.log(e.currentTarget.style.backgroundColor, "color change");
  //   let existing = localStorage.getItem('DefaultValues');
  //           existing = existing ? JSON.parse(existing) : {};
  //           existing[name] = e.currentTarget.style.backgroundColor;
  //           localStorage.setItem('DefaultValues', JSON.stringify(existing));

  // }

  //Map Zoom Functionality End
  return (
    <>
      <Drawer
        BackdropProps={{ invisible: true }}
        open={drawerEnable}
        style={layerBoxStyle}
      >
        {drawerEnable === true ? (
          <div
            id="drawercontent"
            className="arlas-cs-scrollbar"
            data-name={drawerEnable}
          >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                label="Data Layers"
                className={` datalayertab ${
                  layerTabData ? "datatitle-active" : null
                }`}
              />
              {/* 16964 - removing legend tab, adding legend colors in data layer tab */}
              {/* <Tab
                label="Legend"
                className={` ${legendTabData ? "datatitle-active" : null}`}
              /> */}
            </Tabs>
            {map && <SelectQuery map={map} authorization={genaccesToken} />}
            {map && (
              <ImportShapefile map={map} setImportedFiles={setImportedFiles} />
            )}
            {fileImported && (
              <ul className="datalist" style={{ display: layerBlock }}>
                <li>
                  <span
                    onClick={(e) => layerCollapsableChange(e)}
                    className={
                      filtervalues.expand_ImportedShapefile
                        ? "collapseList layerActive"
                        : "collapseList"
                    }
                  >
                    Imported Shapefiles
                  </span>
                  <div
                    style={
                      filtervalues.expand_ImportedShapefile
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    className="collapseGroup"
                    /*  style={{ marginTop: "10px" }} */
                    id="importedshapefile"
                  >
                    <label className="arlas-checkbox">
                      Imported Shapefile
                      <input
                        type="checkbox"
                        checked={
                          JSON.parse(localStorage.getItem("DefaultValues"))[
                            "ImportedShapefile"
                          ]
                        }
                        name="ImportedShapefile"
                        id="ImportedShapefile"
                        onChange={(e) => groupLayerToggle(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <ul
                      className="tgscollapse-panel"
                      id="expand_ImportedShapefile"
                    >
                      {importedShapeFiles.map((file) => {
                        return (
                          <ImportedShapefileTemplate
                            fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
                            filename={file.filename}
                            removeImportedLayer={removeImportedLayer}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </li>
              </ul>
            )}
            {/* epa_emitters */}
            {/* Root Layer Component Start */}

            <ul
              className="datalist"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_BuildOut
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Potential AXIOM Buildouts
                </span>
                <div
                  className="collapseGroup"
                  style={
                    filtervalues.expand_BuildOut
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <ul
                    className="tgscollapse-panel"
                    style={{ marginLeft: "-4px" }}
                    id="expand_BuildOut"
                  >
                    <li>
                      <label className="arlas-checkbox icon-text">
                        Buildout Areas
                        <input
                          type="checkbox"
                          checked={
                            JSON.parse(localStorage.getItem("DefaultValues"))[
                              "BuildOut"
                            ]
                          }
                          name="BuildOut"
                          id="BuildOut2"
                          onChange={(e) =>
                            fnaddccsatlasLayerChange(e, "BuildOut")
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span
                        className="box"
                        style={{ backgroundColor: "#fff" }}
                      ></span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

            <RootLayers
              map={map}
              fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
              layerCollapsableChange={layerCollapsableChange}
              roleAccess={roleAccess}
              genaccesToken={genaccesToken}
            />

            {/* Root Layer Component end */}

            {/* Depleted Hydrocarbon Reservoirs start */}

            <ul
              className="datalist depleted_hydrocarbon_reservoirs"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={`${
                    filtervalues.expand_Volumetrics1 ||
                    filtervalues.expand_StorageResourcesPools1 ||
                    filtervalues.expand_Volumetrics ||
                    filtervalues.expand_StorageResourcesPools ||
                    filtervalues.extend_Formations1 ||
                    filtervalues.extend_Formations2 ||
                    filtervalues.extend_Formations3 ||
                    filtervalues.extend_Formations4 ||
                    filtervalues.extend_Formations5 ||
                    filtervalues.extend_Formations6 ||
                    filtervalues.extend_Formations7 ||
                    filtervalues.extend_Formations8 ||
                    filtervalues.SAextend_Formations1 ||
                    filtervalues.SAextend_Formations2 ||
                    filtervalues.SAextend_Formations3 ||
                    filtervalues.SAextend_Formations4 ||
                    filtervalues.SAextend_Formations5
                      ? "collapseList layerActive"
                      : "collapseList"
                  }`}
                >
                  Depleted Hydrocarbon Reservoirs
                </span>
                {
                  <ul
                    className="volumetrics1"
                    style={
                      filtervalues.expand_Volumetrics1 ||
                      filtervalues.expand_StorageResourcesPools1 ||
                      filtervalues.expand_Volumetrics ||
                      filtervalues.expand_StorageResourcesPools ||
                      filtervalues.extend_Formations1 ||
                      filtervalues.extend_Formations2 ||
                      filtervalues.extend_Formations3 ||
                      filtervalues.extend_Formations4 ||
                      filtervalues.extend_Formations5 ||
                      filtervalues.extend_Formations6 ||
                      filtervalues.extend_Formations7 ||
                      filtervalues.extend_Formations8 ||
                      filtervalues.SAextend_Formations1 ||
                      filtervalues.SAextend_Formations2 ||
                      filtervalues.SAextend_Formations3 ||
                      filtervalues.SAextend_Formations4 ||
                      filtervalues.SAextend_Formations5
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <li>
                      <span
                        onClick={(e) => layerCollapsableChange(e)}
                        className={`${
                          filtervalues.expand_Volumetrics1 ||
                          filtervalues.expand_StorageResourcesPools1 ||
                          filtervalues.SAextend_Formations1 ||
                          filtervalues.SAextend_Formations2 ||
                          filtervalues.SAextend_Formations3 ||
                          filtervalues.SAextend_Formations4 ||
                          filtervalues.SAextend_Formations5
                            ? "collapseList layerActive"
                            : "collapseList"
                        } ${
                          roleAccess.CA_AR_S_Depleted
                            ? "enableList"
                            : "disablelist"
                        }`}
                      >
                        Southern Arkansas
                        {roleAccess.CA_AR_S_Depleted != true && (
                          <span className="rspan">Not Subscribed</span>
                        )}
                      </span>

                      {roleAccess.CA_AR_S_Depleted && (
                        <ul
                          className="storage_pool_1"
                          style={
                            filtervalues.expand_Volumetrics1 ||
                            filtervalues.expand_StorageResourcesPools1 ||
                            filtervalues.SAextend_Formations1 ||
                            filtervalues.SAextend_Formations2 ||
                            filtervalues.SAextend_Formations3 ||
                            filtervalues.SAextend_Formations4 ||
                            filtervalues.SAextend_Formations5
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <span
                              onClick={(e) => layerCollapsableChange(e)}
                              className={`${
                                filtervalues.expand_Volumetrics1 ||
                                filtervalues.expand_StorageResourcesPools1 ||
                                filtervalues.SAextend_Formations1 ||
                                filtervalues.SAextend_Formations2 ||
                                filtervalues.SAextend_Formations3 ||
                                filtervalues.SAextend_Formations4 ||
                                filtervalues.SAextend_Formations5
                                  ? "collapseList layerActive"
                                  : "collapseList"
                              } ${
                                roleAccess.CA_GC_Onshore_Depleted
                                  ? "enableList"
                                  : "disablelist"
                              }`}
                            >
                              Pool/Storage Unit
                            </span>
                            <ul
                              className="storage_pool"
                              style={
                                filtervalues.expand_Volumetrics1 ||
                                filtervalues.expand_StorageResourcesPools1 ||
                                filtervalues.SAextend_Formations1 ||
                                filtervalues.SAextend_Formations2 ||
                                filtervalues.SAextend_Formations3 ||
                                filtervalues.SAextend_Formations4 ||
                                filtervalues.SAextend_Formations5
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <li>
                                {roleAccess.CA_EXPORT && (
                                  <ExportLayer
                                    genaccesToken={genaccesToken}
                                    filename="Southern AR Pools"
                                    layerData="southern_arkansas_pool_polygons"
                                  ></ExportLayer>
                                )}
                                {roleAccess.CA_AR_S_Depleted && (
                                  <div>
                                    <div
                                      className="collapseGroup"
                                      style={{
                                        marginTop: "10px",
                                        width: "85%",
                                      }}
                                    >
                                      <label className="arlas-checkbox">
                                        Pools
                                        <input
                                          type="checkbox"
                                          checked={
                                            JSON.parse(
                                              localStorage.getItem(
                                                "DefaultValues"
                                              )
                                            )["StorageResourcesPools1"]
                                          }
                                          name="StorageResourcesPools1"
                                          id="StorageResourcesPools12"
                                          onChange={(e) => groupLayerToggle(e)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      <ul
                                        className="tgscollapse-panel"
                                        id="expand_StorageResourcesPools1"
                                      >
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            {">50 Mt"}
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetricsGreater50"]
                                              }
                                              name="SAPoolVolumetricsGreater50"
                                              id="SAPoolVolumetricsGreater502"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetricsGreater50"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor: "rgb(255, 0, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            20-50 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetrics20to50"]
                                              }
                                              name="SAPoolVolumetrics20to50"
                                              id="SAPoolVolumetrics20to502"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetrics20to50"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(255, 170, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            10-20 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetrics10to20"]
                                              }
                                              name="SAPoolVolumetrics10to20"
                                              id="PoolVolumetrics5to102"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetrics10to20"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(255, 255, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            5-10 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetrics5to10"]
                                              }
                                              name="SAPoolVolumetrics5to10"
                                              id="SAPoolVolumetrics5to102"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetrics5to10"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(170, 255, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            1-5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetrics1to5"]
                                              }
                                              name="SAPoolVolumetrics1to5"
                                              id="SAPoolVolumetrics1to52"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetrics1to5"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(115, 222, 255)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            0.1-1 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetrics0_1to1"]
                                              }
                                              name="SAPoolVolumetrics0_1to1"
                                              id="SAPoolVolumetrics0_1to1"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetrics0_1to1"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor: "rgb(0, 0, 255)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            {"<" + 0.1 + " Mt"}
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetricsLess0_1"]
                                              }
                                              name="SAPoolVolumetricsLess0_1"
                                              id="SAPoolVolumetricsLess0_1"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetricsLess0_1"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(243, 79, 247)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            NA
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAPoolVolumetricsNA"]
                                              }
                                              name="SAPoolVolumetricsNA"
                                              id="SAPoolVolumetricsNA"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAPoolVolumetricsNA"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(127, 127, 127)",
                                            }}
                                          ></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </li>
                            </ul>
                            {/* storage unit */}
                            <ul
                              className="storage_unit1"
                              style={
                                filtervalues.expand_Volumetrics1 ||
                                filtervalues.expand_StorageResourcesPools1 ||
                                filtervalues.expand_StorageResourcesPools1 ||
                                filtervalues.SAextend_Formations1 ||
                                filtervalues.SAextend_Formations2 ||
                                filtervalues.SAextend_Formations3 ||
                                filtervalues.SAextend_Formations4 ||
                                filtervalues.SAextend_Formations5
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <li>
                                {roleAccess.CA_EXPORT && (
                                  <ExportLayer
                                    genaccesToken={genaccesToken}
                                    filename="Southern AR Storage Units"
                                    layerData="southern_arkansas_su_pool_polygons"
                                  ></ExportLayer>
                                )}
                                {roleAccess.CA_AR_S_Depleted && (
                                  <div>
                                    <div
                                      className="collapseGroup"
                                      style={{
                                        marginTop: "10px",
                                        width: "85%",
                                      }}
                                    >
                                      <label className="arlas-checkbox">
                                        Storage Units
                                        <input
                                          type="checkbox"
                                          checked={
                                            JSON.parse(
                                              localStorage.getItem(
                                                "DefaultValues"
                                              )
                                            )["Volumetrics1"]
                                          }
                                          name="Volumetrics1"
                                          id="Volumetrics1"
                                          onChange={(e) => groupLayerToggle(e)}
                                          //defaultChecked
                                        />
                                        <span className="checkmark"></span>
                                      </label>

                                      <ul
                                        className="tgscollapse-panel"
                                        id="expand_Volumetrics1"
                                      >
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            {">50 Mt"}
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetricsGreater50"]
                                              }
                                              name="SAVolumetricsGreater50"
                                              id="SAVolumetricsGreater502"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetricsGreater50"
                                                )
                                              }
                                              // defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          {/* <span className="box" style={{ 
                          background:"repeating-linear-gradient(-55deg,rgba(255, 0, 0,0.7),rgba(255, 0, 0,0.7) 2px,#666 1px,#666 4px)",
                            }}
                            >
                        </span> */}
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 0, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            20-50 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetrics20to50"]
                                              }
                                              name="SAVolumetrics20to50"
                                              id="SAVolumetrics20to502"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetrics20to50"
                                                )
                                              }
                                              //defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 170, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            10-20 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetrics10to20"]
                                              }
                                              name="SAVolumetrics10to20"
                                              id="SAVolumetrics10to202"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetrics10to20"
                                                )
                                              }
                                              //defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 255, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            5-10 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetrics5to10"]
                                              }
                                              name="SAVolumetrics5to10"
                                              id="SAVolumetrics5to102"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetrics5to10"
                                                )
                                              }
                                              // defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(170, 255, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            1-5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetrics1to5"]
                                              }
                                              name="SAVolumetrics1to5"
                                              id="SAVolumetrics1to52"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetrics1to5"
                                                )
                                              }
                                              //defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(115, 222, 255,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            0.1-1 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["SAVolumetrics0_1to1"]
                                              }
                                              name="SAVolumetrics0_1to1"
                                              id="SAVolumetrics0_1to1s"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "SAVolumetrics0_1to1"
                                                )
                                              }
                                              //defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(0, 0, 255,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </li>
                            </ul>

                            {roleAccess.CA_AR_S_Depleted && (
                              <ul
                                className="formations1"
                                style={
                                  filtervalues.expand_Volumetrics1 ||
                                  filtervalues.expand_StorageResourcesPools1 ||
                                  filtervalues.SAextend_Formations1 ||
                                  filtervalues.SAextend_Formations2 ||
                                  filtervalues.SAextend_Formations3 ||
                                  filtervalues.SAextend_Formations4 ||
                                  filtervalues.SAextend_Formations5
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <li>
                                  <span
                                    onClick={(e) => layerCollapsableChange(e)}
                                    className={`${
                                      filtervalues.SAextend_Formations1 ||
                                      filtervalues.SAextend_Formations2 ||
                                      filtervalues.SAextend_Formations3 ||
                                      filtervalues.SAextend_Formations4 ||
                                      filtervalues.SAextend_Formations5
                                        ? "collapseList layerActive"
                                        : "collapseList"
                                    } ${
                                      roleAccess.CA_AR_S_Depleted
                                        ? "enableList"
                                        : "disablelist"
                                    }`}
                                  >
                                    Storage Unit Formations
                                    {roleAccess.CA_AR_S_Depleted != true && (
                                      <span className="rspan">
                                        Not Subscribed
                                      </span>
                                    )}
                                  </span>

                                  <ul
                                    style={
                                      filtervalues.SAextend_Formations1 ||
                                      filtervalues.SAextend_Formations2 ||
                                      filtervalues.SAextend_Formations3 ||
                                      filtervalues.SAextend_Formations4 ||
                                      filtervalues.SAextend_Formations5
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <li>
                                      {roleAccess.CA_AR_S_Depleted && (
                                        <div id="SAextend_Formations11">
                                          <div
                                            className="collapseGroup"
                                            style={{ marginTop: "10px" }}
                                          >
                                            <label className="arlas-checkbox">
                                              All Formations
                                              <input
                                                type="checkbox"
                                                checked={
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["SATeritiaryEocene"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["SATeritiaryGulfian"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["SATeritiaryComanchean"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["SATeritiaryCoahuila"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["SAJurassicUpp"]
                                                }
                                                name="SAFormationRootLayer"
                                                id="SAFormationRootLayer"
                                                onChange={(e) =>
                                                  rootGroupLayerToggle(e)
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <ul
                                              className="tgscollapse-panel"
                                              id="SAextend_Formations1"
                                            >
                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Tertiary Eocene
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["SATeritiaryEocene"]
                                                      }
                                                      name="SATeritiaryEocene"
                                                      id="SATeritiaryEocene"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="SAextend_Formations1"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Wilcox
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["SAEoceneWILCOX"]
                                                          }
                                                          name="SAEoceneWILCOX"
                                                          id="SAwilcox"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SAEoceneWILCOX"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(68, 101, 137)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Gulfian
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["SATeritiaryGulfian"]
                                                      }
                                                      name="SATeritiaryGulfian"
                                                      id="SATeritiaryGulfian"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="SAextend_Formations2"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Navarro
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousUppGulfNAVARRO"
                                                            ]
                                                          }
                                                          name="SACretaceousUppGulfNAVARRO"
                                                          id="SAnavarro"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousUppGulfNAVARRO"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(170, 102, 205)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Taylor
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousTaylor"
                                                            ]
                                                          }
                                                          name="SACretaceousTaylor"
                                                          id="SATaylor"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousTaylor"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(102, 119, 205)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Austin
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousAustin"
                                                            ]
                                                          }
                                                          name="SACretaceousAustin"
                                                          id="SAAustin"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousAustin"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(205, 102, 153)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Tuscaloosa / Woodbine
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousUppGulfTUSCALOOSAWOODBINE"
                                                            ]
                                                          }
                                                          name="SACretaceousUppGulfTUSCALOOSAWOODBINE"
                                                          id="SAtuscaloosaWoodbine"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousUppGulfTUSCALOOSAWOODBINE"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(0, 197, 255)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Comanchean
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )[
                                                          "SATeritiaryComanchean"
                                                        ]
                                                      }
                                                      name="SATeritiaryComanchean"
                                                      id="SATeritiaryComanchean"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="SAextend_Formations3"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Goodland
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowComancheGoodland"
                                                            ]
                                                          }
                                                          name="SACretaceousLowComancheGoodland"
                                                          id="SAgoodLand"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowComancheGoodland"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 255, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Paluxy
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowComanchePaluxy"
                                                            ]
                                                          }
                                                          name="SACretaceousLowComanchePaluxy"
                                                          id="SAPaluxy"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowComanchePaluxy"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(115, 255, 223)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Rodessa-Glen Rose
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowComancheGLENROSE"
                                                            ]
                                                          }
                                                          name="SACretaceousLowComancheGLENROSE"
                                                          id="SAglenrose"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowComancheGLENROSE"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(115, 178, 255)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        James
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowComancheJames"
                                                            ]
                                                          }
                                                          name="SACretaceousLowComancheJames"
                                                          id="SAJames"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowComancheJames"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(0, 0, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Coahuila
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["SATeritiaryCoahuila"]
                                                      }
                                                      name="SATeritiaryCoahuila"
                                                      id="SATeritiaryCoahuila"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="SAextend_Formations4"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Sligo-Pettet
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowCoahuilaSLIGO"
                                                            ]
                                                          }
                                                          name="SACretaceousLowCoahuilaSLIGO"
                                                          id="SAsligo"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowCoahuilaSLIGO"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 0, 0)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Hosston/ Travis Peak
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SACretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                            ]
                                                          }
                                                          name="SACretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                          id="SAhosstonTravisPeak"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SACretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="collapseGroup">
                                                  <label className="arlas-checkbox">
                                                    Jurassic Upp
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["SAJurassicUpp"]
                                                      }
                                                      name="SAJurassicUpp"
                                                      id="SAJurassicUpp1"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="SAextend_Formations5"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Cotton Valley
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SAJurassicUppCottonValley"
                                                            ]
                                                          }
                                                          name="SAJurassicUppCottonValley"
                                                          id="SAcottonValley"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SAJurassicUppCottonValley"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(39, 79, 137)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Haynesville
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SAJurassicUppHaynesville"
                                                            ]
                                                          }
                                                          name="SAJurassicUppHaynesville"
                                                          id="SAhaynesville"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SAJurassicUppHaynesville"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(230, 152, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Smackover
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "SAJurassicUppSmackover"
                                                            ]
                                                          }
                                                          name="SAJurassicUppSmackover"
                                                          id="SASmackover"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "SAJurassicUppSmackover"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(137, 68, 101)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            )}
                          </li>
                        </ul>
                      )}
                    </li>

                    <li>
                      <span
                        onClick={(e) => layerCollapsableChange(e)}
                        className={`${
                          filtervalues.expand_Volumetrics ||
                          filtervalues.expand_StorageResourcesPools ||
                          filtervalues.extend_Formations1 ||
                          filtervalues.extend_Formations2 ||
                          filtervalues.extend_Formations3 ||
                          filtervalues.extend_Formations4 ||
                          filtervalues.extend_Formations5 ||
                          filtervalues.extend_Formations6 ||
                          filtervalues.extend_Formations7 ||
                          filtervalues.extend_Formations8
                            ? "collapseList layerActive"
                            : "collapseList"
                        } ${
                          roleAccess.CA_GC_Onshore_Depleted
                            ? "enableList"
                            : "disablelist"
                        }`}
                      >
                        Gulf Coast Eaglebine-TMS Area
                        {roleAccess.CA_GC_Onshore_Depleted != true && (
                          <span className="rspan">Not Subscribed</span>
                        )}
                      </span>

                      {roleAccess.CA_GC_Onshore_Depleted && (
                        <ul
                          className="storage_pool"
                          style={
                            filtervalues.expand_Volumetrics ||
                            filtervalues.expand_StorageResourcesPools ||
                            filtervalues.extend_Formations1 ||
                            filtervalues.extend_Formations2 ||
                            filtervalues.extend_Formations3 ||
                            filtervalues.extend_Formations4 ||
                            filtervalues.extend_Formations5 ||
                            filtervalues.extend_Formations6 ||
                            filtervalues.extend_Formations7 ||
                            filtervalues.extend_Formations8
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <span
                              onClick={(e) => layerCollapsableChange(e)}
                              className={`${
                                filtervalues.expand_Volumetrics ||
                                filtervalues.expand_StorageResourcesPools
                                  ? "collapseList layerActive"
                                  : "collapseList"
                              } ${
                                roleAccess.CA_GC_Onshore_Depleted
                                  ? "enableList"
                                  : "disablelist"
                              }`}
                            >
                              Pool/Storage Unit
                            </span>

                            {/* <li>
                      <span
                      onClick={(e) => layerCollapsableChange(e)}
                      className={`${filtervalues.expand_Volumetrics || filtervalues.expand_StorageResourcesPools ? 'collapseList layerActive' : 'collapseList'} ${roleAccess.CA_GC_Onshore_Depleted ? "enableList":"disablelist"}`}
                    >
                      Volumetrics
                      { (roleAccess.CA_GC_Onshore_Depleted != true) &&<span className="rspan">Not Subscribed</span>}
                    </span> */}
                            {/* resource pool */}
                            <ul
                              className="storage_pool"
                              style={
                                filtervalues.expand_Volumetrics ||
                                filtervalues.expand_StorageResourcesPools
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <li>
                                {roleAccess.CA_EXPORT && (
                                  <ExportLayer
                                    genaccesToken={genaccesToken}
                                    filename="Gulf Coast EGB-TMS Pools"
                                    layerData="gc_storage_resources_pools"
                                  ></ExportLayer>
                                )}
                                {roleAccess.CA_GC_Onshore_Depleted && (
                                  <div>
                                    <div
                                      className="collapseGroup"
                                      style={{
                                        marginTop: "10px",
                                        width: "85%",
                                      }}
                                    >
                                      <label className="arlas-checkbox">
                                        Pools
                                        <input
                                          type="checkbox"
                                          checked={
                                            JSON.parse(
                                              localStorage.getItem(
                                                "DefaultValues"
                                              )
                                            )["StorageResourcesPools"]
                                          }
                                          name="StorageResourcesPools"
                                          id="StorageResourcesPools2"
                                          onChange={(e) => groupLayerToggle(e)}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      <ul
                                        className="tgscollapse-panel"
                                        id="expand_StorageResourcesPools"
                                      >
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            20 Mt +
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics20"]
                                              }
                                              name="PoolVolumetrics20"
                                              id="PoolVolumetrics202"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics20"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor: "rgb(255, 0, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            10-20 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics10to20"]
                                              }
                                              name="PoolVolumetrics10to20"
                                              id="PoolVolumetrics10to202"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics10to20"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(255, 170, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            5-10 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics5to10"]
                                              }
                                              name="PoolVolumetrics5to10"
                                              id="PoolVolumetrics5to102"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics5to10"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(255, 255, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            2.5-5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics2_5to5"]
                                              }
                                              name="PoolVolumetrics2_5to5"
                                              id="PoolVolumetrics2_5to52"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics2_5to5"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(170, 255, 0)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            1-2.5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics1to2_5"]
                                              }
                                              name="PoolVolumetrics1to2_5"
                                              id="PoolVolumetrics1to2_52"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics1to2_5"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(115, 222, 255)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            0.1-1 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetrics0_1to1"]
                                              }
                                              name="PoolVolumetrics0_1to1"
                                              id="PoolVolumetrics0_1to1"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetrics0_1to1"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor: "rgb(0, 0, 255)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            {"<" + 0.1 + " Mt"}
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetricsLess0_1"]
                                              }
                                              name="PoolVolumetricsLess0_1"
                                              id="PoolVolumetricsLess0_1"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetricsLess0_1"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(243, 79, 247)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            NA
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["PoolVolumetricsNA"]
                                              }
                                              name="PoolVolumetricsNA"
                                              id="PoolVolumetricsNA"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "PoolVolumetricsNA"
                                                )
                                              }
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgb(127, 127, 127)",
                                            }}
                                          ></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </li>
                            </ul>
                            {/* storage unit */}
                            <ul
                              className="storage_unit"
                              style={
                                filtervalues.expand_Volumetrics ||
                                filtervalues.expand_StorageResourcesPools
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <li>
                                {roleAccess.CA_EXPORT && (
                                  <ExportLayer
                                    genaccesToken={genaccesToken}
                                    filename="Gulf Coast EGB-TMS Storage Units"
                                    layerData="gc_storage_resources"
                                  ></ExportLayer>
                                )}
                                {roleAccess.CA_GC_Onshore_Depleted && (
                                  <div>
                                    <div
                                      className="collapseGroup"
                                      style={{
                                        marginTop: "10px",
                                        width: "85%",
                                      }}
                                    >
                                      <label className="arlas-checkbox">
                                        Storage Units
                                        <input
                                          type="checkbox"
                                          checked={
                                            JSON.parse(
                                              localStorage.getItem(
                                                "DefaultValues"
                                              )
                                            )["Volumetrics"]
                                          }
                                          name="Volumetrics"
                                          id="Volumetrics"
                                          onChange={(e) => groupLayerToggle(e)}
                                          defaultChecked
                                        />
                                        <span className="checkmark"></span>
                                      </label>

                                      <ul
                                        className="tgscollapse-panel"
                                        id="expand_Volumetrics"
                                      >
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            20 Mt +
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["Volumetrics100MillTons"]
                                              }
                                              name="Volumetrics100MillTons"
                                              id="Volumetrics100MillTons2"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "Volumetrics100MillTons"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          {/* <span className="box" style={{ 
                          background:"repeating-linear-gradient(-55deg,rgba(255, 0, 0,0.7),rgba(255, 0, 0,0.7) 2px,#666 1px,#666 4px)",
                            }}
                            >
                        </span> */}
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 0, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            10-20 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["Volumetrics50to100"]
                                              }
                                              name="Volumetrics50to100"
                                              id="Volumetrics50to1002"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "Volumetrics50to100"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 170, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            5-10 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["Volumetrics15to50"]
                                              }
                                              name="Volumetrics15to50"
                                              id="Volumetrics15to502"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "Volumetrics15to50"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(255, 255, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            2.5-5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["VolumetricsSmall5to15"]
                                              }
                                              name="VolumetricsSmall5to15"
                                              id="VolumetricsSmall5to152"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "VolumetricsSmall5to15"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(170, 255, 0,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            1-2.5 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["VolumetricsMarginal5"]
                                              }
                                              name="VolumetricsMarginal5"
                                              id="VolumetricsMarginal52"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "VolumetricsMarginal5"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(115, 222, 255,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                        <li className="icon-list">
                                          <label className="arlas-checkbox icon-text">
                                            0.1-1 Mt
                                            <input
                                              type="checkbox"
                                              checked={
                                                JSON.parse(
                                                  localStorage.getItem(
                                                    "DefaultValues"
                                                  )
                                                )["Unclassified"]
                                              }
                                              name="Unclassified"
                                              id="Unclassifieds"
                                              onChange={(e) =>
                                                fnaddccsatlasLayerChange(
                                                  e,
                                                  "Unclassified"
                                                )
                                              }
                                              defaultChecked
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                          <span
                                            className="box"
                                            style={{
                                              backgroundColor:
                                                "rgba(0, 0, 255,0.7)",
                                            }}
                                          ></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </li>
                            </ul>

                            {roleAccess.CA_GC_Onshore_Depleted && (
                              <ul
                                className="formations"
                                style={
                                  filtervalues.expand_Volumetrics ||
                                  filtervalues.expand_StorageResourcesPools ||
                                  filtervalues.extend_Formations1 ||
                                  filtervalues.extend_Formations2 ||
                                  filtervalues.extend_Formations3 ||
                                  filtervalues.extend_Formations4 ||
                                  filtervalues.extend_Formations5 ||
                                  filtervalues.extend_Formations6 ||
                                  filtervalues.extend_Formations7 ||
                                  filtervalues.extend_Formations8
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <li>
                                  <span
                                    onClick={(e) => layerCollapsableChange(e)}
                                    className={`${
                                      filtervalues.extend_Formations1 ||
                                      filtervalues.extend_Formations2 ||
                                      filtervalues.extend_Formations3 ||
                                      filtervalues.extend_Formations4 ||
                                      filtervalues.extend_Formations5 ||
                                      filtervalues.extend_Formations6 ||
                                      filtervalues.extend_Formations7 ||
                                      filtervalues.extend_Formations8
                                        ? "collapseList layerActive"
                                        : "collapseList"
                                    } ${
                                      roleAccess.CA_GC_Onshore_Depleted
                                        ? "enableList"
                                        : "disablelist"
                                    }`}
                                  >
                                    Storage Unit Formations
                                    {roleAccess.CA_GC_Onshore_Depleted !=
                                      true && (
                                      <span className="rspan">
                                        Not Subscribed
                                      </span>
                                    )}
                                  </span>
                                  <ul
                                    style={
                                      filtervalues.extend_Formations1 ||
                                      filtervalues.extend_Formations2 ||
                                      filtervalues.extend_Formations3 ||
                                      filtervalues.extend_Formations4 ||
                                      filtervalues.extend_Formations5 ||
                                      filtervalues.extend_Formations6 ||
                                      filtervalues.extend_Formations7 ||
                                      filtervalues.extend_Formations8
                                        ? { display: "block" }
                                        : { display: "none" }
                                    }
                                  >
                                    <li>
                                      {roleAccess.CA_GC_Onshore_Depleted && (
                                        <div id="extend_Formations11">
                                          <div
                                            className="collapseGroup"
                                            style={{ marginTop: "10px" }}
                                          >
                                            <label className="arlas-checkbox">
                                              All Formations
                                              <input
                                                type="checkbox"
                                                checked={
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TertiaryMiocene"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TeritiaryOligocene"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TeritiaryEocene"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TertiaryPaleocene"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TeritiaryGulfian"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TeritiaryComanchean"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["TeritiaryCoahuila"] &&
                                                  JSON.parse(
                                                    localStorage.getItem(
                                                      "DefaultValues"
                                                    )
                                                  )["JurassicUpp"]
                                                }
                                                name="FormationRootLayer"
                                                id="FormationRootLayer"
                                                onChange={(e) =>
                                                  rootGroupLayerToggle(e)
                                                }
                                              />
                                              <span className="checkmark"></span>
                                            </label>

                                            <ul
                                              className="tgscollapse-panel"
                                              id="extend_Formations12"
                                            >
                                              <li>
                                                <div className="collapseGroup">
                                                  <label
                                                    className="arlas-checkbox top-icon-text"
                                                    id="extend_Formations1"
                                                  >
                                                    Tertiary Miocene
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TertiaryMiocene"]
                                                      }
                                                      name="TertiaryMiocene"
                                                      id="TeritiaryMiocene3"
                                                      onClick={(e) =>
                                                        fnaddccsatlasLayerChange(
                                                          e,
                                                          "TertiaryMiocene"
                                                        )
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <span
                                                    className="top-box"
                                                    style={{
                                                      border:
                                                        "2px solid rgb(0, 0, 255)",
                                                    }}
                                                  ></span>
                                                </div>
                                              </li>
                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Tertiary Oligocene
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TeritiaryOligocene"]
                                                      }
                                                      name="TeritiaryOligocene"
                                                      id="TeritiaryOligocene"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations2"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Frio
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["OligoceneFRIO"]
                                                          }
                                                          name="OligoceneFRIO"
                                                          id="frio"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "OligoceneFRIO"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 0, 255)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Vicksburg
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "OligoceneVICKSBURG"
                                                            ]
                                                          }
                                                          name="OligoceneVICKSBURG"
                                                          id="vicksburg"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "OligoceneVICKSBURG"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 85, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Other
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["OligoceneOther"]
                                                          }
                                                          name="OligoceneOther"
                                                          id="triogeneother"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "OligoceneOther"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(230, 76, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>

                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Tertiary Eocene
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TeritiaryEocene"]
                                                      }
                                                      name="TeritiaryEocene"
                                                      id="TeritiaryEocene"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations3"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Jackson
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["EoceneJACKSON"]
                                                          }
                                                          name="EoceneJACKSON"
                                                          id="jackson"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneJACKSON"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(39, 79, 137)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Claiborne
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["EoceneCLAIBORNE"]
                                                          }
                                                          name="EoceneCLAIBORNE"
                                                          id="Claiborne"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneCLAIBORNE"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(230, 152, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Yegua/ Cockfield
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "EoceneCOCKFIELDYEGUA"
                                                            ]
                                                          }
                                                          name="EoceneCOCKFIELDYEGUA"
                                                          id="cockFieldYegua"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneCOCKFIELDYEGUA"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(137, 68, 101)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Cook Mountain
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "EoceneCOOKMOUNTAIN"
                                                            ]
                                                          }
                                                          name="EoceneCOOKMOUNTAIN"
                                                          id="cookMountain"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneCOOKMOUNTAIN"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(112, 68, 137)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Queen City
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["EoceneQUEENCITY"]
                                                          }
                                                          name="EoceneQUEENCITY"
                                                          id="queencity"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneQUEENCITY"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(179 119 147)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Wilcox
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["EoceneWILCOX"]
                                                          }
                                                          name="EoceneWILCOX"
                                                          id="wilcox"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneWILCOX"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(68, 101, 137)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Other
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )["EoceneOTHER"]
                                                          }
                                                          name="EoceneOTHER"
                                                          id="ecoceneOther"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "EoceneOTHER"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(137, 112, 68)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>

                                              <li>
                                                <div className="collapseGroup">
                                                  <label
                                                    className="arlas-checkbox top-icon-text"
                                                    id="extend_Formations4"
                                                  >
                                                    Tertiary Paleocene
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TertiaryPaleocene"]
                                                      }
                                                      name="TertiaryPaleocene"
                                                      id="TertiaryPaleocene3"
                                                      onClick={(e) =>
                                                        fnaddccsatlasLayerChange(
                                                          e,
                                                          "TertiaryPaleocene"
                                                        )
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <span
                                                    className="top-box"
                                                    style={{
                                                      border:
                                                        "2px solid rgb(0, 0, 0)",
                                                    }}
                                                  ></span>
                                                </div>
                                              </li>

                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Gulfian
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TeritiaryGulfian"]
                                                      }
                                                      name="TeritiaryGulfian"
                                                      id="TeritiaryGulfian"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations5"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Navarro
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfNAVARRO"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfNAVARRO"
                                                          id="navarro"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfNAVARRO"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(170, 102, 205)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Olmos
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfOLMOS"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfOLMOS"
                                                          id="olmos"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfOLMOS"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(102, 119, 205)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        San Miguel
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfSANMIGUEL"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfSANMIGUEL"
                                                          id="sanMiguel"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfSANMIGUEL"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(102, 119, 205)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Austin
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfAUSTIN"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfAUSTIN"
                                                          id="austin"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfAUSTIN"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(205, 102, 153)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Eagle Ford
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfEAGLEFORD"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfEAGLEFORD"
                                                          id="eagleFord"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfEAGLEFORD"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(205, 102, 153)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Tuscaloosa / Woodbine
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfTUSCALOOSAWOODBINE"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfTUSCALOOSAWOODBINE"
                                                          id="tuscaloosaWoodbine"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfTUSCALOOSAWOODBINE"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(0, 197, 255)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Other
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousUppGulfOTHER"
                                                            ]
                                                          }
                                                          name="CretaceousUppGulfOTHER"
                                                          id="CretaceousUppGulfOTHER2"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousUppGulfOTHER"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(52, 52, 52)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>

                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Comanchean
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TeritiaryComanchean"]
                                                      }
                                                      name="TeritiaryComanchean"
                                                      id="TeritiaryComanchean"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations6"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Buda
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheBUDA"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheBUDA"
                                                          id="buda"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheBUDA"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(115, 255, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Georgetown
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheGEORGETOWN"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheGEORGETOWN"
                                                          id="georgeTown"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheGEORGETOWN"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Fredericksburg
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheFREDERICKSBURG"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheFREDERICKSBURG"
                                                          id="fredericksburg"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheFREDERICKSBURG"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Edwards
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheEDWARDS"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheEDWARDS"
                                                          id="edwards"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheEDWARDS"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(115, 178, 255)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Glen Rose
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheGLENROSE"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheGLENROSE"
                                                          id="glenrose"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheGLENROSE"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Pearsall
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComanchePEARSALL"
                                                            ]
                                                          }
                                                          name="CretaceousLowComanchePEARSALL"
                                                          id="pearsall"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComanchePEARSALL"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Other
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowComancheOTHER"
                                                            ]
                                                          }
                                                          name="CretaceousLowComancheOTHER"
                                                          id="CretaceousOther"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowComancheOTHER"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 255, 0)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>

                                              <li>
                                                <div
                                                  className="collapseGroup"
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  <label className="arlas-checkbox">
                                                    Cretaceous Coahuila
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["TeritiaryCoahuila"]
                                                      }
                                                      name="TeritiaryCoahuila"
                                                      id="TeritiaryCoahuila"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>

                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations7"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Sligo
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowCoahuilaSLIGO"
                                                            ]
                                                          }
                                                          name="CretaceousLowCoahuilaSLIGO"
                                                          id="sligo"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowCoahuilaSLIGO"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 0, 0)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Hosston/ Travis Peak
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                            ]
                                                          }
                                                          name="CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                          id="hosstonTravisPeak"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 115, 223)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>

                                              <li>
                                                <div className="collapseGroup">
                                                  <label className="arlas-checkbox">
                                                    Jurassic Upp
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "DefaultValues"
                                                          )
                                                        )["JurassicUpp"]
                                                      }
                                                      name="JurassicUpp"
                                                      id="JurassicUpp1"
                                                      onChange={(e) =>
                                                        groupLayerToggle(e)
                                                      }
                                                    />
                                                    <span className="checkmark"></span>
                                                  </label>
                                                  <ul
                                                    className="tgscollapse-panel"
                                                    id="extend_Formations8"
                                                  >
                                                    <li>
                                                      <label className="arlas-checkbox icon-text">
                                                        Cotton Valley
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "JurassicUppCottonValley"
                                                            ]
                                                          }
                                                          name="JurassicUppCottonValley"
                                                          id="cottonValley"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "JurassicUppCottonValley"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 127, 127)",
                                                        }}
                                                      ></span>
                                                    </li>

                                                    <li>
                                                      <label className="arlas-checkbox top-icon-text">
                                                        Haynesville
                                                        <input
                                                          type="checkbox"
                                                          checked={
                                                            JSON.parse(
                                                              localStorage.getItem(
                                                                "DefaultValues"
                                                              )
                                                            )[
                                                              "JurassicUppHaynesville"
                                                            ]
                                                          }
                                                          name="JurassicUppHaynesville"
                                                          id="haynesville"
                                                          onChange={(e) =>
                                                            fnaddccsatlasLayerChange(
                                                              e,
                                                              "JurassicUppHaynesville"
                                                            )
                                                          }
                                                        />
                                                        <span className="checkmark"></span>
                                                      </label>
                                                      <span
                                                        className="box"
                                                        style={{
                                                          border:
                                                            "2px solid rgb(255, 127, 127)",
                                                        }}
                                                      ></span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            )}
                          </li>
                        </ul>
                      )}
                    </li>
                  </ul>
                }
                {/* Formations      */}
              </li>
            </ul>

            {/* Depleted Hydrocarbon Reservoirs end */}

            <ul
              className="datalist"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_epa_emitters ||
                    filtervalues.expand_epa_emitters == undefined
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Emission Sources
                </span>
                <div
                  className="collapseGroup"
                  style={
                    filtervalues.expand_epa_emitters ||
                    filtervalues.expand_epa_emitters == undefined
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <ul
                    className="tgscollapse-panel"
                    id="expand_epa_emitters"
                    style={{ marginLeft: "-4px" }}
                  >
                    <li>
                      <label className="arlas-checkbox icon-text">
                        EPA Emitters
                        <input
                          checked={
                            filtervalues.epa_emitters != undefined
                              ? filtervalues.epa_emitters
                              : true
                          }
                          // checked = {filtervalues.epa_emitters}
                          type="checkbox"
                          name="epa_emitters"
                          id="epa_emitters1"
                          onChange={(e) =>
                            fnaddccsatlasLayerChange(e, "epa_emitters")
                          }
                          defaultChecked
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span
                        className="box"
                        style={{ backgroundColor: "rgb(0, 0, 0)" }}
                      ></span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

        
            {/* Property Ownership 
            
            <ul
              className="datalist"
              style={{ display: layerBlock }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_PropertyOwnership
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Property Ownership
                </span>
                <div
                  className="collapseGroup"
                 
                  style={
                    filtervalues.expand_PropertyOwnership
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <ul
                    className="tgscollapse-panel"
                    id="expand_PropertyOwnership"
                    style={{ marginLeft: "-4px" }}
                  >
                    <PropertyOwnership
                      map={map}
                      fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
                      layer={filterDetails.PropertyOwnership}
                      zoomItemText={zoomItemText}
                    />
                  </ul>
                </div>
              </li>
            </ul> */}
            {/*  Protected Lands */}
            <ul
              className="datalist"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_ProtectedLands
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Protected Lands
                </span>
                <div
                  className="collapseGroup"
                  style={
                    filtervalues.expand_ProtectedLands
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <ul
                    className="tgscollapse-panel"
                    style={{ marginLeft: "-4px" }}
                    id="expand_ProtectedLands"
                  >
                    <li>
                      <label className="arlas-checkbox icon-text">
                        Protected Lands
                        <input
                          type="checkbox"
                          checked={
                            JSON.parse(localStorage.getItem("DefaultValues"))[
                              "ProtectedLands"
                            ]
                          }
                          name="ProtectedLands"
                          id="ProtectedLands2"
                          onChange={(e) =>
                            fnaddccsatlasLayerChange(e, "ProtectedLands")
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span
                        className="box"
                        style={{
                          backgroundColor: "#cfa180",
                          border: "2px solid #bc8355",
                        }}
                      ></span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            {map && (
              <ForestLayers
                map={map}
                fnaddccsatlasLayerChange={fnaddccsatlasLayerChange}
                groupLayerToggle={groupLayerToggle}
                layerBlock={layerBlock}
                layerCollapsableChange={layerCollapsableChange}
                zoomItemText={zoomItemText}
              />
            )}
            <ul
              className="datalist"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_PelletPlants
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Pellet Plants
                </span>
                <div
                  style={
                    filtervalues.expand_PelletPlants
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div
                    className="collapseGroup"

                    /*  style={{ marginTop: "10px" }} */
                  >
                    <ul
                      className="tgscollapse-panel"
                      id="expand_PelletPlants"
                      style={{ marginLeft: "-4px" }}
                    >
                      <li>
                        <label className="arlas-checkbox icon-text">
                          Pellet Plants
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "PelletPlants"
                              ]
                            }
                            name="PelletPlants"
                            id="PelletPlants2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "PelletPlants")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="box"
                          style={{ backgroundColor: "rgb(103, 169, 207)" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>

            <ul className="datalist" style={{ display: layerBlock }}>
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_BOEM_Pipelines ||
                    filtervalues.expand_CO2_Pipelines ||
                    filtervalues.expand_EIA_Pipelines
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Pipeline and Transmission
                </span>
                {config.Pipelines.map((dataset) => {
                  return (
                    <div
                      className="testclass"
                      style={
                        filtervalues.expand_BOEM_Pipelines ||
                        filtervalues.expand_CO2_Pipelines ||
                        filtervalues.expand_EIA_Pipelines
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div
                        className="collapseGroup"
                        style={{ marginTop: "10px" }}
                        id={dataset.groupName}
                      >
                        <label className="arlas-checkbox">
                          {dataset.groupName}
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                dataset.groupName.replaceAll(" ", "_")
                              ]
                            }
                            name={dataset.lyrName}
                            id={dataset.groupName}
                            onChange={(e) => groupLayerToggle(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <ul
                          className="tgscollapse-panel"
                          id={"expand_" + dataset.groupName}
                        >
                          {dataset[dataset.groupName].source.map((file) => {
                            return (
                              <EIALayers
                                map={map}
                                fnaddccsatlasLayerChange={
                                  fnaddccsatlasLayerChange
                                }
                                layer={file}
                                zoomItemText={zoomItemText}
                              />
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </li>
            </ul>

            {config.EIADatasets.map((dataset) => {
              return (
                <ul
                  className="datalist"
                  style={{ display: layerBlock /* marginTop: "15px" */ }}
                >
                  <li>
                    <span
                      onClick={(e) => layerCollapsableChange(e)}
                      className={
                        filtervalues[
                          "expand_" + dataset.groupName.replaceAll(" ", "_")
                        ]
                          ? "collapseList layerActive"
                          : "collapseList"
                      }
                    >
                      {dataset.groupName}
                    </span>
                    <div
                      className="testclass"
                      style={
                        filtervalues[
                          "expand_" + dataset.groupName.replaceAll(" ", "_")
                        ]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div
                        className="collapseGroup"
                        style={{ marginTop: "10px" }}
                        id={dataset.groupName}
                      >
                        <label className="arlas-checkbox">
                          {dataset.groupName}
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                dataset.groupName.replaceAll(" ", "_")
                              ]
                            }
                            name={dataset.lyrName}
                            id={dataset.groupName}
                            onChange={(e) => groupLayerToggle(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <ul
                          className="tgscollapse-panel"
                          id={"expand_" + dataset.groupName}
                        >
                          {dataset[dataset.groupName].source.map((file) => {
                            return (
                              <EIALayers
                                map={map}
                                fnaddccsatlasLayerChange={
                                  fnaddccsatlasLayerChange
                                }
                                layer={file}
                                zoomItemText={zoomItemText}
                              />
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              );
            })}

            <ul
              className="datalist well_profile"
              style={{ display: layerBlock /* marginTop: "15px" */ }}
            >
              <li>
                <span
                  onClick={(e) => layerCollapsableChange(e)}
                  className={
                    filtervalues.expand_WellboreProfile ||
                    filtervalues.expand_WellTypes ||
                    filtervalues.expand_InjectorWells
                      ? "collapseList layerActive"
                      : "collapseList"
                  }
                >
                  Wells
                </span>
                <div
                  style={
                    filtervalues.expand_WellboreProfile ||
                    filtervalues.expand_WellTypes ||
                    filtervalues.expand_InjectorWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="collapseGroup" style={{ marginTop: "10px" }}>
                    <label className="arlas-checkbox">
                      Wellbore Profile
                      <input
                        type="checkbox"
                        checked={
                          JSON.parse(localStorage.getItem("DefaultValues"))[
                            "WellboreProfile"
                          ]
                        }
                        name="WellboreProfile"
                        id="WellboreProfile"
                        onChange={(e) => groupLayerToggle(e)}
                      />
                      <span className="checkmark"></span>
                    </label>

                    <ul
                      className="tgscollapse-panel"
                      id="expand_WellboreProfile"
                    >
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Deviated
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "Deviated"
                              ]
                            }
                            name="Deviated"
                            id="Deviated2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "Deviated")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="triange"
                          style={{ borderBottomColor: "rgb(0, 170, 171)" }}
                        ></span>
                      </li>
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Horizontal
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "Horizontal"
                              ]
                            }
                            name="Horizontal"
                            id="Horizontal2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "Horizontal")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="triange"
                          style={{ borderBottomColor: "rgb(0, 54, 96)" }}
                        ></span>
                      </li>
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Vertical
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "Vertical"
                              ]
                            }
                            name="Vertical"
                            id="Vertical2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "Vertical")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="triange"
                          style={{ borderBottomColor: "rgb(249, 160, 27)" }}
                        ></span>
                      </li>
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Unknown
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "Unknown"
                              ]
                            }
                            name="Unknown"
                            id="Unknown2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "Unknown")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="triange"
                          style={{ borderBottomColor: "rgb(170, 0, 113)" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="collapseGroup"
                    id="expand_WellTypes"
                    style={{ marginTop: "10px" }}
                  >
                    <label className="arlas-checkbox">
                      Well Types
                      <input
                        type="checkbox"
                        checked={
                          JSON.parse(localStorage.getItem("DefaultValues"))[
                            "WellTypes"
                          ]
                        }
                        name="WellTypes"
                        id="WellTypes"
                        onChange={(e) => groupLayerToggle(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <ul className="tgscollapse-panel" id="expand_WellTypes">
                      <li>
                        <label className="arlas-checkbox">
                          Injector Wells
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "InjectorWells"
                              ]
                            }
                            name="InjectorWells"
                            id="InjectorWells"
                            onChange={(e) => groupLayerToggle(e)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <ul
                          className="tgscollapse-panel"
                          id="expand_InjectorWells"
                        >
                          <li className="icon-list">
                            <label className="arlas-checkbox icon-text">
                              Disposal
                              <input
                                type="checkbox"
                                checked={
                                  JSON.parse(
                                    localStorage.getItem("DefaultValues")
                                  )["DisposalInjectorWells"]
                                }
                                name="DisposalInjectorWells"
                                id="DisposalInjectorWells2"
                                onChange={(e) =>
                                  fnaddccsatlasLayerChange(
                                    e,
                                    "DisposalInjectorWells"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span
                              className="circle"
                              style={{ backgroundColor: "rgb(0, 153, 255)" }}
                            ></span>
                          </li>
                          <li className="icon-list">
                            <label className="arlas-checkbox icon-text">
                              EOR
                              <input
                                type="checkbox"
                                checked={
                                  JSON.parse(
                                    localStorage.getItem("DefaultValues")
                                  )["EORInjectorWells"]
                                }
                                name="EORInjectorWells"
                                id="EORInjectorWells2"
                                onChange={(e) =>
                                  fnaddccsatlasLayerChange(
                                    e,
                                    "EORInjectorWells"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span
                              className="circle"
                              style={{ backgroundColor: "rgb(153, 0, 204)" }}
                            ></span>
                          </li>
                          <li className="icon-list">
                            <label className="arlas-checkbox icon-text">
                              Storage
                              <input
                                type="checkbox"
                                checked={
                                  JSON.parse(
                                    localStorage.getItem("DefaultValues")
                                  )["StorageInjectorWells"]
                                }
                                name="StorageInjectorWells"
                                id="StorageInjectorWells2"
                                onChange={(e) =>
                                  fnaddccsatlasLayerChange(
                                    e,
                                    "StorageInjectorWells"
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span
                              className="circle"
                              style={{ backgroundColor: "rgb(139, 157, 161)" }}
                            ></span>
                          </li>
                        </ul>
                      </li>
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Production Wells
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "ProductionWells"
                              ]
                            }
                            name="ProductionWells"
                            id="ProductionWells2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "ProductionWells")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="circle"
                          style={{ backgroundColor: "rgb(0, 0, 0)" }}
                        ></span>
                      </li>
                      <li className="icon-list">
                        <label className="arlas-checkbox icon-text">
                          Orphaned Wells
                          <input
                            type="checkbox"
                            checked={
                              JSON.parse(localStorage.getItem("DefaultValues"))[
                                "OrphanedWells"
                              ]
                            }
                            name="OrphanedWells"
                            id="OrphanedWells2"
                            onChange={(e) =>
                              fnaddccsatlasLayerChange(e, "OrphanedWells")
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span
                          className="circle"
                          style={{ backgroundColor: "rgb(255, 0, 0)" }}
                        ></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <div
              className="legendarea"
              style={{ display: legendBlock, marginTop: "15px" }}
            >
              <ul className="datalist">
                {importedShapeFiles.map((file) => {
                  return (
                    <li
                      id={file.filename}
                      style={
                        filtervalues[file.filename]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <h3>{file.filename}</h3>
                      <p className={"legenddataboxgeo " + file.legendType}>
                        <input
                          style={{
                            width: "24px",
                            marginLeft: "28px",
                            height: "25px",
                            border: "solid 1px white",
                            left: "-28px",
                            //backgroundColor: legendColorValue,
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          className="form-control withborder"
                          type="color"
                          name="color"
                          //  onChange={legendColorChange}
                        />
                        <span style={{ top: "0px" }}>{file.filename}</span>
                      </p>
                      <ul>
                        <li>
                          <Slider
                            id={file.filename + "Slider"}
                            min={0}
                            max={100}
                            step={1}
                            key={`slider-${
                              filtervalues[file.filename + "_opacity_value"]
                            }-${defaultStore}`}
                            defaultValue={
                              filtervalues[file.filename + "_opacity_value"] !=
                              undefined
                                ? filtervalues[file.filename + "_opacity_value"]
                                : 20
                            }
                            className="legendslider"
                            onChange={(e, newVal) =>
                              legendSliderChange(e, newVal, file.filename)
                            }
                            aria-labelledby="continuous-slider"
                          />

                          <span
                            style={{
                              color: "#00aaab",
                              fontSize: "20px",
                              paddingLeft: "5%",
                            }}
                            className={file.filename}
                          >
                            {filtervalues[file.filename + "_opacity_value"] !=
                            undefined
                              ? filtervalues[file.filename + "_opacity_value"]
                              : 20}
                            %
                          </span>
                        </li>
                      </ul>
                    </li>
                  );
                })}

                {datasetNames.map((file) => {
                  return (
                    <li
                      id={file.layerName}
                      style={
                        filtervalues[file.layerName]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <h3>{file.displayName}</h3>
                      <p className={"legenddataboxgeo"} style={file.stylClass}>
                        <input
                          style={{
                            width: "24px",
                            marginLeft: "28px",
                            height: "25px",
                            border: "solid 1px white",
                            left: "-28px",
                            //backgroundColor: legendColorValue,
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          className="form-control withborder"
                          type="color"
                          name="color"
                          //  onChange={legendColorChange}
                        />
                        <span style={{ top: "0px" }}>{file.displayName}</span>
                      </p>
                      <ul>
                        <li>
                          <Slider
                            id={file.layerName + "Slider"}
                            min={0}
                            max={100}
                            step={1}
                            key={`slider-${
                              filtervalues[file.layerName + "_opacity_value"]
                            }-${defaultStore}`}
                            defaultValue={
                              filtervalues[file.layerName + "_opacity_value"] !=
                              undefined
                                ? filtervalues[
                                    file.layerName + "_opacity_value"
                                  ]
                                : 20
                            }
                            className="legendslider"
                            onChange={(e, newVal) =>
                              legendSliderChange(e, newVal, file.layerName)
                            }
                            aria-labelledby="continuous-slider"
                          />

                          <span
                            style={{
                              color: "#00aaab",
                              fontSize: "20px",
                              paddingLeft: "5%",
                            }}
                            className={file.layerName}
                          >
                            {filtervalues[file.layerName + "_opacity_value"] !=
                            undefined
                              ? filtervalues[file.layerName + "_opacity_value"]
                              : 20}
                            %
                          </span>
                        </li>
                      </ul>
                    </li>
                  );
                })}

                <li
                  id="TertiaryMiocene"
                  style={
                    filtervalues.TertiaryMiocene
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Tertiary Miocene</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      // border: "Solid 3px #0000FF",
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "TertiaryMiocene_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "TertiaryMiocene_BG"
                            ]
                          : "#0000FF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Tertiary Miocene</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="TertiaryMioceneSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.TertiaryMiocene_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.TertiaryMiocene_opacity_value !=
                          undefined
                            ? filtervalues.TertiaryMiocene_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "TertiaryMiocene")
                        }
                        aria-labelledby="continuous-slider"
                      />

                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="TertiaryMiocene"
                      >
                        {filtervalues.TertiaryMiocene_opacity_value != undefined
                          ? filtervalues.TertiaryMiocene_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="OligoceneFRIO"
                  style={
                    filtervalues.OligoceneFRIO
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Frio</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #FF00FF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "OligoceneFRIO_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "OligoceneFRIO_BG"
                            ]
                          : "#FF00FF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Frio</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="OligoceneFRIOSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.OligoceneFRIO_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.OligoceneFRIO_opacity_value != undefined
                            ? filtervalues.OligoceneFRIO_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "OligoceneFRIO")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="OligoceneFRIO"
                      >
                        {filtervalues.OligoceneFRIO_opacity_value != undefined
                          ? filtervalues.OligoceneFRIO_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="OligoceneVICKSBURG"
                  style={
                    filtervalues.OligoceneVICKSBURG
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Vicksburg</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #FF5500" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "OligoceneVICKSBURG_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "OligoceneVICKSBURG_BG"
                            ]
                          : "#FF5500"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Vicksburg</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="OligoceneVICKSBURGSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.OligoceneVICKSBURG_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.OligoceneVICKSBURG_opacity_value !=
                          undefined
                            ? filtervalues.OligoceneVICKSBURG_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "OligoceneVICKSBURG")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="OligoceneVICKSBURG"
                      >
                        {filtervalues.OligoceneVICKSBURG_opacity_value !=
                        undefined
                          ? filtervalues.OligoceneVICKSBURG_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="OligoceneOther"
                  style={
                    filtervalues.OligoceneOther
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Other</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #E64C00" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "OligoceneOther_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "OligoceneOther_BG"
                            ]
                          : "#E64C00"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Other</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="OligoceneOtherSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.OligoceneOther_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.OligoceneOther_opacity_value != undefined
                            ? filtervalues.OligoceneOther_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "OligoceneOther")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="OligoceneOther"
                      >
                        {filtervalues.OligoceneOther_opacity_value != undefined
                          ? filtervalues.OligoceneOther_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneCLAIBORNE"
                  style={
                    filtervalues.EoceneCLAIBORNE
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Claiborne</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #E69800" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneCLAIBORNE_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneCLAIBORNE_BG"
                            ]
                          : "#E69800"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Claiborne</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneCLAIBORNESlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneCLAIBORNE_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneCLAIBORNE_opacity_value !=
                          undefined
                            ? filtervalues.EoceneCLAIBORNE_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneCLAIBORNE")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneCLAIBORNE"
                      >
                        {filtervalues.EoceneCLAIBORNE_opacity_value != undefined
                          ? filtervalues.EoceneCLAIBORNE_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneQUEENCITY"
                  style={
                    filtervalues.EoceneQUEENCITY
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Queen City</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #E69800" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneQUEENCITY_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneQUEENCITY_BG"
                            ]
                          : "#E69800"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Queen City</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneQUEENCITYSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneQUEENCITY_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneQUEENCITY_opacity_value !=
                          undefined
                            ? filtervalues.EoceneQUEENCITY_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneQUEENCITY")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneQUEENCITY"
                      >
                        {filtervalues.EoceneQUEENCITY_opacity_value != undefined
                          ? filtervalues.EoceneQUEENCITY_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneCOCKFIELDYEGUA"
                  style={
                    filtervalues.EoceneCOCKFIELDYEGUA
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Cockfield</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #894465" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneCOCKFIELDYEGUA_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneCOCKFIELDYEGUA_BG"
                            ]
                          : "#894465"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Cockfield</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneCOCKFIELDYEGUASlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneCOCKFIELDYEGUA_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneCOCKFIELDYEGUA_opacity_value !=
                          undefined
                            ? filtervalues.EoceneCOCKFIELDYEGUA_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneCOCKFIELDYEGUA")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneCOCKFIELDYEGUA"
                      >
                        {filtervalues.EoceneCOCKFIELDYEGUA_opacity_value !=
                        undefined
                          ? filtervalues.EoceneCOCKFIELDYEGUA_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneCOOKMOUNTAIN"
                  style={
                    filtervalues.EoceneCOOKMOUNTAIN
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>CookMountain</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #704489" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneCOOKMOUNTAIN_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneCOOKMOUNTAIN_BG"
                            ]
                          : "#704489"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>CookMountain</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneCOOKMOUNTAINSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneCOOKMOUNTAIN_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneCOOKMOUNTAIN_opacity_value !=
                          undefined
                            ? filtervalues.EoceneCOOKMOUNTAIN_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneCOOKMOUNTAIN")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneCOOKMOUNTAIN"
                      >
                        {filtervalues.EoceneCOOKMOUNTAIN_opacity_value !=
                        undefined
                          ? filtervalues.EoceneCOOKMOUNTAIN_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneJACKSON"
                  style={
                    filtervalues.EoceneJACKSON
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Jackson</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #274F89" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneJACKSON_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneJACKSON_BG"
                            ]
                          : "#274F89"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Jackson</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneJACKSONSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneJACKSON_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneJACKSON_opacity_value != undefined
                            ? filtervalues.EoceneJACKSON_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneJACKSON")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneJACKSON"
                      >
                        {filtervalues.EoceneJACKSON_opacity_value != undefined
                          ? filtervalues.EoceneJACKSON_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneWILCOX"
                  style={
                    filtervalues.EoceneWILCOX
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Wilcox</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #446589" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneWILCOX_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneWILCOX_BG"
                            ]
                          : "#446589"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Wilcox</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneWILCOXSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneWILCOX_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneWILCOX_opacity_value != undefined
                            ? filtervalues.EoceneWILCOX_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneWILCOX")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneWILCOX"
                      >
                        {filtervalues.EoceneWILCOX_opacity_value != undefined
                          ? filtervalues.EoceneWILCOX_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneYEGUA"
                  style={
                    filtervalues.EoceneYEGUA
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Yegua</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #448970" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneYEGUA_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneYEGUA_BG"
                            ]
                          : "#448970"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Yegua</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneYEGUASlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneYEGUA_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneYEGUA_opacity_value != undefined
                            ? filtervalues.EoceneYEGUA_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneYEGUA")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneYEGUA"
                      >
                        {filtervalues.EoceneYEGUA_opacity_value != undefined
                          ? filtervalues.EoceneYEGUA_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EoceneOTHER"
                  style={
                    filtervalues.EoceneOTHER
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Eocene Other</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #897044" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "EoceneOTHER_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "EoceneOTHER_BG"
                            ]
                          : "#897044"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Eocene Other</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="EoceneOTHERSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EoceneOTHER_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EoceneOTHER_opacity_value != undefined
                            ? filtervalues.EoceneOTHER_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EoceneOTHER")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EoceneOTHER"
                      >
                        {filtervalues.EoceneOTHER_opacity_value != undefined
                          ? filtervalues.EoceneOTHER_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="TertiaryPaleocene"
                  style={
                    filtervalues.TertiaryPaleocene
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Tertiary Paleocene</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #000000" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "TertiaryPaleocene_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "TertiaryPaleocene_BG"
                            ]
                          : "#000000"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Tertiary Paleocene</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="TertiaryPaleoceneSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.TertiaryPaleocene_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.TertiaryPaleocene_opacity_value !=
                          undefined
                            ? filtervalues.TertiaryPaleocene_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "TertiaryPaleocene")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="TertiaryPaleocene"
                      >
                        {filtervalues.TertiaryPaleocene_opacity_value !=
                        undefined
                          ? filtervalues.TertiaryPaleocene_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousUppGulfAUSTIN"
                  style={
                    filtervalues.CretaceousUppGulfAUSTIN
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Austin</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #CD6699" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfAUSTIN_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfAUSTIN_BG"
                            ]
                          : "#CD6699"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Austin</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfAUSTINSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfAUSTIN_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfAUSTIN_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfAUSTIN_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfAUSTIN"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfAUSTIN"
                      >
                        {filtervalues.CretaceousUppGulfAUSTIN_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfAUSTIN_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="CretaceousUppGulfEAGLEFORD"
                  style={
                    filtervalues.CretaceousUppGulfEAGLEFORD
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Eagle Ford</h3>
                  <p
                    className="legenddataboxgeo"
                    // // style={{ border: "Solid 3px #CD6699" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfEAGLEFORD_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfEAGLEFORD_BG"
                            ]
                          : "#CD6699"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Eagle Ford</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfEAGLEFORDSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfEAGLEFORD"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfEAGLEFORD"
                      >
                        {filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfEAGLEFORD_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="CretaceousUppGulfNAVARRO"
                  style={
                    filtervalues.CretaceousUppGulfNAVARRO
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Navarro</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #AA66CD" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfNAVARRO_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfNAVARRO_BG"
                            ]
                          : "#AA66CD"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Navarro</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfNAVARROSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfNAVARRO_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfNAVARRO_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfNAVARRO_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfNAVARRO"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfNAVARRO"
                      >
                        {filtervalues.CretaceousUppGulfNAVARRO_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfNAVARRO_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="CretaceousUppGulfOLMOS"
                  style={
                    filtervalues.CretaceousUppGulfOLMOS
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Olmos</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #6677CD" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfOLMOS_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfOLMOS_BG"
                            ]
                          : "#6677CD"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Olmos</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfOLMOSSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfOLMOS_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfOLMOS_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfOLMOS_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfOLMOS"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfOLMOS"
                      >
                        {filtervalues.CretaceousUppGulfOLMOS_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfOLMOS_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="CretaceousUppGulfSANMIGUEL"
                  style={
                    filtervalues.CretaceousUppGulfSANMIGUEL
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>SanMiguel</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #6677CD" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfSANMIGUEL_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfSANMIGUEL_BG"
                            ]
                          : "#6677CD"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>SanMiguel</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfSANMIGUELSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfSANMIGUEL"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfSANMIGUEL"
                      >
                        {filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfSANMIGUEL_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="CretaceousUppGulfTUSCALOOSAWOODBINE"
                  style={
                    filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Tuscaloosa/ Woodbine</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #00C5FF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfTUSCALOOSAWOODBINE_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfTUSCALOOSAWOODBINE_BG"
                            ]
                          : "#00C5FF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Tuscaloosa/ Woodbine</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfTUSCALOOSAWOODBINESlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfTUSCALOOSAWOODBINE"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfTUSCALOOSAWOODBINE"
                      >
                        {filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfTUSCALOOSAWOODBINE_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousUppGulfWOODBINE"
                  style={
                    filtervalues.CretaceousUppGulfWOODBINE
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Woodbine</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #E69800" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfWOODBINE_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfWOODBINE_BG"
                            ]
                          : "#E69800"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Woodbine</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfWOODBINESlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfWOODBINE_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfWOODBINE_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfWOODBINE_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfWOODBINE"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfWOODBINE"
                      >
                        {filtervalues.CretaceousUppGulfWOODBINE_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfWOODBINE_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousUppGulfOTHER"
                  style={
                    filtervalues.CretaceousUppGulfOTHER
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Cretaceous UppGulf Other</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #343434" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousUppGulfOTHER_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousUppGulfOTHER_BG"
                            ]
                          : "#343434"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Cretaceous UppGulf Other</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousUppGulfOTHERSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousUppGulfOTHER_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousUppGulfOTHER_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousUppGulfOTHER_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousUppGulfOTHER"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousUppGulfOTHER"
                      >
                        {filtervalues.CretaceousUppGulfOTHER_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousUppGulfOTHER_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                  style={
                    filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Hosston/ Travis Peak</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FFBEBE" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowCoahuilaHOSSTONTRAVISPEAK_BG"
                            ]
                          : "#FFBEBE"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Hosston/ Travis Peak</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowCoahuilaHOSSTONTRAVISPEAKSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowCoahuilaHOSSTONTRAVISPEAK"
                      >
                        {filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowCoahuilaHOSSTONTRAVISPEAK_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowCoahuilaSLIGO"
                  style={
                    filtervalues.CretaceousLowCoahuilaSLIGO
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Sligo</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF0000" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowCoahuilaSLIGO_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowCoahuilaSLIGO_BG"
                            ]
                          : "#FF0000"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Sligo</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowCoahuilaSLIGOSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowCoahuilaSLIGO"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowCoahuilaSLIGO"
                      >
                        {filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowCoahuilaSLIGO_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheBUDA"
                  style={
                    filtervalues.CretaceousLowComancheBUDA
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Buda</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #73FFDF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheBUDA_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheBUDA_BG"
                            ]
                          : "#73FFDF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Buda</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheBUDASlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheBUDA_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheBUDA_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheBUDA_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheBUDA"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheBUDA"
                      >
                        {filtervalues.CretaceousLowComancheBUDA_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheBUDA_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheEDWARDS"
                  style={
                    filtervalues.CretaceousLowComancheEDWARDS
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Edwards</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #73B2FF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheEDWARDS_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheEDWARDS_BG"
                            ]
                          : "#73B2FF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Edwards</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheEDWARDSSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheEDWARDS_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheEDWARDS_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheEDWARDS_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheEDWARDS"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheEDWARDS"
                      >
                        {filtervalues.CretaceousLowComancheEDWARDS_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheEDWARDS_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheGEORGETOWN"
                  style={
                    filtervalues.CretaceousLowComancheGEORGETOWN
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Georgetown</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF73DF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheGEORGETOWN_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheGEORGETOWN_BG"
                            ]
                          : "#FF73DF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Georgetown</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheGEORGETOWNSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheGEORGETOWN"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheGEORGETOWN"
                      >
                        {filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheGEORGETOWN_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheFREDERICKSBURG"
                  style={
                    filtervalues.CretaceousLowComancheFREDERICKSBURG
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>FREDERICKSBURG</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF73DF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheFREDERICKSBURG_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheFREDERICKSBURG_BG"
                            ]
                          : "#FF73DF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>FREDERICKSBURG</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheFREDERICKSBURGSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheFREDERICKSBURG"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheFREDERICKSBURG"
                      >
                        {filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheFREDERICKSBURG_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheGLENROSE"
                  style={
                    filtervalues.CretaceousLowComancheGLENROSE
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Glen Rose</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF73DF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheGLENROSE_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheGLENROSE_BG"
                            ]
                          : "#FF73DF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Glen Rose</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheGLENROSESlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheGLENROSE_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheGLENROSE_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheGLENROSE_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheGLENROSE"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheGLENROSE"
                      >
                        {filtervalues.CretaceousLowComancheGLENROSE_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheGLENROSE_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComanchePEARSALL"
                  style={
                    filtervalues.CretaceousLowComanchePEARSALL
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>PEARSALL</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF73DF" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComanchePEARSALL_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComanchePEARSALL_BG"
                            ]
                          : "#FF73DF"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Pearsall</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComanchePEARSALLSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComanchePEARSALL_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComanchePEARSALL_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComanchePEARSALL_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComanchePEARSALL"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComanchePEARSALL"
                      >
                        {filtervalues.CretaceousLowComanchePEARSALL_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComanchePEARSALL_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="CretaceousLowComancheOTHER"
                  style={
                    filtervalues.CretaceousLowComancheOTHER
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Cretaceous LowComanche Other</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FFFF00" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "CretaceousLowComancheOTHER_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "CretaceousLowComancheOTHER_BG"
                            ]
                          : "#FFFF00"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>
                      Cretaceous LowComanche Other
                    </span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="CretaceousLowComancheOTHERSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.CretaceousLowComancheOTHER_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.CretaceousLowComancheOTHER_opacity_value !=
                          undefined
                            ? filtervalues.CretaceousLowComancheOTHER_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "CretaceousLowComancheOTHER"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="CretaceousLowComancheOTHER"
                      >
                        {filtervalues.CretaceousLowComancheOTHER_opacity_value !=
                        undefined
                          ? filtervalues.CretaceousLowComancheOTHER_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="JurassicUppCottonValley"
                  style={
                    filtervalues.JurassicUppCottonValley
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Cotton Valley</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF7F7F" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "JurassicUppCottonValley_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "JurassicUppCottonValley_BG"
                            ]
                          : "#FF7F7F"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Cotton Valley</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="JurassicUppCottonValleySlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.JurassicUppCottonValley_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.JurassicUppCottonValley_opacity_value !=
                          undefined
                            ? filtervalues.JurassicUppCottonValley_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "JurassicUppCottonValley"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="JurassicUppCottonValley"
                      >
                        {filtervalues.JurassicUppCottonValley_opacity_value !=
                        undefined
                          ? filtervalues.JurassicUppCottonValley_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="JurassicUppHaynesville"
                  style={
                    filtervalues.JurassicUppHaynesville
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Haynesville</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{ border: "Solid 3px #FF7F7F" }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "JurassicUppHaynesville_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "JurassicUppHaynesville_BG"
                            ]
                          : "#FF7F7F"
                      }`,
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Haynesville</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="JurassicUppHaynesvilleSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.JurassicUppHaynesville_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.JurassicUppHaynesville_opacity_value !=
                          undefined
                            ? filtervalues.JurassicUppHaynesville_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "JurassicUppHaynesville"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="JurassicUppHaynesville"
                      >
                        {filtervalues.JurassicUppHaynesville_opacity_value !=
                        undefined
                          ? filtervalues.JurassicUppHaynesville_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="ForestBoundaries"
                  style={
                    filtervalues.ForestBoundaries
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Forest Boundaries</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{
                    //   border: "Solid 3px #C3DEAB",
                    //   backgroundColor: "#C3DEAB",
                    // }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "ForestBoundaries_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "ForestBoundaries_BG"
                            ]
                          : "#C3DEAB"
                      }`,
                      backgroundColor: JSON.parse(
                        localStorage.getItem("DefaultValues")
                      )["ForestBoundaries_BG"]
                        ? JSON.parse(localStorage.getItem("DefaultValues"))[
                            "ForestBoundaries_BG"
                          ]
                        : "#C3DEAB",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: "#C3DEAB",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Forest Boundaries</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="ForestBoundariesSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.ForestBoundaries_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.ForestBoundaries_opacity_value !=
                          undefined
                            ? filtervalues.ForestBoundaries_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "ForestBoundaries")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="ForestBoundaries"
                      >
                        {filtervalues.ForestBoundaries_opacity_value !=
                        undefined
                          ? filtervalues.ForestBoundaries_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="TimberHarvests"
                  style={
                    filtervalues.TimberHarvests
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Timber Harvests</h3>
                  <p
                    className="legenddataboxgeo"
                    // style={{
                    //   border: "Solid 3px #808080",
                    //   backgroundColor: "#808080",
                    // }}
                    style={{
                      border: `Solid 3px ${
                        JSON.parse(localStorage.getItem("DefaultValues"))[
                          "TimberHarvests_BG"
                        ]
                          ? JSON.parse(localStorage.getItem("DefaultValues"))[
                              "TimberHarvests_BG"
                            ]
                          : "#808080"
                      }`,
                      backgroundColor: JSON.parse(
                        localStorage.getItem("DefaultValues")
                      )["TimberHarvests_BG"]
                        ? JSON.parse(localStorage.getItem("DefaultValues"))[
                            "TimberHarvests_BG"
                          ]
                        : "#808080",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: "#808080",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Timber Harvests</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="TimberHarvestsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.TimberHarvests_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.TimberHarvests_opacity_value != undefined
                            ? filtervalues.TimberHarvests_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "TimberHarvests")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="TimberHarvests"
                      >
                        {filtervalues.TimberHarvests_opacity_value != undefined
                          ? filtervalues.TimberHarvests_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                {/* storage unit volumetric legend tab hide */}
                {/* <li id="Volumetrics100MillTons" style={filtervalues.Volumetrics100MillTons || filtervalues.Volumetrics100MillTons == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>20 Mt + </h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                       backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_BG"]?JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_BG"]:"#ff0000",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.Volumetrics100MillTons_BG ? filtervalues.Volumetrics100MillTons_BG : "#ff0000",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>20 Mt + </span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="Volumetrics100MillTonsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_opacity_value"]}-${defaultStore}`}
                        defaultValue={JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_opacity_value"]  != undefined ? JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_opacity_value"] : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(
                            e,
                            newVal,
                            "Volumetrics100MillTons"
                          )
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="Volumetrics100MillTons"
                      >
                      {JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_opacity_value"] != undefined ? JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics100MillTons_opacity_value"] : 20}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li id="Volumetrics50to100" style={filtervalues.Volumetrics50to100 || filtervalues.Volumetrics50to100 == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>10-20 Mt</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics50to100_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics50to100_BG"] : "#ffaa00",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.Volumetrics50to100_BG ? filtervalues.Volumetrics50to100_BG : "#ffaa00",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>10-20 Mt</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="Volumetrics50to100Slider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.Volumetrics50to100_opacity_value}-${defaultStore}`}
                        defaultValue={filtervalues.Volumetrics50to100_opacity_value != undefined ? filtervalues.Volumetrics50to100_opacity_value : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "Volumetrics50to100")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="Volumetrics50to100"
                      >
                       {filtervalues.Volumetrics50to100_opacity_value != undefined ? filtervalues.Volumetrics50to100_opacity_value : 20}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li id="Volumetrics15to50" style={filtervalues.Volumetrics15to50 || filtervalues.Volumetrics15to50 == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>5-10 Mt</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics15to50_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["Volumetrics15to50_BG"] : "#ffff00",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.Volumetrics15to50_BG ? filtervalues.Volumetrics15to50_BG : "#ffff00",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>5-10 Mt</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="Volumetrics15to50Slider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.Volumetrics15to50_opacity_value}-${defaultStore}`}
                        defaultValue={filtervalues.Volumetrics15to50_opacity_value != undefined ? filtervalues.Volumetrics15to50_opacity_value : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "Volumetrics15to50")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="Volumetrics15to50"
                      >
                        {filtervalues.Volumetrics15to50_opacity_value != undefined ? filtervalues.Volumetrics15to50_opacity_value : 20}%
                      </span>
                    </li>
                  </ul>
                </li>
                <li id="VolumetricsSmall5to15" style={filtervalues.VolumetricsSmall5to15 || filtervalues.VolumetricsSmall5to15 == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>5-2.5 Mt</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["VolumetricsSmall5to15_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["VolumetricsSmall5to15_BG"] : "#aaff00",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.VolumetricsSmall5to15_BG?filtervalues.VolumetricsSmall5to15_BG: "#aaff00",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>5-2.5 Mt</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="VolumetricsSmall5to15Slider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.VolumetricsSmall5to15_opacity_value}-${defaultStore}`}
                        defaultValue={filtervalues.VolumetricsSmall5to15_opacity_value != undefined ? filtervalues.VolumetricsSmall5to15_opacity_value : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "VolumetricsSmall5to15")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="VolumetricsSmall5to15"
                      >
                        {filtervalues.VolumetricsSmall5to15_opacity_value != undefined ? filtervalues.VolumetricsSmall5to15_opacity_value : 20}%
                      </span>
                    </li>
                  </ul>
                </li>
                <li id="VolumetricsMarginal5" style={filtervalues.VolumetricsMarginal5 || filtervalues.VolumetricsMarginal5 == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>1-2.5 Mt</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["VolumetricsMarginal5_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["VolumetricsMarginal5_BG"] : "#73deff",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.VolumetricsMarginal5_BG ? filtervalues.VolumetricsMarginal5_BG : "#73deff",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>1-2.5 Mt</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="VolumetricsMarginal5Slider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.VolumetricsMarginal5_opacity_value}-${defaultStore}`}
                        defaultValue={filtervalues.VolumetricsMarginal5_opacity_value != undefined ? filtervalues.VolumetricsMarginal5_opacity_value : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "VolumetricsMarginal5")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="VolumetricsMarginal5"
                      >
                        {filtervalues.VolumetricsMarginal5_opacity_value != undefined ? filtervalues.VolumetricsMarginal5_opacity_value : 20}%
                      </span>
                    </li>
                  </ul>
                </li>

                <li id="Unclassified" style={filtervalues.Unclassified || filtervalues.Unclassified == undefined ? {display: 'block'}: {display: 'none'} }>
                  <h3>0.1-1 Mt</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      backgroundColor: JSON.parse(localStorage.getItem('DefaultValues'))["Unclassified_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["Unclassified_BG"] : "#0000ff",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: filtervalues.Unclassified_BG ? filtervalues.Unclassified_BG : "#0000ff",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>0.1-1 Mt</span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="UnclassifiedSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.Unclassified_opacity_value}-${defaultStore}`}
                        defaultValue={filtervalues.Unclassified_opacity_value != undefined ? filtervalues.Unclassified_opacity_value : 20}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "Unclassified")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="Unclassified"
                      >
                        {filtervalues.Unclassified_opacity_value != undefined ? filtervalues.Unclassified_opacity_value : 20}%
                      </span>
                    </li>
                  </ul>
                </li> */}
                <li
                  id="epa_emitters"
                  style={
                    filtervalues.epa_emitters ||
                    filtervalues.epa_emitters == undefined
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>EPA Emitters</h3>
                  <ul>
                    <li>
                      <Slider
                        id="epa_emittersSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.epa_emitters_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.epa_emitters_opacity_value != undefined
                            ? filtervalues.epa_emitters_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "epa_emitters")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="epa_emitters"
                      >
                        {filtervalues.epa_emitters_opacity_value != undefined
                          ? filtervalues.epa_emitters_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="PelletPlants"
                  style={
                    filtervalues.PelletPlants
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Pellet Plants</h3>
                  <p
                    className="legenddataboxgeo"
                    style={{
                      // backgroundColor: "#67A9CF",
                      backgroundColor: filtervalues.PelletPlants_BG
                        ? filtervalues.PelletPlants_BG
                        : "#67A9CF",
                      height: "10px",
                      width: "10px",
                    }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ left: "20px", top: "-5px" }}>
                      Pellet Plants
                    </span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="PelletPlantsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.PelletPlants_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.PelletPlants_opacity_value != undefined
                            ? filtervalues.PelletPlants_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "PelletPlants")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="PelletPlants"
                      >
                        {filtervalues.PelletPlants_opacity_value != undefined
                          ? filtervalues.PelletPlants_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  id="ProtectedLands"
                  style={
                    filtervalues.ProtectedLands
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Protected Lands</h3>

                  <div>
                    <img id="ProtectedLands" />
                  </div>
                  <ul>
                    <li>
                      <Slider
                        id="ProtectedLandsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.ProtectedLands_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.ProtectedLands_opacity_value != undefined
                            ? filtervalues.ProtectedLands_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "ProtectedLands")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="ProtectedLands"
                      >
                        {filtervalues.ProtectedLands_opacity_value != undefined
                          ? filtervalues.ProtectedLands_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="PropertyOwnership"
                  style={
                    filtervalues.PropertyOwnership ||
                    filtervalues.PropertyOwnership == undefined
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Property Ownership </h3>
                  <p
                    className="legenddataboxgeo"
                    style={{ border: "Solid 3px rgb(255, 140, 0)" }}
                    // style={{ border: `Solid 3px ${JSON.parse(localStorage.getItem('DefaultValues'))["PropertyOwnership_BG"] ? JSON.parse(localStorage.getItem('DefaultValues'))["PropertyOwnership_BG"] : "rgb(255, 140, 0"}` }}
                  >
                    <input
                      style={{
                        width: "24px",
                        marginLeft: "28px",
                        height: "25px",
                        border: "solid 1px white",
                        left: "-28px",
                        backgroundColor: legendColorValue,
                        // backgroundColor: filtervalues.PropertyOwnership_BG,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      className="form-control withborder"
                      type="color"
                      name="color"
                      onChange={legendColorChange}
                    />
                    <span style={{ top: "0px" }}>Property Ownership </span>
                  </p>
                  <ul>
                    <li>
                      <Slider
                        id="PropertyOwnershipSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.PropertyOwnership_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.PropertyOwnership_opacity_value !=
                          undefined
                            ? filtervalues.PropertyOwnership_opacity_value
                            : 20
                        }
                        // key={`slider-${filtervalues.PropertyOwnership_opacity_value}-${defaultStore}`}
                        // defaultValue={filtervalues.PropertyOwnership_opacity_value}
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "PropertyOwnership")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="PropertyOwnership"
                      >
                        {filtervalues.PropertyOwnership_opacity_value !=
                        undefined
                          ? filtervalues.PropertyOwnership_opacity_value
                          : 20}
                        %{/* {filtervalues.PropertyOwnership_opacity_value}% */}
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="StorageResourcesPools"
                  style={
                    filtervalues.StorageResourcesPools
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Storage Resources Pools</h3>

                  <div>
                    <img id="StorageResourcesPools" />
                  </div>
                  <ul>
                    <li>
                      <Slider
                        id="StorageResourcesPoolsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.StorageResourcesPools_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.StorageResourcesPools_opacity_value !=
                          undefined
                            ? filtervalues.StorageResourcesPools_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "StorageResourcesPools")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="StorageResourcesPools"
                      >
                        {filtervalues.StorageResourcesPools_opacity_value !=
                        undefined
                          ? filtervalues.StorageResourcesPools_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="StorageInjectorWells"
                  style={
                    filtervalues.StorageInjectorWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Storage Injector Wells</h3>
                  <div>
                    <img id="StorageInjectorWells" />
                  </div>

                  <ul>
                    <li>
                      <Slider
                        id="StorageInjectorWellsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.StorageInjectorWells_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.StorageInjectorWells_opacity_value !=
                          undefined
                            ? filtervalues.StorageInjectorWells_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "StorageInjectorWells")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="StorageInjectorWells"
                      >
                        {filtervalues.StorageInjectorWells_opacity_value !=
                        undefined
                          ? filtervalues.StorageInjectorWells_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="DisposalInjectorWells"
                  style={
                    filtervalues.DisposalInjectorWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Disposal Injector Wells</h3>
                  <div>
                    <img id="DisposalInjectorWells" />
                  </div>
                  <ul>
                    <li>
                      <Slider
                        id="DisposalInjectorWellsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.DisposalInjectorWells_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.DisposalInjectorWells_opacity_value !=
                          undefined
                            ? filtervalues.DisposalInjectorWells_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "DisposalInjectorWells")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="DisposalInjectorWells"
                      >
                        {filtervalues.DisposalInjectorWells_opacity_value !=
                        undefined
                          ? filtervalues.DisposalInjectorWells_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="ProductionWells"
                  style={
                    filtervalues.ProductionWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Production Wells</h3>
                  <div>
                    <img id="ProductionWells" />
                  </div>
                  <ul>
                    <li>
                      <Slider
                        id="ProductionWellsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.ProductionWells_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.ProductionWells_opacity_value !=
                          undefined
                            ? filtervalues.ProductionWells_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "ProductionWells")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="ProductionWells"
                      >
                        {filtervalues.ProductionWells_opacity_value != undefined
                          ? filtervalues.ProductionWells_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="OrphanedWells"
                  style={
                    filtervalues.OrphanedWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>Production Wells</h3>
                  <div>
                    <img id="OrphanedWells" />
                  </div>
                  <ul>
                    <li>
                      <Slider
                        id="OrphanedWellsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.OrphanedWells_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.OrphanedWells_opacity_value != undefined
                            ? filtervalues.OrphanedWells_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "OrphanedWells")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="OrphanedWells"
                      >
                        {filtervalues.OrphanedWells_opacity_value != undefined
                          ? filtervalues.OrphanedWells_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
                <li
                  id="EORInjectorWells"
                  style={
                    filtervalues.EORInjectorWells
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <h3>EOR Injector Wells</h3>
                  <div>
                    <img id="EORInjectorWells" />
                  </div>

                  <ul>
                    <li>
                      <Slider
                        id="EORInjectorWellsSlider"
                        min={0}
                        max={100}
                        step={1}
                        key={`slider-${filtervalues.EORInjectorWells_opacity_value}-${defaultStore}`}
                        defaultValue={
                          filtervalues.EORInjectorWells_opacity_value !=
                          undefined
                            ? filtervalues.EORInjectorWells_opacity_value
                            : 20
                        }
                        className="legendslider"
                        onChange={(e, newVal) =>
                          legendSliderChange(e, newVal, "EORInjectorWells")
                        }
                        aria-labelledby="continuous-slider"
                      />
                      <span
                        style={{
                          color: "#00aaab",
                          fontSize: "20px",
                          paddingLeft: "5%",
                        }}
                        className="EORInjectorWells"
                      >
                        {filtervalues.EORInjectorWells_opacity_value !=
                        undefined
                          ? filtervalues.EORInjectorWells_opacity_value
                          : 20}
                        %
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </Drawer>

      <Button
        style={{
          position: "absolute",
          zIndex: 10000,
          height: "48px",
          backgroundColor: "white",
          border: "none",
          left: props.infoData.layerObject.layerArrowLeft,
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
        onClick={toggleLayer}
      >
        <img src={props.infoData.layerObject.layerArrow} alt="" />
      </Button>
      <div
        id="ccsAtlasbaseMap1"
        className="summarycardcontainer "
        style={
          props.infoData.layerObject.drawerEnable
            ? {
                left: "23.5%",
                display: "none",
                bottom: "1%",
              }
            : {
                left: "1%",
                display: "none",
                bottom: "1%",
              }
        }
      ></div>
      <div
        id="ccsAtlasbaseMap1"
        className="summarycardcontainer"
        style={{
          display: "none",
        }}
      ></div>
      <div
        ref={mapElement}
        className="arlas-cs-map map-cursor"
        // style={{ backgroundColor: "white" }}
      ></div>
      <div className="map-tools-wrapper sf-map-view-function sf-map-view-function-true">
        <div className="map-action">
          {map && <BaseMap map={map} />}

          <ul className="ma-center-action" style={{ right: "6rem" }}>
            <li
              className="ma-default"
              ref={defaultZoomref}
              onClick={defaultZoomView}
            >
              <span className="ma-default-ico">
                <div>&nbsp;</div>
                <span className="ma-tooltip">Default View</span>
              </span>
            </li>
          </ul>

          <ul className="ma-left-action" style={{ right: "12rem" }}>
            <li
              className="ma-minus"
              ref={customZoomOut}
              // onClick={zoomSingleMinus}
            >
              <span className="ma-minus-ico">
                <div>&nbsp;</div>
                <span className="ma-tooltip">Zoom Out</span>
              </span>
            </li>
            <li
              id="mascaleselected"
              className={zoomState !== true ? "d-block" : "d-none"}
              onClick={zoomPanelToggle}
            >
              <span href="#" className="ma-scale-count" id="mascalecount">
                <span className="masc-value" id="mascvalue">
                  {zoomItemText}
                </span>
                <div>&nbsp;</div>
                <span className="ma-tooltip">
                  Click Here For More Zoom Levels
                </span>
              </span>
            </li>
            <li
              className={
                zoomState !== true
                  ? "ma-scale-container d-none"
                  : "ma-scale-container d-block"
              }
              id="mascalecontainer"
            >
              <ul className="ma-scale">
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>3</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>4</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>5</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>6</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>7</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>8</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>9</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>10</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>11</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>12</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>13</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>14</span>
                </li>
                <li className="zoomvalue" onClick={zoomSingleItem}>
                  <span>15</span>
                </li>
              </ul>
            </li>
            <li
              className="ma-plus"
              ref={customZoomIn}
              //onClick={zoomSinglePlus}
            >
              <span className="ma-plus-ico">
                <div>&nbsp;</div>
                <span className="ma-tooltip">Zoom In</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div id="ccsAtlasTooltip">Login and View CCS Atlas Layer</div>

      <UsageDialog
        open={usageModal}
        handleClose={handleCloseUsage}
        alertReceived={alertText}
      />
    </>
  );
}

function checkIfJSONisEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export default withAuth(OpenLayerFuncComp);
