import { GetSortOrder } from "./calFunc";
import { v4 as uuidv4 } from "uuid";

export const getQueries = async ({ token, userId }) => {
    let result=[];  
  if (token) 
   { 
    let SavedData = JSON.parse(localStorage.getItem('SaveQueries'));
    if (SavedData !== null) {
        SavedData.map((values)=>{
          if (values.userid === userId)
                result.push({'queryId':values.id, 'queryName': values.name});     
        })      
      }   
  }
  return result;
}



export const saveQuery = async ({ token, payload }) => {  
  try {
    let SavedData = [];
        SavedData = JSON.parse(localStorage.getItem("SaveQueries"));
        const queryId = uuidv4();
        const newquerydata = delete payload.querydata['mapObject'];
        let dataTobeSaved = {
          userid: payload.userId,
          id: queryId,
          name: payload.queryName,
          data: payload.querydata,
        };
    if (SavedData === null) {
      SavedData = [];
    } 
          SavedData.push(dataTobeSaved);
    if (SavedData !== null) 
    {
      SavedData.sort(GetSortOrder("name"))}
    try {
      localStorage.setItem("SaveQueries", JSON.stringify(SavedData));
      return {'queryId':queryId, 'queryName': payload.queryName };      
    } catch (e) {
      return e;
    }
  } catch (e) {
    return {
      status: "error",
      msg: e,
    };
  }
};

export const updateQuery = async ({ token, payload, queryId }) => {
  try {
    
    if (token)
    {
        const savedData = JSON.parse(localStorage.getItem("SaveQueries"));  
        savedData.map((val)=>{
            if (val.id === queryId) {
              const newquerydata = delete payload.querydata['mapObject'];
               val.data = payload.querydata;
              }
        });
        localStorage.setItem("SaveQueries", JSON.stringify(savedData));
        return 'Success'; 
    }    
  } catch (e) {
    console.log(e);
    return {
      status: "error",
      msg: e,
    };
  }
};

export const getQueryById = async ({ token, queryId }) => {
    if (token)
    {
        const savedData = JSON.parse(localStorage.getItem("SaveQueries"));
        const queryData = savedData.filter(d => d.id === queryId); 
        return {'querydata': queryData[0].data};
    }
    }
    
