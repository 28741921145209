import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, SecureRoute, ImplicitCallback } from "@okta/okta-react";
import config from "./configs/appSettings";
import AccessDenied from "./pages/AccessDenied";
import Map from "./pages/Map";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import "./assets/scss/main.scss";
export let ccsFilterContext = React.createContext();

const { issuer, clientId, scope } = config.oidc;
const callbackPath = "/implicit-callback";

function App() {
  const defaultFilterValues = {
    savedQueryNames: [],
    pooltypeValue: "*",
    depthGreaterthanValue: 0,
    depthLessthanValue: 21000,
    welldensityGreaterValue: 0,
    welldensityLessthanValue: 100,
    poolareaAcresGreaterValue: 0,
    poolareaAcresLesserValue: 4800000,
    potentialstorageGreaterValue: 0,
    potentialstorageLesserValue: 100,
    temperatureGreaterthanValue: 1,
    temperatureLessthanValue: 500,
    porosityGreaterthanValue: 0.01,
    porosityLessthanValue: 0.3,
    permeabilityGreaterthanValue: 0.1,
    permeabilityLessthanValue: 500,
    pressureGreaterthanValue: 1,
    pressureLessthanValue: 12000,
    oilGravityGreaterthanValue: 0.1,
    oilGravityLessthanValue: 75,
    filterApplied: false,
    applyEnable: false,
    TertiaryMiocene: false,
    OligoceneFRIO: false,
    OligoceneVICKSBURG: false,
    OligoceneOther: false,
    EoceneCLAIBORNE: false,
    EoceneCOCKFIELDYEGUA: false,
    EoceneQUEENCITY: false,
    EoceneCOOKMOUNTAIN: false,
    EoceneJACKSON: false,
    EoceneWILCOX: false,
    EoceneYEGUA: false,
    EoceneOTHER: false,
    TertiaryPaleocene: false,
    CretaceousUppGulfAUSTIN: false,
    CretaceousUppGulfEAGLEFORD: false,
    CretaceousUppGulfNAVARRO: false,
    CretaceousUppGulfOLMOS: false,
    CretaceousUppGulfSANMIGUEL: false,
    CretaceousUppGulfTUSCALOOSAWOODBINE: false,
    CretaceousUppGulfOTHER: false,
    CretaceousLowCoahuilaSLIGO: false,
    CretaceousLowCoahuilaHOSSTONTRAVISPEAK: false,
    CretaceousLowComancheBUDA: false,
    CretaceousLowComancheEDWARDS: false,
    CretaceousLowComancheGEORGETOWN: false,
    CretaceousLowComancheFREDERICKSBURG: false,
    CretaceousLowComancheGLENROSE: false,
    CretaceousLowComanchePEARSALL: false,
    CretaceousLowComancheOTHER: false,
    JurassicUppCottonValley: false,
    JurassicUppHaynesville: false,
    Unclassified: true,
    VolumetricsMarginal5: true,
    VolumetricsSmall5to15: true,
    Volumetrics100MillTons: true,
    Volumetrics50to100: true,
    Volumetrics15to50: true,
    // SAUnclassified: true,
    // SAVolumetricsMarginal5: true,
    // SAVolumetricsSmall5to15: true,
    // SAVolumetrics100MillTons: true,
    // SAVolumetrics50to100: true,
    // SAVolumetrics15to50: true,
    EpaEmitters: true,
    PropertyOwnership: false,
    ProtectedLands: false,
    applyResetEnable: false,
    COEmission: null,
    StorageInjectorWells: false,
    EORInjectorWells: false,
    DisposalInjectorWells: false,
    StorageResourcesPools: false,
    ProductionWells: false,
    OrphanedWells: false,
    PelletPlants: false,
    NaturalGasPipelines: false,
    HGLPipelines: false,
    CrudeOilPipelines: false,
    BatteryStorage: false,
    Biomass: false,
    Coal: false,
    Geothermal: false,
    Hydroelectric: false,
    NaturalGas: false,
    Nuclear: false,
    Petroleum: false,
    PumpedStorage: false,
    Solar: false,
    Wind: false,
    OtherPowerPlants: false,
    PetroleumRefineries: false,
    BioDieselPlants: false,
    EthanolPlants: false,
    NaturalGasPlants: false,
    EthyleneCrackers: false,
    ForestBoundaries:false,
    TimberHarvests:false,
    mapObject: null,
    ForestData1:  false,
    Volumetrics: true,
    expand_Volumetrics: true,
    //expand_Volumetrics1: true,
    TeritiaryOligocene: false,
    TeritiaryEocene: false,
    TeritiaryGulfian: false,
    TeritiaryComanchean: false,
    TeritiaryCoahuila: false,
    Pipelines_and_Transmission : false,
    ImportedShapefile: false,
    uppermiocene_storage: false, 
    uppermiocene_thickness: false, 
    uppermiocene_porosity: false, 
    uppermiocene_temp: false, 
    uppermiocene_press: false, 
    midmiocene_storage_new: false,
    midmiocene_thickness_new: false,
    midmiocene_porosity_new: false,
    midmiocene_temp_new: false,
    midmiocene_press_new: false,
    lrmiocene_shallow_storage: false,
    lrmiocene_shallow_thickness: false,
    lrmiocene_shallow_porosity: false,
    lrmiocene_shallow_temp: false,
    lrmiocene_shallow_pressure: false, 
    lrmiocene_deep_storage: false, 
    lrmiocene_deep_thickness: false, 
    lrmiocene_deep_porosity: false, 
    lrmiocene_deep_temp: false, 
    lrmiocene_deep_press: false,
    expand_EpaEmitters: true,
    expand_ImportedShapefile: true,
    PoolVolumetrics20: false,
    PoolVolumetrics10to20: false,
    PoolVolumetrics5to10: false,
    PoolVolumetrics2_5to5: false,
    PoolVolumetrics1to2_5: false,
    PoolVolumetrics0_1to1: false,
    PoolVolumetricsLess0_1: false,
    PoolVolumetricsNA: false,
    Deviated: false,
    Horizontal: false,
    Vertical: false,
    Unknown: false

  }
  const [ccsFilterStore, setccsFilterStore] = useState(
    JSON.parse(localStorage.getItem('DefaultValues')) ? JSON.parse(localStorage.getItem('DefaultValues')) : defaultFilterValues
  );
  const [defaultStore, setDefaultStore] = useState(false);

  const updateccsFilterStore = (
    ptype,
    dgreaterVal,
    dlesserVal,
    wgreaterVal,
    wlesserVal,
    pgreaterVal,
    plesserVal,
    psgreaterVal,
    pslesserVal,
    tempgreaterVal,
    templesserVal,
    porositygreaterVal,
    porositylesserval,
    permgreaterVal,
    permlesserVal,
    pressgreaterVal,
    presslesserVal,
    oilGgreaterVal,
    oillesserVal,
    filterApp
  ) => {
    setccsFilterStore((prevState) => ({
      ...prevState,
      pooltypeValue: ptype,
      depthGreaterthanValue: dgreaterVal,
      depthLessthanValue: dlesserVal,
      welldensityGreaterValue: wgreaterVal,
      welldensityLessthanValue: wlesserVal,
      poolareaAcresGreaterValue: pgreaterVal,
      poolareaAcresLesserValue: plesserVal,
      potentialstorageGreaterValue: psgreaterVal,
      potentialstorageLesserValue: pslesserVal,
      temperatureGreaterthanValue: tempgreaterVal,
      temperatureLessthanValue: templesserVal,
      porosityGreaterthanValue: porositygreaterVal,
      porosityLessthanValue: porositylesserval,
      permeabilityGreaterthanValue: permgreaterVal,
      permeabilityLessthanValue: permlesserVal,
      pressureGreaterthanValue: pressgreaterVal,
      pressureLessthanValue: presslesserVal,
      oilGravityGreaterthanValue: oilGgreaterVal,
      oilGravityLessthanValue: oillesserVal,
      filterApplied: filterApp,
    }));
  };

  const updatelayerVisibleStore = (chkbName, boolval) => {
    setccsFilterStore((prevState) => ({
      ...prevState,
      [chkbName]: boolval,
    }));
  };

  const setsavedQueryNames = (savedQueryNames) => {
    setccsFilterStore((prevState) => ({
      ...prevState,
      savedQueryNames,
    }));
  };

  const setMapObject = (mapObject) => {
    setccsFilterStore((prevState) => ({
      ...prevState,
      mapObject: mapObject,
    }));
  };
  
const returnToDefault = () => {
  setccsFilterStore(defaultFilterValues);
  let defaultStoreClick = defaultStore
  setDefaultStore(!defaultStoreClick)
  localStorage.setItem('DefaultValues', JSON.stringify(defaultFilterValues));
}
  return (
    <ccsFilterContext.Provider
      value={{ ccsFilterStore, updateccsFilterStore, updatelayerVisibleStore, setsavedQueryNames, setMapObject, returnToDefault, defaultStore }}
    >
      <div className="App">
        <Router>
          <Security
            issuer={issuer}
            client_id={clientId}
            scope={scope}
            redirect_uri={window.location.origin + callbackPath}
            auto_renew={true}
          >
            <SecureRoute path="/" exact component={Map} />
            <Route path={callbackPath} component={ImplicitCallback} />
            <Route path="/access-denied" component={AccessDenied} />
          </Security>
        </Router>
      </div>
    </ccsFilterContext.Provider>
  );
}

export default App;
