import { GeoJSON, WFS } from "ol/format";
import { equalTo } from 'ol/format/filter';
import React, { useRef, useState } from "react";
import { CSVLink /* CSVDownload */ } from "react-csv";
import { ReactComponent as DownloadIcon } from "../assets/images/download_icon.svg";
import config from "../configs/appSettings";

function ExportLayer(props) {
  const [csvexportData, setcsvexportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const exportdiv = useRef();
  let DownloadIconPath = window.location.protocol + "//" + window.location.host + DownloadIcon;
  const csvLink = useRef()
  let toExportdata = [];
  let startindexfrom = 0;

  
 
  const Spinner = (type) => (
    
    <div class={`${ (type.dataType === "storage_units_group" || type.dataType === "barrier_units") ? "loader ar-ms-al" : "loader"}`}>
    </div>
  );

  const enableSpinner = () => {
    setLoading(true);
    // exportdiv.current.style.top = "28px";
  }
  
  const disableSpinner = () => {
    setLoading(false);
    // exportdiv.current.style.top = "18px";
  }
  const handleFetch = (lyrname) => {
    enableSpinner();
    const featureName = lyrname;
    const filerStr = props.exportFilter;
    const filterArgs = filerStr ? filerStr.split(",") : [];
    const exportRequest = new WFS().writeGetFeature({
      srsName: "EPSG:3857",
      featureNS: "http://openstreemap.org",
      featurePrefix: "osm",
      featureTypes: [featureName],
      outputFormat: "application/json",
      startIndex: startindexfrom,
      filter: filterArgs.length > 0 && equalTo(filterArgs[0], filterArgs[1], filterArgs[2])

    });
    fetch(`${config.endpoints.wfsLayerUrl}`, {
      method: "POST",
      body: new XMLSerializer().serializeToString(exportRequest),
      headers: {
        Authorization: "Bearer " + props.genaccesToken,
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        var filteredjson = json;
        var totalreccordcout = filteredjson.totalFeatures;
        console.log(`totalFeatures = ${filteredjson.totalFeatures}`)
        const features = new GeoJSON().readFeatures(filteredjson);
        if(features.length>0){
          features.map((values) => {
            const arr=values.values_;            
            let exportcofigObj = config.ExportLayerFieldConfig.filter((exobj)=>{
               return exobj.lyrName===featureName;
            });
            if(exportcofigObj.length>0){
              exportcofigObj[0].eliminateField.map((obj)=>{  
                delete arr[obj]; 
              })
              exportcofigObj[0].requiredField.map((obj)=>{            
                let keyp=Object.keys(obj)[0];              
                arr[obj[keyp]] = arr[keyp];
                delete arr[keyp];              
              })
            }
            else{
              delete arr.geometry;
            }
            toExportdata.push(arr);
          });
        }
        if (startindexfrom < totalreccordcout) {
          startindexfrom = startindexfrom + 2000;
          handleFetch(featureName);
        }
        if (startindexfrom > totalreccordcout) {
          if(toExportdata.length>0){
            disableSpinner();
            setcsvexportData(toExportdata);
            console.log("i am here to download");
            csvLink.current.link.click();
            toExportdata = [];
          }
        }
      })
      .catch(error => console.error('Error', error));
  }

  return (
 
    <div className={props.customClassname ? props.customClassname : "exportdiv"} ref={exportdiv}>
     
      {
        loading && <Spinner dataType={props.layerData} />
      }
      {/* <Spinner /> */}
      {/* <Button     
        color="primary"
        onClick={(e) => handleFetch(props.layerData)}    
        size={'small'}
        variant="text"
      > <DownloadIcon fill='#00aaab' stroke='#00aaab' alt="Download"/></Button> */}
       <DownloadIcon onClick={() => handleFetch(props.layerData)} fill='#00aaab' stroke='#00aaab' alt="Download"/>
      <CSVLink
        className='hidden'
        ref={csvLink}
        target='_blank'
        data={csvexportData}
        filename={props.filename}
      >
      </CSVLink>
    
    </div>
    
  );
}

export default ExportLayer;