import React from "react";
import MapArea from "../components/MapArea";
import AppLayout from "../components/AppLayout";
import { withAuth } from "@okta/okta-react";


function Map() {
  return (
    <AppLayout>
      <MapArea />    
    </AppLayout>
  );
}

export default withAuth(Map);
