import React, { useEffect, useContext, useState } from "react";
import { ccsFilterContext } from "../App";

import "ol/ol.css";
import { TileArcGISRest } from "ol/source";
import config from "../configs/appSettings";
import { Tile as TileLayer } from "ol/layer";

const ForestLayers = ({
  map,
  fnaddccsatlasLayerChange,
  groupLayerToggle,
  layerCollapsableChange,
  layerBlock,
  zoomItemText,
}) => {
  const FilterDetails = useContext(ccsFilterContext);
  let filtervalues = FilterDetails.ccsFilterStore;

  const loadForestData = () => {
    const forestLayer = new TileLayer({
      source: new TileArcGISRest({
        url: `${config.endpoints.forestLayerUrl}`,
      }),
      opacity: 0.75,
      title: "ForestBoundaries",
    });
    map.addLayer(forestLayer);
    forestLayer.setVisible(filtervalues.ForestBoundaries);

    const timberLayer = new TileLayer({
      minZoom: 9,
      source: new TileArcGISRest({
        url: `${config.endpoints.timberHarvestUrl}`,
        params: { layers: "Timber Harvest 2021 - Current" },
      }),
      opacity: 0.75,
      title: "TimberHarvests",
    });

    map.addLayer(timberLayer);
    timberLayer.setVisible(filtervalues.TimberHarvests);
  };
  useEffect(() => {
    loadForestData();
  }, []);

  return (
    <div>
      <ul
        className="datalist"
        style={{ display: layerBlock, /* marginTop: "15px" */ }}
      >
        <li>
          <span
            onClick={(e) => layerCollapsableChange(e)}
            className= {filtervalues.expand_ForestData ? 'collapseList layerActive' : 'collapseList'}
          >
            Forest Data
          </span>
          <div style={filtervalues.expand_ForestData ? {display: 'block'}: {display: 'none'}}>
            <div className="collapseGroup">
              <label className="arlas-checkbox">
                Forest Data
                <input
                  type="checkbox"
                  name="ForestData1"
                  id="Forest Data2"
                  onChange={(e) => groupLayerToggle(e)}
                />
                <span className="checkmark"></span>
              </label>

              <ul className="tgscollapse-panel" id="expand_ForestData">
                <li>
                  <label className="arlas-checkbox icon-text">
                    Administrative Forest Boundaries
                    <input
                      type="checkbox"
                      checked = {JSON.parse(localStorage.getItem('DefaultValues'))['ForestBoundaries']}
                      name="ForestBoundaries"
                      id="ForestBoundaries2"
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(e, "ForestBoundaries")
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="box" style={{backgroundColor: '#cee2b4', border: "2px solid #569030"}}></span>
                </li>
                <li>
                  <label
                    className="arlas-checkbox icon-text"
                    style={zoomItemText >= 10 ? {} : { opacity: "0.3" }}
                  >
                    Timber Harvests
                    <input
                      disabled={zoomItemText >= 10 ? false : true}
                      type="checkbox"
                      name="TimberHarvests"
                      id="TimberHarvests2"
                      onChange={(e) =>
                        fnaddccsatlasLayerChange(e, "TimberHarvests")
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                  <span className="box" style={{backgroundColor: 'rgb(128, 128, 128)'}}></span>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ForestLayers;
