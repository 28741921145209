import React from "react";

const MeasureDistance = () => {
  return (
    <div>
      <div className={"show-measure"} id="measuredistance">
        <div
          style={{
            border: "1px solid white",
            padding: "15px 15px 15px 15px",
            margin: "8px 8px 2px -4px",
          }}
        >
          <form
            className="form-inline"
            style={{
              color: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Measure type:
            <select id="type" defaultValue="None" style={{
              marginLeft: "23px",
              width: "9em",
            }}>
              <option value="None">None</option>
              <option value="length">Length (LineString)</option>
              <option value="area">Area (Polygon)</option>
            </select>
          </form>
        </div>
        <div
          style={{
            border: "1px solid white",
            padding: "15px 15px 15px 15px",
            margin: "2px 8px 15px -4px",
          }}
        >
          <form
            className="form-inline"
            style={{
              color: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Metric/ Imperial:
            <select
              id="units"
              style={{
                marginLeft: "23px",
                width: "9em",
              }}
              defaultValue="imperial"
            >
              <option value="imperial">Imperial</option>
              <option value="metric">Metric</option>
            </select>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MeasureDistance;
