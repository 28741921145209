import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";
import { withAuth } from "@okta/okta-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink /* CSVDownload */ } from "react-csv";
import { ccsFilterContext } from "../App";
import arrowClose from "../assets/images/icons/arrow-close.svg";
import arrowOpen from "../assets/images/icons/arrow-open.svg";
import config from "../configs/appSettings";
import MeasureDistance from "./MeasureDistance";
import PropertyOwnershipSearch from "./PropertyOwnershipSearch";

/* eslint-disable */

function MapInputPanel(props) {
  const FilterDetails = useContext(ccsFilterContext);
  const [panelState, setpanelState] = useState(true);
  const [depthSliderValue, setDepthSliderValue] = useState([0, 21000]);
  const [depthSliderValueMeter, setdepthSliderValueMeter] = useState([0, 6401]);

  const [volumeSliderValue, setVolumeSliderValue] = useState([0, 100]);
  const [poolSliderValue, setPoolSliderValue] = useState([0, 4800000]);
  const [poolSliderValueMeter, setpoolSliderValueMeter] = useState([0, 19425]);

  const [wellSliderValue, setWellSliderValue] = useState([0, 100]);
  const [tempSliderValue, setTempSliderValue] = useState([1, 500]);
  const [porositySliderValue, setPorositySliderValue] = useState([0.01, 0.3]);
  const [permeabilitySliderValue, setPermeabilitySliderValue] = useState([
    0.1, 500,
  ]);
  const [pressureSliderValue, setPressureSliderValue] = useState([1, 12000]);
  const [oilGravitySliderValue, setOilGravitySliderValue] = useState([0.1, 75]);
  const [gasChecked, setGasChecked] = useState(true);
  const [oilChecked, setOilChecked] = useState(true);
  const [csvexportData, setcsvexportData] = useState([]);
  const [csvexportDataSA, setcsvexportDataSA] = useState([]);
  const [measurePanelState, setMeasurePanelState] = useState(true);
  const [propertyPanelState, setpropertyPanelState] = useState(false);
  const [roleAccess, setRoleAccess] = useState({});
  let filtervalues = FilterDetails.ccsFilterStore;

  useEffect(() => {
    if (!props.isMeter) {
      setDepthSliderValue([
        filtervalues.depthGreaterthanValue,
        filtervalues.depthLessthanValue,
      ]);
      setPoolSliderValue([
        filtervalues.poolareaAcresGreaterValue,
        filtervalues.poolareaAcresLesserValue,
      ]);
    }
    if (props.isMeter) {
      setdepthSliderValueMeter([
        filtervalues.depthGreaterthanValue / 3.208,
        filtervalues.depthLessthanValue / 3.208,
      ]);
      setpoolSliderValueMeter([
        filtervalues.poolareaAcresGreaterValue / 247.105,
        filtervalues.poolareaAcresLesserValue / 247.105,
      ]);
    }
    setWellSliderValue([
      filtervalues.welldensityGreaterValue,
      filtervalues.welldensityLessthanValue,
    ]);
    setVolumeSliderValue([
      filtervalues.potentialstorageGreaterValue,
      filtervalues.potentialstorageLesserValue,
    ]);
    setTempSliderValue([
      filtervalues.temperatureGreaterthanValue,
      filtervalues.temperatureLessthanValue,
    ]);
    setPorositySliderValue([
      filtervalues.porosityGreaterthanValue,
      filtervalues.porosityLessthanValue,
    ]);
    setPermeabilitySliderValue([
      filtervalues.permeabilityGreaterthanValue,
      filtervalues.permeabilityLessthanValue,
    ]);
    setPressureSliderValue([
      filtervalues.pressureGreaterthanValue,
      filtervalues.pressureLessthanValue,
    ]);
    setOilGravitySliderValue([
      filtervalues.oilGravityGreaterthanValue,
      filtervalues.oilGravityLessthanValue,
    ]);
    FilterDetails.updatelayerVisibleStore(
      "applyResetEnable",
      filtervalues.applyResetEnable
    );
    if (filtervalues.pooltypeValue === "*") {
      setGasChecked(true);
      setOilChecked(true);
    } else if (filtervalues.pooltypeValue === "OIL") {
      setGasChecked(false);
      setOilChecked(true);
    } else if (filtervalues.pooltypeValue === "GAS") {
      setGasChecked(true);
      setOilChecked(false);
    }
  }, [filtervalues.applyEnable]);

  useEffect(() => {
    if (props.oktaaccessToken !== null) {
      const accessTokenDecoded = props.auth._oktaAuth.token.decode(
        props.oktaaccessToken
      );
      let roleaccessObj = {
        CA_EXPORT: accessTokenDecoded.payload.CA_EXPORT
          ? accessTokenDecoded.payload.CA_EXPORT
          : "",
      };
      setRoleAccess(roleaccessObj);
    }
  }, [props.oktaaccessToken]);

  const inputPanelToggle = (event) => {
    if (event.target.id === "measuredistance") {
      if (panelState === true) setpanelState(false);
      else if (propertyPanelState === true) setpropertyPanelState(false);
      setMeasurePanelState(!measurePanelState);
    } else if (event.target.id === "propertyownershipsearch") {
      setpanelState(false);
      setpropertyPanelState(!propertyPanelState);
      setMeasurePanelState(true);
    } else {
      setpanelState(!panelState);
      setpropertyPanelState(true);
      setMeasurePanelState(true);
    }
  };
  // let pooltypeArr = ["OIL", "GAS"];
  const applyCCSFilter = (e) => {
    e.preventDefault();
    FilterDetails.updatelayerVisibleStore("applyEnable", true);
    FilterDetails.updatelayerVisibleStore("applyResetEnable", true);
    FilterDetails.updateccsFilterStore(
      FilterDetails.ccsFilterStore.pooltypeValue,
      FilterDetails.ccsFilterStore.depthGreaterthanValue,
      FilterDetails.ccsFilterStore.depthLessthanValue,
      FilterDetails.ccsFilterStore.welldensityGreaterValue,
      FilterDetails.ccsFilterStore.welldensityLessthanValue,
      FilterDetails.ccsFilterStore.poolareaAcresGreaterValue,
      FilterDetails.ccsFilterStore.poolareaAcresLesserValue,
      FilterDetails.ccsFilterStore.potentialstorageGreaterValue,
      FilterDetails.ccsFilterStore.potentialstorageLesserValue,
      FilterDetails.ccsFilterStore.temperatureGreaterthanValue,
      FilterDetails.ccsFilterStore.temperatureLessthanValue,
      FilterDetails.ccsFilterStore.porosityGreaterthanValue,
      FilterDetails.ccsFilterStore.porosityLessthanValue,
      FilterDetails.ccsFilterStore.permeabilityGreaterthanValue,
      FilterDetails.ccsFilterStore.permeabilityLessthanValue,
      FilterDetails.ccsFilterStore.pressureGreaterthanValue,
      FilterDetails.ccsFilterStore.pressureLessthanValue,
      FilterDetails.ccsFilterStore.oilGravityGreaterthanValue,
      FilterDetails.ccsFilterStore.oilGravityLessthanValue,
      true
    );
  };

  useEffect(() => {
    let toExportdataSA = [];
    let countSA = 0;
    let tempuniquevalueSA = [];

    if (props.csvData.length === 0) countSA = 1;
    else {
      props.csvData.map((values) => {
        countSA = 1;
        for (let x in values) {
          let tempvalSA =
            values[x].values_.ogc_fid + " " + values[x].values_.pool_name;
          if (!tempuniquevalueSA.includes(tempvalSA)) {
            tempuniquevalueSA.push(tempvalSA);
            if (
              values[x].id_ 
            ) {
              toExportdataSA.push(values[x].values_);
            }
          }
        }
      });
    }

    if (countSA === 1) {
      setcsvexportDataSA(toExportdataSA);
    }
  }, [props.csvData]);

  useEffect(() => {
    let toExportdata = [];
    let count = 0;
    let tempuniquevalue = [];

    if (props.csvData.length === 0) count = 1;
    else {
      props.csvData.map((values) => {
        count = 1;
        for (let x in values) {
          let tempval =
            values[x].values_.objectid + " " + values[x].values_.strunt_nm;
          if (!tempuniquevalue.includes(tempval)) {
            tempuniquevalue.push(tempval);
            if (
              values[x].id_
            ) {
              toExportdata.push(values[x].values_);
            }
          }
        }
      });
    }

    if (count === 1) {
      setcsvexportData(toExportdata);
    }
  }, [props.csvData]);

  const resetCCSFilter = (e) => {
    e.preventDefault();
    setDepthSliderValue([0, 21000]);
    setVolumeSliderValue([0, 100]);
    setPoolSliderValue([0, 4800000]);
    setpoolSliderValueMeter([0, 19425]);
    setdepthSliderValueMeter([0, 6401]);
    setWellSliderValue([0, 100]);
    setTempSliderValue([1, 500]);
    setPorositySliderValue([0.01, 0.3]);
    setPermeabilitySliderValue([0.1, 500]);
    setPressureSliderValue([1, 12000]);
    setOilGravitySliderValue([0.1, 75]);
    let checkboxes = document.getElementsByName("poolType");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked === false) {
        checkboxes[i].click();
      }
    }
    FilterDetails.updatelayerVisibleStore("applyEnable", true);
    FilterDetails.updatelayerVisibleStore("applyResetEnable", false);
    FilterDetails.updateccsFilterStore(
      "*",
      0,
      21000,
      0,
      100,
      0,
      4800000,
      0,
      100,
      1,
      500,
      0.01,
      0.3,
      0.1,
      500,
      1,
      12000,
      0.1,
      75,
      true
    );
  };

  const handlepoolTypeChange = (event) => {
    if (event.target.defaultValue === "OIL") {
      // setOilChecked(event.target.checked);
      if (gasChecked === false && event.target.checked === false) {
        setOilChecked(true);
        setGasChecked(true);
      } else {
        setOilChecked(event.target.checked);
      }
    } else if (event.target.defaultValue === "GAS") {
      // setGasChecked(event.target.checked);
      if (oilChecked === false && event.target.checked === false) {
        setOilChecked(true);
        setGasChecked(true);
      } else {
        setGasChecked(event.target.checked);
      }
    }
    FilterDetails.updatelayerVisibleStore("filterApplied", true);
    let chkboxdetail = [];
    let chkboxUnchkd = [];
    let checkboxes = document.getElementsByName("poolType");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked === true) {
        chkboxdetail.push(checkboxes[i].checked);
      } else {
        chkboxUnchkd.push(checkboxes[i].checked);
      }
    }

    if (chkboxdetail.length === 2) {
      FilterDetails.updatelayerVisibleStore("pooltypeValue", "*");
    } else if (chkboxUnchkd.length === 2) {
      FilterDetails.updatelayerVisibleStore("pooltypeValue", "*");
    } else {
      checkboxes = document.getElementsByName("poolType");
      let bxValue = "";
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        if (checkboxes[i].checked === true) {
          chkboxdetail.push(checkboxes[i].checked);
          bxValue = checkboxes[i].value;
        }
      }

      FilterDetails.updatelayerVisibleStore("pooltypeValue", bxValue);
    }
  };

  const handleSliderChange = (_event, newValue, title) => {
    FilterDetails.updatelayerVisibleStore("filterApplied", true);
    if (title === "DepthSlider" && !props.isMeter) {
      setDepthSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "depthGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore("depthLessthanValue", newValue[1]);
    } else if (title === "DepthSliderMeter" && props.isMeter) {
      setdepthSliderValueMeter(newValue);
      FilterDetails.updatelayerVisibleStore(
        "depthGreaterthanValue",
        newValue[0] * 3.28
      );
      FilterDetails.updatelayerVisibleStore(
        "depthLessthanValue",
        newValue[1] * 3.28
      );
    } else if (title === "PotentialStorage") {
      setVolumeSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "potentialstorageGreaterValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "potentialstorageLesserValue",
        newValue[1]
      );
    } else if (title === "PoolAreaSlider" && !props.isMeter) {
      setPoolSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "poolareaAcresGreaterValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "poolareaAcresLesserValue",
        newValue[1]
      );
    } else if (title === "PoolAreaSliderMeter" && props.isMeter) {
      setpoolSliderValueMeter(newValue);
      FilterDetails.updatelayerVisibleStore(
        "poolareaAcresGreaterValue",
        newValue[0] * 247.105
      );
      FilterDetails.updatelayerVisibleStore(
        "poolareaAcresLesserValue",
        newValue[1] * 247.105
      );
    } else if (title === "WellSlider") {
      setWellSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "welldensityGreaterValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "welldensityLessthanValue",
        newValue[1]
      );
    } else if (title === "Temperature") {
      setTempSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "temperatureGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "temperatureLessthanValue",
        newValue[1]
      );
    } else if (title === "Porosity") {
      setPorositySliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "porosityGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "porosityLessthanValue",
        newValue[1]
      );
    } else if (title === "Permeability") {
      setPermeabilitySliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "permeabilityGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "permeabilityLessthanValue",
        newValue[1]
      );
    } else if (title === "Pressure") {
      setPressureSliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "pressureGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "pressureLessthanValue",
        newValue[1]
      );
    } else if (title === "OilGravity") {
      setOilGravitySliderValue(newValue);
      FilterDetails.updatelayerVisibleStore(
        "oilGravityGreaterthanValue",
        newValue[0]
      );
      FilterDetails.updatelayerVisibleStore(
        "oilGravityLessthanValue",
        newValue[1]
      );
    }
  };
  let fileNameVar = "CCS_AXIOM_" + moment().format(`yyyy/MM/DD`);

  let tgslayersActive;

  const layerCollapsableChange = (e) => {
    tgslayersActive = e.currentTarget;
    let activelayers = tgslayersActive.classList.contains("layerActive");
    if (tgslayersActive.nextSibling.id === "measureDis") {
      // if (
      //   document.getElementById("propertyOwnershipSearch").style.display ===
      //   "block"
      // )
      //   document.getElementById("propertyOwnershipSearch").style.display =
      //     "none";
      if (document.getElementById("ccsfilters").style.display === "block")
        document.getElementById("ccsfilters").style.display = "none";
      if (
        document
          .getElementById("ccsfilters")
          .previousSibling.classList.contains("layerActive")
      )
        document
          .getElementById("ccsfilters")
          .previousSibling.classList.remove("layerActive");
    } 
    // else if (tgslayersActive.nextSibling.id === "propertyOwnershipSearch") {
    //   if (document.getElementById("measureDis").style.display === "block")
    //     document.getElementById("measureDis").style.display = "none";
    //   if (
    //     document
    //       .getElementById("measureDis")
    //       .previousSibling.classList.contains("layerActive")
    //   )
    //     document
    //       .getElementById("measureDis")
    //       .previousSibling.classList.remove("layerActive");
    //   if (document.getElementById("ccsfilters").style.display === "block")
    //     document.getElementById("ccsfilters").style.display = "none";
    //   if (
    //     document
    //       .getElementById("ccsfilters")
    //       .previousSibling.classList.contains("layerActive")
    //   )
    //     document
    //       .getElementById("ccsfilters")
    //       .previousSibling.classList.remove("layerActive");
    // } 
    
    else if (tgslayersActive.nextSibling.id === "ccsfilters") {
      // if (
      //   document.getElementById("propertyOwnershipSearch").style.display ===
      //   "block"
      // )
      //   document.getElementById("propertyOwnershipSearch").style.display =
      //     "none";
      // if (
      //   document
      //     .getElementById("propertyOwnershipSearch")
      //     .previousSibling.classList.contains("layerActive")
      // )
      //   document
      //     .getElementById("propertyOwnershipSearch")
      //     .previousSibling.classList.remove("layerActive");

      if (document.getElementById("measureDis").style.display === "block")
        document.getElementById("measureDis").style.display = "none";
      if (
        document
          .getElementById("measureDis")
          .previousSibling.classList.contains("layerActive")
      )
        document
          .getElementById("measureDis")
          .previousSibling.classList.remove("layerActive");
    }
    if (activelayers === true) {
      let currlayers = e.currentTarget;
      currlayers.classList.remove("layerActive");
      currlayers.nextSibling.style.display = "none";
      // currlayers.nextSibling.nextSibling.style.display = "none";
    } else {
      let currlayers = e.currentTarget;
      currlayers.classList.add("layerActive");
      currlayers.nextSibling.style.display = "block";
      //currlayers.nextSibling.nextSibling.style.display = "block";
    }
  };
  // console.log(props.isSummaryOpen)
  // let elem =
  // console.log(document.getElementById("ccsAtlasbaseMap1").style)
  try {
    if (
      document.getElementById("ccsAtlasbaseMap1").style.display == "" ||
      document.getElementById("ccsAtlasbaseMap1").style.display == "none" ||
      document.getElementById("ccsAtlasbaseMap1").style.display == null ||
      document.getElementById("ccsAtlasbaseMap1").style.display == undefined
    ) {
      props.setIsSummaryOpen(false);
    }
  } catch {}

  const handleExport = (e) => {
    e.preventDefault();
    const exportId1 = document.getElementById("export_btn_tms");
    const exportId2 = document.getElementById("export_btn_sa");
    exportId1 && exportId1.click();
    exportId2 && exportId2.click();
  };

  return (
    <>
      <div className="arlas-cs-cross-section">
        <ul className="datalist">
          <li>
            <span
              onClick={(e) => layerCollapsableChange(e)}
              className="collapseFilterList"
            >
              Measurements
            </span>
            <div style={{ display: "none" }} id="measureDis">
              <div className="collapseGroup" style={{ marginTop: "10px" }}>
                <ul className="tgscollapse-panel">
                  <li>
                    <MeasureDistance
                      measurePanelState={measurePanelState}
                      inputPanelToggle={inputPanelToggle}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        <ul className="datalist">
          <li>
            <span
              onClick={(e) => layerCollapsableChange(e)}
              className="collapseFilterList"
            >
              Storage Unit Filters
            </span>
            <div style={{ display: "none" }} id="ccsfilters">
              <div
                className="collapseGroup"
                style={{ marginTop: "10px", height: "396px" }}
              >
                <ul className="tgscollapse-panel">
                  <li>
                    <div className={"form-section"} id="ccsfilters">
                      <div>
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "8px",
                          }}
                        >
                          <Box my={-1} textAlign="left">
                            <label
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Pool HC Type :
                            </label>

                            <FormControlLabel
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                marginBottom: "0.5rem",
                                marginLeft: "0px",
                              }}
                              className="form-label"
                              fontFamily="DINRegular"
                              size="large"
                              control={
                                <Checkbox
                                  name="poolType"
                                  checked={oilChecked}
                                  onChange={handlepoolTypeChange}
                                  value="OIL"
                                ></Checkbox>
                              }
                              label="Oil"
                            />
                            <FormControlLabel
                              htmlFor="handleIncrement"
                              style={{ color: "white", marginBottom: "0.5rem" }}
                              className="form-label"
                              control={
                                <Checkbox
                                  name="poolType"
                                  checked={gasChecked}
                                  onChange={handlepoolTypeChange}
                                  value="GAS"
                                ></Checkbox>
                              }
                              label="Gas"
                            />
                          </Box>
                        </div>
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={-1} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Depth {props.isMeter ? "(m)" : "(ft)"}:
                            </label>
                          </Box>
                          {props.isMeter ? (
                            <Box my={-1}>
                              <label
                                htmlFor="handleIncrement"
                                style={{ color: "white", width: "40px" }}
                                className="form-label"
                              >
                                0
                              </label>
                              <Slider
                                min={0}
                                max={6401}
                                step={1}
                                value={depthSliderValueMeter}
                                style={{
                                  width: "180px",
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                onChange={(e, newVal) =>
                                  handleSliderChange(
                                    e,
                                    newVal,
                                    "DepthSliderMeter"
                                  )
                                }
                              />
                              <label
                                htmlFor="handleIncrement"
                                style={{
                                  color: "white",
                                  width: "50px",
                                  marginLeft: "20px",
                                }}
                                className="form-label"
                              >
                                6401
                              </label>
                            </Box>
                          ) : (
                            <Box my={-1}>
                              <label
                                htmlFor="handleIncrement"
                                style={{ color: "white", width: "40px" }}
                                className="form-label"
                              >
                                0
                              </label>
                              <Slider
                                min={0}
                                max={21000}
                                step={1}
                                value={depthSliderValue}
                                style={{
                                  width: "180px",
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                }}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                onChange={(e, newVal) =>
                                  handleSliderChange(e, newVal, "DepthSlider")
                                }
                              />
                              <label
                                htmlFor="handleIncrement"
                                style={{
                                  color: "white",
                                  width: "50px",
                                  marginLeft: "20px",
                                }}
                                className="form-label"
                              >
                                21,000
                              </label>
                            </Box>
                          )}
                        </div>
                        {/* <Box m={1}>
           
            </Box> */}

                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={-1} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Pool Area {props.isMeter ? "(Sqkm)" : "(Acres)"}:
                            </label>
                          </Box>
                          {!props.isMeter ? (
                            <>
                              <Box mt={-1} id="pooareaBox">
                                <label
                                  htmlFor="handleIncrement"
                                  style={{ color: "white", width: "40px" }}
                                  className="form-label"
                                >
                                  0
                                </label>
                                <Slider
                                  min={0}
                                  max={4800000}
                                  step={5000}
                                  value={poolSliderValue}
                                  //valueLabelFormat={lowHigh}
                                  aria-labelledby="range-slider"
                                  valueLabelDisplay="auto"
                                  style={{
                                    width: "180px",
                                    cursor: "pointer",
                                    top: "5px",
                                    position: "relative",
                                    marginLeft: "0px",
                                  }}
                                  onChange={(e, newVal) =>
                                    handleSliderChange(
                                      e,
                                      newVal,
                                      "PoolAreaSlider"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="handleIncrement"
                                  style={{
                                    color: "white",
                                    width: "50px",
                                    marginLeft: "20px",
                                  }}
                                  className="form-label"
                                >
                                  4,800,000
                                </label>
                              </Box>
                            </>
                          ) : (
                            <Box mt={-1} id="pooareaBox">
                              <label
                                htmlFor="handleIncrement"
                                style={{ color: "white", width: "40px" }}
                                className="form-label"
                              >
                                0
                              </label>
                              <Slider
                                min={0}
                                max={19425}
                                step={1000}
                                value={poolSliderValueMeter}
                                //valueLabelFormat={lowHigh}
                                aria-labelledby="range-slider"
                                valueLabelDisplay="auto"
                                style={{
                                  width: "180px",
                                  cursor: "pointer",
                                  top: "5px",
                                  position: "relative",
                                  marginLeft: "0px",
                                }}
                                onChange={(e, newVal) =>
                                  handleSliderChange(
                                    e,
                                    newVal,
                                    "PoolAreaSliderMeter"
                                  )
                                }
                              />
                              <label
                                htmlFor="handleIncrement"
                                style={{
                                  color: "white",
                                  width: "50px",
                                  marginLeft: "20px",
                                }}
                                className="form-label"
                              >
                                19,425
                              </label>
                            </Box>
                          )}
                        </div>
                        {/* <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={0} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Well Density (Producers):
                            </label>
                          </Box>
                          <Box mt={-1} id="wellareaBox">
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              0
                            </label>
                            <Slider
                              min={0}
                              max={100}
                              step={1}
                              value={wellSliderValue}
                              //valueLabelFormat={lowHigh}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                                marginLeft: "0px",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "WellSlider")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              100
                            </label>
                          </Box>
                        </div> */}
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={-1} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Temperature (° F):
                            </label>
                          </Box>
                          <Box
                            mt={-1}
                            // ml={1}
                          >
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              1
                            </label>
                            <Slider
                              min={1}
                              max={500}
                              step={50}
                              value={tempSliderValue}
                              valueLabelDisplay="auto"
                              aria-label="Medium"
                              //aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "Temperature")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              500+
                            </label>
                          </Box>
                        </div>
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={0} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Porosity (fraction):
                            </label>
                          </Box>
                          <Box mt={-1} id="wellareaBox">
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              0.01
                            </label>
                            <Slider
                              min={0.01}
                              max={0.3}
                              step={0.05}
                              value={porositySliderValue}
                              //valueLabelFormat={lowHigh}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                                marginLeft: "0px",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "Porosity")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              0.3+
                            </label>
                          </Box>
                        </div>
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={0} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Permeability (mD):
                            </label>
                          </Box>
                          <Box mt={-1} id="wellareaBox">
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              0.1
                            </label>
                            <Slider
                              min={0.1}
                              max={500}
                              step={50}
                              value={permeabilitySliderValue}
                              //valueLabelFormat={lowHigh}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                                marginLeft: "0px",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "Permeability")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              500+
                            </label>
                          </Box>
                        </div>
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={0} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Pressure (psia):
                            </label>
                          </Box>
                          <Box mt={-1} id="wellareaBox">
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              1
                            </label>
                            <Slider
                              min={1}
                              max={12000}
                              step={500}
                              value={pressureSliderValue}
                              //valueLabelFormat={lowHigh}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                                marginLeft: "0px",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "Pressure")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              12,000+
                            </label>
                          </Box>
                        </div>
                        {/* <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={0} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                             Oil Gravity (°API)
                            </label>
                          </Box>
                          <Box mt={-1} id="wellareaBox">
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              0.1
                            </label>
                            <Slider
                              min={0.1}
                              max={75}
                              step={1}
                              value={oilGravitySliderValue}
                              //valueLabelFormat={lowHigh}
                              valueLabelDisplay="auto"
                              aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                                marginLeft: "0px",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(e, newVal, "OilGravity")
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              75+
                            </label>
                          </Box>
                        </div>                       */}
                        <div
                          style={{
                            border: "solid 1px white",
                            position: "relative",
                            padding: "10px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            height: "80px",
                          }}
                        >
                          <Box mt={-1} mb={1.5}>
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white" }}
                              className="form-label"
                            >
                              Potential Storage Volume (Mt):
                            </label>
                          </Box>
                          <Box
                            mt={-1}
                            // ml={1}
                          >
                            <label
                              htmlFor="handleIncrement"
                              style={{ color: "white", width: "40px" }}
                              className="form-label"
                            >
                              0
                            </label>
                            <Slider
                              min={0}
                              max={100}
                              step={10}
                              value={volumeSliderValue}
                              valueLabelDisplay="auto"
                              aria-label="Medium"
                              //aria-labelledby="range-slider"
                              style={{
                                width: "180px",
                                cursor: "pointer",
                                top: "5px",
                                position: "relative",
                              }}
                              onChange={(e, newVal) =>
                                handleSliderChange(
                                  e,
                                  newVal,
                                  "PotentialStorage"
                                )
                              }
                            />
                            <label
                              htmlFor="handleIncrement"
                              style={{
                                color: "white",
                                width: "50px",
                                marginLeft: "20px",
                              }}
                              className="form-label"
                            >
                              100+
                            </label>
                          </Box>
                        </div>
                      </div>

                      <form id="welldata">
                        <div className="row-group">
                          <button
                            id="generatecrosssection"
                            className="arlas-cs-btn primary"
                            onClick={applyCCSFilter}
                            disabled={
                              !FilterDetails.ccsFilterStore.filterApplied
                            }
                            style={{ marginRight: "10px", marginBottom: "6px" }}
                          >
                            Filter
                          </button>

                          {!FilterDetails.ccsFilterStore.applyResetEnable && (
                            <button
                              id="generatecrosssection1"
                              className="arlas-cs-btn primary"
                              onClick={resetCCSFilter}
                              style={{
                                marginRight: "10px",
                                marginBottom: "6px",
                              }}
                              disabled
                            >
                              Reset
                            </button>
                          )}

                          {FilterDetails.ccsFilterStore.applyResetEnable && (
                            <button
                              id="generatecrosssection1"
                              className="arlas-cs-btn primary"
                              onClick={resetCCSFilter}
                              style={{ marginRight: "10px" }}
                            >
                              Reset
                            </button>
                          )}

                          <div
                            id="generatecrosssection1"
                            className="arlas-cs-btn a"
                            style={{
                              marginRight: "-19px",
                              marginLeft: "-19px",
                              paddingTop: "0px",
                            }}
                          >
                            <button
                              id="generatecrosssection1"
                              className="arlas-cs-btn primary"
                              style={{ color: "white" }}
                              disabled={!roleAccess.CA_EXPORT}
                              onClick={(e) => handleExport(e)}
                            >
                              Export
                            </button>
                          </div>

                          <div
                            id="generatecrosssection1"
                            className="arlas-cs-btn a"
                            style={{
                              marginRight: "-19px",
                              marginLeft: "-19px",
                              paddingTop: "0px",
                              display: "none",
                            }}
                          >
                            {csvexportData.length > 0 && (
                              <button
                                className="arlas-cs-btn primary"
                                style={{ color: "white" }}
                                disabled={!roleAccess.CA_EXPORT}
                              >
                                <CSVLink
                                  id="export_btn_tms"
                                  headers={config.staticInformation.headers}
                                  data={csvexportData}
                                  filename="Gulf Coast EGB-TMS Storage Units"
                                  style={{
                                    color: "white",
                                  }}
                                />
                              </button>
                            )}

                            {csvexportDataSA.length > 0 && (
                              <button
                                className="arlas-cs-btn primary"
                                style={{ color: "white", display: "none" }}
                                disabled={!roleAccess.CA_EXPORT}
                              >
                                <CSVLink
                                  id="export_btn_sa"
                                  headers={config.staticInformation.headers_SA}
                                  data={csvexportDataSA}
                                  filename="Southern AR Storage Units"
                                  style={{
                                    color: "white",
                                  }}
                                />
                              </button>
                            )}
                          </div>

                          <div></div>
                        </div>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
        {/* <ul
          className="datalist testdelete"
          // style={{ display: layerBlock, marginTop: "15px" }}
        >
          <li>
            <span
              onClick={(e) => layerCollapsableChange(e)}
              className="collapseFilterList"
            >
              Property Ownership Search
            </span>
            <div style={{ display: "none" }} id="propertyOwnershipSearch">
              <div className="collapseGroup" style={{ marginTop: "10px" }}>
                <ul className="tgscollapse-panel">
                  <li>
                    <PropertyOwnershipSearch
                      propertyPanelState={propertyPanelState}
                      setpropertyPanelState={setpropertyPanelState}
                      panelState={panelState}
                      inputPanelToggle={inputPanelToggle}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul> */}
      </div>

      <div
        style={{ display: "none" }}
        id="relatedProperties"
        className="relatedProperties"
      >
        <div
          className="summarycardcontainer"
          style={
            props.infoData.layerObject.layerArrow === arrowClose &&
            props.isSummaryOpen === false
              ? {
                  //closed and none
                  left: "1%",
                  bottom: "1%",
                  display: "flex",
                  flexFlow: "column wrap",
                  opacity: "0.9",
                  position: "absolute",
                  zIndex: "2",
                  background: "white",
                  fontSize: "inherit",
                  fontFamily: "DINRegular",
                  overflowY: "auto",
                  overflowX: "auto",
                  width: "320px",
                  paddingLeft: "24px",
                  // paddingTop: "10px",
                  color: "#00aaab",
                }
              : props.infoData.layerObject.layerArrow === arrowOpen &&
                props.isSummaryOpen === true
              ? {
                  left: "43%",
                  bottom: "1%",
                  display: "flex",
                  flexFlow: "column wrap",
                  opacity: "0.9",
                  position: "absolute",
                  zIndex: "2",
                  background: "white",
                  fontSize: "inherit",
                  fontFamily: "DINRegular",
                  overflowY: "auto",
                  overflowX: "auto",
                  width: "320px",
                  paddingLeft: "24px",
                  // paddingTop: "10px",
                  color: "#00aaab",
                }
              : props.infoData.layerObject.layerArrow === arrowOpen &&
                props.isSummaryOpen === false
              ? {
                  left: "24%",
                  bottom: "1%",
                  display: "flex",
                  flexFlow: "column wrap",
                  opacity: "0.9",
                  position: "absolute",
                  zIndex: "2",
                  background: "white",
                  fontSize: "inherit",
                  fontFamily: "DINRegular",
                  overflowY: "auto",
                  overflowX: "auto",
                  width: "320px",
                  paddingLeft: "24px",
                  // paddingTop: "10px",
                  color: "#00aaab",
                }
              : props.infoData.layerObject.layerArrow === arrowClose &&
                props.isSummaryOpen === true
              ? {
                  left: "20.5%",
                  bottom: "1%",
                  display: "flex",
                  flexFlow: "column wrap",
                  opacity: "0.9",
                  position: "absolute",
                  zIndex: "2",
                  background: "white",
                  fontSize: "inherit",
                  fontFamily: "DINRegular",
                  overflowY: "auto",
                  overflowX: "auto",
                  width: "320px",
                  paddingLeft: "24px",
                  // paddingTop: "10px",
                  color: "#00aaab",
                }
              : {}
          }
        >
          <div className="summarycardcontent">
            Related Properties
            <span className="textspan"></span>
            <span
              id="closeCard"
              style={{
                position: "absolute",
                right: "30px",
                top: "9px",
                color: "#00aaab",
                width: "25px",
                textAlign: "right",
                paddingLeft: "83px",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={() => {
                document.getElementById("relatedProperties").style.display =
                  "none";
              }}
            >
              X
            </span>
            <div id="results" style={{ height: "150px" }}>
              {" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withAuth(MapInputPanel);
